import { ToastContainer } from 'react-toastify';
import { Routing } from '../pages';
import { Modals } from '../processes';
import { MuiThemeProvider } from './providers/MuiThemeProvider';
import { RouterProvider } from './providers/RouterProvider';
import { StoreProvider } from './providers/StoreProvider';
import { TranslationsProvider } from './providers/TranslationsProvider';

export const App = () => {
  return (
    <RouterProvider>
      <StoreProvider>
        <MuiThemeProvider>
          <TranslationsProvider>
            <Modals>
              {/* <PreloadProject> */}
              <Routing />
              <ToastContainer
                position="top-center"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="dark"
              />
              {/* </PreloadProject> */}
            </Modals>
          </TranslationsProvider>
        </MuiThemeProvider>
      </StoreProvider>
    </RouterProvider>
  );
};
