import { LatLng, LatLngLiteral } from 'leaflet';
import { selectAppLoading, setAppLoading, setUserLocationPoint } from 'shared/slices';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'shared/hooks';
import { useMap } from 'react-leaflet';

/**
 * @module useFindLocation
 * Hook to handle find user location
 */
export const useFindLocation = () => {
  const dispatch = useAppDispatch();
  const map = useMap();
  const loading = selectAppLoading();

  const successFunc = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    const coords = { lat: latitude, lng: longitude } as LatLng;
    dispatch(setUserLocationPoint(coords));
    map.flyTo(coords as LatLngLiteral, map.getZoom(), { animate: false });
    dispatch(setAppLoading(false));
  };

  const errorFunc = (err: GeolocationPositionError) => {
    dispatch(setAppLoading(false));
    toast.error(`Geolocation: ${err.message}`);
  };

  const options = {
    maximumAge: Infinity,
    timeout: 10000,
    enableHighAccuracy: true,
  };

  const handleFindUser = () => {
    dispatch(setAppLoading(true));
    window.navigator.geolocation.getCurrentPosition(successFunc, errorFunc, options);
  };

  const checkPermissionGrant = async () => {
    const result = await navigator.permissions.query({ name: 'geolocation' });
    if (result.state === 'granted') {
      return true;
    } else if (result.state === 'prompt') {
      return true;
    }
    return false;
  };

  return { handleFindUser, checkPermissionGrant, loading };
};
