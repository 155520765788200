import axios, { InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { NO_CONNECTION_MESSAGE } from 'shared/constants';
import { isOnline } from 'shared/lib';
import { setIsPolygonInfo } from 'shared/slices';
import { farmApi } from '../api/FarmApi';
import { localStorageService } from '../services';

export const axiosBase = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PUBLIC_API_URL : '/',
});

export const logoutUser = () => {
  localStorageService.removeRapidUsername();
  localStorageService.removeRapidToken();
  localStorageService.removeFarmToken();
  window.location.href = '/common/login';
};

axiosBase.interceptors.request.use(
  (config) => {
    if (!isOnline()) {
      return Promise.reject(new Error(NO_CONNECTION_MESSAGE));
      // return Promise.reject({ message: NO_CONNECTION_MESSAGE });
    }

    const authToken = localStorageService.rapidToken;

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    if (config.url?.includes('/mapmycrop-api')) {
      const accessToken = localStorageService.farmToken;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * refetch access refresh tokens for api.farmboundary.com if no exists
 */
axiosBase.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & { _retry: boolean };

    if (originalRequest?.url?.includes('/mapmycrop-api')) {
      if (error.response && error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await farmApi.login();
          const newAccessToken = response.data?.access_token;
          localStorageService.farmToken = newAccessToken;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosBase(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }

    if (error.response && error.response.status === 401) {
      logoutUser();
    }

    if (error instanceof Error) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  metadata?: {
    handleOffline?: () => void;
  };
}

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config: ExtendedAxiosRequestConfig) => {
    if (!isOnline()) {
      toast.error(NO_CONNECTION_MESSAGE);
      return Promise.reject({ message: NO_CONNECTION_MESSAGE });
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const store = require('../../app/store').default;

    const source = axios.CancelToken.source();
    config.cancelToken = source.token;

    const handleOffline = () => {
      source.cancel(NO_CONNECTION_MESSAGE);
      store.dispatch(setIsPolygonInfo(false));
    };

    window.addEventListener('offline', handleOffline);

    config.metadata = { handleOffline };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const originalRequest = response.config as ExtendedAxiosRequestConfig & { _retry: boolean };
    if (originalRequest.metadata && originalRequest.metadata.handleOffline) {
      window.removeEventListener('offline', originalRequest.metadata.handleOffline);
    }

    if (!isOnline()) {
      return Promise.reject({ message: NO_CONNECTION_MESSAGE });
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config as ExtendedAxiosRequestConfig & { _retry: boolean };

    if (originalRequest && originalRequest.metadata && originalRequest.metadata.handleOffline) {
      window.removeEventListener('offline', originalRequest.metadata.handleOffline);
    }

    if (axios.isCancel(error)) {
      return Promise.reject({ message: NO_CONNECTION_MESSAGE });
    }

    if (error instanceof Error) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
