import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const GoBackIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_2989_33)">
      <path
        d="M18.8567 10.5777H7.89283L10.5223 7.95966C10.7376 7.74438 10.8586 7.45239 10.8586 7.14794C10.8586 6.84349 10.7376 6.5515 10.5223 6.33622C10.3071 6.12094 10.0151 6 9.71062 6C9.40617 6 9.11418 6.12094 8.8989 6.33622L4.32585 10.9093C4.22176 11.018 4.14017 11.1462 4.08576 11.2866C3.97141 11.5649 3.97141 11.8771 4.08576 12.1554C4.14017 12.2958 4.22176 12.424 4.32585 12.5327L8.8989 17.1058C9.00519 17.2129 9.13163 17.298 9.27095 17.356C9.41027 17.4141 9.5597 17.4439 9.71062 17.4439C9.86155 17.4439 10.011 17.4141 10.1503 17.356C10.2896 17.298 10.4161 17.2129 10.5223 17.1058C10.6295 16.9995 10.7145 16.873 10.7726 16.7337C10.8306 16.5944 10.8605 16.445 10.8605 16.2941C10.8605 16.1431 10.8306 15.9937 10.7726 15.8544C10.7145 15.7151 10.6295 15.5886 10.5223 15.4823L7.89283 12.8643H18.8567C19.1599 12.8643 19.4507 12.7438 19.6651 12.5294C19.8796 12.315 20 12.0242 20 11.721C20 11.4178 19.8796 11.127 19.6651 10.9126C19.4507 10.6982 19.1599 10.5777 18.8567 10.5777Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2989_33">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
