import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Icon, Row } from '../../shared/ui';
import { useAppDispatch } from '../../shared/hooks';
import { MAP_LAYER } from '../../shared/enums';
import { selectIsMapTileLoading, selectMap, selectMapLayer, setMapLayer } from '../../shared/slices';
import { useTranslation } from 'react-i18next';

interface TextProps {
  green?: boolean;
}

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'green',
})<TextProps>(({ theme, green }) => ({
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '16px',
  textAlign: 'center',
  marginTop: '5px',
  color: green ? theme.palette.primary.main : theme.palette.info.light,
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const LayersMenu: FC<Props> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const mapLayer = selectMapLayer();
  const isMapTileLoading = selectIsMapTileLoading();

  const LAYERS = useMemo(
    () => [
      { title: t('layers_component_normal_map'), icon: '_normal_map', value: 'roadmap' as MAP_LAYER },
      { title: t('layers_component_satellite'), icon: '_satellite', value: 'hybrid' as MAP_LAYER },
      { title: t('layers_component_terrain'), icon: '_terrain', value: 'terrain' as MAP_LAYER },
    ],
    [t, i18n.language]
  );

  const onChangeLayer = (layer: MAP_LAYER) => {
    !isMapTileLoading && dispatch(setMapLayer(layer));
  };

  return (
    <>
      {open && (
        <Stack
          onClick={onClose}
          alignItems="center"
          sx={{
            position: 'fixed',
            zIndex: 500,
            top: 0,
            left: 0,
            height: '100svh',
            width: '100vw',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            px: '12px',
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{
              width: { xs: '100%', sm: 'fit-content' },
              bgcolor: 'info.main',
              borderRadius: '5px',
              p: '5px 7px',
              mt: '70px',
            }}
          >
            <Row height="80px" alignItems="flex-start">
              {LAYERS.map(({ title, icon, value }, i) => (
                <Button
                  disabled={isMapTileLoading}
                  key={title}
                  onClick={() => onChangeLayer(value)}
                  sx={{
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%',
                    borderRadius: 0,
                    borderRight: i !== LAYERS.length - 1 ? '1px solid gray' : '',
                    borderColor: 'info.light',
                  }}
                >
                  <Stack alignItems="center" justifyContent={'flex-start'} width="70px">
                    <Icon
                      path={`mapLayers/${mapLayer === value ? 'green' : 'grey'}${icon}`}
                      sx={{ width: '50px', height: '45px' }}
                    />
                    <Text green={mapLayer === value} textTransform="capitalize">
                      {title}
                    </Text>
                  </Stack>
                </Button>
              ))}
            </Row>

            {/* <Box sx={{ height: '1px', width: '100%', bgcolor: 'info.light', my: '8px' }} />

          <Row height="61px">
            <Stack alignItems="center">
              <Icon path="mapLayers/distances" sx={{ width: '28px' }} />
              <Text green>Distances</Text>
            </Stack>
            <Border />

            <Stack alignItems="center">
              <Icon path="mapLayers/areas" />
              <Text>Areas</Text>
            </Stack>
            <Border />

            <Stack alignItems="center">
              <Icon path="mapLayers/poi" />
              <Text>POI</Text>
            </Stack>
            <Border />

            <Stack alignItems="center">
              <Icon path="mapLayers/groups" />
              <Text green>Groups</Text>
            </Stack>
          </Row> */}
          </Box>
        </Stack>
      )}
    </>
  );
};
