import { selectIsGpsTrackingStarted, setGpsTrackingState } from 'shared/slices';
import { useAppDispatch } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { Button, ContinueMeasurementIcon, PauseMeasurementIcon, Row, StopMeasurementIcon } from 'shared/ui';
import { Typography } from '@mui/material';
import { useGpsPlayStop } from 'shared/hooks/useGpsPlayStop';

export const TrackingPlayStopButtons = () => {
  const isGpsTrackingStarted = selectIsGpsTrackingStarted();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { startGpsTracking, stopGpsTracking } = useGpsPlayStop();

  const gpsPlayMode = isGpsTrackingStarted ? 1 : 0;

  const PlayModeIcon = !isGpsTrackingStarted ? ContinueMeasurementIcon : PauseMeasurementIcon;

  const pauseGpsTracking = () => dispatch(setGpsTrackingState(false));

  const gpsPlayModeConfig = {
    0: {
      label: t('continue'),
      callback: startGpsTracking,
    },
    1: {
      label: t('pause'),
      callback: pauseGpsTracking,
    },
  };

  return (
    <Row justifyContent={'space-between'}>
      <Button sx={{ border: 'none' }}>
        <Typography
          sx={{ color: 'info.main', cursor: 'pointer', fontSize: '18px', lineHeight: '21px' }}
          onClick={gpsPlayModeConfig[gpsPlayMode].callback}
        >
          <PlayModeIcon />
          {gpsPlayModeConfig[gpsPlayMode].label}
        </Typography>
      </Button>
      <Button sx={{ border: 'none' }}>
        <Typography
          sx={{ color: 'info.main', cursor: 'pointer', fontSize: '18px', lineHeight: '21px' }}
          onClick={stopGpsTracking}
        >
          <StopMeasurementIcon />
          {t('stop')}
        </Typography>
      </Button>
    </Row>
  );
};
