import { blue } from '@mui/material/colors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLngLiteral } from 'leaflet';
import { v4 as makeId } from 'uuid';
import { LINE, NEW_LINE_ID, NEW_POLYGON_ID, POLYGON_ROW_DIRECTION_ID } from '../../constants';
import { useAppSelector } from '../../hooks';
import { checkIfPointExistsInItem, findEastBoundaryPoint, getPolygonCenter } from '../../lib';
import {
  Action,
  ACTION_TYPE,
  Image,
  LineInformation,
  LineModel,
  LinePoint,
  LineStyles,
  Point,
  PolygonModel,
} from '../../models';
import { localStorageService } from '../../services';
import {
  clearItemPoints,
  deleteItemScreenshot,
  editItem,
  saveItem,
  setItemScreenshot,
  setNewItemPoints,
  stopItemDrawing,
  unselectItems,
} from '../mapState';
import { setProject } from '../projectData';
import { Nullable } from 'shared';
import { cancelPolygonDrawing } from '../polygons';

export const newLineTemplate: LineModel = {
  geoJson: { id: NEW_LINE_ID, points: [], color: blue[500], dashArray: ['0'], weight: 2 },
  images: [],
  information: { description: '', name: '', isIrrigationProperties: true },
  properties: {
    lineTotalLength: 0,
    lineTotalLengthUnit: 'm',
    lineCopySettingsFromId: null,
    lineSections: [
      {
        sectionId: makeId(),
        linePipeDiameterUnit: localStorageService.units?.PipeDiameter || 'cm',
        lineLengthUnit: localStorageService.units?.Length || 'm',
        lineMaxVelocityUnit: localStorageService.units?.Velocity || 'm/s',
        lineFlowRateUnit: localStorageService.units?.TotalFlow || 'lpm',
        linePipeType: 7,
        linePipeTypeText: '',
        linePipeDiameter: 0,
        linePipeClass: '',
        lineLength: 0,
        lineMaxVelocity: 0,
        lineSlope: 0,
        lineFlowRate: 0,
      },
    ],
  },
};

export type RowDirectionData = {
  relatedPolygon: PolygonModel;
  isPolygonDrawing: boolean;
  isPolygonEditing: boolean;
};

interface LineState {
  isLineDrawing: boolean;
  newLine: LineModel;
  lines: LineModel[];
  selectedLineId: Nullable<string>;
  initialPoints: LinePoint[];
  initialStyles: LineStyles;
  currentLinePointId: string | null;
  basePointId: Nullable<string>;
  currentTotalLengthInMeters: number;
  rowDirectionData?: RowDirectionData;
  lineActionHistory: Action[];
}

const initialState: LineState = {
  isLineDrawing: false,
  newLine: newLineTemplate,
  lines: [],
  selectedLineId: null,
  initialPoints: [],
  initialStyles: { color: blue[500], dashArray: ['0'], weight: 2 },
  currentLinePointId: null,
  basePointId: null,
  currentTotalLengthInMeters: 0,
  lineActionHistory: [],
};

export const lineSlice = createSlice({
  name: 'lines',
  initialState,
  reducers: {
    setLines(state, action: PayloadAction<LineModel[]>) {
      state.lines = action.payload;
    },
    startLineDrawing(state) {
      state.isLineDrawing = true;
      state.selectedLineId = NEW_LINE_ID;
    },
    saveLine(state, action: PayloadAction<LineInformation>) {
      const {
        lineName: name,
        lineDescription: description,
        isIrrigationProperties,
        images,
        ...properties
      } = action.payload;
      if (state.selectedLineId === NEW_LINE_ID) {
        const newId = makeId();

        state.lines = [
          ...state.lines,
          {
            geoJson: { ...state.newLine.geoJson, id: newId },
            information: { name, description, isIrrigationProperties },
            properties,
            images,
          },
        ];

        state.newLine.geoJson.points = [];
        state.selectedLineId = newId;
      } else {
        const line = state.lines.find((item) => item.geoJson.id === state.selectedLineId) as LineModel;

        line.information = { name, description, isIrrigationProperties };
        line.properties = properties;
        line.images = images;
      }
    },
    cancelLineDrawing(state) {
      if (state.selectedLineId === NEW_LINE_ID) {
        state.newLine.geoJson.points = [];
      } else {
        const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;

        if (line) {
          line.geoJson.points = state.initialPoints;
          const { color, dashArray, weight } = state.initialStyles;

          line.geoJson = { ...line.geoJson, color, dashArray, weight };
        }
      }
    },
    addPolygonRowDirectionLine(state, action: PayloadAction<RowDirectionData>) {
      const { relatedPolygon } = action.payload;

      state.rowDirectionData = action.payload;

      const lineId = `${relatedPolygon.geoJson.id}${POLYGON_ROW_DIRECTION_ID}`;
      const polygonRowDirectionLineExists = state.lines.find((l) => l.geoJson.id === lineId);

      if (!polygonRowDirectionLineExists) {
        const polygonBoundary = relatedPolygon.geoJson.points.map((p) => p.coords);
        const polygonCenter = getPolygonCenter(polygonBoundary);
        const polygonSouthBoundaryPoint = findEastBoundaryPoint(polygonCenter, relatedPolygon.geoJson.points);

        const newLine: LineModel = {
          ...newLineTemplate,
          geoJson: {
            id: lineId,
            points: [
              { id: makeId(), coords: polygonCenter },
              { id: makeId(), coords: polygonSouthBoundaryPoint },
            ],
            color: 'black',
            dashArray: ['10', 'endArrowheads'],
            weight: 3,
          },
          information: { ...newLineTemplate.information, name: `${relatedPolygon.information.name} - Row Direction` },
        };

        state.lines.push(newLine);
      }
      state.isLineDrawing = true;
      state.selectedLineId = lineId;
    },
    updatePolygonRowDirectionLine(state, action: PayloadAction<{ newPoint: LatLngLiteral }>) {
      const { newPoint } = action.payload;
      const line = state.lines.find(
        (l) => l.geoJson.id === state.selectedLineId && l.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID)
      );

      if (line) {
        const pointId = line.geoJson.points[1].id;
        line.geoJson.points[1] = { id: pointId, coords: newPoint };
      }
    },
    updatePolygonRowDirectionLineFromAngle(state, action: PayloadAction<{ angle: number; polygon: PolygonModel }>) {
      const { angle, polygon } = action.payload;
      const lineId = `${polygon.geoJson.id}${POLYGON_ROW_DIRECTION_ID}`;
      const line = state.lines.find((l) => l.geoJson.id === lineId);

      if (line) {
        const polygonBoundary = polygon.geoJson.points.map((p) => p.coords);
        const centerPoint = getPolygonCenter(polygonBoundary);

        const distance = Math.sqrt(
          Math.pow(line.geoJson.points[1].coords.lat - centerPoint.lat, 2) +
            Math.pow(line.geoJson.points[1].coords.lng - centerPoint.lng, 2)
        );

        const radianAngle = (angle * Math.PI) / 180;
        const newLat = centerPoint.lat + distance * Math.sin(radianAngle);
        const newLng = centerPoint.lng + distance * Math.cos(radianAngle);
        const newPoint = {
          lat: newLat,
          lng: newLng,
        };

        line.geoJson.points[0].coords = centerPoint;
        line.geoJson.points[1].coords = newPoint;
      }
    },
    stopPolygonRowDirectionLineDrawing(state, action: PayloadAction<RowDirectionData>) {
      state.selectedLineId = null;
      state.isLineDrawing = false;
      delete state.rowDirectionData;
    },
    deleteLine(state) {
      state.lines = state.lines.filter((p) => p.geoJson.id !== state.selectedLineId);

      state.selectedLineId = null;
    },
    selectLine(state, action: PayloadAction<Nullable<string>>) {
      const id = action.payload;

      if (!state.isLineDrawing) {
        const shouldSelectLine = state.selectedLineId !== id;
        state.selectedLineId = shouldSelectLine ? id : null;

        if (shouldSelectLine) {
          // set the last line point as base point
          const newSelectedLinePoints = state.lines.find((l) => l.geoJson.id === id)?.geoJson.points;
          const basePoint = newSelectedLinePoints?.[newSelectedLinePoints?.length - 1];
          state.basePointId = basePoint?.id ?? null;
        } else {
          state.basePointId = null;
        }
      }
    },
    setCurrentLinePointId(state, action: PayloadAction<string | null>) {
      const pointId = action.payload;
      state.basePointId = pointId;

      if (state.currentLinePointId === pointId) {
        state.currentLinePointId = null;
      } else {
        state.currentLinePointId = pointId;
      }
    },
    setBaseLinePointId(state) {
      state.basePointId = state.currentLinePointId;
    },
    addNewLinePoint(state, action: PayloadAction<Point>) {
      const point = action.payload;
      if (state.currentLinePointId) state.currentLinePointId = point.id;

      if (state.selectedLineId === NEW_LINE_ID) {
        const currentPoints = state.newLine.geoJson.points.map((p) => ({ lat: p.coords.lat, lng: p.coords.lng }));
        const isPointExists = checkIfPointExistsInItem(point.coords, currentPoints);
        if (isPointExists) return;

        const isPointSimple =
          state.newLine.geoJson.points.filter((p) => !p.parentPointId).at(-1)?.id === state.basePointId;

        if (!state.basePointId || isPointSimple) {
          state.newLine.geoJson.points.push(point);
        } else {
          state.newLine.geoJson.points.push({ ...point, parentPointId: state.basePointId });
        }

        state.basePointId = point.id;

        const addAction = { typeName: ACTION_TYPE.ADD, point };
        state.lineActionHistory.push(addAction);
        return;
      }
      const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;

      const isPointSimple = line.geoJson.points.filter((p) => !p.parentPointId).at(-1)?.id === state.basePointId;

      if (!state.basePointId || isPointSimple) {
        line.geoJson.points.push(point);
      } else {
        line.geoJson.points.push({ ...point, parentPointId: state.basePointId });
      }

      state.basePointId = point.id;

      const addAction = { typeName: ACTION_TYPE.ADD, point };
      state.lineActionHistory.push(addAction);
    },
    setNewLinePoints(state, action: PayloadAction<Point[]>) {
      state.newLine.geoJson.points = action.payload;
    },
    dragLinePoint(state, action: PayloadAction<LatLngLiteral>) {
      const newPos = action.payload;

      if (state.selectedLineId === NEW_LINE_ID) {
        state.newLine.geoJson.points = state.newLine.geoJson.points.map((point) =>
          point.id === state.currentLinePointId ? { ...point, coords: newPos } : point
        );
        return;
      }

      const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
      const newPoints: Point[] = line.geoJson.points.map((point) =>
        point.id === state.currentLinePointId ? { ...point, coords: newPos } : point
      );
      state.lines = state.lines.map((p) =>
        p.geoJson.id === state.selectedLineId ? { ...p, geoJson: { ...line.geoJson, points: newPoints } } : p
      );
    },
    saveDragLinePointAction(state, action: PayloadAction<LatLngLiteral>) {
      const id = state.currentLinePointId as string;
      const coords = action.payload;
      const point: Point = { id, coords };

      const moveAction = { typeName: ACTION_TYPE.MOVE, point };
      state.lineActionHistory.push(moveAction);
    },
    deleteLinePoint(state, action: PayloadAction<string>) {
      const pointId = action.payload;

      let point: LinePoint, index: number;
      const connectedPointIds: string[] = [];

      // reset current point to null
      if (state.currentLinePointId === pointId) state.currentLinePointId = null;

      if (state.selectedLineId === NEW_LINE_ID) {
        point = state.newLine.geoJson.points.find((point) => point.id === pointId) as Point;
        index = state.newLine.geoJson.points.findIndex((point) => point.id === pointId);

        connectedPointIds.push(...findConnectedPointIds(point, state.newLine.geoJson.points));
      } else {
        const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
        point = line.geoJson.points.find((point) => point.id === pointId) as Point;
        index = line.geoJson.points.findIndex((point) => point.id === pointId);

        connectedPointIds.push(...findConnectedPointIds(point, line.geoJson.points));
      }
      const delAction = { typeName: ACTION_TYPE.DELETE, point, index };
      state.lineActionHistory.push(delAction);

      if (state.selectedLineId === NEW_LINE_ID) {
        state.newLine.geoJson.points = state.newLine.geoJson.points
          .filter((point) => point.id !== pointId)
          .filter((point) => !connectedPointIds.includes(point.id));

        const previousPoint = state.newLine.geoJson.points.at(-1);
        if (previousPoint) {
          state.basePointId = previousPoint.id;
        }
        return;
      }

      const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
      const newPoints: Point[] = line.geoJson.points
        .filter((point) => point.id !== pointId)
        .filter((point) => !connectedPointIds.includes(point.id));

      const previousPoint = newPoints.at(-1);
      if (previousPoint) {
        state.basePointId = previousPoint.id;
      }

      state.lines = state.lines.map((p) =>
        p.geoJson.id === state.selectedLineId ? { ...p, geoJson: { ...line.geoJson, points: newPoints } } : p
      );
    },
    undoLineAction(state) {
      if (!state.lineActionHistory.length) return;
      state.currentLinePointId = null;

      const { typeName, point, index } = state.lineActionHistory.pop() as Action;

      switch (typeName) {
        case ACTION_TYPE.ADD:
          {
            if (state.selectedLineId === NEW_LINE_ID) {
              state.newLine.geoJson.points = state.newLine.geoJson.points.filter((p) => p.id !== point.id);
              state.basePointId = state.newLine.geoJson.points.at(-1)?.id;
              return;
            }

            const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
            const newPoints: Point[] = line.geoJson.points.filter((p) => p.id !== point.id);

            state.lines = state.lines.map((p) =>
              p.geoJson.id === state.selectedLineId
                ? { ...p, geoJson: { ...p.geoJson, id: line.geoJson.id, points: newPoints } }
                : p
            );
            state.basePointId = newPoints.at(-1)?.id;
          }
          break;

        case ACTION_TYPE.DELETE:
          {
            if (state.selectedLineId === NEW_LINE_ID) {
              let updatedPoints = [];
              const before = state.newLine.geoJson.points.slice(0, index);
              const after = state.newLine.geoJson.points.slice(index);
              updatedPoints = [...before, point, ...after];

              state.newLine.geoJson.points = updatedPoints;
              return;
            }

            const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;

            let updatedPoints: Point[] = [];
            const before = line.geoJson.points.slice(0, index);
            const after = line.geoJson.points.slice(index);

            updatedPoints = [...before, point, ...after];

            state.lines = state.lines.map((p) =>
              p.geoJson.id === state.selectedLineId ? { ...p, geoJson: { ...p.geoJson, points: updatedPoints } } : p
            );
          }
          break;

        case ACTION_TYPE.MOVE:
          {
            const { id, coords } = point;

            if (state.selectedLineId === NEW_LINE_ID) {
              state.newLine.geoJson.points = state.newLine.geoJson.points.map((point) =>
                point.id === id ? { id, coords } : point
              );
              return;
            }

            const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
            const newPoints: Point[] = line.geoJson.points.map((point) => (point.id === id ? { id, coords } : point));

            state.lines = state.lines.map((p) =>
              p.geoJson.id === state.selectedLineId
                ? { ...p, geoJson: { ...p.geoJson, id: line.geoJson.id, points: newPoints } }
                : p
            );
          }
          break;
      }
    },
    setLineStyles(state, action: PayloadAction<Partial<LineStyles>>) {
      if (state.selectedLineId === NEW_LINE_ID) {
        state.newLine.geoJson = { ...state.newLine.geoJson, ...action.payload };
        return;
      }

      const line = state.lines.find((l) => l.geoJson.id === state.selectedLineId) as LineModel;
      line.geoJson = { ...line.geoJson, ...action.payload };
    },
    setLineScreenshot(state, action: PayloadAction<Image>) {
      const screenShot = action.payload;

      const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
      if (line) {
        const isHaveScreenShot = line?.images.find((img) => img.isSnapShot);

        if (isHaveScreenShot) {
          line.images = line.images.map((img) => (img.isSnapShot ? screenShot : img));
        } else {
          line.images = [screenShot, ...line.images];
        }
      }
    },
    setCurrentTotalLength(state, action: PayloadAction<number>) {
      state.currentTotalLengthInMeters = action.payload;
    },
  },
  selectors: {
    selectLinesState: (state) => state,
    selectLines: (state) => state.lines,
    selectLineId: (state) => state.selectedLineId,
    selectLineById: (state, id: string) => state.lines.find((line) => line.geoJson.id === id),
    selectIsLineDrawing: (state) => state.isLineDrawing,
    selectNewLine: (state) => state.newLine,
    selectCurrentLinePointId: (state) => state.currentLinePointId,
    selectCurrentTotalLengthInMeters: (state) => state.currentTotalLengthInMeters,
    selectLineActionHistory: (state) => state.lineActionHistory,
    selectRowDirectiondata: (state) => state.rowDirectionData,
    selectBasePointId: (state) => state.basePointId,
  },
  extraReducers: (builder) => {
    builder.addCase(setProject, () => {
      return initialState;
    });
    builder.addCase(editItem, (state) => {
      if (!state.selectedLineId) return;
      state.isLineDrawing = true;

      const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;
      const { color, dashArray, weight, points } = line.geoJson;

      if (line) {
        state.initialPoints = points;
        state.initialStyles = { color, dashArray, weight };
      }
    });
    builder.addCase(saveItem, (state, action) => {
      if (action.payload.itemType !== 'line') return;

      const {
        lineName: name,
        lineDescription: description,
        isIrrigationProperties,
        images,
        ...properties
      } = action.payload.information as LineInformation;
      if (action.payload.itemId === NEW_LINE_ID) {
        const newId = makeId();

        state.lines.push({
          geoJson: { ...state.newLine.geoJson, id: newId },
          information: { name, description, isIrrigationProperties },
          properties,
          images,
        });

        state.newLine.geoJson.points = [];
        state.selectedLineId = newId;
      } else {
        const line = state.lines.find((item) => item.geoJson.id === action.payload.itemId) as LineModel;

        line.information = { name, description, isIrrigationProperties };
        line.properties = properties;
        line.images = images;
      }
    });
    builder.addCase(unselectItems, (state) => {
      state.selectedLineId = null;
    });
    builder.addCase(stopItemDrawing, (state) => {
      state.isLineDrawing = false;
      state.selectedLineId = null;
      state.currentLinePointId = null;
      state.basePointId = null;
      state.lineActionHistory = [];
      state.initialPoints = [];
    });
    builder.addCase(deleteItemScreenshot, (state) => {
      const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;

      if (!line) return;

      line.images = line.images.filter((img) => !img.isSnapShot);
    });
    builder.addCase(setItemScreenshot, (state, action) => {
      const { images, isNewItem, item } = action.payload;
      if (!isNewItem && item === LINE) {
        const screenShot = images.pop();

        const line = state.lines.find((p) => p.geoJson.id === state.selectedLineId) as LineModel;

        if (line && screenShot) {
          const isHaveScreenShot = line?.images.find((img) => img.isSnapShot);

          if (isHaveScreenShot) {
            line.images = line.images.map((img) => (img.isSnapShot ? screenShot : img));
          } else {
            line.images = [screenShot, ...line.images];
          }
        }
      }
    });
    builder.addCase(clearItemPoints, (state, action) => {
      const itemId = action.payload;

      if (itemId === NEW_LINE_ID) {
        state.newLine.geoJson.points = [];
        return;
      }

      const line = state.lines.find((l) => l.geoJson.id === itemId);

      if (!line) return;

      line.geoJson.points = [];
    });
    builder.addCase(setNewItemPoints, (state, action) => {
      if (state.selectedLineId === NEW_LINE_ID) {
        state.newLine.geoJson.points = action.payload;
        return;
      }

      const line = state.lines.find((l) => l.geoJson.id === state.selectedLineId);

      if (!line) return;

      line.geoJson.points = action.payload;
    });
    builder.addCase(cancelPolygonDrawing, (state) => {
      const newLineId = `${NEW_POLYGON_ID}${POLYGON_ROW_DIRECTION_ID}`;
      state.lines = state.lines.filter((l) => l.geoJson.id !== newLineId);
    });
  },
});

export const {
  startLineDrawing,
  saveLine,
  cancelLineDrawing,
  deleteLine,
  selectLine,
  setCurrentLinePointId,
  setBaseLinePointId,
  addNewLinePoint,
  dragLinePoint,
  saveDragLinePointAction,
  deleteLinePoint,
  undoLineAction,
  setLines,
  setLineStyles,
  setLineScreenshot,
  setCurrentTotalLength,
  setNewLinePoints,
  addPolygonRowDirectionLine,
  updatePolygonRowDirectionLine,
  updatePolygonRowDirectionLineFromAngle,
  stopPolygonRowDirectionLineDrawing,
} = lineSlice.actions;

export const findConnectedPointIds = (basePoint: LinePoint, points: LinePoint[]): string[] => {
  const connectedPoints: string[] = [];

  const nonLinearPoints = points.filter((p) => p.parentPointId);

  const findConnections = (basePoint: LinePoint) => {
    nonLinearPoints.forEach((p) => {
      if (p.parentPointId === basePoint.id) {
        connectedPoints.push(p.id);

        findConnections(p);
      }
    });
  };

  findConnections(basePoint);

  return connectedPoints;
};

export const selectLineActionHistory = () => useAppSelector(lineSlice.selectors.selectLineActionHistory);
export const selectLinesState = () => useAppSelector(lineSlice.selectors.selectLinesState);
export const selectLines = () => useAppSelector(lineSlice.selectors.selectLines);
export const selectLineId = () => useAppSelector(lineSlice.selectors.selectLineId);
export const selectCurrentLinePointId = () => useAppSelector(lineSlice.selectors.selectCurrentLinePointId);
export const selectCurrentTotalLengthInMeters = () =>
  useAppSelector(lineSlice.selectors.selectCurrentTotalLengthInMeters);
export const selectIsLineDrawing = () => useAppSelector(lineSlice.selectors.selectIsLineDrawing);
export const selectNewLine = () => useAppSelector(lineSlice.selectors.selectNewLine);
export const selectRowDirectiondata = () => useAppSelector(lineSlice.selectors.selectRowDirectiondata);
export const selectBasePointId = () => useAppSelector(lineSlice.selectors.selectBasePointId);
export const selectLineById = (id: string) =>
  useAppSelector((state) => {
    return id === NEW_LINE_ID ? newLineTemplate : lineSlice.selectors.selectLineById(state, id);
  });

export const lineReducer = lineSlice.reducer;
