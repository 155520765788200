import { Box, FormControlLabel, FormControlLabelProps, Radio, RadioGroup, styled, useMediaQuery } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '../Row';

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.info.dark,
  px: '5px',
  ml: '15px',
  '&.Mui-checked': {
    color: theme.palette.info.main,
  },
}));

const StyledLabel: FC<FormControlLabelProps> = ({ value, checked, control, label, sx }) => {
  const isMobile = useMediaQuery('(max-width:400px)');

  return (
    <FormControlLabel
      value={value}
      control={control}
      label={label}
      checked={checked}
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: isMobile ? '40%' : '146px',

        borderRadius: '9px',
        margin: 0,
        marginBottom: '5px',
        justifyContent: isMobile ? 'flex-start' : 'center',
        padding: '0 10px',
        height: '33px',
        border: '1px solid grey',
        boxShadow: '0px 3px 5px 1px rgba(0, 0, 0, 0.3)',
        bgcolor: checked ? 'primary.main' : 'info.main',
        borderColor: checked ? 'primary.main' : 'info.dark',
        overflow: 'hidden',
        ...sx,
        '& .MuiTypography-root': {
          color: checked ? 'info.main' : 'info.dark',
          fontSize: '16px',
          lineHeight: '16px',
          fontWeight: 400,
          marginLeft: '9px',
          overflow: 'hidden',

          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        span: {
          padding: '4px 0px',
        },
      }}
    />
  );
};

interface Props {
  trueFlag: boolean;
  onChange: () => void;
}

export const RadioSwitcher: FC<Props> = ({ onChange, trueFlag }) => {
  const { t, i18n } = useTranslation();

  const INTEGRATED_SWITCHER_VALUES = useMemo(() => [t('forms_integrated'), t('forms_on_line')], [t, i18n.language]);

  return (
    <Box pb="2px">
      <RadioGroup onChange={onChange} row sx={{ flexWrap: 'nowrap' }}>
        <Row flexWrap="nowrap" width="100%">
          <StyledLabel
            value={INTEGRATED_SWITCHER_VALUES[0]}
            label={INTEGRATED_SWITCHER_VALUES[0]}
            checked={trueFlag}
            control={<StyledRadio size="small" />}
            sx={{ mb: '5px', mr: '10px', width: '50%' }}
          />
          <StyledLabel
            value={INTEGRATED_SWITCHER_VALUES[1]}
            label={INTEGRATED_SWITCHER_VALUES[1]}
            checked={!trueFlag}
            control={<StyledRadio size="small" />}
            sx={{ width: '50%' }}
          />
        </Row>
      </RadioGroup>
    </Box>
  );
};
