import { FC, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LinePipeSection } from '../../entities';
import { useAppDispatch } from 'shared/hooks';
import { v4 as makeId } from 'uuid';
import { selectFormState, selectMasterGr, selectUnits, setLineInformation } from 'shared/slices';
import { Icon, Text } from 'shared/ui';

export const LinePipeSectionProperties: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { lineInformation } = selectFormState();
  const { masterGroups } = selectMasterGr();
  const { units } = selectUnits();

  useEffect(() => {
    if (lineInformation.isIrrigationProperties && !lineInformation.lineSections.length) {
      onAddLineSection();
    }
  }, [lineInformation.isIrrigationProperties]);

  const onAddLineSection = () => {
    const linePipeType = 7;
    const linePipeTypeText = masterGroups.find((m) => m.ID === linePipeType)?.MASTERGROUPNAME ?? '';

    const lineSection = {
      sectionId: makeId(),
      linePipeDiameterUnit: units.PipeDiameter,
      lineLengthUnit: units.Length,
      lineMaxVelocityUnit: units.Velocity,
      lineFlowRateUnit: units.Flow,
      linePipeType,
      linePipeTypeText,
      linePipeDiameter: 0,
      linePipeClass: '',
      lineLength: 0,
      lineMaxVelocity: 0,
      lineSlope: 0,
      lineFlowRate: 0,
    };

    dispatch(
      setLineInformation({
        lineSections: [...lineInformation.lineSections, lineSection],
        lineCopySettingsFromId: null,
      })
    );
  };
  const onDeleteLineSection = (id: string) => {
    const lineSections = lineInformation.lineSections.filter(({ sectionId }) => sectionId !== id);
    dispatch(setLineInformation({ lineSections, lineCopySettingsFromId: null }));
  };

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      {lineInformation.lineSections?.map((ls, i) => (
        <LinePipeSection
          key={ls.sectionId}
          index={i + 1}
          value={ls}
          onDelete={() => onDeleteLineSection(ls.sectionId)}
          linePipeSections={lineInformation.lineSections}
        />
      ))}

      <Box mt={2}>
        <Button
          onClick={onAddLineSection}
          fullWidth
          sx={{ bgcolor: 'rgba(233, 243, 237, 1)', height: '45px', borderRadius: 0, justifyContent: 'flex-start' }}
        >
          <Icon path="plus" />
          <Text
            sx={{ fontSize: '18px', fontWeight: 700, lineHeight: '45px', textTransform: 'capitalize', mb: 0, ml: 1 }}
          >
            {t('forms_add_section')}
          </Text>
        </Button>
      </Box>
    </Box>
  );
};
