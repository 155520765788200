import { FC } from 'react';
import { useAnchorEl, useAppDispatch } from 'shared/hooks';
import {
  selectCurrentMapItem,
  selectIsColorPicker,
  selectPointId,
  setPointStyles,
  toggleColorPicker,
} from 'shared/slices';
import { ColorPickSnackbar, ColorsPanel } from 'shared/ui';
import { List, ListItemButton, Popover, Typography } from '@mui/material';
import { PointModel } from 'shared/models';
import DoneIcon from '@mui/icons-material/Done';
import { blue } from '@mui/material/colors';

const pointIconSizes = [2, 1];

export const PointColorPicker: FC = () => {
  const dispatch = useAppDispatch();

  const { open, anchorEl, onCloseMenu } = useAnchorEl();

  const openedPoint = selectCurrentMapItem() as PointModel;
  const selectedPointId = selectPointId();
  const isColorPicker = selectIsColorPicker();

  const color = openedPoint?.geoJson.color ?? blue[500];
  const size = openedPoint?.geoJson.size ?? 1;

  const handleClose = () => dispatch(toggleColorPicker());
  const onSelectPointColor = (color: string) => dispatch(setPointStyles({ color }));
  const onSelectPointSize = (size: number) => dispatch(setPointStyles({ size }));

  return (
    <>
      <ColorPickSnackbar open={isColorPicker && !!selectedPointId} onClose={handleClose}>
        <>
          <ColorsPanel color={color} onSelect={onSelectPointColor} />

          {/*  <Row justifyContent="space-around" sx={{ px: 2, pt: 1 }}>
            <Row onClick={onOpenMenu} alignItems="center" sx={{ cursor: 'pointer' }}>
              <Icon path="/mapIcons/point_size" />

              <Typography sx={{ ml: 0.5, fontSize: '14px', lineHeight: '25px' }}>Size: {size}</Typography>
            </Row>
          </Row> */}
        </>
      </ColorPickSnackbar>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: -40,
          horizontal: 'center',
        }}
        sx={{
          zIndex: 1401,
          '& .MuiPaper-root': {
            borderRadius: '10px',
          },
        }}
      >
        <List>
          {pointIconSizes.map((s) => (
            <ListItemButton
              key={s}
              onClick={() => onSelectPointSize(s)}
              sx={{
                py: 0,
                bgcolor: size === s ? '#0C8CE9' : 'white',
                '&:hover': {
                  bgcolor: size === s ? '#0C8CE9' : 'white',
                },
              }}
            >
              <DoneIcon sx={{ color: size === s ? 'black' : 'white' }} />

              <Typography sx={{ ml: 1 }}>{s}</Typography>
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
};
