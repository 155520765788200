import { Box, Collapse, Grid, ListItemText, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import getCenter from '@turf/center-of-mass';
import { points as getPoints } from '@turf/helpers';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from 'shared/axios';
import { importLocales, INITIAL_ROW_DIRECTION_ARROW_ROTATION, LINE, UNITS } from 'shared/constants';
import { useAppDispatch } from 'shared/hooks';
import { useChangeUnit } from 'shared/hooks/useChangeUnit';
import { ConvertUnit, getRelatedPolygonId, isOnline, Math_round } from 'shared/lib';
import { CatalogItem, PolygonInformation, PolygonModel, PolygonProperties } from 'shared/models';
import {
  addPolygonRowDirectionLine,
  selectCurrentMapItem,
  selectFormState,
  selectIsItemDrawing,
  selectIsItemEditing,
  selectPolygons,
  selectSelectedItem,
  setIsRowDirectionForm,
} from 'shared/slices';
import { AdditionalSelect, CheckBox, FreeSoloInput, Icon, PolygonInput, Select } from 'shared/ui';
import { handleApiError } from '../../shared/lib/errorHandling';
import { PolygonProductProperties } from '../PolygonProductProperties';
import { MannaIcon } from './MannaIcon';

dayjs.extend(localeData);

interface Props {
  value: PolygonInformation;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUnit: (value: number, unit: string, fieldName: keyof PolygonProperties) => void;
  onChangePlantingDate: (val: string | null) => void;
  onChangeCopySettingsFrom: (e: any, item: { id: number | string; name: string } | string) => void;
  onChangeCropType: (e: any, item: { id: number | string; name: string } | string) => void;
  onChangeDailyConsumption: (value: number, shouldResetCopySettingsFrom?: boolean) => void;
  onChangePolygonSoilType: (event: SelectChangeEvent<string[]>) => void;
  onToggleSelectProduct: () => void;
  onTogglePolygonSoilType: () => void;
  saveEmitter: (item: CatalogItem) => void;
  saveLateral: (item: CatalogItem) => void;
  copySettingsFromObject: string | null;
}

export const PolygonIrrigationProperties: FC<Props> = ({
  value,
  onChange,
  onChangeUnit,
  onChangePlantingDate,
  onChangeCopySettingsFrom,
  onChangeCropType,
  onToggleSelectProduct,
  onTogglePolygonSoilType,
  onChangePolygonSoilType,
  onChangeDailyConsumption,
  saveEmitter,
  saveLateral,
  copySettingsFromObject,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const currentItemType = selectSelectedItem();
  const polygons = selectPolygons();
  const currentMapItem = selectCurrentMapItem();

  const isPolygonDrawing = selectIsItemDrawing();
  const isPolygonEditing = selectIsItemEditing();

  const polygon = useMemo(() => {
    // if row direction line is selected, get the related polygon
    if (currentItemType === LINE && currentMapItem) {
      const polygonId = getRelatedPolygonId(currentMapItem.geoJson.id);
      const currentPolygon = polygons.find((p) => p.geoJson.id === polygonId);
      if (currentPolygon) return currentPolygon;
    }

    return currentMapItem as PolygonModel;
  }, [currentMapItem]);

  const copySettingsFromOptions = polygons
    .filter((polygon) => polygon.information.isIrrigationProperties)
    .filter((polygon) => !polygon.properties.polygonCopySettingsFromId) // get only parent items
    .map((polygon) => ({
      id: polygon.geoJson.id,
      name: polygon.information.name,
    }));

  const [showManna, setShowManna] = useState(false);
  const [cropTypes, setCropTypes] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [cropTypesLoading, setCropTypesLoading] = useState(false);
  const [defaultDCLoading, setDefaultDCLoading] = useState(false);
  const [defaultDCValue, setDefaultDCValue] = useState(0);

  const { polygonFormState } = selectFormState();

  const isNoTouched = !!polygonFormState.validationCount;
  const isPolygonCropTypeError = isNoTouched && !polygonFormState.isValidPolygonCropType;
  const isPolygonDailyConsumptionError = isNoTouched && !polygonFormState.isValidPolygonDailyConsumption;
  const isPolygonIrrigationHoursError = isNoTouched && !polygonFormState.isValidPolygonIrrigationHours;
  const isPolygonRowSpacingError = isNoTouched && !polygonFormState.isValidPolygonRowSpacing;
  const isPolygonPlantSpacingError = isNoTouched && !polygonFormState.isValidPolygonPlantSpacing;
  const isPolygonPlantingDateError = isNoTouched && !polygonFormState.isValidPolygonPlantingDate;

  const rotationAngle = INITIAL_ROW_DIRECTION_ARROW_ROTATION - (value.polygonRowDirection ?? 0);

  useEffect(() => {
    const getCropTypes = async () => {
      if (!isOnline()) {
        return;
      }

      try {
        setCropTypesLoading(true);

        const { data } = await axiosInstance.get<{ result: { id: number; name: string }[] }>(
          'https://express.manna-api.com/wcadi/crops'
        );

        const formattedCropTypes = data.result
          .map((ct) => ({ ...ct, name: t(ct.name) }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setCropTypes(formattedCropTypes);
      } catch (e) {
        handleApiError(e);
      } finally {
        setCropTypesLoading(false);
      }
    };

    getCropTypes();
  }, []);

  const getDefaultDailyConsumptionValue = async () => {
    const isCropTypeExist = cropTypes.find((ct) => ct.name === value.polygonCropType);

    if (!polygon || !isCropTypeExist || !value.polygonPlantingDate) return;

    const points = polygon.geoJson.points;
    const calcPoints = points.map((p) => [p.coords.lat, p.coords.lng]);
    const [lat, lng] = getCenter(getPoints(calcPoints)).geometry.coordinates;

    if (!isOnline()) {
      return;
    }

    try {
      setDefaultDCLoading(true);

      const formattedDate = value.polygonPlantingDate.split('T').at(0);
      const cropId = cropTypes.find((ct) => ct.name === value.polygonCropType)?.id;

      const { data } = await axiosInstance.get<{ maxEtc: number }>(
        `https://express.manna-api.com/wcadi/etc/${lat}/${lng}/${cropId}/${formattedDate}`
      );

      const currentDCUnits = value.polygonDailyConsumptionUnit; // value is PolygonInfo
      let dCValue = Math_round(data.maxEtc, 3);

      // we get response data in mm, so we need to convert it to a proper units
      if (currentDCUnits !== 'mm') {
        dCValue = Math_round(ConvertUnit(data.maxEtc, 'mm', currentDCUnits, null), 3);
      }

      onChangeDailyConsumption(dCValue, false);
      setDefaultDCValue(dCValue);
    } catch (e) {
      handleApiError(e);
    } finally {
      setShowManna(true);
      setDefaultDCLoading(false);
    }
  };

  useEffect(() => {
    if (!value.isIrrigationProperties) return;

    getDefaultDailyConsumptionValue();
  }, [value.isIrrigationProperties, value.polygonCropType, value.polygonPlantingDate]);

  const onCopySettingsFromBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const settings = copySettingsFromOptions.find((csf) => csf.name === value);

    if (settings) {
      onChangeCopySettingsFrom(null, settings);
    }
  };

  const onCropTypeBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cropType = cropTypes.find((ct) => ct.name === value);

    onChangeCropType(null, value);

    if (cropType) {
      onChangeCropType(null, cropType);
      getDefaultDailyConsumptionValue();
      return;
    }

    setShowManna(false);
  };

  const handleChangeDailyConsumption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    onChangeDailyConsumption(value);

    if (value === defaultDCValue) {
      setShowManna(true);
      return;
    }

    setShowManna(false);
  };

  const [locale, setLocale] = useState('en');

  useEffect(() => {
    const mapper = new Map([
      ['en-us', 'en'],
      ['no', 'nb'],
    ]);
    const fix = (l: string): string => (mapper.has(l) ? mapper.get(l)! : l);

    if (!isOnline()) {
      return;
    }

    const contains = (l: string): boolean => Object.keys(importLocales).includes(l);

    const load = async (locale: string) => {
      if (locale === 'en') return;

      await importLocales[locale as keyof typeof importLocales]().then(() => dayjs.locale(locale));

      setLocale(locale);
    };

    const myLocales = navigator.languages;

    myLocales.some((originalLocale) => {
      const fixedLocale = fix(originalLocale.toLowerCase());

      if (contains(fixedLocale)) {
        load(fixedLocale);
        return true;
      }

      const [langTag] = originalLocale.split('-');

      if (contains(langTag)) {
        const fixedLangTag = fix(langTag);
        load(fixedLangTag);
        return true;
      }

      return false;
    });
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  const unitRefs: Record<string, React.RefObject<HTMLInputElement>> = {
    polygonDailyConsumption: useRef(null),
    polygonRowSpacing: useRef(null),
    polygonPlantSpacing: useRef(null),
  };

  const { handleChangeUnit } = useChangeUnit(onChangeUnit, unitRefs);

  const soilTypes = useMemo(
    () => [
      { label: t('polygon_soil_type_gravel'), value: 'Gravel' },
      { label: t('polygon_soil_type_sand'), value: 'Sand' },
      { label: t('polygon_soil_type_silt'), value: 'Silt' },
      { label: t('polygon_soil_type_clay'), value: 'Clay' },
    ],
    [t, i18n.language]
  );

  const openRowDirectionForm = () => {
    dispatch(setIsRowDirectionForm(true));
    dispatch(addPolygonRowDirectionLine({ relatedPolygon: polygon, isPolygonDrawing, isPolygonEditing }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Box>
        {(copySettingsFromOptions.length > 1 ||
          (copySettingsFromOptions.length === 1 && copySettingsFromOptions[0].id !== polygon.geoJson.id)) && (
          <FreeSoloInput
            options={copySettingsFromOptions}
            name="polygonCopySettingsFrom"
            value={copySettingsFromObject ?? ''}
            onBlur={onCopySettingsFromBlur}
            onChange={onChangeCopySettingsFrom}
            placeholder={t('forms_area_name')}
            label={t('forms_copy_settings_from')}
          />
        )}

        <FreeSoloInput
          loading={cropTypesLoading}
          options={cropTypes}
          name="polygonCropType"
          value={value.polygonCropType ?? ''}
          onBlur={onCropTypeBlur}
          onChange={onChangeCropType}
          helperText={isPolygonCropTypeError ? t('validation_errors_null') : undefined}
          error={isPolygonCropTypeError}
          label={t('forms_crop_type')}
        />

        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <DesktopDatePicker
              inputFormat={'MMM D, YYYY'}
              value={dayjs(value.polygonPlantingDate)}
              onChange={(newValue) =>
                onChangePlantingDate(dayjs(newValue).isValid() ? dayjs(newValue).toISOString() : null)
              }
              renderInput={(params) => (
                <PolygonInput
                  {...params}
                  label={t('forms_planting_date')}
                  type="number"
                  helperText={t('validation_errors_incorrect_date')}
                  error={isPolygonPlantingDateError}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <PolygonInput
              inputRef={unitRefs.polygonDailyConsumption}
              label={t('forms_daily_consumption')}
              placeholder="Enter"
              name="polygonDailyConsumption"
              loading={defaultDCLoading}
              type="number"
              value={value.polygonDailyConsumption || ''}
              onKeyDown={handleKeyDown}
              onChange={handleChangeDailyConsumption}
              helperText={t('validation_errors_null')}
              error={isPolygonDailyConsumptionError}
              additionalInput={
                <AdditionalSelect
                  name="polygonDailyConsumptionUnit"
                  withGreenBorder
                  value={value.polygonDailyConsumptionUnit}
                  onChange={handleChangeUnit}
                >
                  {UNITS.AppnDepth.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </AdditionalSelect>
              }
            />
            {showManna && (
              <Typography sx={{ mt: 1, fontSize: 13, color: '#0BA6D8', display: 'flex', alignItems: 'flex-end' }}>
                <MannaIcon sx={{ mr: '5px' }} /> Powered by manna
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <PolygonInput
              value={value.polygonIrrigationHoursPerDay || ''}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              label={t('forms_irrigation_hours_per_day')}
              placeholder="Enter"
              name="polygonIrrigationHoursPerDay"
              type="number"
              helperText={t('validation_errors_null')}
              error={isPolygonIrrigationHoursError}
            />
          </Grid>
          <Grid item xs={6}>
            <PolygonInput
              value={value.polygonLateralsPerRow || ''}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              label={t('forms_laterals_per_row')}
              placeholder="Enter"
              name="polygonLateralsPerRow"
              type="number"
            />
          </Grid>
        </Grid>

        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <PolygonInput
              inputRef={unitRefs.polygonRowSpacing}
              label={t('forms_row_spacing')}
              placeholder="Enter"
              value={value.polygonRowSpacing || ''}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              name="polygonRowSpacing"
              type="number"
              helperText={t('validation_errors_null')}
              error={isPolygonRowSpacingError}
              additionalInput={
                <AdditionalSelect
                  withGreenBorder
                  name="polygonRowSpacingUnit"
                  value={value.polygonRowSpacingUnit}
                  onChange={handleChangeUnit}
                >
                  {UNITS.Length.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </AdditionalSelect>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <PolygonInput
              inputRef={unitRefs.polygonPlantSpacing}
              label={t('forms_plant_spacing')}
              placeholder="Enter"
              name="polygonPlantSpacing"
              type="number"
              value={value.polygonPlantSpacing || ''}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              helperText={t('validation_errors_null')}
              error={isPolygonPlantSpacingError}
              additionalInput={
                <AdditionalSelect
                  name="polygonPlantSpacingUnit"
                  withGreenBorder
                  value={value.polygonPlantSpacingUnit}
                  onChange={handleChangeUnit}
                >
                  {UNITS.Length.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </AdditionalSelect>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PolygonInput
              label={t('forms_row_direction')}
              placeholder={
                value.polygonRowDirection !== null ? t('forms_change_row_direction') : t('forms_set_row_direction')
              }
              name="polygonRowDirection"
              onKeyDown={handleKeyDown}
              helperText={t('validation_errors_null')}
              onClick={openRowDirectionForm}
              InputProps={{
                endAdornment:
                  value.polygonRowDirection !== null ? (
                    <Icon path="row-direction-icon" sx={{ transform: `rotate(${rotationAngle}deg)` }} />
                  ) : null,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckBox
              label={t('forms_soil_type')}
              small
              name="polygonIsSoilType"
              checked={value.polygonIsSoilType}
              value={value.polygonIsSoilType}
              onChange={onTogglePolygonSoilType}
              sx={{
                '& .MuiTypography-root': {
                  color: 'black',
                  fontSize: 16,
                  userSelect: 'none',
                  ml: 1,
                  mt: '3px',
                  fontWeight: '600 !important',
                },
                '& .MuiCheckbox-root': {
                  p: 0,
                },
              }}
            />
            {value.polygonIsSoilType && (
              <Select
                name="polygonSoilType"
                multiple
                value={value.polygonSoilType}
                onChange={(e) => onChangePolygonSoilType(e as SelectChangeEvent<string[]>)}
                renderValue={(selected) =>
                  (selected as string[])
                    .map((value) => soilTypes.find((type) => type.value === value)?.label)
                    .join(', ')
                }
              >
                {soilTypes.map((item) => (
                  <MenuItem key={item.value} value={item.value} sx={{ py: 0.5 }}>
                    <CheckBox
                      small
                      checked={value.polygonSoilType.includes(item.value)}
                      sx={{
                        mr: 1,
                        '& .MuiCheckbox-root': {
                          p: 0,
                        },
                      }}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>

        <CheckBox
          checked={value.isSelectProduct}
          onChange={onToggleSelectProduct}
          label={t('forms_select_product')}
          sx={{ mt: 2 }}
        />

        <Collapse in={value.isSelectProduct}>
          <PolygonProductProperties
            value={value}
            onChange={onChange}
            saveEmitter={saveEmitter}
            saveLateral={saveLateral}
          />
        </Collapse>
      </Box>
    </LocalizationProvider>
  );
};
