import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { usePolygonStyles } from './hooks';
import { ColorPickSnackbar, ColorsPanel, Icon, Row } from '../../shared/ui';
import { selectMap, selectPolygonId } from '../../shared/slices';

export const PolygonColorPicker: FC = () => {
  const selectedPolygonId = selectPolygonId();
  const { isColorPicker } = selectMap();

  const {
    currentMode,
    fillColor,
    borderColor,
    fillOpacity,
    onModeChange,
    handleClose,
    onSelectFillColor,
    onSelectBorderColor,
    onToggleFillOpacity,
  } = usePolygonStyles();

  return (
    <ColorPickSnackbar open={isColorPicker && !!selectedPolygonId} onClose={handleClose}>
      <>
        <ColorsPanel
          color={currentMode === 'border' ? borderColor : fillColor}
          onSelect={currentMode === 'border' ? onSelectBorderColor : onSelectFillColor}
          isBorders={currentMode === 'border'}
          isOpacityToggler={currentMode === 'fill'}
          onToggleOpacity={onToggleFillOpacity}
          fillOpacity={fillOpacity}
        />

        <Row sx={{ width: '100%', borderRadius: '10px', bgcolor: 'grey', mt: '24px' }}>
          <Box
            sx={{
              borderRadius: '10px',
              height: '3px',
              width: '50%',
              ...(currentMode === 'fill' && { bgcolor: 'white' }),
            }}
          />
          <Box
            sx={{
              borderRadius: '10px',
              height: '3px',
              width: '50%',
              ...(currentMode === 'border' && { bgcolor: 'white' }),
            }}
          />
        </Row>

        <Row>
          <Row
            onClick={() => onModeChange('fill')}
            justifyContent="center"
            sx={{
              width: '50%',
              display: 'flex',
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
              px: 1,
              py: 0.5,
              pt: 2,
            }}
          >
            <Typography sx={{ mr: 1, fontSize: '18px', lineHeight: '25px', color: 'white' }}>Fill</Typography>
            {fillOpacity === 0 ? (
              <Icon path="mapIcons/opacity_toggle" sx={{ height: '25px', width: '25px' }} />
            ) : (
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  borderRadius: '5px',
                  background: fillColor,
                }}
              />
            )}
          </Row>

          <Row
            onClick={() => onModeChange('border')}
            justifyContent="center"
            sx={{
              width: '50%',
              display: 'flex',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              px: 1,
              py: 0.5,
              pt: 2,
            }}
          >
            <Typography sx={{ mr: 1, fontSize: '18px', lineHeight: '25px', color: 'white' }}>Border</Typography>
            <Box
              sx={{
                width: '25px',
                height: '25px',
                borderRadius: '5px',
                border: '3px solid white',
                borderColor: borderColor,
              }}
            />
          </Row>
        </Row>
      </>
    </ColorPickSnackbar>
  );
};
