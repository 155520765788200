import { ProjectLayout } from '../../shared/ui';
import { Header, NewProject, ProjectManagement, SideMenu } from '../../widgets';

export const ProjectManagementPage = () => {
  return (
    <>
      <Header />
      <ProjectLayout>
        <SideMenu />
        <ProjectManagement />
        <NewProject />
      </ProjectLayout>
    </>
  );
};
