import L, { LatLng } from 'leaflet';
import { FC } from 'react';
import { Marker } from 'react-leaflet';

const icon = L.icon({ iconUrl: '/images/mapIcons/searched_marker.png', iconSize: [50, 50], iconAnchor: [25, 50] });

interface Props {
  coords: LatLng | null;
  onClick: (coords: LatLng) => void;
}

export const SearchedPoint: FC<Props> = ({ coords, onClick }) => {
  return (
    <>
      {coords && (
        <Marker
          icon={icon}
          position={coords}
          eventHandlers={{
            click() {
              onClick(coords);
            },
          }}
        />
      )}
    </>
  );
};
