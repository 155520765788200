import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { NO_CONNECTION_MESSAGE } from 'shared/constants';
import { useAppDispatch } from 'shared/hooks';
import { isAddressInCoordinates, isOnline } from 'shared/lib';
import { selectSearchRequestCount, setSearchedPoint, setSearchRequestCount } from 'shared/slices';
import { Icon } from 'shared/ui';
import { useDebounce } from 'usehooks-ts';
import { localStorageService } from '../../shared/services';
import { LatLng } from 'leaflet';

interface Props {
  onClose: () => void;
}

export const SearchMenu: FC<Props> = ({ onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [address, setAddress] = useState('');
  const [history, setHistory] = useState<string[]>([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const searchRequestCount = selectSearchRequestCount();

  const debouncedAddress = useDebounce<string>(address, 300);

  useEffect(() => {
    if (!debouncedAddress) return;

    const getSuggestions = async () => {
      if (isOnline()) {
        const result = await axios.get(
          `/google/place/autocomplete/json?input=${debouncedAddress}&language=${language}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );

        if (!result.data.predictions[0]) return;

        setSuggestions(result.data.predictions);
      } else {
        toast.error(NO_CONNECTION_MESSAGE);
      }
    };

    getSuggestions();
  }, [debouncedAddress]);

  useEffect(() => {
    const allHistory: string[] = JSON.parse(localStorageService.previousSearch ?? '[]');
    const history = allHistory.reverse();

    setHistory(history);
  }, [isShowAll]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };
  const onClearSearch = () => setAddress('');
  const toggleShowAll = () => setIsShowAll((prev) => !prev);
  const onClickHistoryItem = (target: string) => onSearchClick(target);

  const handleClose = () => {
    onClearSearch();
    onClose();
  };

  const onSearchClick = async (address: string) => {
    if (!address) return;

    if (isAddressInCoordinates(address)) {
      const [lat, lng] = address.split(/[\s,]+/).map(Number);
      const searchedPoint = { lat, lng } as LatLng;
      dispatch(setSearchedPoint(searchedPoint));
      dispatch(setSearchRequestCount(searchRequestCount + 1));
      handleClose();
      setIsFocus(false);
      return;
    }

    setIsLoading(true);

    try {
      const result = await axios.get(
        `/google/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );

      if (result.data.status === 'ZERO_RESULTS') {
        toast.error(t('errors_not_found'));
        return;
      }

      const previousSearch = JSON.parse(localStorageService.previousSearch ?? '[]');

      if (!previousSearch.includes(address)) {
        const newPreviousSearch = [...previousSearch, address];

        if (newPreviousSearch.length > 50) {
          newPreviousSearch.shift();
        }

        localStorageService.previousSearch = JSON.stringify(newPreviousSearch);
      }

      const searchedPoint = result.data.results[0].geometry.location;
      dispatch(setSearchedPoint(searchedPoint));
      dispatch(setSearchRequestCount(searchRequestCount + 1));
      handleClose();
    } catch (error) {
      toast.error(t('errors_something_went_wrong'));
    } finally {
      setIsFocus(false);
      setIsLoading(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsFocus(false)}>
      <Stack
        sx={{
          position: 'fixed',
          top: '75px',
          right: '24px',
          zIndex: 450,
          maxWidth: '400px',
          width: '100%',
          pl: '48px',
        }}
      >
        <InputBase
          value={address}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.code === 'Enter' || e.key === 'Enter') {
              onSearchClick(address);
            }
          }}
          onFocus={() => setIsFocus(true)}
          fullWidth
          sx={{
            bgcolor: 'rgb(255, 255, 255, 1)',
            borderRadius: '16px',
          }}
          placeholder={t('search')}
          startAdornment={
            <IconButton color="info" sx={{ m: 0 }} onClick={() => onSearchClick(address)}>
              <Icon path="mapIcons/search" />
            </IconButton>
          }
          endAdornment={
            <>
              {isLoading ? (
                <CircularProgress size={24} sx={{ m: '8px 10px' }} />
              ) : (
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              )}
            </>
          }
        />

        {isFocus && (
          <Box sx={{ pt: '10px' }}>
            {history.length > 0 && !address && (
              <Stack
                onClick={(e) => e.stopPropagation()}
                sx={{
                  borderRadius: '16px',
                  maxWidth: '870px',
                  m: '0 auto',
                  bgcolor: 'info.main',
                  p: '10px 10px',
                  pb: '5px',
                  maxHeight: 'calc(100svh - 80px)',
                }}
              >
                <Typography sx={{ fontWeight: 600, color: 'primary.main', mb: 1, ml: 1 }}>
                  {t('search_component_history')}
                </Typography>

                <Stack sx={{ height: '100%', overflow: 'auto' }}>
                  {history
                    .filter((_, i) => (isShowAll ? true : i < 5))
                    .map((item, i) => (
                      <Button
                        onClick={() => onClickHistoryItem(item)}
                        key={i}
                        fullWidth
                        sx={{
                          justifyContent: 'flex-start',
                          borderBottom: `1px solid ${palette.info.light} `,
                          py: 1,
                          textTransform: 'capitalize',
                          color: 'secondary.dark',
                        }}
                      >
                        <Icon path="green_clock" />
                        <Typography sx={{ ml: '7px', lineHeight: '16px' }}>{item}</Typography>
                      </Button>
                    ))}
                </Stack>

                {history.length > 5 && (
                  <Button onClick={toggleShowAll} fullWidth sx={{ textTransform: 'capitalize', p: 0, mt: 1 }}>
                    <Typography align="center" sx={{ fontWeight: 600, color: 'primary.main' }}>
                      {t('search_component_more_history')}
                    </Typography>
                  </Button>
                )}
              </Stack>
            )}

            {address && (
              <Stack
                onClick={(e) => e.stopPropagation()}
                sx={{
                  borderRadius: '16px',
                  maxWidth: '870px',
                  m: '0 auto',
                  bgcolor: 'info.main',
                  p: '10px 10px',
                  pb: '10px',
                  maxHeight: 'calc(100svh - 80px)',
                }}
              >
                {!suggestions.length ? (
                  <Typography textAlign="center" sx={{ ml: '7px', lineHeight: '16px' }}>
                    No suggestions
                  </Typography>
                ) : (
                  suggestions
                    .filter((_, i) => i < 10)
                    .map((item, i) => (
                      <Button
                        onClick={() => onClickHistoryItem(item['description'])}
                        key={i}
                        fullWidth
                        sx={{
                          justifyContent: 'flex-start',
                          borderBottom: `1px solid ${palette.info.light} `,
                          py: 1,
                          textTransform: 'capitalize',
                          color: 'secondary.dark',
                        }}
                      >
                        <Typography textAlign="left" sx={{ ml: '7px', lineHeight: '16px' }}>
                          {item['description']}
                        </Typography>
                      </Button>
                    ))
                )}
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </ClickAwayListener>
  );
};
