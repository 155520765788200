import { FC, useEffect, useState } from 'react';
import { BoxProps, styled, Typography, useMediaQuery } from '@mui/material';
import { useAppDispatch } from 'shared/hooks';
import {
  selectCurrentMapItem,
  selectLineId,
  selectLines,
  selectNewLine,
  selectNewPoint,
  selectNewPolygon,
  selectPointId,
  selectPoints,
  selectPolygonId,
  selectPolygons,
  selectSelectedItem,
  selectUnits,
  setCurrentArea,
  setCurrentElevation,
  setCurrentTotalLength,
} from 'shared/slices';
import { getLengthByUnit, getPerimeter, getPolygonArea, textFormatter } from 'shared/lib';
import { Row } from 'shared/ui';
import { LineModel, PointModel, PolygonModel } from 'shared/models';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { POINT } from 'shared/constants';

const wrappedStyles = {
  width: '100%',
  m: 0,
};

const InfoText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.info.main,
  fontSize: '18px',
  margin: '7px 5px',
}));

export const FigureSizes: FC<BoxProps> = ({ sx }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isSmallMobile = useMediaQuery('(max-width:365px)');

  const polygons = selectPolygons();
  const newPolygon = selectNewPolygon();
  const selectedPolygonId = selectPolygonId();

  const lines = selectLines();
  const selectedLineId = selectLineId();
  const newLine = selectNewLine();

  const points = selectPoints();
  const selectedPointId = selectPointId();
  const newPoint = selectNewPoint();

  const {
    units: { Area, Length },
  } = selectUnits();

  const currentMapItem = selectCurrentMapItem();
  const currentMapItemType = selectSelectedItem();

  const [polygonArea, setPolygonArea] = useState(0);
  const [polygonPerimeter, setPolygonPerimeter] = useState(0);
  const [lineTotalLength, setLineTotalLength] = useState(0);
  const [pointHeight, setPointHeight] = useState(0);
  const [isPointLoading, setIsPointLoading] = useState(false);

  useEffect(() => {
    const polygon = currentMapItem as PolygonModel;

    const calcPoints = polygon?.geoJson.points.length
      ? polygon.geoJson.points.map((p) => [p.coords.lng, p.coords.lat])
      : [];

    const area = getPolygonArea(calcPoints, Area);
    setPolygonArea(area);
    const areaInHa = getPolygonArea(calcPoints, 'ha');
    dispatch(setCurrentArea(areaInHa));
    const perimeter = getPerimeter(calcPoints, Length);
    setPolygonPerimeter(perimeter);
  }, [polygons, newPolygon.geoJson.points, selectedPolygonId, Area, Length]);

  useEffect(() => {
    const line = currentMapItem as LineModel;
    const calcPoints = line?.geoJson.points.length ? line.geoJson.points.map((l) => [l.coords.lng, l.coords.lat]) : [];

    const totalLength = getPerimeter(calcPoints, Length, true);
    setLineTotalLength(totalLength);

    const totalLengthInMeters = getPerimeter(calcPoints, 'm', true);
    dispatch(setCurrentTotalLength(totalLengthInMeters));
  }, [lines, newLine.geoJson.points, selectedLineId, Length]);

  useEffect(() => {
    const getPointElevation = async () => {
      if (currentMapItemType === POINT) {
        const pointItem = currentMapItem as PointModel;

        const point = pointItem?.geoJson?.points?.[0];
        if (!point) return;

        const { lat, lng } = point.coords;

        setIsPointLoading(true);
        try {
          const res = await axios.get(
            `/google/elevation/json?locations=${lat}%2C${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          );
          const alt = res.data.results[0].elevation as number;
          const elevationInMeters = alt;
          dispatch(setCurrentElevation(elevationInMeters));

          const elevation = getLengthByUnit(elevationInMeters, Length);

          setPointHeight(elevation);
        } catch (error) {
          console.log(error);
          setPointHeight(0);
        } finally {
          setIsPointLoading(false);
        }
      }
    };
    getPointElevation();
  }, [points, newPoint, Length, selectedPointId]);

  const areaDigitsCount = polygonArea.toString().length;
  const perimeterDigitsCount = polygonPerimeter.toString().length;

  const isWrapped = isSmallMobile && (areaDigitsCount >= 5 || perimeterDigitsCount >= 5);

  return (
    <Row
      sx={{
        position: 'absolute',
        bottom: '100%',
        alignItems: 'center',
        width: '100%',
        bgcolor: 'rgba(0, 0, 0, 0.68)',
        ...sx,
      }}
    >
      {selectedPolygonId && (
        <>
          <Row width="50%" flexWrap={'wrap'} justifyContent="center">
            <InfoText
              noWrap
              sx={{
                ...(isWrapped && wrappedStyles),
              }}
            >
              {textFormatter(t('polygon_info_size'), ['', '']).output}
            </InfoText>
            <InfoText noWrap sx={{ ...(isWrapped && wrappedStyles) }}>{`${polygonArea.toString()} ${Area}`}</InfoText>
          </Row>

          <InfoText sx={{ width: 'auto' }}>|</InfoText>

          <Row width="50%" flexWrap={'wrap'} justifyContent="center">
            <InfoText
              noWrap
              sx={{
                ...(isWrapped && wrappedStyles),
              }}
            >
              {textFormatter(t('polygon_info_perimeter'), ['', '']).output}
            </InfoText>
            <InfoText
              noWrap
              sx={{ ...(isWrapped && wrappedStyles) }}
            >{`${polygonPerimeter.toString()} ${Length}`}</InfoText>
          </Row>
        </>
      )}

      {selectedLineId && (
        <InfoText sx={{ width: '100%' }}>
          {textFormatter(t('polygon_info_length'), [lineTotalLength.toString(), Length]).output}
        </InfoText>
      )}

      {selectedPointId && (
        <InfoText sx={{ width: '100%' }}>
          {textFormatter(t('point_info_elevation'), [isPointLoading ? '...' : pointHeight.toFixed(2), Length]).output}
        </InfoText>
      )}
    </Row>
  );
};
