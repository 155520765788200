import { Box, Typography, Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { AuthInput, Button, WhiteModal } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { Project, UpdateProjectResponse } from 'shared/models';
import { axiosBase } from 'shared/axios';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  onClose: () => void;
  project: Project;
  onUpdateProject: (projectId: number, p: Project) => void;
}

export const ProjectRenameModal: FC<Props> = ({ open, onClose, project, onUpdateProject }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    if (open) {
      setProjectName(project.project.projectName);
      setCustomerName(project.project.customerName);
    }
  }, [open]);

  const onRenameClick = async () => {
    setLoading(true);
    try {
      const newProjectData = { ...project.project, projectName, customerName };
      await axiosBase.put<UpdateProjectResponse>('/api/Project/projects', {
        id: project.id,
        project: JSON.stringify(newProjectData),
      });

      const renamedProject: Project = { ...project, project: newProjectData };
      onUpdateProject(project.id, renamedProject);

      onClose();
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    !loading && onClose();
  };

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value && value.length > 50) {
      setInputError('Max 50 characters');
      return;
    }
    inputError && setInputError('');
    setProjectName(value);
  };

  return (
    <WhiteModal open={open} onClose={onClose} hideCloseIcon margin={6}>
      <Box sx={{ mt: -2 }}>
        <AuthInput
          label={t('new_project_project_name')}
          name="project-name"
          placeholder={t('new_project_project_name')}
          value={projectName}
          onChange={handleChangeProjectName}
          error={!!inputError}
          helperText={inputError}
        />
        <AuthInput
          label={t('new_project_customer_name')}
          name="customer-name"
          placeholder={t('new_project_customer_name')}
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
      </Box>

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={5}>
          <Button outlined btnColor="info.dark" fullWidth onClick={onCancelClick}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('new_project_cancel')}</Typography>
          </Button>
        </Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <Button loading={loading} btnColor="primary.main" fullWidth onClick={onRenameClick} disabled={!projectName}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('units_save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </WhiteModal>
  );
};
