import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UserProfile } from '../../features';
import { useAppDispatch, useAppNavigator, useMobileDetect } from '../../shared/hooks';
import { textFormatter } from '../../shared/lib';
import { logout, setSearchedPoint, toggleAddToHome, toggleNewProject } from '../../shared/slices';
import { Icon } from '../../shared/ui';
import { ExportProject } from '../ExportProject';
import { ShareProject } from '../ShareProject';
import { useSideMenuItems } from './hooks';

export const SideMenu: React.FC = () => {
  const mobileInfo = useMobileDetect();
  const { t } = useTranslation();
  const { navigateToLogin, navigate } = useAppNavigator();
  const dispatch = useAppDispatch();
  const { pathname, state } = useLocation();

  const { sideMenuItems } = useSideMenuItems();

  const closeMenu = () => navigate(pathname, { state: { sideMenu: false }, replace: true });
  const closeShareModal = () => {
    navigate(pathname, { state: { sideMenuShareModal: false }, replace: true });
  };
  const closeSendByMailModal = () => {
    navigate(pathname, { state: { sideMenuSendModal: false }, replace: true });
  };

  const onExitClick = () => {
    navigate(pathname, { state: { ...state, signOutConfirm: true } });
    const isOk = confirm('Are you sure?');
    if (isOk) {
      dispatch(logout());
      navigateToLogin();
      closeMenu();
    }
  };

  const onListItemClick = (icon: string) => {
    if (icon.includes('share')) {
      navigate(pathname, { state: { sideMenuShareModal: true, sideMenu: true } });
    }
    if (icon.includes('email')) {
      navigate(pathname, { state: { sideMenuSendModal: true, sideMenu: true } });
    }
    if (icon.includes('projects')) {
      dispatch(setSearchedPoint(null));
      navigate('/main/project-management', { state: { sideMenu: false } });
    }
    if (icon.includes('newproject')) {
      pathname === '/main/home'
        ? dispatch(toggleNewProject())
        : navigate('/main/new-project', { state: { sideMenu: false } });
    }
    if (icon.includes('units')) {
      navigate(pathname, { state: { unitsModal: true, sideMenu: false } });
    }
    if (icon.includes('language')) {
      navigate(pathname, { state: { languageModal: true, sideMenu: false } });
    }
  };

  const isMobile = (mobileInfo?.isAndroid() || mobileInfo?.isIos()) && mobileInfo?.standalone === false;

  return (
    <Drawer
      id="sideMenu"
      anchor="left"
      open={!!state?.sideMenu}
      onClose={closeMenu}
      sx={{
        position: 'relative',
        '& .MuiPaper-root': {
          top: '48px',
          bgcolor: 'primary.main',
          width: '288px',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          pb: '48px',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          background: 'url("/images/sideMenuIcons/sidemenu_bg.png") center/cover no-repeat',
        }}
      />
      <UserProfile />
      <List component="nav">
        {sideMenuItems.map(({ title, icon }, i) => (
          <ListItem disablePadding key={i} sx={{ ...((i == 1 || i == 3) && { mb: '10px' }) }}>
            <ListItemButton
              onClick={() => onListItemClick(icon)}
              disabled={(icon.includes('email') || icon.includes('share')) && !pathname.includes('/main/home')}
              sx={{
                p: '0 24px',
                color: 'info.main',
                fontSize: '18px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  py: '10px',
                  ...((i == 1 || i == 3) && { borderBottom: '1px solid white', pb: '15px' }),
                }}
              >
                <Box sx={{ display: 'flex', width: '22px', justifyContent: 'center' }}>
                  <Icon path={`sideMenuIcons/${icon}`} />
                </Box>

                <ListItemText
                  primary={title}
                  sx={{
                    ml: '15px',
                    ' & .MuiTypography-root': {
                      fontSize: '18px',
                    },
                  }}
                />
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Stack sx={{ flexGrow: 1, minHeight: '30px' }} />

      <List disablePadding>
        {isMobile && (
          <ListItemButton
            onClick={() => {
              dispatch(toggleAddToHome());
            }}
            sx={{ pb: 1 }}
          >
            <ListItemText
              primary={t('addToHomeScreen')}
              sx={{
                span: { fontSize: 18, fontWeight: 600 },
                textAlign: 'center',
                color: 'info.main',
              }}
            />
          </ListItemButton>
        )}

        <ListItemButton sx={{ p: 0, pl: '20px' }} onClick={onExitClick}>
          <ListItemIcon sx={{ minWidth: '20px' }}>
            <Icon path={`sideMenuIcons/exit`} />
          </ListItemIcon>

          <ListItemText
            primary={t('side_menu_log_out')}
            sx={{
              span: { fontSize: 18, fontWeight: 600 },
              ml: '10px',
              color: 'info.main',
              mb: '5px',
            }}
          />
        </ListItemButton>

        <Typography
          sx={{
            fontSize: 18,
            color: 'info.main',
            p: '16px 24px',
          }}
        >
          {textFormatter(t('side_menu_version'), [process.env.REACT_APP_VERSION as string]).output}
        </Typography>
      </List>

      <ShareProject open={!!state?.sideMenuShareModal} onClose={closeShareModal} />
      <ExportProject open={!!state?.sideMenuSendModal} onClose={closeSendByMailModal} />
    </Drawer>
  );
};
