import {
  resetDrawMode,
  selectGpsTrackingPoints,
  selectIsGpsMeasurementInitialized,
  setGpsTrackingState,
  setIsGpsMeasurementInitialized,
  toggleGpsPermissionBlocked,
} from '../slices';
import { useAppDispatch } from './useAppDispatch';
import { LatLng } from 'leaflet';
import { useGpsTrackingData } from './useGpsTrackingData';

export const useGpsPlayStop = () => {
  const isGpsMeasurementInitialized = selectIsGpsMeasurementInitialized();
  const gpsTrackingPoints = selectGpsTrackingPoints();

  const { createMapItemFromGpsData } = useGpsTrackingData();
  const dispatch = useAppDispatch();

  const startGpsTracking = async () => {
    const isGpsPermissionGranted = await checkPermissionGrant();
    if (!isGpsPermissionGranted) {
      dispatch(toggleGpsPermissionBlocked());
      return;
    }
    if (!isGpsMeasurementInitialized) {
      dispatch(setIsGpsMeasurementInitialized(true));
    }
    dispatch(setGpsTrackingState(true));
  };

  const stopGpsTracking = () => {
    createMapItemFromGpsData(gpsTrackingPoints as LatLng[]);
    // dispatch(setShowSimplifyOption(true));
    dispatch(resetDrawMode());
    dispatch(setGpsTrackingState(false));
    dispatch(setIsGpsMeasurementInitialized(false));
  };

  return { startGpsTracking, stopGpsTracking, isGpsMeasurementInitialized };
};

/**
 * Create same func as in {@link module:useFindLocation.checkPermissionGrant} because useMap() could be used in context of {@link MapContainer}
 * @see https://react-leaflet.js.org/docs/start-introduction/
 * */
const checkPermissionGrant = async () => {
  const result = await navigator.permissions.query({ name: 'geolocation' });
  if (result.state === 'granted') {
    return true;
  } else if (result.state === 'prompt') {
    return true;
  }
  return false;
};
