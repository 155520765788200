import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const OpenIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M21 10C21 10.269 20.9006 10.5029 20.7018 10.7018C20.5146 10.9006 20.2807 11 20 11C19.731 11 19.4971 10.9006 19.2982 10.7018C19.0994 10.5029 19 10.269 19 10V4.14035C19 4.08187 19.0819 4.12865 19.2456 4.2807C19.4211 4.42105 19.5789 4.57895 19.7193 4.75439C19.8713 4.91813 19.9181 5 19.8596 5H14C13.731 5 13.4971 4.90058 13.2982 4.70175C13.0994 4.50292 13 4.26901 13 4C13 3.7193 13.0994 3.48538 13.2982 3.29825C13.4971 3.09942 13.731 3 14 3H19.8596C20.1754 3 20.4444 3.11111 20.6667 3.33333C20.8889 3.55556 21 3.82456 21 4.14035V10ZM14.7018 10.7018C14.5263 10.9006 14.2924 11 14 11C13.731 11 13.4971 10.9006 13.2982 10.7018C13.0994 10.5029 13 10.269 13 10C13 9.7076 13.0994 9.47368 13.2982 9.29825L19.2982 3.29825C19.4854 3.09942 19.7193 3 20 3C20.2807 3 20.5146 3.09942 20.7018 3.29825C20.9006 3.48538 21 3.7193 21 4C21 4.2807 20.9006 4.51462 20.7018 4.70175L14.7018 10.7018ZM3 14C3 13.7193 3.09942 13.4854 3.29825 13.2982C3.49708 13.0994 3.73099 13 4 13C4.2807 13 4.51462 13.0994 4.70175 13.2982C4.90058 13.4854 5 13.7193 5 14V19.8596C5 19.9181 4.91228 19.8772 4.73684 19.7368C4.5731 19.5848 4.4152 19.4269 4.26316 19.2632C4.12281 19.0877 4.08187 19 4.14035 19H10C10.2807 19 10.5146 19.0994 10.7018 19.2982C10.9006 19.4854 11 19.7193 11 20C11 20.269 10.9006 20.5029 10.7018 20.7018C10.5146 20.9006 10.2807 21 10 21H4.14035C3.82456 21 3.55556 20.8889 3.33333 20.6667C3.11111 20.4444 3 20.1754 3 19.8596V14ZM9.29825 13.2982C9.48538 13.0994 9.7193 13 10 13C10.2807 13 10.5146 13.0994 10.7018 13.2982C10.9006 13.4854 11 13.7193 11 14C11 14.2807 10.9006 14.5146 10.7018 14.7018L4.70175 20.7018C4.52632 20.9006 4.2924 21 4 21C3.73099 21 3.49708 20.9006 3.29825 20.7018C3.09942 20.5029 3 20.269 3 20C3 19.7076 3.09942 19.4737 3.29825 19.2982L9.29825 13.2982Z"
      fill="#8C8D92"
    />
  </SvgIcon>
);
