import React from 'react';
import { createSvgIcon } from '@mui/material';

export const MannaIcon = createSvgIcon(<svg
  xmlns='http://www.w3.org/2000/svg'
  width='27'
  height='13'
  viewBox='0 0 27 13'
  fill='none'
>
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M13.517 2.34677C12.9351 2.95509 12.2356 3.44155 11.4602 3.77728C10.6847 4.11301 9.84902 4.29118 9.00266 4.30121C8.64735 4.30121 8.16861 4.07172 8.16113 4.06801C8.21723 4.09392 8.94282 4.60475 9.67215 5.16369C10.7381 5.98174 11.9125 6.51107 13.3001 6.51107C15.0542 6.51107 16.6886 5.40059 16.6886 5.40059C16.8532 5.25252 17.0589 5.11186 17.2496 5.00082C18.2255 4.45235 19.329 4.16528 20.4512 4.16796C23.8884 4.16796 26.6972 6.822 26.8206 10.1201V6.70355C26.7945 2.99825 23.4022 0.0369775 19.2469 0.0369775C17.104 0.0257631 15.0438 0.854916 13.517 2.34307'
    fill='url(#paint0_linear_881_287)'
  />
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M0 10.2645V13H4.75745V7.73635C4.78094 6.91387 5.13018 6.13351 5.72982 5.56359C6.32946 4.99367 7.13143 4.67989 7.96274 4.68993C8.38911 4.68993 8.79679 4.77507 9.16706 4.92684C8.30912 4.45594 7.35001 4.19452 6.36944 4.16431C2.93226 4.16801 0.123424 6.96641 0 10.2645Z'
    fill='url(#paint1_linear_881_287)'
  />
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M19.0747 4.26056C19.9032 4.26799 20.6964 4.59375 21.2867 5.16907C21.8771 5.74439 22.2185 6.52424 22.2389 7.34399L22.2426 12.9038H27V10.0239C26.8729 6.72952 24.0678 4.07178 20.6306 4.07178C20.092 4.07178 19.5721 4.13841 19.0747 4.25686'
    fill='url(#paint2_linear_881_287)'
  />
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M0 6.84425V10.2645C0.127164 6.9664 2.93226 4.168 6.36944 4.168C7.53636 4.168 8.62848 4.61219 9.56725 5.14151C9.77112 5.25466 9.96069 5.39134 10.132 5.54869C10.132 5.54869 11.8113 7.22551 13.5617 7.22551C14.953 7.22551 16.1162 6.14465 17.1859 5.32659C17.9152 4.76765 18.8091 4.37899 18.8652 4.35308C18.8652 4.35308 18.1733 4.44191 17.818 4.44191C16.0751 4.44191 14.4556 3.67568 13.2999 2.48747C11.9198 1.09937 9.82906 0 7.5775 0C3.41848 0 0.0261809 3.13895 0 6.84425Z'
    fill='url(#paint3_linear_881_287)'
  />
  <defs>
    <linearGradient
      id='paint0_linear_881_287'
      x1='609.371'
      y1='404.98'
      x2='1759.97'
      y2='844.774'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#779572' />
      <stop offset='1' stopColor='#B1DFC1' />
    </linearGradient>
    <linearGradient
      id='paint1_linear_881_287'
      x1='0'
      y1='506.474'
      x2='458.353'
      y2='506.474'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#6CCAF0' />
      <stop offset='1' stopColor='#04AADC' />
    </linearGradient>
    <linearGradient
      id='paint2_linear_881_287'
      x1='346.391'
      y1='544.061'
      x2='743.345'
      y2='723.051'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#3BB268' />
      <stop offset='1' stopColor='#016B41' />
    </linearGradient>
    <linearGradient
      id='paint3_linear_881_287'
      x1='-2.03641e-06'
      y1='485.409'
      x2='644.2'
      y2='424.763'
      gradientUnits='userSpaceOnUse'
    >
      <stop stopColor='#00A2D6' />
      <stop offset='1' stopColor='#7FD3F4' />
    </linearGradient>
  </defs>
</svg>, 'Manna');