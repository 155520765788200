import React, { FocusEvent, useEffect, useState } from 'react';
import { LINE_DEFAULT_NAME, NEW_LINE_ID, POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { useAppDispatch } from 'shared/hooks';
import { getLengthByUnit, getStringWithoutFirstZero } from 'shared/lib';
import { LineModel } from 'shared/models';
import {
  selectCurrentMapItem,
  selectCurrentTotalLengthInMeters,
  selectIsLineInfo,
  selectLineById,
  selectLineId,
  selectLineInformation,
  selectLines,
  selectMasterGr,
  selectProjectLineCount,
  selectUnits,
  setLineFormState,
  setLineInformation,
} from 'shared/slices';

export const useManageLineInfo = () => {
  const dispatch = useAppDispatch();

  const line = selectCurrentMapItem() as LineModel;
  const lineSettingsFrom = selectLineById(line?.properties.lineCopySettingsFromId || '');
  const lines = selectLines();

  const isLineInfo = selectIsLineInfo();
  const lineInformation = selectLineInformation();
  const selectedLineId = selectLineId();
  const currentTotalLengthInMeters = selectCurrentTotalLengthInMeters();

  const [currentLineCopySettingsFrom, setCurrentLineCopySettingsFrom] = useState<string | null>(null);

  const { masterGroups } = selectMasterGr();

  const lineCounter = selectProjectLineCount();

  const {
    units: { Length },
  } = selectUnits();

  const copySettingsFromOptions = lines
    .filter((line) => !line.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID)) // skip row direction lines
    .filter((line) => line.information.isIrrigationProperties)
    .filter((line) => !line.properties.lineCopySettingsFromId) // get only parent items
    .map((line) => ({
      id: line.geoJson.id,
      name: line.information.name,
    }));

  useEffect(() => {
    if (lineInformation.lineCopySettingsFromId) {
      const lineSettingsFrom = lines.find((l) => l.geoJson.id === lineInformation.lineCopySettingsFromId);
      if (lineSettingsFrom) {
        setCurrentLineCopySettingsFrom(lineSettingsFrom.information.name);

        const { lineSections } = lineSettingsFrom.properties;

        dispatch(
          setLineInformation({
            lineCopySettingsFromId: lineSettingsFrom.geoJson.id,
            lineSections,
          })
        );
      }
    } else {
      setCurrentLineCopySettingsFrom(null);
    }
  }, [lineInformation.lineCopySettingsFromId]);

  useEffect(() => {
    const currentLine = lineSettingsFrom ?? line;

    if (currentLine) {
      const lineSections = currentLine.properties?.lineSections?.map((ls) => {
        if (!ls.linePipeTypeText) {
          const linePipeTypeText = masterGroups.find((m) => m.ID === ls.linePipeType)?.MASTERGROUPNAME ?? '';
          return {
            ...ls,
            linePipeTypeText,
          };
        }
        return ls;
      });

      dispatch(
        setLineInformation({
          lineName: line?.information.name,
          lineDescription: line?.information.description,
          isIrrigationProperties: currentLine.information.isIrrigationProperties,
          lineTotalLength: line?.properties.lineTotalLength,
          lineTotalLengthUnit: line?.properties.lineTotalLengthUnit,
          lineCopySettingsFromId:
            currentLine?.properties.lineCopySettingsFromId || line?.properties.lineCopySettingsFromId,
          images: line?.images,
          lineSections,
        })
      );
    }

    if (selectedLineId === NEW_LINE_ID) {
      onChangeLineTotalLength(Length);
      const lineName = `${LINE_DEFAULT_NAME} ${lineCounter}`;

      dispatch(
        setLineInformation({
          lineName,
          lineTotalLengthUnit: Length,
        })
      );
    }

    if (!isLineInfo) {
      // dispatch(resetFormState());
      dispatch(setLineFormState({ validationCount: 0 }));
    }
  }, [isLineInfo, selectedLineId]);

  useEffect(() => {
    const { lineName } = lineInformation;

    const isValidLineName = !!lineName;

    dispatch(setLineFormState({ isValidLineName }));
  }, [lineInformation, lineInformation.isIrrigationProperties]);

  useEffect(() => {
    const unit = lineInformation.lineTotalLengthUnit;
    onChangeLineTotalLength(unit);
  }, [lineInformation.lineTotalLengthUnit]);

  // const isNoValidName = !!lineFormState.validationCount && !lineFormState.isValidLineName;

  const onChangeInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = getStringWithoutFirstZero(e.target.value, e.target.type);
    dispatch(setLineInformation({ [e.target.name]: value }));
  };

  const onToggleIsIrrigationProps = () => {
    dispatch(setLineInformation({ isIrrigationProperties: !lineInformation.isIrrigationProperties }));
  };

  const onChangeLineTotalLength = (unit: string) => {
    const length = getLengthByUnit(currentTotalLengthInMeters, unit);

    dispatch(setLineInformation({ lineTotalLength: length }));
  };

  const onChangeCopySettingsFrom = (
    _: any,
    item:
      | {
          id: number | string;
          name: string;
        }
      | string
  ) => {
    if (typeof item === 'object' && typeof item.id === 'string') {
      dispatch(setLineInformation({ lineCopySettingsFromId: item.id }));
    }
  };

  const onCopySettingsFromBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const settings = copySettingsFromOptions.find((csf) => csf.name === value);

    if (settings) {
      onChangeCopySettingsFrom(null, settings);
    }
  };

  const onLineNameFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return {
    isLineInfo,
    lineInformation,
    line,
    onChangeInformation,
    onLineNameFocus,
    onToggleIsIrrigationProps,
    copySettingsFromOptions,
    onChangeCopySettingsFrom,
    onCopySettingsFromBlur,
    currentLineCopySettingsFrom,
  };
};
