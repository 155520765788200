import { Box, Collapse, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useLinePipeFilters, useToggle } from 'shared/hooks';
import { ConvertUnit, getStringWithoutFirstZero, Math_round, textFormatter } from 'shared/lib';
import { AdditionalSelect, Icon, PolygonInput, Row, Select } from 'shared/ui';
import { KeyboardArrowDown } from '@mui/icons-material';
import { LineSectionProperties } from 'shared/models';
import { UNITS } from 'shared/constants';
import { setLineInformation } from 'shared/slices';
import { useChangeUnit } from '../../shared/hooks/useChangeUnit';

interface Props {
  index: number;
  value: LineSectionProperties;
  onDelete: () => void;
  linePipeSections: LineSectionProperties[];
}

export const LinePipeSection: FC<Props> = ({ index, onDelete, value, linePipeSections }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, toggle } = useToggle(true);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lineSections = linePipeSections.map((section) => {
      if (section.sectionId === value.sectionId) {
        const value = getStringWithoutFirstZero(e.target.value, e.target.type);
        return { ...section, [e.target.name]: value };
      }
      return section;
    });

    dispatch(setLineInformation({ lineSections, lineCopySettingsFromId: null }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  const onChangeUnit = (fieldValue: number, destinationUnit: string, fieldName: keyof LineSectionProperties) => {
    // get current line unit
    const unitFieldName = (fieldName + 'Unit') as keyof LineSectionProperties;
    const lineSections = linePipeSections.map((section) => {
      if (section.sectionId === value.sectionId) {
        const sourceUnit = section[unitFieldName] as string;
        const convertedValue = Math_round(ConvertUnit(fieldValue, sourceUnit, destinationUnit, null), 6).toFixed(2);
        return { ...section, [unitFieldName]: destinationUnit, [fieldName]: convertedValue };
      }
      return section;
    });

    dispatch(setLineInformation({ lineSections, lineCopySettingsFromId: null }));
  };

  const { masterGroups, masterGroupId, onTypeChange } = useLinePipeFilters(value.sectionId);

  const unitRefs: Record<string, React.RefObject<HTMLInputElement>> = {
    linePipeDiameter: useRef(null),
    lineLength: useRef(null),
    lineFlowRate: useRef(null),
    lineMaxVelocity: useRef(null),
  };

  const { handleChangeUnit } = useChangeUnit(onChangeUnit, unitRefs);

  return (
    <Box sx={{ width: '100%', my: 2, pb: 1 }}>
      <Row sx={{ height: '45px', bgcolor: 'rgba(233, 243, 237, 1)', pl: 1.5 }}>
        <Typography sx={{ color: 'primary.main', fontSize: '18px', lineHeight: '45px', fontWeight: 600 }}>
          {textFormatter(t('forms_section'), [index]).output}
        </Typography>

        <Row sx={{ mr: 0.5 }}>
          <IconButton onClick={toggle}>
            <KeyboardArrowDown color="primary" sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
          </IconButton>
          {index > 1 && (
            <IconButton onClick={onDelete}>
              <Icon path="green_trash" />
            </IconButton>
          )}
        </Row>
      </Row>

      <Collapse in={open}>
        <div>
          <Grid container>
            <Grid container item xs={12} spacing={1.5}>
              <Grid item xs={6}>
                <Select name="linePipeType" value={masterGroupId} onChange={onTypeChange} label={t('forms_pipe_type')}>
                  {masterGroups.map((item) => (
                    <MenuItem key={item.ID} value={item.ID}>
                      {item.MASTERGROUPNAME}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <PolygonInput
                  inputRef={unitRefs.linePipeDiameter}
                  label={t('forms_pipe_diameter')}
                  placeholder={t('Enter')}
                  type="number"
                  onKeyDown={handleKeyDown}
                  name="linePipeDiameter"
                  helperText={t('validation_errors_null')}
                  value={value.linePipeDiameter || ''}
                  onChange={onChange}
                  additionalInput={
                    <AdditionalSelect
                      withGreenBorder
                      name="linePipeDiameterUnit"
                      value={value.linePipeDiameterUnit}
                      onChange={handleChangeUnit}
                    >
                      {UNITS.PipeDiameter.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </AdditionalSelect>
                  }
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1.5}>
              <Grid item xs={6}>
                <PolygonInput
                  name="linePipeClass"
                  placeholder={t('Enter')}
                  helperText={t('validation_errors_null')}
                  value={value.linePipeClass}
                  onChange={onChange}
                  label={t('forms_pipe_class')}
                />
              </Grid>
              <Grid item xs={6}>
                <PolygonInput
                  inputRef={unitRefs.lineLength}
                  type="number"
                  placeholder={t('Enter')}
                  name="lineLength"
                  helperText={t('validation_errors_null')}
                  value={value.lineLength || ''}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  label={t('forms_length')}
                  additionalInput={
                    <AdditionalSelect
                      withGreenBorder
                      name="lineLengthUnit"
                      value={value.lineLengthUnit}
                      onChange={handleChangeUnit}
                    >
                      {UNITS.Length.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </AdditionalSelect>
                  }
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1.5}>
              <Grid item xs={6}>
                <PolygonInput
                  inputRef={unitRefs.lineFlowRate}
                  type="number"
                  placeholder={t('Enter')}
                  name="lineFlowRate"
                  helperText={t('validation_errors_null')}
                  value={value.lineFlowRate || ''}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  label={t('forms_flow_rate')}
                  additionalInput={
                    <AdditionalSelect
                      withGreenBorder
                      name="lineFlowRateUnit"
                      value={value.lineFlowRateUnit}
                      onChange={handleChangeUnit}
                    >
                      {UNITS.TotalFlow.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </AdditionalSelect>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <PolygonInput
                  inputRef={unitRefs.lineMaxVelocity}
                  type="number"
                  placeholder={t('Enter')}
                  name="lineMaxVelocity"
                  helperText={t('validation_errors_null')}
                  value={value.lineMaxVelocity || ''}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  label={t('forms_max_vel')}
                  additionalInput={
                    <AdditionalSelect
                      withGreenBorder
                      name="lineMaxVelocityUnit"
                      value={value.lineMaxVelocityUnit}
                      onChange={handleChangeUnit}
                    >
                      {UNITS.Velocity.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </AdditionalSelect>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Collapse>
    </Box>
  );
};
