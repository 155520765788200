import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'shared/hooks';
import {
  getLanguagesThunk,
  getMasterGroupsThunk,
  getMeThunk,
  getPipeGroupsThunk,
  getPipesThunk,
  selectAuth,
  selectMasterGr,
  selectPipes,
} from 'shared/slices';
import { localStorageService } from 'shared/services';
import { Box, CircularProgress } from '@mui/material';

export const PrefetchData: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { masterGroups, loading } = selectMasterGr();
  const { loading: userDataLoading } = selectAuth();
  const { pipeGroups, pipeGroupsLoadedOnce, pipesLoadedOnce } = selectPipes();

  const token = useMemo(() => localStorageService.rapidToken, [localStorageService.rapidToken]);

  useEffect(() => {
    if (token) {
      dispatch(getMasterGroupsThunk());
      dispatch(getLanguagesThunk());
      dispatch(getMeThunk());
    }
  }, [token]);

  useEffect(() => {
    if (masterGroups.length) {
      dispatch(getPipeGroupsThunk({ masterGroup: masterGroups[6].ID }));
    }
  }, [masterGroups]);

  useEffect(() => {
    if (pipeGroups.length) {
      dispatch(getPipesThunk({ group: pipeGroups[0].GROUPS, region: 'Europe', standard: true, limited: true }));
    }
  }, [pipeGroups]);

  const isDataLoaded = pipesLoadedOnce && pipeGroupsLoadedOnce;

  const isLoaded = isDataLoaded && !loading && !userDataLoading;

  return (
    <>
      {isLoaded ? (
        children
      ) : (
        <Box
          sx={{
            height: '100svh',
            bgcolor: 'primary.main',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color={'info'} />
        </Box>
      )}
    </>
  );
};
