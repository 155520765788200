import { Box, Stack, Typography } from '@mui/material';
import { Icon } from '../../shared/ui';
import { localStorageService } from '../../shared/services';
import { selectProject } from '../../shared/slices';

export const UserProfile = () => {
  const { projectData } = selectProject();
  const username = localStorageService.rapidUsername ?? 'Username';

  return (
    <Box sx={{ padding: '24px', paddingBottom: 'unset', position: 'relative', maxWidth: '288px' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pb: '20px',
          mb: '20px',
          color: 'info.main',
          borderBottom: '1px solid white',
          borderColor: 'info.main',
        }}
      >
        <Icon path="/sideMenuIcons/user_avatar" sx={{ width: '32px' }} />

        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 600,
            ml: '14px',
          }}
        >
          {username}
        </Typography>
      </Stack>

      <Typography
        sx={{
          fontSize: 18,
          lineHeight: '24px',
          color: 'rgba(189, 189, 189, 1)',
          wordBreak: 'break-word',
        }}
      >
        {projectData?.projectName}
      </Typography>
    </Box>
  );
};
