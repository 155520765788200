import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

type Props = SvgIconProps & {
  selected?: boolean;
};

export const RowDirectionLineIcon: React.FC<Props> = ({ selected, ...props }) => {
  const iconColor = selected ? '#FFF' : '#242731';

  return (
    <SvgIcon {...props} viewBox="0 0 53 11">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.985 10.0707L52.9935 5.0488L47.9581 0L47.9688 4.00012L19.3612 4.00292L19.357 5.99999L47.9741 5.9972L47.985 10.0707ZM0.878415 3.99998V5.99998H3.87842V3.99998H0.878415ZM6.88128 5.99998V3.99998H9.88128V5.99998H6.88128ZM12.8783 3.99998V5.99998H15.8783V3.99998H12.8783Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
};
