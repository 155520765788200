import { createSlice, Draft, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { LatLng } from 'leaflet';
import { shallowEqual } from 'react-redux';
import {
  LINE,
  NEW_LINE_ID,
  NEW_POINT_ID,
  NEW_POLYGON_ID,
  POINT,
  POLYGON,
  POLYGON_ROW_DIRECTION_ID,
} from '../../constants';
import { MAP_LAYER, SavingStep } from '../../enums';
import { useAppSelector } from '../../hooks';
import { Nullable } from '../../index';
import { Image, LineInformation, Point, PointInformation, PolygonInformation } from '../../models';
import { cancelGpsTracking } from '../gpsTracking';
import {
  addPolygonRowDirectionLine,
  deleteLine,
  selectLine,
  startLineDrawing,
  stopPolygonRowDirectionLineDrawing,
} from '../lines';
import { deletePoint, selectPoint, startPointDrawing } from '../points';
import { deletePolygon, selectPolygon, startPolygonDrawing } from '../polygons';
import { saveProject } from '../projectData';

interface MapState {
  isSideMenu: boolean;
  isPolygonInfo: boolean;
  isLineInfo: boolean;
  isPointInfo: boolean;
  isColorPicker: boolean;
  isImagesView: boolean;
  isTrackingPointDrawing: boolean;
  isItemDrawing: boolean;
  isItemEditing: boolean;
  isItemShowedInfo: boolean;
  isMapTileLoading: boolean;
  mapLayer: MAP_LAYER;
  searchedPoint: LatLng | null;
  searchRequestCount: number;
  userLocationPoint: LatLng | null;
  trackingPoint: LatLng | null;
  saveProjectStep: SavingStep;
  drawMode: 'manual' | 'gps' | 'none';
  selectedItem: Nullable<'polygon' | 'line' | 'point'>;
  currentItemId: Nullable<string>;
  shouldTakeScreenshot: boolean;
  showSimplifyOption: boolean /* only for item debugging purposes */;
}

const initialState: MapState = {
  isSideMenu: false,
  isPolygonInfo: false,
  isLineInfo: false,
  isPointInfo: false,
  isColorPicker: false,
  isImagesView: false,
  isTrackingPointDrawing: false,
  isItemDrawing: false,
  isItemEditing: false,
  isItemShowedInfo: false,
  isMapTileLoading: false,
  mapLayer: 'hybrid',
  searchedPoint: null,
  searchRequestCount: 0,
  userLocationPoint: null,
  trackingPoint: null,
  saveProjectStep: SavingStep.DONE,
  drawMode: 'none',
  selectedItem: null,
  currentItemId: null,
  shouldTakeScreenshot: false,
  showSimplifyOption: false /* only for item debugging purposes */,
};

const newItemIdConfig = {
  [startLineDrawing.type]: NEW_LINE_ID,
  [startPolygonDrawing.type]: NEW_POLYGON_ID,
  [startPointDrawing.type]: NEW_POINT_ID,
};

const drawingItemTypeConfig = {
  [startLineDrawing.type]: LINE,
  [startPolygonDrawing.type]: POLYGON,
  [startPointDrawing.type]: POINT,
} as const;

export const mapSlice = createSlice({
  name: 'mapState',
  initialState,
  reducers: {
    toggleColorPicker(state) {
      state.isColorPicker = !state.isColorPicker;
    },
    toggleImagesView(state) {
      state.isImagesView = !state.isImagesView;
    },
    toggleTrackingPointDrawing(state) {
      state.isTrackingPointDrawing = !state.isTrackingPointDrawing;
      if (!state.isTrackingPointDrawing) {
        state.trackingPoint = null;
      }
    },
    /* only for item debugging purposes */
    setShowSimplifyOption(state, action: PayloadAction<boolean>) {
      state.showSimplifyOption = action.payload;
    },
    setMapTileLoading(state, action: PayloadAction<boolean>) {
      state.isMapTileLoading = action.payload;
    },
    setIsItemInfo(state, action: PayloadAction<boolean>) {
      setIsItemShowedInfo(state, action.payload);
    },
    setIsPolygonInfo(state, action: PayloadAction<boolean>) {
      state.isPolygonInfo = action.payload;
    },
    setIsLineInfo(state, action: PayloadAction<boolean>) {
      state.isLineInfo = action.payload;
    },
    setIsPointInfo(state, action: PayloadAction<boolean>) {
      state.isPointInfo = action.payload;
    },
    setSearchedPoint(state, action: PayloadAction<LatLng | null>) {
      state.searchedPoint = action.payload;
    },
    setSearchRequestCount(state, action: PayloadAction<number>) {
      state.searchRequestCount = action.payload;
    },
    setUserLocationPoint(state, action: PayloadAction<LatLng | null>) {
      state.userLocationPoint = action.payload;
    },
    setMapLayer(state, action: PayloadAction<MAP_LAYER>) {
      state.mapLayer = action.payload;
    },
    setTrackingPoint(state, action: PayloadAction<LatLng | null>) {
      state.trackingPoint = action.payload;
    },
    setSaveProjectStep(state, action: PayloadAction<SavingStep>) {
      state.saveProjectStep = action.payload;
    },
    setDrawMode(state, action: PayloadAction<MapState['drawMode']>) {
      /** has also case in {@link gpsTrackingSlice} */
      state.drawMode = action.payload;
    },
    setShouldTakeScreenshot(state, action: PayloadAction<boolean>) {
      state.shouldTakeScreenshot = action.payload;
    },
    setItemScreenshot(
      state,
      action: PayloadAction<{ images: Image[]; isNewItem: boolean; item: MapState['selectedItem'] }>
    ) {
      // has also cases in each items reducer
      if (!state.currentItemId) return;
      state.saveProjectStep = action.payload.isNewItem ? SavingStep.DONE : SavingStep.SAVING;
    },
    deleteItemScreenshot(state) {
      // has also cases in each items reducer
      if (!state.currentItemId) return;
    },
    editItem(state) {
      // has also cases in each items reducer
      if (!state.currentItemId) return;
      state.isItemDrawing = true;
      state.isItemEditing = true;
    },
    saveItem(
      state,
      action: PayloadAction<{
        information: Nullable<PolygonInformation | LineInformation | PointInformation>;
        itemType: MapState['selectedItem'];
        itemId: string;
      }>
    ) {
      /** handled with case in {@link projectSlice}*/
      /* handled with cases in each item reducer */
      if (!action.payload.itemId || !action.payload.information) return;
    },
    setNewItemPoints(state, action: PayloadAction<Point[]>) {
      /** handled by {@link polygonSlice} & {@link lineSlice} */
    },
    clearItemPoints(state, action: PayloadAction<string>) {
      /** handled by {@link polygonSlice} & {@link lineSlice} */
    },
    stopItemDrawing(state) {
      // has also cases in each items reducer
      stopDrawing(state);
    },
    unselectItems(state) {
      // has also cases in each items reducer
      state.isItemEditing = false;
      state.selectedItem = null;
      state.currentItemId = null;
    },
    resetDrawMode(state) {
      /** also handled by {@link gpsTrackingSlice} */
      state.drawMode = initialState.drawMode;
    },
  },
  selectors: {
    selectMap: (state) => state,
    selectIsImagesView: (state) => state.isImagesView,
    selectIsPointInfo: (state) => state.isPointInfo,
    selectIsLineInfo: (state) => state.isLineInfo,
    selectIsPolygonInfo: (state) => state.isPolygonInfo,
    selectDrawMode: (state) => state.drawMode,
    selectIsItemDrawing: (state) => state.isItemDrawing,
    selectIsItemEditing: (state) => state.isItemEditing,
    selectIsItemInfo: (state) => state.isPolygonInfo || state.isLineInfo || state.isPointInfo,
    selectSelectedItem: (state) => state.selectedItem,
    selectCurrentItemId: (state) => state.currentItemId,
    selectIsColorPicker: (state) => state.isColorPicker,
    selectIsMapTileLoading: (state) => state.isMapTileLoading,
    selectSaveProjectStep: (state) => state.saveProjectStep,
    selectIsTrackingPointDrawing: (state) => state.isTrackingPointDrawing,
    selectSearchedPoint: (state) => state.searchedPoint,
    selectTrackingPoint: (state) => state.trackingPoint,
    selectMapLayer: (state) => state.mapLayer,
    selectUserLocationPoint: (state) => state.userLocationPoint,
    selectShouldTakeScreenshot: (state) => state.shouldTakeScreenshot,
    selectSearchRequestCount: (state) => state.searchRequestCount,
    selectShowSimplifyOption: (state) => state.showSimplifyOption /* only for item debugging purposes */,
  },
  extraReducers: (builder) => {
    builder.addCase(saveProject, (state) => {
      setIsItemShowedInfo(state, false);
      state.saveProjectStep = SavingStep.DONE;
      state.isImagesView = false;
      stopDrawing(state);
    });
    builder.addCase(selectLine, (state, action) => {
      if (action.payload) {
        state.selectedItem = state.selectedItem === LINE ? null : LINE;
        state.currentItemId = action.payload;
      }
    });
    builder.addCase(selectPoint, (state, action) => {
      if (action.payload) {
        state.selectedItem = POINT;
        state.currentItemId = action.payload;
      }
    });
    builder.addCase(selectPolygon, (state, action) => {
      if (action.payload) {
        state.selectedItem = state.selectedItem === POLYGON ? null : POLYGON;
        state.currentItemId = action.payload;
      }
    });
    builder.addCase(cancelGpsTracking, (state) => {
      state.drawMode = 'manual';
    });
    builder.addCase(addPolygonRowDirectionLine, (state, action) => {
      const { relatedPolygon } = action.payload;
      const lineId = `${relatedPolygon.geoJson.id}${POLYGON_ROW_DIRECTION_ID}`;
      state.selectedItem = LINE;
      state.currentItemId = lineId;
      state.isItemDrawing = true;
      state.isItemEditing = true;
    });
    builder.addCase(stopPolygonRowDirectionLineDrawing, (state, action) => {
      const { relatedPolygon, isPolygonDrawing, isPolygonEditing } = action.payload;
      state.isItemDrawing = isPolygonDrawing;
      state.isItemEditing = isPolygonEditing;
      state.selectedItem = POLYGON;
      state.currentItemId = relatedPolygon.geoJson.id;
    });
    builder.addMatcher(isAnyOf(startPolygonDrawing, startLineDrawing, startPointDrawing), (state, action) => {
      state.selectedItem = drawingItemTypeConfig[action.type];
      state.currentItemId = newItemIdConfig[action.type];
      state.isItemDrawing = true;
    });
    builder.addMatcher(isAnyOf(deleteLine, deletePolygon, deletePoint), (state) => {
      stopDrawing(state);
    });
    builder.addMatcher(isAnyOf(selectLine, selectPoint, selectPolygon), (state) => {
      /* when some item is selected then we set isItemEditing to true and vise versa */
      /* we could edit item only if it is not NEW */
      if (state.currentItemId?.includes('new')) return;

      state.isItemEditing = state.selectedItem !== null;
    });
  },
});

export const {
  setIsPolygonInfo,
  setIsLineInfo,
  setIsPointInfo,
  toggleColorPicker,
  toggleImagesView,
  toggleTrackingPointDrawing,
  setSearchedPoint,
  setUserLocationPoint,
  setMapLayer,
  setTrackingPoint,
  setSaveProjectStep,
  setMapTileLoading,
  setDrawMode,
  unselectItems,
  stopItemDrawing,
  editItem,
  setItemScreenshot,
  saveItem,
  setIsItemInfo,
  resetDrawMode,
  clearItemPoints,
  setNewItemPoints,
  setShowSimplifyOption,
  deleteItemScreenshot,
  setShouldTakeScreenshot,
  setSearchRequestCount,
} = mapSlice.actions;

export const selectMap = () => useAppSelector(mapSlice.selectors.selectMap);
export const selectDrawMode = () => useAppSelector(mapSlice.selectors.selectDrawMode);
export const selectIsItemDrawing = () => useAppSelector(mapSlice.selectors.selectIsItemDrawing);
export const selectIsTrackingPointDrawing = () => useAppSelector(mapSlice.selectors.selectIsTrackingPointDrawing);
export const selectIsItemEditing = () => useAppSelector(mapSlice.selectors.selectIsItemEditing);
export const selectIsItemInfo = () => useAppSelector(mapSlice.selectors.selectIsItemInfo);
export const selectSelectedItem = () => useAppSelector(mapSlice.selectors.selectSelectedItem);
export const selectCurrentItemId = () => useAppSelector(mapSlice.selectors.selectCurrentItemId);
export const selectIsPointInfo = () => useAppSelector(mapSlice.selectors.selectIsPointInfo);
export const selectIsLineInfo = () => useAppSelector(mapSlice.selectors.selectIsLineInfo);
export const selectIsPolygonInfo = () => useAppSelector(mapSlice.selectors.selectIsPolygonInfo);
export const selectIsColorPicker = () => useAppSelector(mapSlice.selectors.selectIsColorPicker);
export const selectIsMapTileLoading = () => useAppSelector(mapSlice.selectors.selectIsMapTileLoading);
export const selectSaveProjectStep = () => useAppSelector(mapSlice.selectors.selectSaveProjectStep);
export const selectMapLayer = () => useAppSelector(mapSlice.selectors.selectMapLayer);
export const selectSearchedPoint = () => useAppSelector(mapSlice.selectors.selectSearchedPoint, shallowEqual);
export const selectTrackingPoint = () => useAppSelector(mapSlice.selectors.selectTrackingPoint, shallowEqual);
export const selectUserLocationPoint = () => useAppSelector(mapSlice.selectors.selectUserLocationPoint, shallowEqual);
export const selectIsImagesView = () => useAppSelector(mapSlice.selectors.selectIsImagesView);
export const selectShouldTakeScreenshot = () => useAppSelector(mapSlice.selectors.selectShouldTakeScreenshot);
export const selectSearchRequestCount = () => useAppSelector(mapSlice.selectors.selectSearchRequestCount);

export const selectCurrentMapItem = () =>
  useAppSelector((state) => {
    const currentItem = state.mapState?.selectedItem;
    const id = state.mapState?.currentItemId || '';
    const { lines: lData, points: pData, polygons: polData } = state;
    switch (currentItem) {
      case POLYGON:
        return polData?.polygons.find((pol) => pol.geoJson.id === id) ?? polData?.newPolygon;
      case LINE:
        return lData?.lines.find((line) => line.geoJson.id === id) ?? lData?.newLine;
      case POINT:
        return pData?.points.find((point) => point.geoJson.id === id) ?? pData?.newPoint;
      default:
        return null;
    }
  });

export const selectCurrentItemInformation = () =>
  useAppSelector((state) => {
    const currentItem = state.mapState?.selectedItem;
    const {
      formState: { polygonInformation, lineInformation, pointInformation },
    } = state;
    switch (currentItem) {
      case POLYGON:
        return polygonInformation;
      case LINE:
        return lineInformation;
      case POINT:
        return pointInformation;
      default:
        return null;
    }
  });

export const selectIsNewItem = () => useAppSelector((state) => state.mapState?.currentItemId?.includes('new'));

const stopDrawing = (state: Draft<MapState>) => {
  state.isItemDrawing = false;
  state.isItemEditing = false;
  state.currentItemId = null;
  state.selectedItem = null;
};

const setIsItemShowedInfo = (state: Draft<MapState>, isShow: boolean) => {
  state.isItemShowedInfo = false;
  switch (state.selectedItem) {
    case 'line':
      if (state.currentItemId?.includes(POLYGON_ROW_DIRECTION_ID)) {
        state.isPolygonInfo = isShow;
        break;
      }
      state.isLineInfo = isShow;
      break;
    case 'polygon':
      state.isPolygonInfo = isShow;
      break;
    case 'point':
      state.isPointInfo = isShow;
      break;
    default:
      break;
  }
};

export const mapReducer = mapSlice.reducer;
