import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { METRIC_DEFAULTS } from '../../constants';
import { Unit } from '../../models';
import { useAppSelector } from '../../hooks';
import { saveProject } from '../projectData';

interface UserState {
  loading: boolean;
  units: Unit;
  unitsChanged: boolean;
}

const initialState: UserState = {
  loading: true,
  units: METRIC_DEFAULTS,
  unitsChanged: false,
};

// export const getUnitsThunk = createAsyncThunk('getUnits', async (params: UnitsBody, thunkAPI) => {
//   try {
//     const response = await customAxios.get<UnitsResponse>(
//       'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetUnits',
//       { params }
//     );
//     return response.data;
//   } catch (e) {
//     if (e instanceof Error) {
//       return thunkAPI.rejectWithValue(e.message);
//     }
//   }
// });

// export const saveUnitsThunk = createAsyncThunk('aveUnits', async (body: SaveUnitsBody, thunkAPI) => {
//   try {
//     const response = await customAxios.post<SaveUnitsResponse>(
//       'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/SaveUnits',
//       body
//     );
//     return response.data;
//   } catch (e) {
//     if (e instanceof Error) {
//       return thunkAPI.rejectWithValue(e.message);
//     }
//   }
// });

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setUnitsValue: (state, action: PayloadAction<{ units: Partial<Unit>; unitsChanged?: boolean }>) => {
      const { units, unitsChanged = false } = action.payload;
      state.units = { ...state.units, ...units };
      state.unitsChanged = unitsChanged;
    },
    resetUnits: () => initialState,
  },
  selectors: {
    selectUnits: (state) => state,
    selectUnitsLength: (state) => state.units.Length,
    selectUnitsChanged: (state) => state.unitsChanged,
  },
  extraReducers: (builder) => {
    builder.addCase(saveProject, (state) => {
      if (state.unitsChanged) {
        state.unitsChanged = false;
      }
    });
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getUnitsThunk.pending.type, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(getUnitsThunk.fulfilled.type, (state, action: PayloadAction<UnitsResponse>) => {
  //       state.units = action.payload.GetUnitsResult.RootResults[0] ?? {};
  //       state.loading = false;
  //     })
  //     .addCase(getUnitsThunk.rejected.type, (state) => {
  //       state.loading = false;
  //     });
  // },
});

export const { resetUnits, setUnitsValue } = unitsSlice.actions;

export const selectUnits = () => useAppSelector(unitsSlice.selectors.selectUnits);
export const selectUnitsLength = () => useAppSelector(unitsSlice.selectors.selectUnitsLength);
export const selectUnitsChanged = () => useAppSelector(unitsSlice.selectors.selectUnitsChanged);

export const unitsReducer = unitsSlice.reducer;
