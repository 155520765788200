import distance from '@turf/distance';
import { LatLng, LineUtil, Point } from 'leaflet';
import { useRef } from 'react';
import { convertLatLngToXYPoints, convertXYPointsToLatLng, getAzimuth, getPositionFromLatLng } from '../lib';
import { useMapActions } from './useMapActions';
import simplify = LineUtil.simplify;

const SIMPLIFY_TOLERANCE = 0.00001;

export const useGpsTrackingData = () => {
  const { createPoint } = useMapActions();

  const prevPointsAngle = useRef<number | null>(null);
  const allowableDistancePoint = useRef<{ x: number; y: number } | null>(null);

  const filterPointsByAllowableDistance = (data: LatLng[], minDistance: number) => {
    return data.filter((point) => {
      const currentPoint = { x: point.lng, y: point.lat };
      // if we don't have start point then initialize it and return true to begin comparison
      if (!allowableDistancePoint.current) {
        allowableDistancePoint.current = currentPoint;
        return true;
      }
      // if points too close to each other than skip one of ones
      const currentDistance = distance(
        [allowableDistancePoint.current.x, allowableDistancePoint.current.y],
        [currentPoint.x, currentPoint.y],
        {
          units: 'meters',
        }
      );
      const couldAddNewPoint = currentDistance > minDistance;
      if (couldAddNewPoint) {
        allowableDistancePoint.current = currentPoint;
      }
      return couldAddNewPoint;
    });
  };

  const filterPointsByAllowableAngle = (data: LatLng[], minAngle?: number) => {
    return data.filter((point, index) => {
      if (index === 0 || index === data.length - 1) {
        return true;
      }
      const latLngTupleCurrent = getPositionFromLatLng(point);
      const latLngTupleNext = getPositionFromLatLng(data[index + 1]);
      const lineAngle = getAzimuth(latLngTupleCurrent, latLngTupleNext);
      if (prevPointsAngle.current === null) {
        prevPointsAngle.current = lineAngle;
        return true;
      }
      const couldAddNewPoint = prevPointsAngle.current !== lineAngle;
      prevPointsAngle.current = lineAngle;
      return couldAddNewPoint;
    });
  };

  const createMapItemFromGpsData = (data: LatLng[]) => {
    if (!data.length) return;

    // check if distance is more than minimal
    const filteredMinimalDistanceData = filterPointsByAllowableDistance(data, 3);

    // check if the line is even
    const filteredAngleData = filterPointsByAllowableAngle(filteredMinimalDistanceData);

    /** simplify object shape with tolerance {@link SIMPLIFY_TOLERANCE} */
    const convertedPointsToXY = convertLatLngToXYPoints(filteredAngleData) as Point[];
    const itemSimplified = simplify(convertedPointsToXY, SIMPLIFY_TOLERANCE);
    const convertedPointsToLatLngSimplified = convertXYPointsToLatLng(itemSimplified) as LatLng[];

    // create item from points
    convertedPointsToLatLngSimplified.forEach((point) => {
      createPoint(point);
    });
  };

  return { createMapItemFromGpsData };
};
