import React from 'react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

export const useChangeUnit = <T>(
  onChangeUnit: (value: number, destinationUnit: string, fieldName: keyof T) => void,
  unitRefs: Record<string, React.RefObject<HTMLInputElement>> = {}
) => {
  const fetchUnitFieldsFromEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unit = e.target.value;
    // get field name that is changed
    const fieldName = e.target.name.replace('Unit', '') as keyof T;
    const value = Number(unitRefs[fieldName as string].current?.value || 0);
    return {
      unit,
      fieldName,
      value,
    };
  };
  const handleChangeUnit = (e: SelectChangeEvent<unknown>) => {
    const { unit, fieldName, value } = fetchUnitFieldsFromEvent(e as React.ChangeEvent<HTMLInputElement>);
    onChangeUnit(value, unit, fieldName);
  };

  return { handleChangeUnit };
};
