import { Box, Container, Stack, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui';

type Props = PropsWithChildren & {
  onSaveItem: () => void;
  sx?: SxProps;
};

export const ItemInfoContainer: FC<Props> = ({ onSaveItem, children, sx }) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.68)',
        position: 'fixed',
        zIndex: 2,
        top: '104px',
        width: '100%',
        height: 'calc(100svh - 104px)',
        ...sx,
      }}
    >
      <Container maxWidth="sm" disableGutters>
        <Stack sx={{ py: 2, bgcolor: 'rgba(240, 251, 255, 1)', minHeight: 'calc(100svh - 104px)' }}>
          <Box
            sx={{
              px: 2,
              height: 'calc(100svh - 104px - 108px - 16px)',
              overflowY: 'auto',
              overflowX: 'hidden',
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
              },
              '&::-webkit-scrollbar': {
                width: '5px',
              },
            }}
          >
            {children}
          </Box>

          <Box
            sx={{
              pl: 2,
              pr: '21px',
              height: '44px',
              py: 3,
              position: 'sticky',
              bottom: 40,
            }}
          >
            <Button onClick={onSaveItem} fullWidth btnColor="primary.main" sx={{ borderRadius: '16px' }}>
              {t('units_save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};
