import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const SearchIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4 16.8C13.9346 16.8 16.8 13.9346 16.8 10.4C16.8 6.86538 13.9346 4 10.4 4C6.86538 4 4 6.86538 4 10.4C4 13.9346 6.86538 16.8 10.4 16.8Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2 15.2002L20 20.0002"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
