import { FC } from 'react';
import { LineModel } from '../../shared/models';
import { useAppDispatch } from '../../shared/hooks';
import {
  selectIsItemDrawing,
  selectIsTrackingPointDrawing,
  selectLine,
  selectLineId,
  selectPoint,
  selectPolygon,
  unselectItems,
} from '../../shared/slices';
import { BaseLineItem } from './components/BaseLineItem';
import { RowDirectionLineItem } from './components/RowDirectionLineItem';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';

interface Props {
  lineInfo: LineModel;
}

export const LineItem: FC<Props> = ({ lineInfo }) => {
  const dispatch = useAppDispatch();
  const isDrawing = selectIsItemDrawing();
  const selectedLineId = selectLineId();
  const isTrackingPointDrawing = selectIsTrackingPointDrawing();

  const onSelectLine = () => {
    if (isDrawing || isTrackingPointDrawing) return;
    const { id } = lineInfo.geoJson;

    dispatch(selectPolygon(null));
    dispatch(selectPoint(null));
    if (selectedLineId) {
      dispatch(selectLine(null));
      dispatch(unselectItems());
    } else {
      dispatch(selectLine(id));
    }
  };

  const eventHandlers = {
    click() {
      onSelectLine();
    },
  };

  return (
    <>
      {lineInfo.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID) ? (
        <RowDirectionLineItem lineInfo={lineInfo} eventHandlers={eventHandlers} />
      ) : (
        <BaseLineItem lineInfo={lineInfo} eventHandlers={eventHandlers} />
      )}
    </>
  );
};
