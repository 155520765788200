import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M13.2329 11.9985L18.7414 6.49874C18.906 6.3341 18.9985 6.11079 18.9985 5.87794C18.9985 5.6451 18.906 5.42179 18.7414 5.25714C18.5767 5.0925 18.3534 5 18.1206 5C17.8877 5 17.6644 5.0925 17.4998 5.25714L12 10.7657L6.50024 5.25714C6.33559 5.0925 6.11228 5 5.87944 5C5.64659 5 5.42328 5.0925 5.25864 5.25714C5.09399 5.42179 5.00149 5.6451 5.00149 5.87794C5.00149 6.11079 5.09399 6.3341 5.25864 6.49874L10.7671 11.9985L5.25864 17.4983C5.17668 17.5796 5.11164 17.6763 5.06725 17.7828C5.02285 17.8894 5 18.0036 5 18.1191C5 18.2345 5.02285 18.3488 5.06725 18.4553C5.11164 18.5619 5.17668 18.6586 5.25864 18.7399C5.33992 18.8218 5.43663 18.8869 5.54318 18.9313C5.64972 18.9757 5.76401 18.9985 5.87944 18.9985C5.99486 18.9985 6.10915 18.9757 6.2157 18.9313C6.32225 18.8869 6.41895 18.8218 6.50024 18.7399L12 13.2314L17.4998 18.7399C17.581 18.8218 17.6778 18.8869 17.7843 18.9313C17.8909 18.9757 18.0051 18.9985 18.1206 18.9985C18.236 18.9985 18.3503 18.9757 18.4568 18.9313C18.5634 18.8869 18.6601 18.8218 18.7414 18.7399C18.8233 18.6586 18.8884 18.5619 18.9328 18.4553C18.9771 18.3488 19 18.2345 19 18.1191C19 18.0036 18.9771 17.8894 18.9328 17.7828C18.8884 17.6763 18.8233 17.5796 18.7414 17.4983L13.2329 11.9985Z"
      fill={props.fill ?? '#1D8742'}
    />
  </SvgIcon>
);
