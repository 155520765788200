import React, { FocusEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NEW_POINT_ID, POINT_DEFAULT_NAME } from 'shared/constants';
import { PointIconType } from 'shared/enums';
import { useAppDispatch } from 'shared/hooks';
import { ConvertUnit, getLengthByUnit, getStringWithoutFirstZero, Math_round } from 'shared/lib';
import { PointModel, PointProperties } from 'shared/models';
import {
  selectCurrentElevationInMeters,
  selectCurrentMapItem,
  selectIsPointInfo,
  selectPointById,
  selectPointId,
  selectPointInforamtion,
  selectPoints,
  selectProjectPointCount,
  selectUnits,
  setPointFormState,
  setPointInformation,
} from 'shared/slices';

export const useManagePointInfo = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const point = selectCurrentMapItem() as PointModel;
  const pointSettingsFrom = selectPointById(point?.properties.pointCopySettingsFromId || '');
  const points = selectPoints();

  const copySettingsFromOptions = points
    .filter((point) => point.information.isIrrigationProperties)
    .filter((point) => !point.properties.pointCopySettingsFromId) // get only parent items
    .map((point) => ({
      id: point.geoJson.id,
      name: point.information.name,
    }));

  const {
    units: { Length, Pressure, TotalFlow, ValveDiameter },
  } = selectUnits();

  const isPointInfo = selectIsPointInfo();
  const pointInformation = selectPointInforamtion();
  const selectedPointId = selectPointId();
  const currentElevationInMeters = selectCurrentElevationInMeters();

  const pointCounter = selectProjectPointCount();

  const [currentPointCopySettingsFrom, setCurrentPointCopySettingsFrom] = useState<string | null>(null);

  const TYPES = useMemo(
    () => [
      { label: t('point_types_water_source'), value: PointIconType.WATER_SOURCE },
      { label: t('point_types_valve'), value: PointIconType.VALVE },
      { label: t('point_types_other'), value: PointIconType.OBSTACLE },
    ],
    [t, i18n.language]
  );

  useEffect(() => {
    if (pointInformation.pointCopySettingsFromId) {
      const pointSettingsFrom = points.find((p) => p.geoJson.id === pointInformation.pointCopySettingsFromId);

      if (pointSettingsFrom) {
        setCurrentPointCopySettingsFrom(pointSettingsFrom.information.name);

        const {
          pointDiameter,
          pointDiameterUnit,
          pointPressure,
          pointPressureUnit,
          pointFlowRate,
          pointFlowRateUnit,
          pointWaterType,
          isFiltration,
          isPumping,
          isFertigation,
          isOperationAndControl,
          isExistingFiltration,
          isExistingPump,
          isExistingFertigation,
          isExistingOperationAndControl,
          pointFiltrationMode,
          pointFiltrationType,
          pointPumpType,
          pointPumpValue,
          pointPumpCapacity,
          pointPumpPressure,
          isElectricFertigation,
          pointWaterMotivatedFertigationType,
          pointElectricFertigationType,
          operationAndControlMode,
          operationAndControlCommunicationType,
        } = pointSettingsFrom.properties;

        dispatch(
          setPointInformation({
            pointCopySettingsFromId: pointSettingsFrom.geoJson.id,
            pointDiameter,
            pointDiameterUnit,
            pointPressure,
            pointPressureUnit,
            pointFlowRate,
            pointFlowRateUnit,
            pointWaterType,
            isFiltration,
            isPumping,
            isFertigation,
            isOperationAndControl,
            isExistingFiltration,
            isExistingPump,
            isExistingFertigation,
            isExistingOperationAndControl,
            pointFiltrationMode,
            pointFiltrationType,
            pointPumpType,
            pointPumpValue,
            pointPumpCapacity,
            pointPumpPressure,
            isElectricFertigation,
            pointWaterMotivatedFertigationType,
            pointElectricFertigationType,
            operationAndControlMode,
            operationAndControlCommunicationType,
          })
        );
      }
    } else {
      setCurrentPointCopySettingsFrom(null);
    }
  }, [pointInformation.pointCopySettingsFromId]);

  useEffect(() => {
    const currentPoint = pointSettingsFrom ?? point;

    if (currentPoint) {
      const { isIrrigationProperties } = currentPoint.information;

      dispatch(
        setPointInformation({
          pointName: point?.information.name,
          pointDescription: point?.information.description,
          ...currentPoint.properties,
          pointElevation: point?.properties.pointElevation,
          pointElevationUnit: point?.properties.pointElevationUnit,
          isIrrigationProperties,
          pointCopySettingsFromId:
            currentPoint?.properties.pointCopySettingsFromId || point?.properties.pointCopySettingsFromId,
          images: point?.images,
        })
      );
    }

    if (selectedPointId === NEW_POINT_ID) {
      onChangePointElevation(Length);

      const pointName = `${POINT_DEFAULT_NAME} ${pointCounter}`;

      dispatch(
        setPointInformation({
          pointName,
          pointElevationUnit: Length,
          pointDiameterUnit: ValveDiameter,
          pointPressureUnit: Pressure,
          pointFlowRateUnit: TotalFlow,
        })
      );

      // if (pointInformation.pointType === 'waterSource') {
      //   dispatch(setPointInformation({ pointName: t('point_types_water_source') }));
      // }
      // if (pointInformation.pointType === 'valve') {
      //   dispatch(setPointInformation({ pointName: t('point_types_valve') }));
      // }
      // if (pointInformation.pointType === 'obstacle') {
      //   dispatch(setPointInformation({ pointName: t('point_types_obstacle') }));
      // }
    }

    if (!isPointInfo) {
      // dispatch(resetFormState());
      dispatch(setPointFormState({ validationCount: 0 }));
    }
  }, [isPointInfo, selectedPointId]);

  useEffect(() => {
    const unit = pointInformation.pointElevationUnit;
    onChangePointElevation(unit);
  }, [pointInformation.pointElevationUnit]);

  useEffect(() => {
    const { pointName } = pointInformation;
    const isValidPointName = !!pointName;

    dispatch(setPointFormState({ isValidPointName }));
  }, [pointInformation, pointInformation.pointType]);

  // const isNoValidName = !!pointFormState.validationCount && !pointFormState.isValidPointName;
  const [isTouchedName, setIsTouchedName] = useState(false);
  const onChangeInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value;

    switch (e.target.type) {
      case 'checkbox':
        value = e.target.checked;
        break;
      case 'radio':
        value = e.target.value;
        break;
      default:
        value = getStringWithoutFirstZero(e.target.value, e.target.type);
    }

    dispatch(setPointInformation({ [e.target.name]: value, pointCopySettingsFromId: null }));

    if (selectedPointId === NEW_POINT_ID) {
      if (e.target.name === 'pointName') setIsTouchedName(true);

      // if (e.target.name === 'pointType' && !isTouchedName) {
      // e.target.value === 'waterSource' && dispatch(setPointInformation({ pointName: t('point_types_water_source') }));
      // e.target.value === 'valve' && dispatch(setPointInformation({ pointName: t('point_types_valve') }));
      // e.target.value === 'obstacle' && dispatch(setPointInformation({ pointName: t('point_types_obstacle') }));
      // }
    }
  };

  const onToggleIsIrrigationProps = () => {
    dispatch(setPointInformation({ isIrrigationProperties: !pointInformation.isIrrigationProperties }));
  };

  const onChangePointElevation = (unit: string) => {
    const elevation = getLengthByUnit(currentElevationInMeters, unit);

    dispatch(setPointInformation({ pointElevation: elevation }));
  };

  /**
   * Handle PointIrrigationProperties changes of unit values and convert it
   * @param value
   * @param destinationUnit
   * @param fieldName
   */
  const onChangeUnit = (value: number, destinationUnit: string, fieldName: keyof PointProperties) => {
    // get current unit
    const unitFieldName = (fieldName + 'Unit') as keyof PointProperties;
    const sourceUnit = pointInformation[unitFieldName] as string;
    const convertedValue = Math_round(ConvertUnit(value, sourceUnit, destinationUnit, null), 6).toFixed(2);
    dispatch(
      setPointInformation({
        [unitFieldName]: destinationUnit,
        [fieldName]: convertedValue,
        pointCopySettingsFromId: null,
      })
    );
  };

  const onChangeCopySettingsFrom = (
    _: any,
    item:
      | {
          id: number | string;
          name: string;
        }
      | string
  ) => {
    if (typeof item === 'object' && typeof item.id === 'string') {
      dispatch(setPointInformation({ pointCopySettingsFromId: item.id }));
    }
  };

  const onPointNameFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return {
    isPointInfo,
    pointInformation,
    point,
    onChangeInformation,
    onPointNameFocus,
    TYPES,
    onToggleIsIrrigationProps,
    onChangeUnit,
    copySettingsFromOptions,
    onChangeCopySettingsFrom,
    currentPointCopySettingsFrom,
  };
};
