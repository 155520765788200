import { PayloadAction, createSlice, Draft, createAsyncThunk } from '@reduxjs/toolkit';
import { Project, ProjectDataFromServer, ProjectFromServer } from '../../models';
import { useAppSelector } from '../../hooks';
import { shallowEqual } from 'react-redux';
import { saveItem } from '../mapState';
import { LINE, POINT, POLYGON } from '../../constants';
import { Nullable } from '../../index';
import { projectApi } from '../../api';
import { RootState } from '../../../app/store';
import { mapLineToGeoJSON, mapPointToGeoJSON, mapPolygonToGeoJSON } from '../../lib';

interface ProjectState {
  createDate: string;
  modifyDate: string;
  id: number | null;
  userId: string | null;
  projectData: ProjectDataFromServer | null;
}

const initialState: ProjectState = {
  projectData: {
    customerName: '',
    projectName: '',
    lineCount: 0,
    pointCount: 0,
    polygonCount: 0,
    geoJSON: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  createDate: '',
  modifyDate: '',
  id: null,
  userId: null,
};

export const updateProjectThunk = createAsyncThunk<ProjectFromServer, undefined, { state: RootState }>(
  'project/update',
  async (_, { getState }) => {
    const state = getState();

    const polygons = state.polygons?.polygons;
    const lines = state.lines?.lines;
    const points = state.points?.points;

    const projectId = state.project?.id;

    const projectData = state.project?.projectData;

    const mapToSave = {
      type: 'FeatureCollection',
      features: [...mapPolygonToGeoJSON(polygons), ...mapLineToGeoJSON(lines), ...mapPointToGeoJSON(points)],
    };

    const project = {
      projectName: projectData?.projectName ?? '',
      customerName: projectData?.customerName ?? '',
      geoJSON: mapToSave,
      polygonCount: projectData?.polygonCount ?? 1,
      lineCount: projectData?.lineCount ?? 1,
      pointCount: projectData?.pointCount ?? 1,
    } as ProjectDataFromServer;

    const { data: responseData } = await projectApi.updateProject(projectId, project);
    return responseData.data;
  }
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectData(state, action: PayloadAction<ProjectDataFromServer>) {
      state.projectData = action.payload;
    },
    setProject(state, action: PayloadAction<Project>) {
      state.projectData = action.payload.project;
      state.createDate = action.payload.createDate;
      state.modifyDate = action.payload.modifyDate;
      state.userId = action.payload.userId;
      state.id = action.payload.id;
    },
    saveProject(state) {
      /** handled with case in mapState slice reducer @see {@link mapSlice} */
    },
    incrementLineCount(state) {
      if (state.projectData?.lineCount) {
        state.projectData.lineCount += 1;
      }
    },
    incrementPolygonCount(state) {
      if (state.projectData?.polygonCount) {
        state.projectData.polygonCount += 1;
      }
    },
    incrementPointCount(state) {
      if (state.projectData?.pointCount) {
        state.projectData.pointCount += 1;
      }
    },
  },
  selectors: {
    selectProject: (state) => state,
    selectProjectData: (state) => state.projectData,
    selectProjectId: (state) => state.id,
    selectProjectLineCount: (state) => state.projectData?.lineCount,
    selectProjectPointCount: (state) => state.projectData?.pointCount,
    selectProjectPolygonCount: (state) => state.projectData?.polygonCount,
  },
  extraReducers: (builder) => {
    builder.addCase(saveItem, (state, action) => {
      const isNewItem = action.payload.itemId.includes('new');
      if (isNewItem) {
        incrementItemCountByItemType(state, action.payload.itemType);
      }
    });
  },
});

export const {
  setProject,
  setProjectData,
  saveProject,
  incrementLineCount,
  incrementPolygonCount,
  incrementPointCount,
} = projectSlice.actions;

export const selectProject = () => useAppSelector(projectSlice.selectors.selectProject);
export const selectProjectId = () => useAppSelector(projectSlice.selectors.selectProjectId);
export const selectProjectData = () => useAppSelector(projectSlice.selectors.selectProjectData, shallowEqual);
export const selectProjectLineCount = () => useAppSelector(projectSlice.selectors.selectProjectLineCount);
export const selectProjectPointCount = () => useAppSelector(projectSlice.selectors.selectProjectPointCount);
export const selectProjectPolygonCount = () => useAppSelector(projectSlice.selectors.selectProjectPolygonCount);

export const projectReducer = projectSlice.reducer;

const incrementItemCountByItemType = (
  state: Draft<ProjectState>,
  type: Nullable<typeof LINE | typeof POLYGON | typeof POINT>
) => {
  switch (type) {
    case 'polygon':
      if (state.projectData?.polygonCount) {
        state.projectData.polygonCount += 1;
      }
      break;
    case 'line':
      if (state.projectData?.lineCount) {
        state.projectData.lineCount += 1;
      }
      break;
    case 'point':
      if (state.projectData?.pointCount) {
        state.projectData.pointCount += 1;
      }
      break;
    default:
      return state;
  }
};
