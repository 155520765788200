import { Box, Snackbar, SnackbarProps } from '@mui/material';
import { FC } from 'react';
import { Icon } from '../Icon';

interface Props extends SnackbarProps {
  onClose: () => void;
}

export const ColorPickSnackbar: FC<Props> = ({ open, children, onClose }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ p: 0, bottom: '50px !important' }}
    >
      <Box sx={{ bgcolor: '#000', borderRadius: '16px', position: 'relative', px: '16px', pb: '25px' }}>
        {children}

        <Box
          onClick={onClose}
          sx={{
            position: 'absolute',
            bottom: '-40px',
            left: 'calc(50% - 32px)',
          }}
        >
          <Icon path="mapIcons/big_color_pick" />
        </Box>
      </Box>
    </Snackbar>
  );
};
