import { Grid, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Button, WhiteModal } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { BaseResponse, Project, SharedProject } from 'shared/models';
import { axiosBase } from '../../../shared/axios';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ProjectStatus } from '../../../shared/enums';

interface Props {
  open: boolean;
  onClose: () => void;
  project: Project;
  onUpdateProject: (projectId: number, projectModel: Partial<Project>) => void;
}

export const ProjectRestoreModal: FC<Props> = ({ open, onClose, project, onUpdateProject }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onRestoreClick = async () => {
    setLoading(true);
    try {
      const { data } = await axiosBase.post<BaseResponse<SharedProject>>('/api/Project/change-status-share-project', {
        id: project.id,
        status: ProjectStatus.Active,
      });

      if (data.succeeded) {
        onUpdateProject(project.id, { status: ProjectStatus.Active });
        toast.success(`project: ${project.project.projectName} was successfully Restored`);
      } else {
        toast.error(`${data.error.message} with code ${data.error.code} `);
      }
    } catch (err) {
      console.error('restore project error:', err);
      isAxiosError(err) && toast.error(err.response?.data?.error?.message || err.message);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const onCancelClick = () => {
    !loading && onClose();
  };

  return (
    <WhiteModal open={open} onClose={onClose} hideCloseIcon margin={6}>
      <Typography align="center" sx={{ fontSize: 18 }}>
        {t(`Are you sure want to restore ${project.project.projectName} project?`)}
      </Typography>

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={5}>
          <Button outlined btnColor="primary.main" fullWidth onClick={onCancelClick}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('No')}</Typography>
          </Button>
        </Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <Button loading={loading} btnColor="primary.main" fullWidth onClick={onRestoreClick} disabled={loading}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('Yes')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </WhiteModal>
  );
};
