import { ProjectLayout } from 'shared/ui';
import { Header, NewProject, SideMenu } from '../../widgets';

export const NewProjectPage = () => {
  return (
    <>
      <Header />
      <ProjectLayout>
        <NewProject />
        <SideMenu />
      </ProjectLayout>
    </>
  );
};
