import { FC, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { v4 as makeId } from 'uuid';
import { useAppDispatch } from 'shared/hooks';
import { getFeatureBounds } from 'shared/lib';
import { LatLngBoundsExpression, LatLngLiteral } from 'leaflet';
import {
  selectCurrentItemId,
  selectCurrentMapItem,
  selectIsItemInfo,
  selectIsMapTileLoading,
  selectIsNewItem,
  selectLineId,
  selectNewPoint,
  selectPointId,
  selectPoints,
  selectPolygonId,
  selectSaveProjectStep,
  selectSelectedItem,
  selectShouldTakeScreenshot,
  setItemScreenshot,
  setMapTileLoading,
  setSaveProjectStep,
  setShouldTakeScreenshot,
} from 'shared/slices';
import { Image, LineModel, Point, PointModel, PolygonModel } from 'shared/models';
import { NEW_POINT_ID } from 'shared/constants';
import { SavingStep } from 'shared/enums';
import html2canvas from 'html2canvas';
import { projectApi } from 'shared/api';
import { handleApiError } from 'shared/lib/errorHandling';

/* need to be initialized outside the Component so on every rerender we have new instance with new *aborted* state */
let abortControllerRef: AbortController;

export const MapScreenShooter: FC = () => {
  const map = useMap();
  const dispatch = useAppDispatch();

  const currentMapItem = selectCurrentMapItem();
  const currentMapItemId = selectCurrentItemId();
  const currentItemType = selectSelectedItem();

  const selectedPolygonId = selectPolygonId();
  const selectedLineId = selectLineId();
  const selectedPointId = selectPointId();
  const points = selectPoints();
  const newPoint = selectNewPoint();
  const isMapTileLoading = selectIsMapTileLoading();
  const saveProjectStep = selectSaveProjectStep();
  const shouldTakeScreenshot = selectShouldTakeScreenshot();
  const isItemShowingInfo = selectIsItemInfo();
  const isNewItem = selectIsNewItem();

  const isScreenshotDisabled = useRef(false);
  abortControllerRef = new AbortController();

  useEffect(() => {
    switch (saveProjectStep) {
      case SavingStep.SCREEN_SHOOTING:
        fitItemToViewPort();
        break;
      case SavingStep.CANCELED:
        abortControllerRef.abort();
        break;
      case SavingStep.SCREEN_SHOOTING_DONE:
        if (isItemShowingInfo || isNewItem) return;
        dispatch(setSaveProjectStep(SavingStep.SAVING));
        break;
    }
  }, [saveProjectStep]);

  useEffect(() => {
    if (saveProjectStep === SavingStep.SCREEN_SHOOTING && !isMapTileLoading && !isScreenshotDisabled.current) {
      isScreenshotDisabled.current = true;
      setTimeout(() => {
        takeScreenShot();
        isScreenshotDisabled.current = false;
      }, 1000);
    }
  }, [isMapTileLoading, saveProjectStep]);

  useEffect(() => {
    if (
      saveProjectStep === SavingStep.SCREEN_SHOOTING &&
      !isMapTileLoading &&
      shouldTakeScreenshot &&
      !isScreenshotDisabled.current
    ) {
      isScreenshotDisabled.current = true;
      setTimeout(() => {
        takeScreenShot();
        isScreenshotDisabled.current = false;
      }, 500);
    }
  }, [isMapTileLoading, saveProjectStep]);

  const fitItemToViewPort = () => {
    isScreenshotDisabled.current = true;
    dispatch(setMapTileLoading(true));
    let calculatePoints: Point[] = [];

    map.dragging.disable();

    if (selectedPolygonId) {
      calculatePoints = (currentMapItem as PolygonModel).geoJson.points;
    }
    if (selectedLineId) {
      calculatePoints = (currentMapItem as LineModel).geoJson.points;
    }

    if (selectedPolygonId || selectedLineId) {
      const featureBounds = getFeatureBounds(calculatePoints);

      map.fitBounds(featureBounds as LatLngBoundsExpression, { animate: false });
      const zoom = map.getZoom();
      map.setZoom(zoom - 1, { animate: false });
    }

    if (selectedPointId) {
      const coords = (
        selectedPointId === NEW_POINT_ID
          ? newPoint
          : (points.find((p) => p.geoJson.id === selectedPointId) as PointModel)
      ).geoJson.points[0].coords;
      map.flyTo(coords as LatLngLiteral, map.getZoom(), { animate: false });
      const zoom = map.getZoom();
      map.setZoom(zoom + 1, { animate: false });
    }
    dispatch(setMapTileLoading(false));
    isScreenshotDisabled.current = false;
  };

  const takeScreenShot = async () => {
    if (!currentMapItemId || !currentItemType) return;

    const element = document.querySelector<HTMLElement>('#map');
    if (!element) return;

    const ignoreRenderElementsIds = ['find-buttons', 'drawing-control'];

    const source = await html2canvas(element, {
      useCORS: true,
      ignoreElements: (element) => ignoreRenderElementsIds.includes(element.id),
    }).then((canvas) => {
      return canvas.toDataURL('image/jpeg', 0.5);
    });

    try {
      const url = await projectApi.uploadImage(source, abortControllerRef.signal);

      if (url) {
        const screenShotObj: Image = { id: makeId(), url, title: 'ScreenShot', isSnapShot: true };
        const isNewItem = shouldTakeScreenshot ? true : currentMapItemId.includes('new');
        dispatch(setItemScreenshot({ images: [screenShotObj], isNewItem, item: currentItemType }));
        dispatch(setSaveProjectStep(SavingStep.SCREEN_SHOOTING_DONE));
      }
    } catch (e) {
      if (abortControllerRef.signal.aborted) {
        dispatch(setSaveProjectStep(SavingStep.SCREEN_SHOOTING_DONE));
      } else {
        /* show toast only when it is api error */
        handleApiError(e);
      }
    } finally {
      dispatch(setShouldTakeScreenshot(false));
      map.dragging.enable();
    }
  };

  return <></>;
};
