import { Unit } from '../models';
export const localStorageService = {
  get lastOpenedProjectId() {
    return localStorage.getItem('last-opened-projectId')!;
  },

  set lastOpenedProjectId(projectId: string) {
    localStorage.setItem('last-opened-projectId', projectId);
  },

  get rapidToken() {
    return localStorage.getItem('rapid-token')!;
  },

  set rapidToken(token: string) {
    localStorage.setItem('rapid-token', token);
  },

  get rapidUsername() {
    return localStorage.getItem('rapid-username')!;
  },

  set rapidUsername(username: string) {
    localStorage.setItem('rapid-username', username);
  },

  get hasSeenAddToHome() {
    return localStorage.getItem('hasSeenAddToHome')!;
  },

  set hasSeenAddToHome(value: string) {
    localStorage.setItem('hasSeenAddToHome', value);
  },

  get transferEmailSuggestions() {
    return localStorage.getItem('transfer-email-suggestions')!;
  },

  set transferEmailSuggestions(suggestions: string) {
    localStorage.setItem('transfer-email-suggestions', suggestions);
  },

  get previousSearch() {
    return localStorage.getItem('previous-search')!;
  },

  set previousSearch(prevSearch: string) {
    localStorage.setItem('previous-search', prevSearch);
  },

  get recentProjects() {
    return localStorage.getItem('recentProjects')!;
  },

  set recentProjects(recentProjects: string) {
    localStorage.setItem('recentProjects', recentProjects);
  },

  get userLogin() {
    return localStorage.getItem('user-login')!;
  },

  set userLogin(userLogin: string) {
    localStorage.setItem('user-login', userLogin);
  },
  get userPassword() {
    return localStorage.getItem('user-password')!;
  },

  set userPassword(userPassword: string) {
    localStorage.setItem('user-password', userPassword);
  },

  get userOrganizationId() {
    return localStorage.getItem('organizationId')!;
  },

  set userOrganizationId(userOrganizationId: string) {
    localStorage.setItem('organizationId', userOrganizationId);
  },

  get userOrganizationsIds() {
    return localStorage.getItem('organizationsIds')!;
  },

  set userOrganizationsIds(userOrganizationsIds: string) {
    localStorage.setItem('organizationsIds', userOrganizationsIds);
  },

  get units() {
    return JSON.parse(localStorage.getItem('units') || '{}');
  },

  set units(units: Unit) {
    localStorage.setItem('units', JSON.stringify(units));
  },

  removeUserCredentials() {
    localStorage.removeItem('user-login');
    localStorage.removeItem('user-password');
  },

  removeRapidUsername() {
    localStorage.removeItem('rapid-username');
  },

  removeRapidToken() {
    localStorage.removeItem('rapid-token');
  },

  removeLastOpenedProjectId() {
    localStorage.removeItem('last-opened-projectId');
  },

  removeUnits() {
    localStorage.removeItem('units');
  },

  get farmToken() {
    return localStorage.getItem('farm-token') || '';
  },

  set farmToken(token: string) {
    localStorage.setItem('farm-token', token);
  },

  removeFarmToken() {
    localStorage.removeItem('farm-token');
  },

  get gpsPermission() {
    return localStorage.getItem('location') || '';
  },

  set gpsPermission(granted: string) {
    localStorage.setItem('location', granted);
  },

  get lineCount() {
    const storedValue = localStorage.getItem('lineCount');
    return storedValue !== null ? parseInt(storedValue) : 0;
  },

  set lineCount(value: number) {
    localStorage.setItem('lineCount', value.toString());
  },

  get pointCount() {
    const storedValue = localStorage.getItem('pointCount');
    return storedValue !== null ? parseInt(storedValue) : 0;
  },

  set pointCount(value: number) {
    localStorage.setItem('pointCount', value.toString());
  },

  get polygonCount() {
    const storedValue = localStorage.getItem('polygonCount');
    return storedValue !== null ? parseInt(storedValue) : 0;
  },

  set polygonCount(value: number) {
    localStorage.setItem('polygonCount', value.toString());
  },
};
