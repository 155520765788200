import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { FC } from 'react';

interface Props extends DialogProps {
  title: string;
  subtitle?: string;
  margin?: number;
}

export const Modal: FC<Props> = ({ title, open, onClose, subtitle, children, margin = 4, ...props }) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => onClose?.(e, 'backdropClick');
  const isMobile = useMediaQuery('(max-width:550px)');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{ sx: { background: '#F5FBFF', margin, width: `calc(100% - ${margin * 2}px)` } }}
      {...props}
    >
      <DialogTitle sx={{ p: isMobile ? '5px 12px' : '16px 24px' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: '2px solid green', borderColor: 'primary.main', color: 'primary.main' }}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 600,
              fontSize: '16px',
              color: '4D4E4C',
              p: 0,
            }}
            color="primary.main"
          >
            {title}
          </Typography>
          {subtitle && <Typography color="primary.main">{subtitle}</Typography>}
          <IconButton onClick={handleClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          p: isMobile ? '5px 12px' : '16px 24px',
          mb: '10px',
          position: 'relative',
          overflow: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'primary.main',
            borderRight: isMobile ? '12px solid #F5FBFF' : '25px solid #F5FBFF',
          },
          '&::-webkit-scrollbar': {
            width: isMobile ? '18px' : '32px',
            backgroundColor: '#F5FBFF',
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
