import { FC, useEffect } from 'react';
import { ConfirmModal } from '../../shared/ui';
import { Typography } from '@mui/material';
import {
  deleteLine,
  deletePoint,
  deletePolygon,
  selectLineId,
  selectMap,
  selectPointId,
  selectPolygonId,
  setSaveProjectStep,
} from '../../shared/slices';
import { SavingStep } from 'shared/enums';
import { useTranslation } from 'react-i18next';
import { isOnline } from 'shared/lib';
import { useAppDispatch } from '../../shared/hooks';
import { toast } from 'react-toastify';
import { NO_CONNECTION_MESSAGE } from 'shared/constants';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DeleteItemModal: FC<Props> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { saveProjectStep } = selectMap();
  const selectedPolygonId = selectPolygonId();
  const selectedLineId = selectLineId();
  const selectedPointId = selectPointId();

  const onCloseModal = () => {
    if (saveProjectStep === SavingStep.SAVING) return;
    onClose();
  };

  const onDeleteItem = () => {
    if (!isOnline()) {
      toast.error(NO_CONNECTION_MESSAGE);
      onCloseModal();
    } else {
      selectedPolygonId && dispatch(deletePolygon());
      selectedLineId && dispatch(deleteLine());
      selectedPointId && dispatch(deletePoint());

      dispatch(setSaveProjectStep(SavingStep.SAVING));
    }
  };

  useEffect(() => {
    if (saveProjectStep === SavingStep.DONE) {
      open && onCloseModal();
    }
  }, [saveProjectStep]);

  // const confirmText = useMemo(() => {
  //   let item: string = '';
  //   if (selectedPolygonId) item = t('map_speed_dial_field');
  //   if (selectedLineId) item = t('line');
  //   if (selectedPointId) item = t('map_speed_dial_point');
  //
  //   return textFormatter(t('are_you_sure_you_want_to_delete_the_item_you_created'), [item.toLocaleLowerCase()]).output;
  // }, [open]);

  return (
    <ConfirmModal
      title="Delete Modal"
      open={open}
      onClose={onCloseModal}
      onConfirm={onDeleteItem}
      loading={saveProjectStep === SavingStep.SAVING}
      maxWidth="xs"
    >
      <Typography sx={{ color: '#242731', fontSize: 18, textAlign: 'center' }}>
        {t('are_you_sure_you_want_to_delete_the_item_you_created')}
      </Typography>
      <Typography sx={{ color: '#242731', fontSize: 18, textAlign: 'center' }}>
        {t('this_action_cannot_be_undone')}
      </Typography>
    </ConfirmModal>
  );
};
