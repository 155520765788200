import { Box, CircularProgress, InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import React, { FC } from 'react';

type Props = TextFieldProps & {
  label?: string;
  additionalInput?: JSX.Element;
  loading?: boolean;
};

const autoFillColor = {
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:hover': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
};

export const PolygonInput: FC<Props> = ({ label, disabled, error, helperText, additionalInput, loading, ...props }) => {
  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Typography noWrap sx={{ color: error ? 'red' : 'secondary.dark', fontSize: 16, fontWeight: 600 }}>
        {`${label ? label : ''} ${error ? '*' : ''}`}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <TextField
          fullWidth
          error={error}
          helperText={error ? helperText : ''}
          disabled={disabled}
          InputProps={{
            startAdornment: loading ? (
              <InputAdornment sx={{ mr: 1, ml: 0 }} position="end">
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            ) : null,
          }}
          sx={{
            mt: 0.5,
            'input, textarea': {
              ...autoFillColor,
              py: 0,
              bgcolor: 'white',
              color: 'info.dark',
            },
            '& .MuiInputBase-root': {
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.15)',
              borderRadius: '10px',
              py: 1,

              border: '1px solid white',
              borderColor: disabled ? 'info.light' : error ? 'red' : 'info.light',

              bgcolor: disabled ? 'info.light' : 'white',
              height: props.multiline ? 'auto' : '42px',
              minHeight: '42px',
            },
            '& .MuiFormHelperText-root': {
              m: 0,
              fontSize: 12,
              fontWeight: 400,
            },
            fieldset: {
              border: 'none !important',
            },
          }}
          {...props}
        />
        <Box sx={{ position: 'absolute', top: 0, right: 0, width: 'auto', mt: 0.5 }}>{additionalInput}</Box>
      </Box>
    </Box>
  );
};
