import { LatLngLiteral } from 'leaflet';

export type Point = {
  id: string;
  coords: LatLngLiteral;
};

export type LinePoint = Point & {
  parentPointId?: string; // connected line point id
};

export type PolygonType = {
  id: string;
  points: Point[];
  fillColor: string;
  borderColor: string;
  fillOpacity: number;
};

export type LineType = {
  id: string;
  points: LinePoint[];
  color: string;
  dashArray: string[];
  weight: number;
};

export type PointType = {
  id: string;
  points: Point[];
  color: string;
  size: number;
};

export enum ACTION_TYPE {
  ADD,
  DELETE,
  MOVE,
}

export type Action = {
  typeName: ACTION_TYPE;
  point: Point;
  index?: number;
};
