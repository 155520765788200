// const normal = 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}';
// const satellite = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
// const terrain = 'https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}';
// const satellite = 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}';
// export const MAP_TILE_LAYERS = [normal, satellite, terrain];

export const MAP_TILE_LAYERS = {
  'roadmap': `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  'hybrid': `https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  'terrain': `https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
};


// const normalMapTileUrl = 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&scale=2&key=YOUR_API_KEY';
// const hybridMapTileUrl = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&scale=2&key=YOUR_API_KEY';
// const terrainMapTileUrl = 'https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&scale=2&key=YOUR_API_KEY';


// export const SUBDOMAINS = ['mt0', 'mt1', 'mt2', 'mt3'];
