import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppNavigator } from '../../shared/hooks';
import { AuthInput, Button, WhiteModal } from '../../shared/ui';
import { closeProjectModals, setLines, setPoints, setPolygons, setProject } from '../../shared/slices';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProjectDataFromServer, ProjectInfo } from '../../shared/models';
import { addRecentProjectToLS } from 'shared/lib';
import { localStorageService } from '../../shared/services';
import { projectApi } from '../../shared/api';
import { handleApiError } from '../../shared/lib/errorHandling';

export const NewProject = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pathname, state } = useLocation();
  const { navigateToProject, navigate } = useAppNavigator();

  const openNewProject = !!state?.newProjectModal || !!state?.sideMenuNewProjectModal;
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [inputError, setInputError] = useState('');

  const createProject = async () => {
    try {
      setLoading(true);

      const lineCount = 1;
      const polygonCount = 1;
      const pointCount = 1;

      const response = await projectApi.createProject({
        projectName,
        customerName,
        lineCount,
        polygonCount,
        pointCount,
      });

      const data = response.data.data;

      const currentProject = { ...data, project: JSON.parse(data.project.toString()) as ProjectDataFromServer };

      localStorageService.lastOpenedProjectId = currentProject.id.toString();

      dispatch(setProject(currentProject));
      dispatch(setPolygons([]));
      dispatch(setLines([]));
      dispatch(setPoints([]));
      dispatch(closeProjectModals());

      const projectInfo: ProjectInfo = { id: currentProject.id, name: currentProject.project.projectName };
      addRecentProjectToLS(projectInfo);

      setProjectName('');
      setCustomerName('');
      toast.success(t('project_created'));
      navigateToProject(projectInfo.id);
    } catch (e) {
      handleApiError(e);
      toast.error('Create new project: error');
    } finally {
      setLoading(false);
    }
  };

  const isOpened = useMemo(() => {
    if (state?.sideMenu) return false;
    if (pathname === '/main/new-project') return true;

    return openNewProject;
  }, [state?.sideMenu, pathname, openNewProject]);

  useEffect(() => {
    if (!isOpened && (projectName || customerName)) {
      setProjectName('');
      setCustomerName('');
    }
  }, [isOpened]);

  const handleClose = () => {
    if (pathname === '/main/new-project') return;
    setProjectName('');
    setCustomerName('');
    navigate(pathname, { state: { newProjectModal: false, sideMenuNewProjectModal: false }, replace: true });
  };
  const onCancelClick = () => {
    setProjectName('');
    setCustomerName('');
    if (!pathname.includes('/main/home')) {
      navigate('main/project-management', { replace: true });
    } else {
      navigate(pathname, { state: { newProjectModal: false, sideMenuNewProjectModal: false }, replace: true });
    }
  };

  const onCreateClick = async () => {
    await createProject();
  };

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value && value.length > 50) {
      setInputError('Max 50 characters');
      return;
    }
    inputError && setInputError('');
    setProjectName(value);
  };

  return (
    <WhiteModal
      open={isOpened}
      onClose={handleClose}
      hideFade={pathname === '/main/new-project'}
      hideCloseIcon
      margin={6}
    >
      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>{t('new_project_new_project')}</Typography>

      <Box sx={{ mt: 2 }}>
        <AuthInput
          label={t('new_project_project_name')}
          name="project-name"
          placeholder={t('new_project_project_name')}
          value={projectName}
          onChange={handleChangeProjectName}
          autoComplete="new-password"
          error={!!inputError}
          helperText={inputError}
        />
        <AuthInput
          label={t('new_project_customer_name')}
          name="customer-name"
          placeholder={t('new_project_customer_name')}
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          autoComplete="new-password"
        />
      </Box>

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={5}>
          <Button outlined btnColor="info.dark" fullWidth onClick={onCancelClick}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('new_project_cancel')}</Typography>
          </Button>
        </Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <Button loading={loading} btnColor="primary.main" fullWidth onClick={onCreateClick} disabled={!projectName}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('new_project_create_project')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </WhiteModal>
  );
};
