import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Select as MuiSelect,
  SelectProps,
  Typography,
} from '@mui/material';
import { forwardRef } from 'react';
import { useToggle } from 'shared/hooks';
import { Icon } from '../Icon';

type Props = SelectProps & {
  label?: string;
  loading?: boolean;
  isPolygonInfo?: boolean;
  additionalInput?: JSX.Element;
};

export const Select = forwardRef<unknown, Props>(
  ({ label, loading, isPolygonInfo, additionalInput, disabled, ...props }, ref) => {
    const { open, toggle } = useToggle(false);

    return (
      <Box sx={{ width: '100%', mt: 1 }}>
        {label && (
          <Typography noWrap sx={{ color: 'secondary.dark', fontSize: 16, fontWeight: 600 }}>
            {label}
          </Typography>
        )}
        <Box display="flex" alignItems="center" position="relative">
          <Box width={additionalInput ? '50%' : '100%'}>
            <MuiSelect
              open={open}
              onOpen={toggle}
              onClose={toggle}
              disabled={disabled}
              {...props}
              fullWidth
              sx={{
                mt: 0.5,
                bgcolor: 'white',
                color: 'info.dark',

                height: 42,
                borderRadius: '10px',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.15)',
                border: '1px solid white',
                borderColor: disabled ? 'info.light' : 'info.light',

                ...(isPolygonInfo && {
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  bgcolor: 'white',
                  fontSize: '14px',
                }),
                ...(additionalInput && {
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                }),
                fieldset: { border: 'none !important' },
                svg: { display: 'none' },
                pr: 0,
              }}
              endAdornment={
                loading ? (
                  <InputAdornment sx={{ mr: 3 }} position="end">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : (
                  <IconButton onClick={toggle}>
                    <Icon path={'chevron-down'} sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
                  </IconButton>
                )
              }
              MenuProps={{ PaperProps: { sx: { maxHeight: '40%' } } }}
              inputRef={ref}
            >
              {props.children}
            </MuiSelect>
          </Box>
          {additionalInput && <Box width="50%">{additionalInput}</Box>}
        </Box>
      </Box>
    );
  }
);
