import { useState } from 'react';
import { useAppDispatch } from 'shared/hooks';
import { green } from '@mui/material/colors';
import { PolygonModel } from 'shared/models';
import { selectCurrentMapItem, setPolygonStyles, toggleColorPicker } from 'shared/slices';

type ColorPickMode = 'fill' | 'border';

export const usePolygonStyles = () => {
  const dispatch = useAppDispatch();

  const [currentMode, setCurrentMode] = useState<ColorPickMode>('fill');

  const openedPolygon = selectCurrentMapItem() as PolygonModel;

  const fillColor = openedPolygon?.geoJson.fillColor ?? green[500];
  const borderColor = openedPolygon?.geoJson.borderColor ?? green[500];
  const fillOpacity = openedPolygon?.geoJson.fillOpacity ?? 0.7;

  const onModeChange = (mode: ColorPickMode) => setCurrentMode(mode);
  const handleClose = () => dispatch(toggleColorPicker());

  const onSelectFillColor = (fillColor: string) => dispatch(setPolygonStyles({ fillColor, fillOpacity: 0.6 }));
  const onSelectBorderColor = (borderColor: string) => dispatch(setPolygonStyles({ borderColor }));
  const onToggleFillOpacity = () => {
    const value = fillOpacity === 0 ? 0.6 : 0;
    dispatch(setPolygonStyles({ fillOpacity: value }));
  };

  return {
    currentMode,
    fillColor,
    borderColor,
    fillOpacity,
    onModeChange,
    handleClose,
    onSelectFillColor,
    onSelectBorderColor,
    onToggleFillOpacity,
  };
};
