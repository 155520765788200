import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePointSelectItems = () => {
  const { t, i18n } = useTranslation();

  const waterTypes = useMemo(
    () => [
      { label: t('point_water_types_drinking'), value: 'drinkingWater' },
      { label: t('point_water_types_grey'), value: 'greyWater' },
      { label: t('point_water_types_waste'), value: 'wasteWater' },
      { label: t('point_water_types_reservoir'), value: 'reservoir' },
    ],
    [t, i18n.language]
  );

  const filtrationTypes = useMemo(
    () => [
      { label: t('point_filtration_types_gravel'), value: 'gravel' },
      { label: t('point_filtration_types_disc'), value: 'disc' },
      { label: t('point_filtration_types_screen'), value: 'screen' },
      { label: t('point_filtration_types_hydrocyclone'), value: 'hydrocyclone' },
    ],
    [t, i18n.language]
  );

  const filtrationModes = useMemo(
    () => [
      { label: t('point_filtration_modes_automatic'), value: 'automatic' },
      { label: t('point_filtration_modes_semiautomatic'), value: 'semi-automatic' },
      { label: t('point_filtration_modes_manual'), value: 'manual' },
    ],
    [t, i18n.language]
  );

  const pumpTypes = useMemo(
    () => [
      { label: t('point_pump_types_turbine'), value: 'turbine' },
      { label: t('point_pump_types_well'), value: 'well' },
      { label: t('point_pump_types_centrifugal_booster'), value: 'centrifugalBooster' },
      { label: t('point_pump_types_multistage_booster'), value: 'multistageBooster' },
      { label: t('point_pump_types_submersible'), value: 'submersible' },
    ],
    [t, i18n.language]
  );

  const fertigationElectricTypes = useMemo(
    () => [
      { label: t('point_fertigation_types_booster_pump'), value: 'boosterPump' },
      { label: t('point_fertigation_types_booster_pump_venturi'), value: 'boosterPump&Venturi' },
      { label: t('point_fertigation_types_fertigation_table'), value: 'fertigationTable' },
    ],
    [t, i18n.language]
  );

  const fertigationWaterMotivatedTypes = useMemo(
    () => [
      { label: t('point_fertigation_types_venturi'), value: 'venturi' },
      { label: t('point_fertigation_types_tefen'), value: 'tefen' },
      { label: t('point_fertigation_types_amiad'), value: 'amiad' },
    ],
    [t, i18n.language]
  );

  const operationAndControlCommunicationType = useMemo(
    () => [
      { label: t('point_operation_and_control_radio_and_rtu'), value: 'radio&RTU' },
      { label: t('point_operation_and_control_2_wires_and_rtu'), value: '2Wires&RTU' },
      { label: t('point_operation_and_control_multi_cables_24_vac'), value: 'multiCables24VAC' },
    ],
    [t, i18n.language]
  );

  return {
    waterTypes,
    filtrationTypes,
    filtrationModes,
    pumpTypes,
    fertigationElectricTypes,
    fertigationWaterMotivatedTypes,
    operationAndControlCommunicationType,
  };
};
