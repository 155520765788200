import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogProps, IconButton, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { BigTitle, Row } from '..';

interface Props extends DialogProps {
  title?: string;
  subtitle?: string;
  margin?: number;
  hideFade?: boolean;
  hideCloseIcon?: boolean;
}

export const WhiteModal: FC<Props> = ({
  open,
  onClose,
  children,
  title,
  margin = 4,
  hideFade,
  hideCloseIcon,
  ...props
}) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => onClose?.(e, 'backdropClick');
  const isMobile = useMediaQuery('(max-width:550px)');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        mt: hideFade ? '48px' : 0,
        '& .MuiBackdrop-root': {
          mt: hideFade ? '48px' : 0,
          bgcolor: hideFade ? 'transparent' : 'rgba(0, 0, 0, 0.5)',
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'white',
          borderRadius: '18px',
          margin,
          width: `calc(100% - ${margin * 2}px)`,

          p: 0,
        },
      }}
      {...props}
    >
      <Row m={title ? 2 : 0} mb={0}>
        {title && <BigTitle sx={{ ml: 1 }}>{title}</BigTitle>}

        {!hideCloseIcon && (
          <IconButton onClick={handleClose}>
            <CloseIcon color="primary" />
          </IconButton>
        )}
      </Row>
      <DialogContent
        sx={{
          p: '24px',
          position: 'relative',
          overflow: 'auto',
          backgroundColor: 'info.main',

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'primary.main',
            borderRight: isMobile ? '12px solid #F5FBFF' : '25px solid #F5FBFF',
          },
          '&::-webkit-scrollbar': {
            width: isMobile ? '18px' : '32px',
            backgroundColor: 'info.main',
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
