import React, { useEffect, useRef } from 'react';
import { Polyline, PolylineProps } from 'react-leaflet';
import './leaflet-arrowheads';
import * as L from 'leaflet'
interface ArrowheadsPolylineProps extends PolylineProps {
	arrowheads?: {
		size: string,
		frequency: number
	}
}

export const ArrowheadsPolyline: React.FC<ArrowheadsPolylineProps> = (props) => {
	const polylineRef = useRef<any>(null);

	useEffect(() => {
		const polyline = polylineRef.current;

		if (props.arrowheads) {
			polyline.arrowheads(props.arrowheads);
			polyline._update();
		}

		return () => {
			if (props.arrowheads) {
				polyline.deleteArrowheads();
				polyline.remove();
			}
		};
	}, []);

	return <Polyline {...props} ref={polylineRef} />;
};