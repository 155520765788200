import { saveProject, selectProjectId, selectSaveProjectStep, stopItemDrawing, updateProjectThunk } from '../slices';
import { SavingStep } from '../enums';
import { useContext, useEffect } from 'react';
import { handleApiError } from '../lib/errorHandling';
import { ProjectHubContext } from '../../processes';
import { useAppDispatch } from './useAppDispatch';

export const useSaveProject = () => {
  const { updateProject: synchronizeProject } = useContext(ProjectHubContext)!;
  const dispatch = useAppDispatch();

  const projectId = selectProjectId();
  const saveProjectStep = selectSaveProjectStep();

  const updateProject = async () => {
    try {
      return await dispatch(updateProjectThunk()).unwrap();
    } catch (e) {
      handleApiError(e);
    }
  };
  const onSaveProject = async () => {
    const response = await updateProject();
    if (!response) return;

    synchronizeProject(response.id.toString(), response.project);

    dispatch(saveProject());
    dispatch(stopItemDrawing());
  };

  useEffect(() => {
    if (!projectId || saveProjectStep !== SavingStep.SAVING) return;

    onSaveProject();
  }, [saveProjectStep, projectId]);
};
