import { Box, Container, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Row } from '..';

interface Props {
  children?: ReactNode;
  dark?: boolean;
}

export const BaseLayout: FC<Props> = ({ dark, children }) => {
  const isMobile = useMediaQuery('(max-width:400px)');

  return (
    <Box
      sx={{
        height: '100svh',
        width: '100%',
        bgcolor: 'info.dark',
      }}
    >
      <Row justifyContent="center" sx={{ height: '48px', bgcolor: 'primary.main' }}>
        <Box component="img" src="/images/miniLogo.svg" sx={{ width: '92px' }} />
      </Row>

      <Box sx={{ p: '20px', height: 'calc(100svh - 48px)' }}>
        <Container
          maxWidth="sm"
          sx={{
            bgcolor: dark ? '' : 'white',
            px: isMobile ? '15px' : '25px',
            height: '100%',
            borderRadius: '5px',
            minHeight: '500px !important',
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};
