import { green } from '@mui/material/colors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLngLiteral } from 'leaflet';
import { Nullable } from 'shared';
import { PolygonSelectedProductType } from 'shared/enums';
import { checkIfPointExistsInItem, getPolygonArea } from 'shared/lib';
import { v4 as makeId } from 'uuid';
import { NEW_POLYGON_ID, POLYGON } from '../../constants';
import { useAppSelector } from '../../hooks';
import { Action, ACTION_TYPE, Image, Point, PolygonInformation, PolygonModel, PolygonStyles } from '../../models';
import { localStorageService } from '../../services';
import {
  clearItemPoints,
  deleteItemScreenshot,
  editItem,
  saveItem,
  setItemScreenshot,
  setNewItemPoints,
  stopItemDrawing,
  unselectItems,
} from '../mapState';
import { setProject } from '../projectData';
import { addPolygonRowDirectionLine, stopPolygonRowDirectionLineDrawing } from '../lines';

const newPolygonTemplate: PolygonModel = {
  geoJson: {
    id: NEW_POLYGON_ID,
    points: [],
    fillColor: green[500],
    borderColor: green[500],
    fillOpacity: 0.6,
  },
  images: [],
  information: { description: '', name: '', isIrrigationProperties: true },
  properties: {
    isSelectProduct: false,
    selectedProductType: PolygonSelectedProductType.INTEGRATED,
    emitter: null,
    lateral: null,
    polygonOtherProduct: null,
    polygonCopySettingsFromId: null,
    polygonCropType: '',
    polygonDailyConsumption: 0,
    polygonFieldSize: 0,
    polygonIrrigationHoursPerDay: 0,
    polygonLateralsPerRow: 0,
    polygonPlantingDate: new Date().toISOString(),
    polygonPlantSpacing: 0,
    lateralType: 1,
    lateralTypeText: '',
    lateralSubtypeText: '',
    lateralGroup: '99951',
    lateralNominalFlow: 'All',
    lateralClassType: 'All',
    lateralDiameter: 'All',
    lateralFlowPer: 'All',
    lateralSpacing: 'All',
    polygonRowSpacing: 0,
    emitterSpacing: 0,
    emitterGroup: '03260',
    emitterNominalFlow: '',
    emitterType: 3,
    emitterTypeText: '',
    emitterSubtypeText: '',
    polygonFieldSizeUnit: localStorageService.units?.Area || 'Ha',
    polygonDailyConsumptionUnit: localStorageService.units?.AppnDepth || 'mm',
    polygonRowSpacingUnit: localStorageService.units?.Length || 'm',
    polygonPlantSpacingUnit: localStorageService.units?.Length || 'm',
    polygonIsSoilType: false,
    polygonSoilType: [],
    polygonRowDirection: null,
  },
};

interface PolygonState {
  polygons: PolygonModel[];
  isPolygonDrawing: boolean;
  initialPoints: Point[];
  initialStyles: PolygonStyles;
  selectedPolygonId: Nullable<string>;
  currentPolygonPointId: string | null;
  currentAreaInHa: number;
  newPolygon: PolygonModel;
  actionHistory: Action[];
  isRowDirectionForm: boolean;
}

const initialState: PolygonState = {
  polygons: [],
  isPolygonDrawing: false,
  initialPoints: [],
  initialStyles: { fillColor: green[500], borderColor: green[500], fillOpacity: 0.6 },
  selectedPolygonId: null,
  currentPolygonPointId: null,
  newPolygon: newPolygonTemplate,
  actionHistory: [],
  currentAreaInHa: 0,
  isRowDirectionForm: false,
};

export const polygonSlice = createSlice({
  name: 'polygons',
  initialState,
  reducers: {
    setPolygons(state, action: PayloadAction<PolygonModel[]>) {
      state.polygons = action.payload;
    },
    startPolygonDrawing(state) {
      state.isPolygonDrawing = true;
      state.selectedPolygonId = NEW_POLYGON_ID;
    },
    editPolygon(state) {
      if (!state.selectedPolygonId) return;
      state.isPolygonDrawing = true;

      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;

      if (polygon) {
        const { fillColor, borderColor, fillOpacity, points } = polygon.geoJson;
        state.initialPoints = points;
        state.initialStyles = { fillColor, borderColor, fillOpacity };
      }
    },
    setNewPolygonPoints(state, action: PayloadAction<Point[]>) {
      state.newPolygon.geoJson.points = action.payload;
    },
    savePolygon(state, action: PayloadAction<PolygonInformation>) {
      const {
        polygonName: name,
        polygonDescription: description,
        isIrrigationProperties,
        images,
        ...properties
      } = action.payload;
      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        const newId = makeId();
        state.polygons = [
          ...state.polygons,
          {
            geoJson: { ...state.newPolygon.geoJson, id: newId },
            information: { name, description, isIrrigationProperties },
            properties,
            images,
          },
        ];
        state.newPolygon.geoJson.points = [];
        state.selectedPolygonId = newId;
      } else {
        const polygon = state.polygons.find((item) => item.geoJson.id === state.selectedPolygonId) as PolygonModel;

        polygon.information = { name, description, isIrrigationProperties };
        polygon.properties = properties;
        polygon.images = images;
      }
    },
    cancelPolygonDrawing(state) {
      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        state.newPolygon.geoJson.points = [];
      } else {
        const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;
        if (polygon) {
          const pointsTupleArray = state.initialPoints.map((p) => [p.coords.lng, p.coords.lat]);
          const fieldSizeUnits = polygon.properties.polygonFieldSizeUnit;

          polygon.geoJson.points = state.initialPoints;
          // reset Field Size to initial state
          polygon.properties.polygonFieldSize = getPolygonArea(pointsTupleArray, fieldSizeUnits);
          const { fillColor, borderColor, fillOpacity } = state.initialStyles;
          polygon.geoJson = { ...polygon.geoJson, fillColor, borderColor, fillOpacity };
        }
      }
    },
    deletePolygon(state) {
      state.polygons = state.polygons.filter((p) => p.geoJson.id !== state.selectedPolygonId);

      state.selectedPolygonId = null;
    },
    selectPolygon(state, action: PayloadAction<Nullable<string>>) {
      const id = action.payload;

      if (!state.isPolygonDrawing) {
        state.selectedPolygonId = state.selectedPolygonId === id ? null : id;
      }
    },
    setCurrentPolygonPointId(state, action: PayloadAction<string | null>) {
      const pointId = action.payload;

      if (state.currentPolygonPointId === pointId) {
        state.currentPolygonPointId = null;
      } else {
        state.currentPolygonPointId = pointId;
      }
    },
    setDelineationPoints(state, action: PayloadAction<Point[]>) {
      state.newPolygon.geoJson.points = action.payload;
    },
    addNewPolygonPoint(state, action: PayloadAction<{ point: Point; index: number }>) {
      const { point, index } = action.payload;
      if (state.currentPolygonPointId) state.currentPolygonPointId = point.id;

      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        const currentPoints = state.newPolygon.geoJson.points.map((p) => ({ lat: p.coords.lat, lng: p.coords.lng }));
        const isPointExists = checkIfPointExistsInItem(point.coords, currentPoints);
        if (isPointExists) return;
        state.newPolygon.geoJson.points.splice(index, 0, point);

        const addAction = { typeName: ACTION_TYPE.ADD, point };
        state.actionHistory.push(addAction);
        return;
      }

      // find the current polygon if there is no then cancel the operation
      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);
      if (!polygon) return;

      polygon.geoJson.points.splice(index, 0, point);

      const addAction = { typeName: ACTION_TYPE.ADD, point };
      state.actionHistory.push(addAction);
    },
    dragPolygonPoint(state, action: PayloadAction<LatLngLiteral>) {
      const newPos = action.payload;

      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        state.newPolygon.geoJson.points = state.newPolygon.geoJson.points.map((point) =>
          point.id === state.currentPolygonPointId ? { id: point.id, coords: newPos } : point
        );
        return;
      }

      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;
      const newPoints: Point[] = polygon.geoJson.points.map((point) =>
        point.id === state.currentPolygonPointId ? { id: point.id, coords: newPos } : point
      );

      // CALCULATION OF NEW POLYGON AREA FOR UPDATING VALUE IN FORM CORRECTLY

      const calcPoints = newPoints.length ? polygon.geoJson.points.map((p) => [p.coords.lat, p.coords.lng]) : [];
      const unit = polygon.properties.polygonFieldSizeUnit;
      const area = getPolygonArea(calcPoints, unit);

      state.polygons = state.polygons.map((p) =>
        p.geoJson.id === state.selectedPolygonId
          ? {
              ...p,
              properties: { ...p.properties, polygonFieldSize: area },
              geoJson: { ...p.geoJson, points: newPoints },
            }
          : p
      );
    },
    saveDragPolygonAction(state, action: PayloadAction<LatLngLiteral>) {
      const id = state.currentPolygonPointId;
      const coords = action.payload;
      const point: Point = { id: id as string, coords };

      const moveAction = { typeName: ACTION_TYPE.MOVE, point };
      state.actionHistory.push(moveAction);
    },
    deletePolygonPoint(state, action: PayloadAction<string>) {
      const pointId = action.payload;

      let point: Point, index: number;

      // reset current point to null
      if (state.currentPolygonPointId === pointId) state.currentPolygonPointId = null;

      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        point = state.newPolygon.geoJson.points.find((point) => point.id === pointId) as Point;
        index = state.newPolygon.geoJson.points.findIndex((point) => point.id === pointId);
      } else {
        const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;
        point = polygon.geoJson.points.find((point) => point.id === pointId) as Point;
        index = polygon.geoJson.points.findIndex((point) => point.id === pointId);
      }
      const delAction = { typeName: ACTION_TYPE.DELETE, point, index };
      state.actionHistory.push(delAction);

      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        state.newPolygon.geoJson.points = state.newPolygon.geoJson.points.filter((point) => point.id !== pointId);
        return;
      }

      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;
      const newPoints: Point[] = polygon.geoJson.points.filter((point) => point.id !== pointId);

      state.polygons = state.polygons.map((p) =>
        p.geoJson.id === state.selectedPolygonId ? { ...p, geoJson: { ...p.geoJson, points: newPoints } } : p
      );
    },
    undoPolygonAction(state) {
      if (!state.actionHistory.length) return;
      state.currentPolygonPointId = null;

      const { typeName, point, index } = state.actionHistory.pop() as Action;

      switch (typeName) {
        case ACTION_TYPE.ADD:
          {
            if (state.selectedPolygonId === NEW_POLYGON_ID) {
              state.newPolygon.geoJson.points = state.newPolygon.geoJson.points.filter((p) => p.id !== point.id);
              return;
            }

            const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;
            const newPoints: Point[] = polygon.geoJson.points.filter((p) => p.id !== point.id);

            state.polygons = state.polygons.map((p) =>
              p.geoJson.id === state.selectedPolygonId
                ? { ...p, geoJson: { ...p.geoJson, id: polygon.geoJson.id, points: newPoints } }
                : p
            );
          }
          break;

        case ACTION_TYPE.DELETE:
          {
            if (state.selectedPolygonId === NEW_POLYGON_ID) {
              let updatedPoints = [];
              const before = state.newPolygon.geoJson.points.slice(0, index);
              const after = state.newPolygon.geoJson.points.slice(index);
              updatedPoints = [...before, point, ...after];

              state.newPolygon.geoJson.points = updatedPoints;
              return;
            }

            const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId) as PolygonModel;

            let updatedPoints: Point[] = [];
            const before = polygon.geoJson.points.slice(0, index);
            const after = polygon.geoJson.points.slice(index);

            updatedPoints = [...before, point, ...after];

            state.polygons = state.polygons.map((p) =>
              p.geoJson.id === state.selectedPolygonId ? { ...p, geoJson: { ...p.geoJson, points: updatedPoints } } : p
            );
          }
          break;

        case ACTION_TYPE.MOVE:
          {
            const { id, coords } = point;

            if (state.selectedPolygonId === NEW_POLYGON_ID) {
              state.newPolygon.geoJson.points = state.newPolygon.geoJson.points.map((point) =>
                point.id === id ? { id, coords } : point
              );
              return;
            }

            const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);

            if (!polygon) return;

            const newPoints: Point[] = polygon.geoJson.points.map((point) =>
              point.id === id ? { id, coords } : point
            );

            state.polygons = state.polygons.map((p) =>
              p.geoJson.id === state.selectedPolygonId
                ? { ...p, geoJson: { ...p.geoJson, id: polygon.geoJson.id, points: newPoints } }
                : p
            );
          }
          break;
      }
    },
    setPolygonStyles(state, action: PayloadAction<Partial<PolygonStyles>>) {
      if (state.selectedPolygonId === NEW_POLYGON_ID) {
        state.newPolygon.geoJson = { ...state.newPolygon.geoJson, ...action.payload };
        return;
      }

      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);

      if (!polygon) return;

      polygon.geoJson = { ...polygon.geoJson, ...action.payload };
    },
    setCurrentArea(state, action: PayloadAction<number>) {
      state.currentAreaInHa = action.payload;
    },
    setPolygonScreenshot(state, action: PayloadAction<Image>) {
      const screenShot = action.payload;

      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);
      if (polygon) {
        const isHaveScreenShot = polygon?.images.find((img) => img.isSnapShot);

        if (isHaveScreenShot) {
          polygon.images = polygon.images.map((img) => (img.isSnapShot ? screenShot : img));
        } else {
          polygon.images = [screenShot, ...polygon.images];
        }
      }
    },
    setIsRowDirectionForm(state, action: PayloadAction<boolean>) {
      state.isRowDirectionForm = action.payload;
    },
  },
  selectors: {
    selectPolygonsState: (state) => state,
    selectPolygons: (state) => state.polygons,
    selectPolygonId: (state) => state.selectedPolygonId,
    selectPolygonById: (state, id: string) => state.polygons.find((pol) => pol.geoJson.id === id),
    selectIsPolygonDrawing: (state) => state.isPolygonDrawing,
    selectNewPolygon: (state) => state.newPolygon,
    selectCurrentPolygonPointId: (state) => state.currentPolygonPointId,
    selectCurrentAreaInHa: (state) => state.currentAreaInHa,
    selectActionHistory: (state) => state.actionHistory,
    selectIsRowDirectionForm: (state) => state.isRowDirectionForm,
  },
  extraReducers: (builder) => {
    builder.addCase(setProject, () => {
      return initialState;
    });
    builder.addCase(editItem, (state) => {
      if (!state.selectedPolygonId) return;
      state.isPolygonDrawing = true;

      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);

      if (polygon) {
        const { fillColor, borderColor, fillOpacity, points } = polygon.geoJson;
        state.initialPoints = points;
        state.initialStyles = { fillColor, borderColor, fillOpacity };
      }
    });
    builder.addCase(saveItem, (state, action) => {
      if (action.payload.itemType !== 'polygon') return;

      const {
        polygonName: name,
        polygonDescription: description,
        isIrrigationProperties,
        images,
        ...properties
      } = action.payload.information as PolygonInformation;

      if (action.payload.itemId === NEW_POLYGON_ID) {
        const newId = makeId();
        state.polygons.push({
          geoJson: { ...state.newPolygon.geoJson, id: newId },
          information: { name, description, isIrrigationProperties },
          properties,
          images,
        });
        state.newPolygon.geoJson.points = [];
        state.selectedPolygonId = newId;
      } else {
        const polygon = state.polygons.find((item) => item.geoJson.id === action.payload.itemId);

        if (!polygon) return;

        polygon.information = { name, description, isIrrigationProperties };
        polygon.properties = properties;
        polygon.images = images;
      }
    });
    builder.addCase(unselectItems, (state) => {
      state.selectedPolygonId = null;
    });
    builder.addCase(stopItemDrawing, (state) => {
      state.isPolygonDrawing = false;
      state.selectedPolygonId = null;
      state.currentPolygonPointId = null;
      state.actionHistory = [];
      state.initialPoints = [];
    });
    builder.addCase(setItemScreenshot, (state, action) => {
      const { images, isNewItem, item } = action.payload;
      if (!isNewItem && item === POLYGON) {
        const screenShot = images.pop();

        const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);

        if (polygon && screenShot) {
          const isHaveScreenShot = polygon?.images.find((img) => img.isSnapShot);

          if (isHaveScreenShot) {
            polygon.images = polygon.images.map((img) => (img.isSnapShot ? screenShot : img));
          } else {
            polygon.images = [screenShot, ...polygon.images];
          }
        }
      }
    });
    builder.addCase(deleteItemScreenshot, (state) => {
      const polygon = state.polygons.find((p) => p.geoJson.id === state.selectedPolygonId);

      if (!polygon) return;

      console.log('set images to empty array');
      polygon.images = polygon.images.filter((img) => !img.isSnapShot);
    });
    builder.addCase(setNewItemPoints, (state, action) => {
      if (state.selectedPolygonId == NEW_POLYGON_ID) {
        state.newPolygon.geoJson.points = action.payload;
        return;
      }

      const polygon = state.polygons.find((pol) => pol.geoJson.id === state.selectedPolygonId);

      if (!polygon) return;

      polygon.geoJson.points = action.payload;
    });
    builder.addCase(clearItemPoints, (state, action) => {
      const itemId = action.payload;

      if (itemId === NEW_POLYGON_ID) {
        state.newPolygon.geoJson.points = [];
        return;
      }

      const polygon = state.polygons.find((pol) => pol.geoJson.id === itemId);

      if (!polygon) return;

      polygon.geoJson.points = [];
    });
    builder.addCase(addPolygonRowDirectionLine, (state) => {
      state.selectedPolygonId = null;
      state.isPolygonDrawing = false;
    });
    builder.addCase(stopPolygonRowDirectionLineDrawing, (state, action) => {
      const { relatedPolygon, isPolygonDrawing } = action.payload;
      state.selectedPolygonId = relatedPolygon.geoJson.id;
      state.isPolygonDrawing = isPolygonDrawing;
    });
  },
});

export const {
  startPolygonDrawing,
  setDelineationPoints,
  savePolygon,
  cancelPolygonDrawing,
  deletePolygon,
  selectPolygon,
  setCurrentPolygonPointId,
  addNewPolygonPoint,
  dragPolygonPoint,
  saveDragPolygonAction,
  deletePolygonPoint,
  undoPolygonAction,
  setPolygons,
  setPolygonStyles,
  setCurrentArea,
  setPolygonScreenshot,
  editPolygon,
  setNewPolygonPoints,
  setIsRowDirectionForm,
} = polygonSlice.actions;

export const selectActionHistory = () => useAppSelector(polygonSlice.selectors.selectActionHistory);
export const selectPolygonsState = () => useAppSelector(polygonSlice.selectors.selectPolygonsState);
export const selectPolygons = () => useAppSelector(polygonSlice.selectors.selectPolygons);
export const selectPolygonId = () => useAppSelector(polygonSlice.selectors.selectPolygonId);
export const selectCurrentPolygonPointId = () => useAppSelector(polygonSlice.selectors.selectCurrentPolygonPointId);
export const selectCurrentAreaInHa = () => useAppSelector(polygonSlice.selectors.selectCurrentAreaInHa);
export const selectIsPolygonDrawing = () => useAppSelector(polygonSlice.selectors.selectIsPolygonDrawing);
export const selectNewPolygon = () => useAppSelector(polygonSlice.selectors.selectNewPolygon);
export const selectIsRowDirectionForm = () => useAppSelector(polygonSlice.selectors.selectIsRowDirectionForm);
export const selectPolygonById = (id: string) =>
  useAppSelector((state) => {
    return id === NEW_POLYGON_ID
      ? polygonSlice.selectors.selectNewPolygon(state)
      : polygonSlice.selectors.selectPolygonById(state, id);
  });

export const polygonReducer = polygonSlice.reducer;
