import { blue, green } from '@mui/material/colors';
import { v4 as makeId } from 'uuid';
import {
  GeoJSONLine,
  GeoJSONPoint,
  GeoJSONPolygon,
  LineModel,
  PointModel,
  PolygonModel,
  ProjectDataFromServer,
} from '../models';

export const mapPolygonToGeoJSON = (polygons: PolygonModel[]) => {
  return polygons.map((p) => {
    const coordinates = p.geoJson.points.map((item) => [item.coords.lat, item.coords.lng]);

    return {
      id: p.geoJson.id,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[...coordinates, coordinates[0]]],
      },
      properties: {
        ...p.information,
        ...p.properties,
        images: p.images,
        style: {
          fillColor: p.geoJson.fillColor,
          borderColor: p.geoJson.borderColor,
          fillOpacity: p.geoJson.fillOpacity,
        },
      },
    };
  });
};

export const mapLineToGeoJSON = (lines: LineModel[]) => {
  return lines.map((l) => ({
    id: l.geoJson.id,
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [
        ...l.geoJson.points.map((item) => ({
          id: item.id,
          coords: [item.coords.lat, item.coords.lng],
          ...(item.parentPointId && { parentPointId: item.parentPointId }),
        })),
      ],
    },
    properties: {
      ...l.information,
      ...l.properties,
      images: l.images,
      style: {
        color: l.geoJson.color,
        dashArray: l.geoJson.dashArray,
        weight: l.geoJson.weight,
      },
    },
  }));
};

export const mapPointToGeoJSON = (points: PointModel[]) => {
  return points.map((p) => ({
    id: p.geoJson.id,
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: p.geoJson.points.map((item) => [item.coords.lat, item.coords.lng])[0],
    },
    properties: {
      ...p.information,
      ...p.properties,
      images: p.images,
      style: {
        color: p.geoJson.color,
        size: p.geoJson.size,
      },
    },
  }));
};

export const parsePolygonsFromServer = (project: ProjectDataFromServer): PolygonModel[] => {
  return (project.geoJSON.features.filter((i) => i.geometry.type === 'Polygon') as GeoJSONPolygon[]).map((item) => ({
    geoJson: {
      id: item.id ?? makeId(),
      points: item.geometry.coordinates[0]
        .filter((_, i, arr) => i !== arr.length - 1)
        .map((c) => ({ id: makeId(), coords: { lat: c[0], lng: c[1] } })),
      fillColor: item.properties.style.fillColor ?? green[500],
      borderColor: item.properties.style.borderColor ?? green[500],
      fillOpacity: item.properties.style.fillOpacity ?? 0.6,
    },
    images: item.properties.images,
    information: {
      description: item.properties.description,
      name: item.properties.name,
      isIrrigationProperties: item.properties.isIrrigationProperties,
    },
    properties: {
      isSelectProduct: item.properties.isSelectProduct,
      selectedProductType: item.properties.selectedProductType,
      emitter: item.properties.emitter,
      lateral: item.properties.lateral,
      polygonCopySettingsFromId: item.properties.polygonCopySettingsFromId,
      polygonCropType: item.properties.polygonCropType,
      polygonDailyConsumption: item.properties.polygonDailyConsumption,
      polygonFieldSize: item.properties.polygonFieldSize,
      polygonIrrigationHoursPerDay: item.properties.polygonIrrigationHoursPerDay,
      polygonPlantSpacing: item.properties.polygonPlantSpacing,
      polygonRowSpacing: item.properties.polygonRowSpacing,
      polygonFieldSizeUnit: item.properties.polygonFieldSizeUnit,
      polygonDailyConsumptionUnit: item.properties.polygonDailyConsumptionUnit,
      polygonRowSpacingUnit: item.properties.polygonRowSpacingUnit,
      polygonPlantSpacingUnit: item.properties.polygonPlantSpacingUnit,
      emitterSpacing: item.properties.emitterSpacing,
      polygonOtherProduct: item.properties.polygonOtherProduct,
      polygonPlantingDate: item.properties.polygonPlantingDate,
      polygonLateralsPerRow: item.properties.polygonLateralsPerRow,
      polygonIsSoilType: item.properties.polygonIsSoilType,
      polygonSoilType: item.properties.polygonSoilType ?? [],
      polygonRowDirection: item.properties.polygonRowDirection,
      lateralType: item.properties.lateralType,
      lateralTypeText: item.properties.lateralTypeText,
      lateralSubtypeText: item.properties.lateralSubtypeText,
      lateralGroup: item.properties.lateralGroup,
      lateralNominalFlow: item.properties.lateralNominalFlow,
      lateralFlowPer: item.properties.lateralFlowPer,
      lateralSpacing: item.properties.lateralSpacing,
      lateralClassType: item.properties.lateralClassType,
      lateralDiameter: item.properties.lateralDiameter,
      emitterType: item.properties.emitterType,
      emitterTypeText: item.properties.emitterTypeText,
      emitterSubtypeText: item.properties.emitterSubtypeText,
      emitterNominalFlow: item.properties.emitterNominalFlow,
      emitterGroup: item.properties.emitterGroup,
    },
  }));
};

export const parseLinesFromServer = (project: ProjectDataFromServer): LineModel[] => {
  return (project.geoJSON.features.filter((i) => i.geometry.type === 'LineString') as GeoJSONLine[]).map((item) => ({
    geoJson: {
      id: item.id ?? makeId(),
      points: item.geometry.coordinates.map((p) => {
        if (typeof p === 'object' && 'id' in p) {
          // new data format
          return {
            id: p.id ?? makeId(),
            coords: { lat: p.coords[0], lng: p.coords[1] },
            ...(p.parentPointId && { parentPointId: p.parentPointId }),
          };
        } else {
          // old data format (array)
          return {
            id: makeId(),
            coords: { lat: p[0], lng: p[1] },
          };
        }
      }),
      color: item.properties.style.color ?? blue[500],
      dashArray: item.properties.style.dashArray ?? '0',
      weight: item.properties.style.weight ?? 2,
    },
    images: item.properties.images,
    information: {
      description: item.properties.description,
      name: item.properties.name,
      isIrrigationProperties: item.properties.isIrrigationProperties,
    },
    properties: {
      lineSections: item.properties.lineSections,
      lineTotalLength: item.properties.lineTotalLength,
      lineTotalLengthUnit: item.properties.lineTotalLengthUnit,
      lineCopySettingsFromId: item.properties.lineCopySettingsFromId,
    },
  }));
};

export const parsePointsFromServer = (project: ProjectDataFromServer): PointModel[] => {
  return (project.geoJSON.features.filter((i) => i.geometry.type === 'Point') as GeoJSONPoint[]).map((item) => ({
    geoJson: {
      id: item.id ?? makeId(),
      points: [{ id: makeId(), coords: { lat: item.geometry.coordinates[0], lng: item.geometry.coordinates[1] } }],

      color: item.properties.style.color ?? blue[500],
      size: item.properties.style.size ?? 1,
    },
    images: item.properties.images,
    information: {
      description: item.properties.description,
      name: item.properties.name,
      isIrrigationProperties: item.properties.isIrrigationProperties,
    },
    properties: {
      pointType: item.properties.pointType,
      pointElevation: item.properties.pointElevation,
      pointDiameter: item.properties.pointDiameter,
      pointFlowRate: item.properties.pointFlowRate,
      pointPressure: item.properties.pointPressure,
      pointElevationUnit: item.properties.pointElevationUnit,
      pointDiameterUnit: item.properties.pointDiameterUnit,
      pointFlowRateUnit: item.properties.pointFlowRateUnit,
      pointPressureUnit: item.properties.pointPressureUnit,
      pointWaterType: item.properties.pointWaterType,

      isFiltration: item.properties.isFiltration,
      isExistingFiltration: item.properties.isExistingFiltration,
      pointFiltrationType: item.properties.pointFiltrationType,
      pointFiltrationMode: item.properties.pointFiltrationMode,

      isPumping: item.properties.isPumping,
      isExistingPump: item.properties.isExistingPump,
      pointPumpType: item.properties.pointPumpType,
      pointPumpValue: item.properties.pointPumpValue,
      pointPumpCapacity: item.properties.pointPumpCapacity,
      pointPumpPressure: item.properties.pointPumpPressure,

      isFertigation: item.properties.isFertigation,
      isExistingFertigation: item.properties.isExistingFertigation,
      isElectricFertigation: item.properties.isElectricFertigation,
      pointElectricFertigationType: item.properties.pointElectricFertigationType,
      pointWaterMotivatedFertigationType: item.properties.pointWaterMotivatedFertigationType,

      isOperationAndControl: item.properties.isOperationAndControl,
      isExistingOperationAndControl: item.properties.isExistingOperationAndControl,
      operationAndControlMode: item.properties.operationAndControlMode,
      operationAndControlCommunicationType: item.properties.operationAndControlCommunicationType,
      pointCopySettingsFromId: item.properties.pointCopySettingsFromId,
    },
  }));
};
