import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Nullable } from 'shared';
import { projectApi } from 'shared/api';
import { selectProject } from 'shared/slices';

export const useExportProject = (callback: () => void, projectId?: Nullable<number>) => {
  let prId = projectId;

  if (prId === undefined) {
    const { id } = selectProject();
    prId = id;
  }

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onExportProject = async (email: string) => {
    try {
      setLoading(true);

      const language = i18n.language.split('-').shift();

      const { data: responseData } = await projectApi.exportProjectByEmail(prId, email, language);

      if (responseData.data) {
        toast.success(t('notifications_project_exported'));
        callback();
      }
    } catch (e) {
      toast.error('Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const onExportProjectBulk = async (emails: string[]) => {
    try {
      setLoading(true);

      const language = i18n.language.split('-').shift();

      const promises = emails.map((email) => projectApi.exportProjectByEmail(prId, email, language));

      await Promise.all(promises);
      toast.success(t('notifications_project_exported'));
      callback();
    } catch (e) {
      toast.error('Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return { loading, onExportProject, onExportProjectBulk };
};
