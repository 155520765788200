import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { SelectChangeEvent } from '@mui/material';
import { CatalogItem } from '../models';
import {
  getEmitterGroupsThunk,
  getEmittersThunk,
  selectEmitters,
  selectFormState,
  selectMasterGr,
  setPolygonInformation,
} from '../slices';

const ALL = 'All';

export const useEmitterFilters = ({ isIntegrated }: { isIntegrated: boolean }) => {
  const dispatch = useAppDispatch();
  const { masterGroups } = selectMasterGr();
  const { polygonInformation } = selectFormState();

  const filteredMasterGroups = useMemo(() => masterGroups.filter((item) => item.EMITTER), [masterGroups, isIntegrated]);

  const { emitters, emitterGroups } = selectEmitters();
  const [region] = useState('Europe');
  const [standard] = useState(true);
  const [limited] = useState(true);

  useEffect(() => {
    if (!polygonInformation.isSelectProduct) return;
    const getDefaults = async () => {
      // resetFilters();
      try {
        const groups = await dispatch(getEmitterGroupsThunk({ masterGroup: polygonInformation.emitterType })).unwrap();

        if (!groups) return;

        dispatch(setPolygonInformation({ emitterGroup: polygonInformation.emitterGroup }));

        const emitters = await dispatch(
          getEmittersThunk({
            group: polygonInformation.emitterGroup,
            region: region,
            standard: standard,
            limited: limited,
          })
        ).unwrap();

        if (!emitters) return;

        dispatch(setPolygonInformation({ emitter: polygonInformation.emitter }));
      } catch (e) {
        console.log(e);
      }
    };

    getDefaults();
  }, [polygonInformation.isSelectProduct]);

  const updateEmitters = async (group: string) => {
    const emitters = await dispatch(
      getEmittersThunk({
        group,
        region: region,
        standard: standard,
        limited: limited,
      })
    ).unwrap();

    if (!emitters) return;

    dispatch(setPolygonInformation({ emitter: emitters.GetCatalogsResult.RootResults[0] }));
  };

  const onMasterGroupChange = async (value: number) => {
    try {
      const emitterTypeText = masterGroups.find((m) => m.ID === value)?.MASTERGROUPNAME ?? '';

      dispatch(setPolygonInformation({ emitterType: value, emitterTypeText }));

      resetFilters();

      const groups = await dispatch(getEmitterGroupsThunk({ masterGroup: value })).unwrap();

      if (!groups) return;

      dispatch(setPolygonInformation({ emitterGroup: groups.GetGroupTypesResult.RootResults[0].GROUPS }));

      await updateEmitters(groups.GetGroupTypesResult.RootResults[0].GROUPS);
    } catch (e) {
      console.log(e);
    }
  };

  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        emitterGroup: e.target.value as string,
        polygonCopySettingsFromId: null,
      })
    );

    resetFilters();

    await updateEmitters(e.target.value as string);
  };
  const onEmitterChange = (_: any, item: CatalogItem) => {
    dispatch(setPolygonInformation({ emitter: item }));
  };

  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        emitterNominalFlow: e.target.value === ALL ? e.target.value : +(e.target.value as string),
        polygonCopySettingsFromId: null,
      })
    );
  };
  const onSpacingChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPolygonInformation({ emitterSpacing: +e.target.value, polygonCopySettingsFromId: null }));
  };

  const nominalFlowFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.AltQnom !== null) {
      return polygonInformation.emitterNominalFlow !== ALL
        ? item.AltQnom === polygonInformation.emitterNominalFlow
        : true;
    }

    return polygonInformation.emitterNominalFlow !== ALL ? item.Qnom === polygonInformation.emitterNominalFlow : true;
  };
  // const spacingFilter = (item: CatalogItem) => {
  //   if (region === 'USA' && item.AltSpacing !== null) {
  //     return polygonInformation.emitterSpacing !== 0 ? item.AltSpacing === polygonInformation.emitterSpacing : true;
  //   }
  //   return polygonInformation.emitterSpacing !== 0 ? item.Spacing === polygonInformation.emitterSpacing : true;
  // };

  const resetFilters = () => {
    dispatch(setPolygonInformation({ emitterNominalFlow: ALL, emitterSpacing: 0 }));
  };

  const emittersFiltered = useMemo(() => {
    return emitters.filter((item) => nominalFlowFilter(item));
  }, [polygonInformation.emitterNominalFlow, polygonInformation.emitterSpacing, emitters]);

  useEffect(() => {
    if (
      emittersFiltered.length &&
      !emittersFiltered.find((item) => item.CATLOG === polygonInformation.emitter?.CATLOG)
    ) {
      onEmitterChange(null, emittersFiltered[0]);
    }
  }, [emittersFiltered]);

  const nominalFlows = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emitters
          .map((item) => (region === 'USA' && item.AltQnom !== null ? item.AltQnom : item.Qnom))
          .sort((a, b) => a - b)
      ),
    ];
  }, [emitters]);

  useEffect(() => {
    if (
      emittersFiltered.length &&
      !emittersFiltered.find((item) => item.CATLOG === polygonInformation.emitter?.CATLOG)
    ) {
      dispatch(setPolygonInformation({ emitterNominalFlow: ALL, emitterSpacing: 0 }));
    }
  }, [nominalFlows, emittersFiltered]);

  const spacings = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emitters
          .filter((item) => nominalFlowFilter(item))
          .map((item) => (region === 'USA' && item.AltSpacing !== null ? item.AltSpacing : item.Spacing))
          .sort((a, b) => a - b)
      ),
    ];
  }, [polygonInformation.emitterNominalFlow, emitters]);

  useEffect(() => {
    if (
      emittersFiltered.length &&
      !emittersFiltered.find((item) => item.CATLOG === polygonInformation.emitter?.CATLOG)
    ) {
      dispatch(setPolygonInformation({ emitterSpacing: 0 }));
    }
  }, [spacings]);

  const emitterSubtype = useMemo(
    () =>
      emitterGroups.find((item) => item.GROUPS === polygonInformation.emitterGroup?.trim())
        ? polygonInformation.emitterGroup?.trim()
        : '',
    [polygonInformation.emitterGroup, emitterGroups]
  );

  const emitterProduct = useMemo(() => {
    if (emittersFiltered.length) {
      return emittersFiltered.find((item) => item.CATLOG === polygonInformation.emitter?.CATLOG) ?? emittersFiltered[0];
    }
    return null;
  }, [polygonInformation.lateral?.CATLOG, emittersFiltered]);

  const nominalFlowValue = useMemo(
    () => nominalFlows.find((item) => item === polygonInformation.emitterNominalFlow) ?? ALL,
    [polygonInformation.emitterNominalFlow, nominalFlows]
  );

  const spacingValue = useMemo(
    () => spacings.find((item) => item === polygonInformation.emitterSpacing) ?? '',
    [polygonInformation.emitterSpacing, spacings]
  );

  const getEmitterMasterGroup = () => {
    return (
      filteredMasterGroups.find((item) => item.ID === polygonInformation.emitterType)?.ID ?? filteredMasterGroups[0].ID
    );
  };

  const emitterMasterGroup = getEmitterMasterGroup();

  useEffect(() => {
    if (!filteredMasterGroups.find((item) => item.ID === polygonInformation.lateralType))
      onMasterGroupChange(emitterMasterGroup);
  }, [emitterMasterGroup]);

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: emitterMasterGroup,
    onMasterGroupChange,
    emitterSubtype,
    onGroupTypeChange,
    nominalFlow: nominalFlowValue,
    onNominalFlowChange,
    spacing: spacingValue,
    onSpacingChange,
    nominalFlows,
    spacings,
    emitterProduct,
    emitter: polygonInformation.emitter,
    emittersFiltered,
    onEmitterChange,
  };
};
