import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DescSortIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        d="M7.9987 11.0406C7.98958 11.2477 7.91927 11.443 7.76953 11.5927L6.95443 12.4078L5.65755 13.7047L5.36198 14.0003C5.29557 14.0667 5.21875 14.1187 5.13672 14.1552C4.84896 14.2841 4.48958 14.2333 4.25651 14.0003L1.84896 11.5927C1.70703 11.4508 1.61979 11.2411 1.61979 11.0406C1.61979 10.8492 1.70443 10.6201 1.84896 10.4885C1.9974 10.3518 2.19271 10.2503 2.40104 10.2594C2.60937 10.2698 2.80599 10.3401 2.95573 10.4885L3.77083 11.3036L4.02734 11.5602V3.00417C4.02734 2.86224 4.02604 2.72031 4.02734 2.57969V2.56146C4.02734 2.36094 4.11458 2.1513 4.25651 2.00937C4.39193 1.87396 4.61458 1.77239 4.80859 1.78021C5.01042 1.78932 5.22005 1.85573 5.36068 2.00937C5.5013 2.16172 5.58984 2.35052 5.58984 2.56146V11.5641L6.36979 10.7841L6.66536 10.4885C6.80729 10.3466 7.01693 10.2594 7.21745 10.2594C7.40885 10.2594 7.63802 10.344 7.76953 10.4885C7.90625 10.637 8.00781 10.8323 7.9987 11.0406Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M7.2487 7.47526C7.03776 7.47526 6.84896 7.5638 6.69661 7.70443C6.54297 7.84505 6.47656 8.05469 6.46745 8.25651C6.45964 8.45052 6.5612 8.67318 6.69661 8.80859C6.83854 8.95052 7.04818 9.03776 7.2487 9.03776H7.25911C7.51563 9.04037 7.77344 9.03776 8.02995 9.03776H13.5964C13.8073 9.03776 13.9961 8.94922 14.1484 8.80859C14.3021 8.66797 14.3685 8.45833 14.3776 8.25651C14.3854 8.0625 14.2839 7.83984 14.1484 7.70443C14.0065 7.5625 13.7969 7.47526 13.5964 7.47526H13.5859C13.3294 7.47266 13.0716 7.47526 12.8151 7.47526H7.2487ZM11.5898 6.74089C11.8021 6.74089 11.9896 6.65234 12.1419 6.51172C12.2956 6.37109 12.362 6.16146 12.3711 5.95964C12.3789 5.76562 12.2773 5.54297 12.1419 5.40755C12 5.26563 11.7904 5.17839 11.5898 5.17839H7.2487C7.03776 5.17839 6.84896 5.26693 6.69661 5.40755C6.54297 5.54818 6.47656 5.75781 6.46745 5.95964C6.45964 6.15365 6.5612 6.3763 6.69661 6.51172C6.83854 6.65365 7.04818 6.74089 7.2487 6.74089H11.5898ZM9.58203 4.44531C9.79427 4.44531 9.98177 4.35677 10.1341 4.21615C10.2878 4.07552 10.3542 3.86589 10.3633 3.66406C10.3711 3.47005 10.2695 3.2474 10.1341 3.11198C9.99219 2.97005 9.78255 2.88281 9.58203 2.88281H7.2487C7.03776 2.88281 6.84896 2.97135 6.69661 3.11198C6.54297 3.2526 6.47656 3.46224 6.46745 3.66406C6.45964 3.85807 6.5612 4.08073 6.69661 4.21615C6.83854 4.35807 7.04818 4.44531 7.2487 4.44531H9.58203Z"
        fill="black"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2258_8042">
        <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.33333 1.3335)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
