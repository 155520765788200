export const mapStyles = {
  '& .track_icon': {},

  '& .trackingPoint': {
    pointerEvents: 'none',
    cursor: 'default',
    zIndex: 9999,
    borderRadius: '50%',
    border: '1px solid #E5E5E5',
    position: 'relative',
    width: '12px',
    height: '12px',
    backgroundColor: '#3444D0',
    animation: 'gps-pulsate 2s ease-out',
    animationIterationCount: 'infinite',
  },

  '& .container': {
    position: 'relative',
    height: '20px',
    width: '20px',

    '.point': {
      position: 'absolute',
      height: '10px',
      width: '10px',
      top: '5px',
      left: '5px',
      borderRadius: '30px',
      bgcolor: 'white',
    },

    '.pulse_ring': {
      position: 'absolute',
      border: '3px solid #fff',
      borderRadius: '30px',
      height: '20px',
      width: '20px',
      animation: 'pulsate 1s ease-out',
      animationIterationCount: 'infinite',
    },

    '.label_box': {
      position: 'absolute',
      top: '-37px',
      left: '-40px',
      width: '110px',

      '.label': {
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.68)',
        padding: '5px 10px',
        fontSize: 16,
        borderRadius: '16px',
        width: 'fit-content',
        margin: '0 auto',
      },
    },
  },

  '@-webkit-keyframes pulsate': {
    '0%': { transform: 'scale(0.1, 0.1)', opacity: 0.0 },
    '50%': { opacity: 1.0 },
    '100%': { transform: 'scale(1.2, 1.2)', opacity: 0.0 },
  },

  '@-webkit-keyframes gps-pulsate': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(82, 82, 255, 1)',
    },
    '80%': {
      boxShadow: '0 0 0 24px rgba(82, 82, 255, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 24px rgba(82, 82, 255, 0)',
    },
  },

  '& .distance_icon, .selected_figure_point, .track_icon': {
    zIndex: '1000 !important',
  },
  '& .red_icon, & .blue_icon': {
    zIndex: '1001 !important',
  },

  '& .distance_icon_container': {
    backgroundColor: '#1D8742',
    padding: '5px 9px',
    borderRadius: '30px',
    border: '0.5px solid white',
    color: 'white',
    fontSize: '16px',
    lineHeight: '16px',
  },

  '& .static_point, .static_base_point': {
    zIndex: '500 !important',
  },

  '& .red_board': {
    border: '2px solid red',
    borderRadius: '50%',
  },

  '& .point_container': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '.static_point, .ref_point, .red_point, .blue_point, .static_base_point': {
      height: '12px',
      width: '12px',
      borderRadius: '20px',
      border: '2px solid #1D8742',
      backgroundColor: 'white',
    },
    '.static_point': {
      backgroundColor: 'white',
    },
    '.static_base_point': {
      backgroundColor: '#1D8742',
    },
    '.ref_point': {
      backgroundColor: 'white',
      borderColor: 'white',
      height: '10px',
      width: '10px',
    },
    '.red_point': {
      backgroundColor: '#E56353',
      height: '14px',
      width: '14px',
    },
    '.blue_point': {
      backgroundColor: 'blue',
      height: '14px',
      width: '14px',
    },
  },
};
