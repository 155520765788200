import { Box, CircularProgress, IconButton, InputAdornment, Select as MuiSelect, SelectProps } from '@mui/material';
import { forwardRef } from 'react';
import { useToggle } from 'shared/hooks';
import { Icon } from '../Icon';
import { Row } from '../Row';

type Props = SelectProps & {
  loading?: boolean;
  withGreenBorder?: boolean;
};

export const AdditionalSelect = forwardRef<unknown, Props>(({ loading, withGreenBorder, ...props }, ref) => {
  const { open, toggle } = useToggle(false);

  return (
    <Row p="1px">
      {withGreenBorder && <Box sx={{ height: '20px', width: '1px', bgcolor: 'green' }} />}
      <MuiSelect
        open={open}
        onOpen={toggle}
        onClose={toggle}
        {...props}
        fullWidth
        sx={{
          bgcolor: 'white',
          height: 40,
          borderRadius: '10px',
          border: 'none',
          pr: 0,
          color: 'info.dark',
          fieldSet: { display: 'none' },
          svg: { display: 'none' },
          '& .MuiSelect-select': { pr: '0 !important' },
        }}
        endAdornment={
          loading ? (
            <InputAdornment sx={{ mr: 3 }} position="end">
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ) : (
            <IconButton onClick={toggle}>
              <Icon path={'chevron-down'} sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
            </IconButton>
          )
        }
        MenuProps={{ PaperProps: { sx: { maxHeight: '40%' } } }}
        inputRef={ref}
      >
        {props.children}
      </MuiSelect>
    </Row>
  );
});
