import { styled } from '@mui/material';

export const StopMeasurementIcon = styled('a')`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #db3232;
  border-radius: 4px;
  vertical-align: top;
  margin-right: 7px;
`;
