import { BaseLayout } from '../../shared/ui';
import { ForgotPassword } from '../../widgets';

export const ForgotPasswordPage = () => {
  return (
    <BaseLayout>
      <ForgotPassword />
    </BaseLayout>
  );
};
