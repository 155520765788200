import L, { divIcon, LatLngLiteral, LeafletEventHandlerFnMap } from 'leaflet';
import { FC, useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { LineModel, Point, PolygonModel } from '../../models';
import { selectCurrentMapItem, selectSelectedItem } from 'shared/slices';

export const redIcon = divIcon({
  html: `
      <div class="point_container">
        <div class='red_point'></div>
      </div>
      `,
  className: 'red_icon',
  iconAnchor: [15, 15],
  iconSize: [30, 30],
});

export const blueIcon = divIcon({
  html: `
      <div class="point_container">
        <div class='blue_point'></div>
      </div>
      `,
  className: 'blue_icon',
  iconAnchor: [15, 15],
  iconSize: [30, 30],
});

interface Props {
  pointInfo: Point;
  currentPointId: string | null;
  basePointId?: string | null;
  onSelect: () => void;
  onChangePosition: (newPos: LatLngLiteral) => void;
  onSaveStartDragPosition: (oldPos: LatLngLiteral) => void;
  disabled?: boolean;
  isReferencePoint?: boolean;
  tooNearPointId?: string | null;
  onDragEnd?: () => void;
  isSnapTargetPointId?: boolean;
}

export const DraggablePoint: FC<Props> = ({
  pointInfo: { id, coords },
  currentPointId,
  basePointId,
  onSelect,
  onChangePosition,
  onSaveStartDragPosition,
  disabled,
  isReferencePoint,
  tooNearPointId,
  onDragEnd,
  isSnapTargetPointId,
}) => {
  const selectedObjectType = selectSelectedItem();
  const selectedObject = selectCurrentMapItem();

  const markerRef = useRef<any>(null);

  const iconBorderColor = useMemo(() => {
    let color = '#1D8742';

    if (selectedObjectType === 'polygon') {
      color = (selectedObject as PolygonModel)?.geoJson.borderColor;
    } else if (selectedObjectType === 'line') {
      color = (selectedObject as LineModel)?.geoJson.color;
    }

    return color;
  }, [selectedObject]);

  const iconClassName = useMemo(() => {
    let className = 'static_point';

    if (basePointId === id) {
      className = 'static_base_point';
    } else if (isReferencePoint) {
      className = 'ref_point';
    }

    return className;
  }, [basePointId, isReferencePoint]);

  const pointIcon = useMemo(() => {
    return divIcon({
      html: `
      <div class="point_container">
        <div style='color: #fff; font-weight: 900; font-size: 22px; border-color: ${iconBorderColor} !important' class=${iconClassName}></div>
      </div>
      `,
      className: 'static_icon',
      iconAnchor: [15, 15],
      iconSize: [30, 30],
    });
  }, [iconBorderColor, iconClassName]);

  const selectedIcon = useMemo(() => {
    return L.icon({
      iconUrl: `/images/mapIcons/${basePointId === id ? 'selected_figure_point_base' : 'selected_figure_point'}.svg`,
      iconSize: [48, 76],
      iconAnchor: [23.2, 7.2],
      className: 'selected_figure_point',
    });
  }, [basePointId]);

  const eventHandlers: LeafletEventHandlerFnMap = {
    drag() {
      const marker = markerRef.current;
      if (marker != null) {
        onChangePosition(marker.getLatLng());
      }
    },
    click() {
      onSelect();
    },
    dragstart() {
      const marker = markerRef.current;
      if (marker != null) {
        onSaveStartDragPosition(marker.getLatLng());
      }
    },
    dragend() {
      onDragEnd?.();
    },
  };

  return (
    <>
      <Marker
        icon={currentPointId === id ? selectedIcon : pointIcon}
        draggable={currentPointId === id && !disabled}
        eventHandlers={disabled ? {} : eventHandlers}
        position={coords}
        ref={markerRef}
      />
      {currentPointId === id && tooNearPointId && <Marker icon={redIcon} position={coords} />}
      {currentPointId === id && isSnapTargetPointId && <Marker icon={blueIcon} position={coords} />}
    </>
  );
};
