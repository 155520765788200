import { BaseLayout } from '../../shared/ui';
import { RequestAccess } from '../../widgets';

export const RequestAccessPage = () => {
  return (
    <BaseLayout>
      <RequestAccess />
    </BaseLayout>
  );
};
