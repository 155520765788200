import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: false,
    react: {
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added',
    },
  });
// .then(async () => {
//   await loadFallbackTranslations();
//   await loadTranslations(i18n.language);
// });

// i18n.on('languageChanged', async (lng) => {
//   await loadTranslations(lng);
// });

export default i18n;
