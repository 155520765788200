// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TEMPORARY DISABLED TS BECAUSE OF BUG IN MUI

import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useToggle } from 'shared/hooks';
import { CatalogItem } from 'shared/models';
import { Icon } from 'shared/ui/Icon';

interface Props {
  onChange?: (e: any, item: CatalogItem) => void;
  value: null | CatalogItem;
  options: any[];
  loading?: boolean;
  disabled?: boolean;
  label?: string;
}

export const HTProductSelect: FC<Props> = ({ onChange, options, value, disabled, loading, label }) => {
  const { open, toggle } = useToggle(false);

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      {label && (
        <Typography noWrap sx={{ color: 'secondary.dark', fontSize: '16px', fontWeight: 500 }}>
          {label}
        </Typography>
      )}
      <Stack direction="row" alignItems="center">
        <Autocomplete
          open={open}
          onOpen={toggle}
          onClose={toggle}
          disabled={disabled}
          onChange={onChange}
          value={value}
          options={options ?? []}
          getOptionLabel={(item) => item.CATLOGDESC.trim()}
          isOptionEqualToValue={(option, value) => option.CATLOG === value.CATLOG}
          sx={{
            flexGrow: 1,
            borderRadius: '9px',

            '.MuiOutlinedInput-root': {
              borderRadius: '10px',

              border: '1px solid white',
              borderColor: disabled ? 'info.light' : 'info.light',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.15)',

              bgcolor: disabled ? 'info.light' : 'white',
              height: 42,
              py: 0,
              pr: '0 !important',
              '.MuiAutocomplete-popupIndicator': { display: 'none' },
              '.MuiAutocomplete-endAdornment': { mr: 3 },
            },

            '& .MuiOutlinedInput-root': { '& > fieldset': { border: 'none !important' } },

            // ...(error && {
            //   background: palette.error.light,
            //   '& .MuiOutlinedInput-root': { '& > fieldset': { border: `1px solid ${palette.error.main}` } },
            // }),
          }}
          filterOptions={createFilterOptions({ matchFrom: 'any' })}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {loading ? (
                      <CircularProgress color="primary" size={20} sx={{ mr: 1 }} />
                    ) : (
                      <IconButton onClick={toggle}>
                        <Icon path={'chevron-down'} sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
