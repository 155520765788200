import { LatLngLiteral, PathOptions } from 'leaflet';
import { FC, useEffect, useMemo, useState } from 'react';
import { Polygon, useMap } from 'react-leaflet';
import { Point, PolygonModel } from 'shared/models';
import { useAppDispatch } from 'shared/hooks';
import {
  deletePolygonPoint,
  dragPolygonPoint,
  saveDragPolygonAction,
  selectCurrentPolygonPointId,
  selectIsColorPicker,
  selectIsItemDrawing,
  selectIsPolygonDrawing,
  selectIsTrackingPointDrawing,
  selectLine,
  selectLineId,
  selectLines,
  selectNewPolygon,
  selectPoint,
  selectPoints,
  selectPolygon,
  selectPolygonId,
  selectPolygons,
  selectSaveProjectStep,
  setCurrentPolygonPointId,
} from 'shared/slices';
import { DistanceLine, DraggablePoint } from 'shared/ui';
import { SavingStep } from 'shared/enums';
import { useThrottle } from 'shared/hooks/useThrottle';
import { checkPointsIsTooClosely, getRefPointByCounterClockWise } from 'shared/lib';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';

interface Props {
  polygonInfo: PolygonModel;
}

export const PolygonItem: FC<Props> = ({ polygonInfo }) => {
  const dispatch = useAppDispatch();
  const map = useMap();

  const [tooNearPointId, setTooNearPointId] = useState<string | null>(null);
  const [nearPoint, setNearPoint] = useState<Point | null>(null);
  const [isPointMoving, setIsPointMoving] = useState(false);
  const [totalObjectsPoints, setTotalObjectsPoints] = useState<Point[]>([]);

  const isPolygonDrawing = selectIsPolygonDrawing();
  const selectedPolygonId = selectPolygonId();
  const polygons = selectPolygons();
  const newPolygon = selectNewPolygon();
  const currentPolygonPointId = selectCurrentPolygonPointId();
  const isDrawing = selectIsItemDrawing();
  const isColorPicker = selectIsColorPicker();
  const saveProjectStep = selectSaveProjectStep();
  const isTrackingPointDrawing = selectIsTrackingPointDrawing();
  const selectedLineId = selectLineId();

  const lines = selectLines();
  const points = selectPoints();

  const colorOptions: PathOptions = useMemo(() => {
    const { id, borderColor, fillColor, fillOpacity } = polygonInfo.geoJson;

    if (selectedPolygonId !== id && saveProjectStep === SavingStep.SCREEN_SHOOTING) {
      return { fillOpacity: 0, opacity: 0 };
    }

    const polygonOptions = { fillColor, fillOpacity, color: borderColor, weight: 2, opacity: 1 };
    const selectedOptions = {
      fillColor,
      fillOpacity,
      color: 'white',
      opacity: 1,
      weight: 3,
    };

    return selectedPolygonId === id && !isPolygonDrawing && saveProjectStep !== SavingStep.SCREEN_SHOOTING
      ? selectedOptions
      : polygonOptions;
  }, [isPolygonDrawing, selectedPolygonId, polygons, newPolygon, saveProjectStep, selectedLineId]);

  const isVisibleSubElements =
    selectedPolygonId === polygonInfo.geoJson.id && isPolygonDrawing && saveProjectStep !== SavingStep.SCREEN_SHOOTING;

  useEffect(() => {
    if (!isDrawing) {
      const filteredPolygons = polygons.filter((p) => p.geoJson.id !== polygonInfo.geoJson.id);
      const filteredLines = lines.filter((l) => !l.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID));

      const allPoints = [...points, ...filteredLines, ...filteredPolygons].flatMap((i) => i.geoJson.points);
      setTotalObjectsPoints(allPoints);
    }
  }, [lines, polygons, points, isDrawing]);

  // snap to another object's point
  useEffect(() => {
    if (selectedPolygonId === polygonInfo.geoJson.id && currentPolygonPointId && isPointMoving) {
      const polygonPoints = polygonInfo.geoJson.points;
      const selectedPoint = polygonPoints.find((p) => p.id === currentPolygonPointId) as Point;

      if (selectedPoint) {
        const selCoords = map.latLngToContainerPoint(selectedPoint.coords);

        let foundNearPoint: Point | null = null;

        for (const p of totalObjectsPoints) {
          const pointCoords = map.latLngToContainerPoint(p.coords);
          const isNear = checkPointsIsTooClosely(selCoords, pointCoords);

          if (isNear) {
            foundNearPoint = p;
            break;
          }
        }

        setNearPoint(foundNearPoint);
      }
    }
  }, [polygonInfo]);

  useEffect(() => {
    if (selectedPoint) {
      const selCoords = map.latLngToContainerPoint(selectedPoint.coords);

      polygonInfo.geoJson.points.forEach((p) => {
        if (p.id === currentPolygonPointId) return;

        const pointCoords = map.latLngToContainerPoint(p.coords);
        const isNear = checkPointsIsTooClosely(selCoords, pointCoords);

        if (isNear) {
          setTooNearPointId(p.id);
        } else {
          if (tooNearPointId === p.id) setTooNearPointId(null);
        }
      });
    }
  }, [polygonInfo]);

  const selectedPoint = useMemo(() => {
    if (selectedPolygonId === polygonInfo.geoJson.id && currentPolygonPointId) {
      const points = polygonInfo.geoJson.points;
      return points.find((p) => p.id === currentPolygonPointId) as Point;
    }
  }, [polygonInfo, currentPolygonPointId, tooNearPointId]);

  const referencePoint = useMemo(() => {
    if (
      selectedPolygonId === polygonInfo.geoJson.id &&
      polygonInfo.geoJson.points.length > 1 &&
      currentPolygonPointId
    ) {
      const points = polygonInfo.geoJson.points;

      return getRefPointByCounterClockWise(currentPolygonPointId, points);
    }
  }, [currentPolygonPointId, tooNearPointId]);

  const onSelectPolygon = () => {
    if (isDrawing || isTrackingPointDrawing) return;
    const { id } = polygonInfo.geoJson;

    dispatch(selectLine(null));
    dispatch(selectPoint(null));
    dispatch(selectPolygon(id === selectedPolygonId ? null : id));
  };
  const onSelect = (pointId: string | null) => dispatch(setCurrentPolygonPointId(pointId));
  const onSaveStartDragPosition = (oldPos: LatLngLiteral) => {
    dispatch(saveDragPolygonAction(oldPos));
    setIsPointMoving(true);
  };

  const throttledUpdatePos = useThrottle((newPos: LatLngLiteral) => {
    dispatch(dragPolygonPoint(newPos));
    setTooNearPointId(null);
  }, 30);
  const onChangePosition = (newPos: LatLngLiteral) => {
    throttledUpdatePos(newPos);
  };
  const onDeletePoint = () => {
    if (tooNearPointId) {
      dispatch(deletePolygonPoint(tooNearPointId));
    }
    setTooNearPointId(null);
  };
  const snapToObject = () => {
    if (nearPoint) {
      dispatch(dragPolygonPoint(nearPoint.coords));
    }
    setIsPointMoving(false);
    setNearPoint(null);
  };

  const onDragEnd = () => {
    onDeletePoint();
    snapToObject();
  };

  return (
    <Polygon
      pathOptions={colorOptions}
      positions={polygonInfo.geoJson.points.map((p) => p?.coords)}
      eventHandlers={{
        click() {
          onSelectPolygon();
        },
      }}
    >
      {isVisibleSubElements &&
        polygonInfo.geoJson.points.map((p) => (
          <DraggablePoint
            key={p.id}
            pointInfo={p}
            currentPointId={currentPolygonPointId}
            onSelect={() => onSelect(p.id)}
            onChangePosition={onChangePosition}
            onSaveStartDragPosition={onSaveStartDragPosition}
            disabled={isColorPicker}
            isReferencePoint={p.id === referencePoint?.id}
            tooNearPointId={tooNearPointId}
            isSnapTargetPointId={Boolean(nearPoint)}
            onDragEnd={onDragEnd}
          />
        ))}

      {isVisibleSubElements && currentPolygonPointId && polygonInfo.geoJson.points.length > 1 && (
        <DistanceLine
          points={[selectedPoint as Point, referencePoint as Point]}
          onClose={() => onSelect(currentPolygonPointId)}
        />
      )}
    </Polygon>
  );
};
