import { capitalize } from '../lib';

export * from './colors';
export * from './locales';
export const NEW_POLYGON_ID = 'new_polygon_id';
export const NEW_LINE_ID = 'new_line_id';
export const NEW_POINT_ID = 'new_point_id';
export const POLYGON_ROW_DIRECTION_ID = '-row-direction';
export const HEADER_HEIGHT = 48;

export const POLYGON = 'polygon';
export const LINE = 'line';
export const POINT = 'point';

export const POLYGON_DEFAULT_NAME = 'Area';
export const LINE_DEFAULT_NAME = capitalize(LINE);
export const POINT_DEFAULT_NAME = capitalize(POINT);

export const INITIAL_ROW_DIRECTION_ARROW_ROTATION = 30.595;

export const METRIC_DEFAULTS = {
  Pressure: 'm',
  FlowPerLength: 'lph/100m',
  Flow: 'lph',
  TotalFlow: 'm³/h',
  Length: 'm',
  Velocity: 'm/s',
  PipeDiameter: 'mm',
  ValveDiameter: 'mm',
  EmitterSpacing: 'm',
  LateralSpacing: 'm',
  Area: 'Ha',
  AppnDepth: 'mm',
  AppnRate: 'mm/h',
};

export const US_DEFAULTS = {
  Pressure: 'psi',
  FlowPerLength: 'gpm/100ft',
  Flow: 'gph',
  TotalFlow: 'gpm',
  Length: 'ft',
  Velocity: 'ft/s',
  PipeDiameter: 'in',
  ValveDiameter: 'in',
  EmitterSpacing: 'in',
  LateralSpacing: 'in',
  Area: 'acres',
  AppnDepth: 'in',
  AppnRate: 'in/h',
};

export const UNITS = {
  Pressure: ['m', 'ft', 'bar', 'kpa', 'kg/cm²', 'atm', 'psi'],
  FlowPerLength: ['lph/100m', 'lph/m', 'gpm/100ft', 'gpm/ft'],
  Flow: ['lph', 'lpm', 'lps', 'gph', 'gpm'],
  TotalFlow: ['lph', 'lpm', 'lps', 'gph', 'gpm', 'm³/h', 'm³/s'],
  Length: ['mm', 'cm', 'in', 'm', 'ft', 'miles', 'km'],
  Velocity: ['m/s', 'ft/s', 'kph', 'mph'],
  PipeDiameter: ['mm', 'cm', 'in', 'm', 'ft'],
  ValveDiameter: ['mm', 'cm', 'in', 'm', 'ft'],
  EmitterSpacing: ['mm', 'cm', 'in', 'm', 'ft', 'miles', 'km'],
  LateralSpacing: ['mm', 'cm', 'in', 'm', 'ft', 'miles', 'km'],
  Area: ['m²', 'ft²', 'Ha', 'acres', 'dunam'],
  AppnDepth: ['mm', 'cm', 'in', 'm', 'ft'],
  AppnRate: ['mm/h', 'in/h'],
};

export const LENGTH_UNITS = {
  mm: 'millimeters',
  cm: 'centimeters',
  in: 'inches',
  m: 'meters',
  ft: 'feet',
  miles: 'miles',
  km: 'kilometers',
};

export const NO_CONNECTION_MESSAGE = 'Network Connection Lost';
