import { Dialog, DialogContent, DialogProps, Typography, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from '..';

interface Props extends DialogProps {
  title: string;
  margin?: number;
  onConfirm: () => void;
  loading?: boolean;
  white?: boolean;
}

export const ConfirmModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  children,
  loading,
  margin = 4,
  white,
  ...props
}) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => onClose?.(e, 'backdropClick');
  const isMobile = useMediaQuery('(max-width:550px)');
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '25px',
          boxShadow: '0px -1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 0px rgba(0, 0, 0, 0.16)',
          padding: '24px',
        },
      }}
      {...props}
    >
      <DialogContent
        sx={{
          // p: isMobile ? '5px 12px' : '16px 24px',
          p: 0,
          position: 'relative',
          overflow: 'auto',
          backgroundColor: '#fff',

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'primary.main',
            borderRight: isMobile ? '12px solid #F5FBFF' : '25px solid #F5FBFF',
          },
          '&::-webkit-scrollbar': {
            width: isMobile ? '18px' : '32px',
            backgroundColor: 'secondary.dark',
          },
        }}
      >
        {children}

        <Row mt="20px" width="100%" justifyContent={'center'} gap={'28px'}>
          <Button onClick={handleClose} btnColor="#fff" borderColor={'#1D8742'}>
            <Typography sx={{ fontWeight: 600, color: '#1D8742' }}>{t('no')}</Typography>
          </Button>
          <Button
            btnColor="primary.main"
            borderColor={white ? 'primary.main' : 'info.main'}
            onClick={onConfirm}
            loading={loading}
          >
            <Typography sx={{ fontWeight: 600 }}>{t('yes')}</Typography>
          </Button>
        </Row>
      </DialogContent>
    </Dialog>
  );
};
