import { CircularProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SharedWithUsers } from '../../../../shared/models';
import { ShareIcon } from '../../../../shared/ui';
import { SharedWithUser } from './SharedWithUser';

type Props = {
  sharedUsers: SharedWithUsers[];
  loading: boolean;
  projectId: number;
  toggleShareModal: () => void;
  revalidateSharedWithUsers: (data: SharedWithUsers[]) => void;
};

export const ManageAccessItem: React.FC<Props> = ({
  projectId,
  toggleShareModal,
  sharedUsers,
  loading,
  revalidateSharedWithUsers,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <ListItem sx={{ justifyContent: 'center', mt: '10px' }}>
        <CircularProgress color="inherit" size={20} />
      </ListItem>
    );
  }

  return (
    <>
      {sharedUsers.length ? (
        sharedUsers.map((sharedItem) => (
          <SharedWithUser
            revalidateSharedWithUsers={revalidateSharedWithUsers}
            key={sharedItem.userId}
            projectId={projectId}
            item={sharedItem}
          />
        ))
      ) : (
        <ListItem sx={{ justifyContent: 'center' }}>
          <Typography color={'#ccc'}>Project is not shared yet</Typography>
        </ListItem>
      )}
      <ListItem disablePadding sx={{ px: '12px', '&:hover': { backgroundColor: 'rgba(29, 135, 69, 0.16)' } }}>
        <ListItemButton
          disableRipple
          disableGutters
          onClick={toggleShareModal}
          sx={{
            borderBottom: '1px solid grey',
            borderColor: 'info.light',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <ListItemIcon sx={{ minWidth: '45px' }}>
            <ShareIcon green />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                sx={{
                  color: '#242731',
                  fontSize: '16px',
                  lineHeight: '130%',
                }}
              >
                {t('Share project with new user')}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
    </>
  );
};
