import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DeleteIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_3027_2331)">
      <path
        d="M16.3817 22.3446H7.61753C7.00125 22.3465 6.40646 22.1183 5.94956 21.7047C5.49267 21.2911 5.20654 20.7219 5.14718 20.1085L3.72787 5.87485C3.71617 5.75978 3.72876 5.64354 3.76484 5.53365C3.80091 5.42376 3.85966 5.32267 3.93729 5.23693C4.01492 5.15119 4.10969 5.08271 4.21546 5.03592C4.32124 4.98914 4.43566 4.96509 4.55132 4.96533H19.4479C19.5635 4.96534 19.6778 4.98957 19.7834 5.03644C19.8891 5.08331 19.9837 5.15179 20.0613 5.23748C20.1389 5.32316 20.1976 5.42414 20.2338 5.53392C20.27 5.6437 20.2828 5.75984 20.2713 5.87485L18.852 20.1077C18.7927 20.7212 18.5066 21.2905 18.0497 21.7042C17.5928 22.1179 16.998 22.3463 16.3817 22.3446ZM5.46498 6.62051L6.79408 19.9438C6.81404 20.1482 6.90946 20.3379 7.06172 20.4758C7.21397 20.6136 7.41213 20.6898 7.61753 20.6895H16.3817C16.5871 20.6898 16.7852 20.6136 16.9375 20.4758C17.0897 20.3379 17.1852 20.1482 17.2051 19.9438L18.5342 6.62051H5.46498Z"
        fill="#808080"
      />
      <path
        d="M21.5173 6.6205H2.48286C2.26337 6.6205 2.05287 6.53331 1.89767 6.37811C1.74247 6.22291 1.65527 6.01241 1.65527 5.79292C1.65527 5.57343 1.74247 5.36293 1.89767 5.20773C2.05287 5.05252 2.26337 4.96533 2.48286 4.96533H21.5173C21.7368 4.96533 21.9473 5.05252 22.1025 5.20773C22.2577 5.36293 22.3449 5.57343 22.3449 5.79292C22.3449 6.01241 22.2577 6.22291 22.1025 6.37811C21.9473 6.53331 21.7368 6.6205 21.5173 6.6205ZM12.0001 19.0343C11.7806 19.0343 11.5701 18.9471 11.4149 18.7919C11.2597 18.6367 11.1725 18.4262 11.1725 18.2067V9.10326C11.1725 8.88377 11.2597 8.67327 11.4149 8.51807C11.5701 8.36287 11.7806 8.27568 12.0001 8.27568C12.2196 8.27568 12.4301 8.36287 12.5853 8.51807C12.7405 8.67327 12.8277 8.88377 12.8277 9.10326V18.2067C12.8277 18.4262 12.7405 18.6367 12.5853 18.7919C12.4301 18.9471 12.2196 19.0343 12.0001 19.0343ZM9.10272 19.0343C8.89626 19.0341 8.69733 18.9567 8.54498 18.8174C8.39262 18.6781 8.29785 18.4868 8.27927 18.2812L7.45169 9.17775C7.43191 8.95926 7.49969 8.74187 7.64014 8.57334C7.78058 8.40481 7.98219 8.29893 8.20065 8.27899C8.41926 8.25966 8.63663 8.32769 8.80521 8.4682C8.97379 8.60871 9.07987 8.81027 9.10024 9.02878L9.92783 18.1322C9.93818 18.2468 9.92453 18.3623 9.88776 18.4713C9.85099 18.5803 9.7919 18.6804 9.71426 18.7653C9.63662 18.8502 9.54214 18.9179 9.43684 18.9642C9.33155 19.0106 9.21775 19.0344 9.10272 19.0343ZM14.8975 19.0343C14.7824 19.0344 14.6687 19.0106 14.5634 18.9642C14.4581 18.9179 14.3636 18.8502 14.2859 18.7653C14.2083 18.6804 14.1492 18.5803 14.1124 18.4713C14.0757 18.3623 14.062 18.2468 14.0724 18.1322L14.9 9.02878C14.9422 8.57361 15.3394 8.24588 15.7995 8.27899C16.018 8.29893 16.2196 8.40481 16.3601 8.57334C16.5005 8.74187 16.5683 8.95926 16.5485 9.17775L15.7209 18.2812C15.7023 18.4868 15.6076 18.6781 15.4552 18.8174C15.3029 18.9567 15.1039 19.0341 14.8975 19.0343Z"
        fill="#808080"
      />
      <path
        d="M15.7241 6.62079H8.27583C8.05634 6.62079 7.84584 6.5336 7.69064 6.3784C7.53543 6.22319 7.44824 6.01269 7.44824 5.7932V2.48286C7.44824 2.26337 7.53543 2.05287 7.69064 1.89767C7.84584 1.74247 8.05634 1.65527 8.27583 1.65527H15.7241C15.9436 1.65527 16.1541 1.74247 16.3093 1.89767C16.4645 2.05287 16.5517 2.26337 16.5517 2.48286V5.7932C16.5517 6.01269 16.4645 6.22319 16.3093 6.3784C16.1541 6.5336 15.9436 6.62079 15.7241 6.62079ZM9.10341 4.96562H14.8965V3.31045H9.10341V4.96562Z"
        fill="#808080"
      />
    </g>
  </SvgIcon>
);
