import { Autocomplete, createFilterOptions, IconButton, StackProps, TextField } from '@mui/material';
import { useSharingEmails } from '../TransferProjectModal/hooks';
import { CloseIcon, Row } from 'shared/ui';
import { Nullable } from 'shared';

interface Props extends StackProps {
  id: string;
  open: boolean;
  email: string;
  error?: string;
  onEmailChange: (id: string, value: string) => void;
  showDelete: boolean;
  onDelete: (id: string) => void;
}
export const EmailAutocomplete: React.FC<Props> = ({
  id,
  email,
  open,
  error,
  onEmailChange,
  onDelete,
  showDelete,
  ...props
}) => {
  const { emails, isLoading } = useSharingEmails(open, '');

  const handleEmailChange = (_: any, value: Nullable<string>) => {
    onEmailChange(id, value || '');
  };

  return (
    <Row sx={{ mt: 1, gap: '10px' }} {...props}>
      <Autocomplete
        fullWidth
        onChange={handleEmailChange}
        value={email}
        options={emails ?? []}
        freeSolo
        autoSelect
        getOptionLabel={(item) => item}
        isOptionEqualToValue={(option, value) => option == value}
        filterOptions={createFilterOptions({ matchFrom: 'any' })}
        disabled={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Enter email"
            error={!!error}
            helperText={error}
            sx={{
              '& .MuiInputBase-root': {
                height: '44px',
                borderRadius: '8px',
                p: '3px 10px',
                fontSize: 16,
              },
            }}
          />
        )}
        noOptionsText={'No such email'}
      />
      {showDelete && (
        <IconButton sx={{ width: '5px', height: '5px' }} onClick={() => onDelete(id)}>
          <CloseIcon fill={'#f00'} width={5} height={5} />
        </IconButton>
      )}
    </Row>
  );
};
