import * as process from 'process';
import { axiosBase } from '../axios';
import { FarmAPIAuthResponse, FarmAPILocationDataResponse } from '../models';

export const farmApi = {
  async login() {
    return await axiosBase.post<FarmAPIAuthResponse>('/mapmycrop-api/auth/login', {
      email: process.env.REACT_APP_FARM_API_EMAIL,
      password: process.env.REACT_APP_FARM_API_PASSWORD,
    });
  },
  async getLocationData({ lat, lon }: { lat: number; lon: number }) {
    const options = {
      params: { lat, lon },
    };

    return await axiosBase.get<FarmAPILocationDataResponse>('/mapmycrop-api/farm/location', options);
  },
};
