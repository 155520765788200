import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, IconButton, InputAdornment, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

type Props = TextFieldProps & {
  label?: string;
  isPassword?: boolean;
  isCheckedIcon?: boolean;
};

const autoFillColor = {
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 30px #fff inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:hover': {
    WebkitBoxShadow: '0 0 0 30px #fff inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 30px #fff inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px #fff inset !important',
    WebkitTextFillColor: 'black !important',
  },
};

export const AuthInput: FC<Props> = ({ label, isPassword, isCheckedIcon, disabled, error, helperText, ...props }) => {
  const theme = useTheme();
  const [isShow, setIsShow] = React.useState(false);

  const handleShow = () => setIsShow(!isShow);

  return (
    <Box sx={{ width: '100%', ...props.sx }}>
      <Typography
        noWrap
        sx={{ color: 'secondary.dark', fontSize: 18, lineHeight: '23px', fontWeight: 400, margin: '16px 0 8px 0' }}
      >
        {label}
      </Typography>
      <TextField
        type={isPassword ? (isShow ? 'text' : 'password') : 'text'}
        error={error}
        helperText={error ? helperText : ''}
        disabled={disabled}
        sx={{
          width: '100%',
          height: '48px',
          '& .MuiInputBase-root': {
            borderRadius: '8px',
            border: '1px solid white',
            borderColor: disabled ? 'info.light' : error ? 'red' : '#D0D5DD',
            px: '10px',
            bgcolor: disabled ? 'info.light' : 'info.main',
          },
          '& .MuiFormHelperText-root': {
            lineHeight: 1,
          },
          '& .Mui-focused': {
            borderWidth: '1px !important',
          },
          '& .Mui-disabled': {
            WebkitTextFillColor: `${theme.palette.info.dark} !important`,
          },
          input: {
            ...autoFillColor,
            color: disabled ? 'info.dark' : 'black',
            fontSize: '16px',
            lineHeight: '20px',
            p: 0,
            height: '44px',
          },
          fieldset: {
            border: 'none !important',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isPassword && (
                <IconButton onClick={handleShow}>
                  {isShow ? (
                    <Visibility sx={{ color: 'secondary.dark', fontSize: '18px' }} />
                  ) : (
                    <VisibilityOff sx={{ color: 'secondary.dark', fontSize: '18px' }} />
                  )}
                </IconButton>
              )}
              {isCheckedIcon && <CheckCircleIcon sx={{ color: 'primary.main', fontSize: '22px' }} />}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Box>
  );
};
