import { LatLng } from 'leaflet';
import { Point } from '../models';
import { v4 as makeId } from 'uuid';
import {
  addNewLinePoint,
  addNewPolygonPoint,
  addSourcePoint,
  selectCurrentLinePointId,
  selectCurrentMapItem,
  selectCurrentPolygonPointId,
  selectIsLineDrawing,
  selectIsPointDrawing,
  selectIsPolygonDrawing,
  selectIsTrackingPointDrawing,
  selectLineId,
  selectNewPoint,
  selectPointId,
  setCurrentLinePointId,
  setCurrentPolygonPointId,
  setTrackingPoint,
  updatePolygonRowDirectionLine,
} from '../slices';
import { getNearestPointIndex } from '../lib';
import { NEW_POINT_ID, POLYGON_ROW_DIRECTION_ID } from '../constants';
import { useAppDispatch } from './useAppDispatch';

export const useMapActions = () => {
  const isLineDrawing = selectIsLineDrawing();
  const isPolygonDrawing = selectIsPolygonDrawing();
  const isPointDrawing = selectIsPointDrawing();
  const currentPolygonPointId = selectCurrentPolygonPointId();
  const isTrackingPointDrawing = selectIsTrackingPointDrawing();
  const currentLinePointId = selectCurrentLinePointId();
  const newPoint = selectNewPoint();
  const selectedPointId = selectPointId();

  const selectedLineId = selectLineId();

  const dispatch = useAppDispatch();
  const currentMapItem = selectCurrentMapItem();

  const createPoint = (coords: LatLng) => {
    if (isTrackingPointDrawing) {
      dispatch(setTrackingPoint(coords));
    }

    if (!currentMapItem || !coords) return;

    const point: Point = { id: makeId(), coords };

    if (isPolygonDrawing) {
      if (currentPolygonPointId) {
        // toggle select current point
        dispatch(setCurrentPolygonPointId(currentPolygonPointId));
        return;
      }

      const calcPoints = currentMapItem.geoJson.points;

      const index = getNearestPointIndex(point, calcPoints);
      dispatch(addNewPolygonPoint({ point, index }));
      return;
    }
    if (isLineDrawing) {
      if (currentLinePointId && !selectedLineId?.includes(POLYGON_ROW_DIRECTION_ID)) {
        // toggle select current point
        dispatch(setCurrentLinePointId(currentLinePointId));
        return;
      }

      if (selectedLineId?.includes(POLYGON_ROW_DIRECTION_ID)) {
        return;
      }

      dispatch(addNewLinePoint(point));
      return;
    }

    if (isPointDrawing && newPoint.geoJson.points.length === 0 && selectedPointId === NEW_POINT_ID) {
      dispatch(addSourcePoint(point));
      return;
    }
  };

  return { createPoint };
};
