import { FC, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, InputBase, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfirmModal, Icon, ImageInput, Row, Text } from '../../shared/ui';
import { useImageViewerTools } from './hooks/useImageViewerTools';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation } from 'react-router-dom';

const ControlButton = styled(Button)({
  height: '55px',
  width: '100%',
  maxWidth: '300px',
  borderRadius: 'none',
});

export const ImagesViewer: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    image,
    isEditing,
    isImagesView,
    isLoading,
    isDeleting,
    onChangeImage,
    onChangeTitle,
    onDeleteImg,
    showOtherImage,
    handleToggleDeleting,
    handleCloseView,
    toggleDeleting,
    toggleEditing,
    onDownLoadImage,
  } = useImageViewerTools();

  useEffect(() => {
    if (isImagesView) {
      handleCloseView();
    }
  }, [pathname]);

  return (
    <>
      {isImagesView && (
        <Stack
          sx={{
            position: 'absolute',
            top: '50px',
            left: 0,
            p: '7px',
            zIndex: 20,
            height: 'calc(100svh - 50px)',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Stack
            sx={{
              bgcolor: 'secondary.dark',
              border: '1px solid white',
              borderRadius: '5px',
              width: '100%',
              maxWidth: '850px',
              height: '100%',
            }}
          >
            <Row justifyContent='flex-end' sx={{ borderBottom: '1px solid white', m: '4px 6px' }}>
              <IconButton onClick={handleCloseView} sx={{ p: '5px', mb: '2px' }}>
                <CloseIcon color='info' sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Row>

            <Row m={2} mb={0}>
              <Text sx={{ color: 'info.main', mb: 0 }}>{t('title')}:</Text>
              <InputBase
                value={image.title}
                onChange={onChangeTitle}
                disabled={!isEditing}
                fullWidth
                sx={{
                  mx: 2,
                  color: 'white',
                  borderBottom: '1px solid white',
                  borderColor: isEditing ? 'info.main' : 'secondary.dark',
                  '& .Mui-disabled': {
                    WebkitTextFillColor: 'rgba(255, 255, 255, 1) !important',
                  },
                }}
              />

              <IconButton onClick={toggleEditing} sx={{ p: '5px', mb: '2px ' }}>
                <Icon path='mapIcons/pencil' />
              </IconButton>
            </Row>

            <Stack
              sx={{
                m: 2,
                height: 'calc(100svh - 58px - 14px - 32px - 32px - 57px)',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <ImageInput
                image={image?.url as string}
                onChange={onChangeImage}
                loading={isLoading}
                disabled={image?.isSnapShot}
              />
            </Stack>

            <Row justifyContent='space-between' sx={{ borderTop: '1px solid white', m: '4px 6px' }}>
              <ControlButton onClick={() => showOtherImage('prev')}>
                <Icon path='mapIcons/left_arrow' />
              </ControlButton>
              {image.id && !image?.isSnapShot && (
                <ControlButton onClick={handleToggleDeleting}>
                  <Icon path='mapIcons/trash' />
                </ControlButton>
              )}
              <ControlButton onClick={onDownLoadImage}>
                <DownloadIcon sx={{ color: 'white', height: '22px' }} />
              </ControlButton>
              <ControlButton onClick={() => showOtherImage('prev')}>
                <Icon path='mapIcons/right_arrow' />
              </ControlButton>
            </Row>
          </Stack>
        </Stack>
      )}

      <ConfirmModal
        title='Delete Modal'
        open={isDeleting}
        onClose={toggleDeleting}
        onConfirm={onDeleteImg}
        maxWidth='xs'
        white
      >
        <Typography sx={{ color: '#242731', fontSize: 18, textAlign: 'center' }}>
          {t('are_you_sure_you_want_to_delete_this_image')}
        </Typography>
        <Typography sx={{ color: '#242731', fontSize: 18, textAlign: 'center' }}>
          {t('this_action_cannot_be_undone')}
        </Typography>
      </ConfirmModal>
    </>
  );
};
