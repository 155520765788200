import { Box, TextField, TextFieldProps, Tooltip, Typography, useTheme } from '@mui/material';
import { forwardRef } from 'react';

type Props = TextFieldProps & {
  label?: string;
  isResultBox?: boolean;
  isNotifyBox?: boolean;
  isErrorBox?: boolean;
  errorText?: string;
  isResetInlet?: boolean;
  onResetClick?: () => void;
  labelInput?: JSX.Element;
  additionalInput?: JSX.Element;
  tooltip?: string;
};

export const HTInput = forwardRef<unknown, Props>(
  (
    {
      label,
      isResetInlet,
      isResultBox,
      isErrorBox,
      isNotifyBox,
      labelInput,
      additionalInput,
      errorText,
      tooltip,
      ...props
    },
    ref
  ) => {
    const { palette } = useTheme();

    return (
      <Box position="relative">
        {label && (
          <Box display="flex" alignItems="flex-end" sx={{ color: 'black' }}>
            {labelInput}
            <Tooltip title={tooltip ?? ''} placement="top">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography
                  noWrap
                  sx={{
                    ...(labelInput && { lineHeight: '11px' }),
                    fontSize: '11px',
                    // textTransform: 'uppercase',
                    color: palette.secondary.dark,
                    marginBottom: '3px',
                  }}
                >
                  {label}
                </Typography>
                {errorText && isErrorBox && (
                  <Typography
                    noWrap
                    sx={{
                      ...(labelInput && { lineHeight: '11px' }),
                      fontSize: '11px',
                      color: palette.error.main,
                      marginBottom: '3px',
                    }}
                  >
                    {errorText}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
        <TextField
          fullWidth
          type="number"
          sx={{
            background: palette.info.main,
            borderRadius: '9px',
            ...(isNotifyBox && {
              background: '#ECEFF1',
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
              },
            }),
            ...(isResultBox && {
              background: '#ECEFF1',
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
              },
              '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: '#000000 !important' } },
            }),
            ...(isErrorBox && {
              background: palette.error.light,
              '& .MuiOutlinedInput-root': { '& > fieldset': { border: `1px solid ${palette.error.main}` } },
            }),
          }}
          inputProps={{
            min: 0,
            step: 1,
            ...(isResultBox && { style: { WebkitTextFillColor: '#000000' } }),
            ...(isErrorBox && { style: { WebkitTextFillColor: palette.error.main } }),
          }}
          InputProps={{
            sx: {
              height: '34px',
              borderRadius: '9px',
              borderColor: palette.error.main,
              ...(isResetInlet && { pr: '0px' }),
            },
            ...(isResultBox && {
              readOnly: true,
            }),
          }}
          inputRef={ref}
          color="primary"
          {...props}
          placeholder={props.placeholder ?? '_'}
        />
        {additionalInput}
      </Box>
    );
  }
);
