import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogItem, EmittersBody, EmittersResponse, Group, GroupsBody, GroupsResponse } from '../../models';
import { useAppSelector } from '../../hooks';
import { axiosInstance } from 'shared/axios';
import { isOnline } from 'shared/lib';

interface UserState {
  emittersLoading: boolean;
  emitterGroupsLoading: boolean;
  emitters: CatalogItem[];
  emittersLoadedOnce: boolean;
  emitterGroupsLoadedOnce: boolean;
  emitterGroups: Group[];
}

const initialState: UserState = {
  emittersLoading: true,
  emitterGroupsLoading: true,
  emitters: [],
  emitterGroupsLoadedOnce: false,
  emittersLoadedOnce: false,
  emitterGroups: [],
};

export const getEmittersThunk = createAsyncThunk('getEmitters', async (params: EmittersBody, thunkAPI) => {
  if (!isOnline()) {
    return;
  }

  const options = { params };

  try {
    const response = await axiosInstance.get<EmittersResponse>(
      '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogs',
      options,
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getEmitterGroupsThunk = createAsyncThunk(
  'groupTypes/getEmitterGroupTypes',
  async (params: GroupsBody, thunkAPI) => {
    if (!isOnline()) {
      return;
    }

    const options = { params };

    try {
      const response = await axiosInstance.get<GroupsResponse>(
        '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        options,
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const emittersSlice = createSlice({
  name: 'emitters',
  initialState,
  reducers: {
    resetEmitters: () => initialState,
  },
  selectors: {
    selectEmitters: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmittersThunk.pending.type, (state) => {
        state.emittersLoading = true;
      })
      .addCase(getEmittersThunk.fulfilled.type, (state, action: PayloadAction<EmittersResponse>) => {
        state.emitters = action.payload.GetCatalogsResult.RootResults;
        state.emittersLoading = false;
        if (!state.emittersLoadedOnce) state.emittersLoadedOnce = true;
      })
      .addCase(getEmittersThunk.rejected.type, (state) => {
        state.emittersLoading = false;
      })
      .addCase(getEmitterGroupsThunk.pending.type, (state) => {
        state.emitterGroupsLoading = true;
      })
      .addCase(getEmitterGroupsThunk.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        if (action.payload) {
          state.emitterGroups = action.payload.GetGroupTypesResult.RootResults;
          state.emitterGroupsLoading = false;
        }
        if (!state.emitterGroupsLoadedOnce) state.emitterGroupsLoadedOnce = true;
      })
      .addCase(getEmitterGroupsThunk.rejected.type, (state) => {
        state.emitterGroupsLoading = false;
      });
  },
});

export const { resetEmitters } = emittersSlice.actions;

export const selectEmitters = () => useAppSelector(emittersSlice.selectors.selectEmitters);

export const emittersReducer = emittersSlice.reducer;
