import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';

export type MenuItem = {
  title: string;
  icon: React.FC<SvgIconProps<'svg', object>>;
  callback: () => void;
};

type Props = {
  menuItems: MenuItem[];
};

export const ProjectActionsItems: React.FC<Props> = ({ menuItems }) => {
  const { t } = useTranslation();

  return (
    <TransitionGroup>
      {menuItems.map((item, i) => {
        const Icon = item.icon;
        return (
          <Collapse key={item.title}>
            <ListItem
              disablePadding
              sx={{
                pt: 0,
                px: '12px',
                '&:hover': { backgroundColor: 'rgba(29, 135, 69, 0.16)' },
              }}
            >
              <ListItemButton
                disableRipple
                disableGutters
                onClick={() => {
                  item.callback();
                  // onClose();
                }}
                sx={{
                  ...(i == 0 && { borderTop: '1px solid grey' }),
                  borderBottom: '1px solid grey',
                  borderColor: 'info.light',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <ListItemIcon sx={{ minWidth: '45px' }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        color: '#242731',
                        fontSize: '16px',
                        lineHeight: '130%',
                      }}
                    >
                      {t(item.title)}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Collapse>
        );
      })}
    </TransitionGroup>
  );
};
