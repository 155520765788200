import { BoxProps, Drawer } from '@mui/material';
import { FC } from 'react';

interface Props extends BoxProps {
  open: boolean;
  onClose?: () => void;
}

export const ModeDrawer: FC<Props> = ({ open, onClose, children }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          py: '12px',
          width: '100%',
          boxShadow: 'none',
          background: 'url("/images/mapIcons/chooseBg.png") center/cover no-repeat',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      }}
    >
      {children}
    </Drawer>
  );
};
