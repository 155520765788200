import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LINE, NO_CONNECTION_MESSAGE, POINT, POLYGON, POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { SavingStep } from 'shared/enums';
import { useAppDispatch } from 'shared/hooks';
import { isOnline } from 'shared/lib';
import {
  cancelGpsTracking,
  cancelLineDrawing,
  cancelPointDrawing,
  cancelPolygonDrawing,
  editItem,
  resetDrawMode,
  saveItem,
  selectCurrentItemId,
  selectCurrentItemInformation,
  selectCurrentMapItem,
  selectIsColorPicker,
  selectIsImagesView,
  selectIsItemDrawing,
  selectIsItemEditing,
  selectIsItemInfo,
  selectLineId,
  selectPointId,
  selectPolygonId,
  selectPolygonInformation,
  selectSaveProjectStep,
  selectSelectedItem,
  selectTrackingPoint,
  setDrawMode,
  setIsItemInfo,
  setIsRowDirectionForm,
  setSaveProjectStep,
  setTrackingPoint,
  stopItemDrawing,
  toggleColorPicker,
  toggleImagesView,
  unselectItems,
} from 'shared/slices';

const itemValidationConfig = {
  [POLYGON]: { pointsLength: 2, message: 'Need more points!' },
  [LINE]: { pointsLength: 1, message: 'Need more points!' },
  [POINT]: { pointsLength: 0, message: 'Tap the point!' },
};

export const useMapItemActions = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const currentItemType = selectSelectedItem();
  const currentMapItem = selectCurrentMapItem();
  const currentMapItemInformation = selectCurrentItemInformation();
  const currentMapItemId = selectCurrentItemId();

  const selectedPolygonId = selectPolygonId();

  const selectedLineId = selectLineId();

  const selectedPointId = selectPointId();

  const isColorPicker = selectIsColorPicker();
  const trackingPoint = selectTrackingPoint();
  const saveProjectStep = selectSaveProjectStep();

  const isItemDrawing = selectIsItemDrawing();
  const isSelectedItem = currentMapItemId || '';
  const isItemShowedInfo = selectIsItemInfo();
  const isItemEditing = selectIsItemEditing();
  const isImagesView = selectIsImagesView();

  const polygonInfo = selectPolygonInformation();

  const itemName = useMemo(() => {
    return currentMapItem?.information.name;
  }, [isSelectedItem]);

  const isLoading = useMemo(() => {
    if (isItemEditing) {
      return saveProjectStep !== SavingStep.DONE;
    }
    return saveProjectStep === SavingStep.SAVING;
  }, [saveProjectStep]);

  const onRemoveTrackingPoint = () => dispatch(setTrackingPoint(null));
  const onToggleImageView = () => dispatch(toggleImagesView());

  const checkIsInValidGeoJson = () => {
    if (currentMapItem && currentItemType) {
      if (currentMapItem.geoJson.points.length > itemValidationConfig[currentItemType].pointsLength) {
        return false;
      } else {
        toast.error(t('need_more_points'));
        return true;
      }
    }
  };

  const onCancelDrawing = () => {
    if (selectedPolygonId) {
      dispatch(cancelPolygonDrawing());
    }
    if (selectedLineId) {
      dispatch(cancelLineDrawing());
    }
    if (selectedPointId) {
      dispatch(cancelPointDrawing());
    }

    isColorPicker && dispatch(toggleColorPicker());
    dispatch(cancelGpsTracking());
    dispatch(setDrawMode('none'));
    onStopDrawing();
    onCloseInfo();
  };
  const onStopDrawing = () => {
    dispatch(stopItemDrawing());
  };
  const onStartEdit = () => {
    if (currentMapItemId?.includes(POLYGON_ROW_DIRECTION_ID)) dispatch(setIsRowDirectionForm(true));
    dispatch(editItem());
  };
  const onCloseInfo = () => {
    dispatch(setIsItemInfo(false));

    isImagesView && onToggleImageView();

    switch (saveProjectStep) {
      case SavingStep.SCREEN_SHOOTING_DONE:
        dispatch(setSaveProjectStep(SavingStep.DONE));
        break;
      case SavingStep.SCREEN_SHOOTING:
        dispatch(setSaveProjectStep(SavingStep.CANCELED));
        break;
    }
  };
  const unselectItem = () => {
    dispatch(unselectItems());
    onCloseInfo();
  };

  const onOpenItemInfo = () => {
    isColorPicker && dispatch(toggleColorPicker());
    if (checkIsInValidGeoJson()) return;

    if (!isOnline() && (currentItemType === 'polygon' || !(isItemEditing || isItemShowedInfo))) {
      toast.error(NO_CONNECTION_MESSAGE);
    } else {
      dispatch(setIsItemInfo(true));
      // if (isItemDrawing && !isItemEditing) {
      //   dispatch(setSaveProjectStep(SavingStep.SCREEN_SHOOTING));
      // }
    }
  };

  const onSaveItem = async () => {
    if (!isOnline()) {
      toast.error(NO_CONNECTION_MESSAGE);
      onCloseInfo();
    } else {
      isColorPicker && dispatch(toggleColorPicker());
      if (checkIsInValidGeoJson()) return;

      if (isItemShowedInfo && currentMapItemId) {
        if (currentMapItemId.includes(POLYGON_ROW_DIRECTION_ID)) {
          // if row direction line is selected, it's polygon is edited,
          // so polygon should be saved instead of line
          const polygonId = currentMapItemId.replace(POLYGON_ROW_DIRECTION_ID, '').trim();
          dispatch(
            saveItem({
              itemId: polygonId,
              information: polygonInfo,
              itemType: POLYGON,
            })
          );
        } else {
          dispatch(
            saveItem({
              itemId: currentMapItemId,
              information: currentMapItemInformation,
              itemType: currentItemType,
            })
          );
        }
      }

      dispatch(resetDrawMode());

      {
        /* Disable screenshooting temporary */
      }
      dispatch(setSaveProjectStep(SavingStep.SAVING));

      {
        /* Disable screenshooting temporary */
      }
      // if (isItemDrawing && isItemEditing) {
      //   dispatch(setSaveProjectStep(SavingStep.SCREEN_SHOOTING));
      // } else {
      //   dispatch(setSaveProjectStep(SavingStep.SAVING));
      // }
    }
  };

  return {
    itemName,
    isLoading,
    isDrawing: isItemDrawing,
    isItemShowedInfo,
    isSelectedItem,
    isItemEditing,
    isImagesView,
    onOpenItemInfo,
    onSaveItem,
    onCancelDrawing,
    onStartEdit,
    unselectItem,
    onCloseInfo,
    trackingPoint,
    onRemoveTrackingPoint,
    currentItemType,
  };
};
