import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NO_CONNECTION_MESSAGE } from 'shared/constants';
import { isOnline } from 'shared/lib';
import { ProtectedRoute } from '../features/ProtectedRoute';
import { PrefetchData, ProjectHubProvider } from '../processes';
import { useAppDispatch } from '../shared/hooks';
import { localStorageService } from '../shared/services';
import {
  cancelLineDrawing,
  cancelPointDrawing,
  cancelPolygonDrawing,
  selectAuthToken,
  selectIsColorPicker,
  selectIsLineDrawing,
  selectIsPointDrawing,
  selectIsPolygonDrawing,
  setIsLineInfo,
  setIsPointInfo,
  setIsPolygonInfo,
  setUnitsValue,
  toggleColorPicker,
  unselectItems,
} from '../shared/slices';
import { ForgotPasswordPage } from './ForgotPassword';
import { HomePage } from './Home';
import { LoginPage } from './Login';
import { NewProjectPage } from './NewProject';
import { ProjectManagementPage } from './ProjectManagement';
import { RequestAccessPage } from './RequestAccess';
import { Box, CircularProgress } from '@mui/material';

const commonRoutes = [
  { path: '/common/login', element: <LoginPage /> },
  { path: '/common/forgot-password', element: <ForgotPasswordPage /> },
  { path: '/common/request-access', element: <RequestAccessPage /> },
  { path: '/*', element: <LoginPage />, replace: true },
];

const privateRoutes = [
  {
    path: '/main/home/:projectId?',
    element: (
      <PrefetchData>
        <ProjectHubProvider>
          <HomePage />
        </ProjectHubProvider>
      </PrefetchData>
    ),
  },
  { path: '/main/new-project', element: <NewProjectPage /> },
  {
    path: '/main/project-management',
    element: (
      <PrefetchData>
        <ProjectManagementPage />
      </PrefetchData>
    ),
  },
];

export const Routing = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const stateToken = selectAuthToken();
  const token = localStorageService.rapidToken;

  const isPolygonDrawing = selectIsPolygonDrawing();
  const isLineDrawing = selectIsLineDrawing();
  const isPointDrawing = selectIsPointDrawing();
  const isColorPicker = selectIsColorPicker();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isConnection, setIsConnection] = useState(isOnline());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsConnection(isOnline());
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (stateToken || token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [stateToken, token]);

  useEffect(() => {
    if (isAuthenticated !== null) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isConnection) {
      toast.error(NO_CONNECTION_MESSAGE);
    }
  }, [isConnection]);

  useEffect(() => {
    isPolygonDrawing && dispatch(cancelPolygonDrawing());
    isLineDrawing && dispatch(cancelLineDrawing());
    isPointDrawing && dispatch(cancelPointDrawing());

    dispatch(unselectItems());

    dispatch(setIsPolygonInfo(false));
    dispatch(setIsLineInfo(false));
    dispatch(setIsPointInfo(false));

    isColorPicker && dispatch(toggleColorPicker());
  }, [pathname]);

  useEffect(() => {
    // isAuthenticated && navigateToProjectManagement();

    const units = localStorageService.units;
    units && dispatch(setUnitsValue({ units }));
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          height: '100svh',
          bgcolor: 'primary.main',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color={'info'} />
      </Box>
    );
  }

  return (
    <Routes>
      {commonRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.replace ? (
              <Navigate to={isAuthenticated ? '/main/project-management' : '/common/login'} />
            ) : (
              route.element
            )
          }
        />
      ))}

      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        {privateRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
};
