import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ShareIcon: React.FC<SvgIconProps & { green?: boolean }> = ({ green, ...props }) => (
  <SvgIcon {...props}>
    {green ? (
      <>
        <g clipPath="url(#clip0_3005_378)">
          <path
            d="M16.7999 8.8C18.1254 8.8 19.1999 7.72548 19.1999 6.4C19.1999 5.07452 18.1254 4 16.7999 4C15.4744 4 14.3999 5.07452 14.3999 6.4C14.3999 7.72548 15.4744 8.8 16.7999 8.8Z"
            fill="#1D8742"
            stroke="#1D8742"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.7999 20.0002C18.1254 20.0002 19.1999 18.9257 19.1999 17.6002C19.1999 16.2747 18.1254 15.2002 16.7999 15.2002C15.4744 15.2002 14.3999 16.2747 14.3999 17.6002C14.3999 18.9257 15.4744 20.0002 16.7999 20.0002Z"
            fill="#1D8742"
            stroke="#1D8742"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.80005 11.2L14.4 8M8.80005 12.8L14.4 16"
            stroke="#1D8742"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.4 14.4001C7.72548 14.4001 8.8 13.3256 8.8 12.0001C8.8 10.6746 7.72548 9.6001 6.4 9.6001C5.07452 9.6001 4 10.6746 4 12.0001C4 13.3256 5.07452 14.4001 6.4 14.4001Z"
            fill="#1D8742"
            stroke="#1D8742"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3005_378">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </>
    ) : (
      <>
        <path
          d="M16.8004 8.8C18.1259 8.8 19.2004 7.72548 19.2004 6.4C19.2004 5.07452 18.1259 4 16.8004 4C15.4749 4 14.4004 5.07452 14.4004 6.4C14.4004 7.72548 15.4749 8.8 16.8004 8.8Z"
          stroke="#8C8D92"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M16.8004 20C18.1259 20 19.2004 18.9254 19.2004 17.6C19.2004 16.2745 18.1259 15.2 16.8004 15.2C15.4749 15.2 14.4004 16.2745 14.4004 17.6C14.4004 18.9254 15.4749 20 16.8004 20Z"
          stroke="#8C8D92"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M8.7998 11.2L14.3998 8M8.7998 12.8L14.3998 16"
          stroke="#8C8D92"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M6.4 14.4001C7.72548 14.4001 8.8 13.3256 8.8 12.0001C8.8 10.6746 7.72548 9.6001 6.4 9.6001C5.07452 9.6001 4 10.6746 4 12.0001C4 13.3256 5.07452 14.4001 6.4 14.4001Z"
          stroke="#8C8D92"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </>
    )}
  </SvgIcon>
);
