import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { SelectChangeEvent } from '@mui/material';
import { CatalogItem } from '../models';
import {
  getLateralGroupsThunk,
  getLateralsThunk,
  selectFormState,
  selectLaterals,
  selectMasterGr,
  setPolygonInformation,
} from '../slices';

const ALL = 'All';

export const useLateralFilters = ({ isIntegrated }: { isIntegrated: boolean }) => {
  const dispatch = useAppDispatch();
  const { masterGroups } = selectMasterGr();
  const { polygonInformation } = selectFormState();

  useEffect(() => {
    if (!polygonInformation.isSelectProduct) return;
    const getDefaults = async () => {
      resetFilters();

      try {
        const groups = await dispatch(getLateralGroupsThunk({ masterGroup: polygonInformation.lateralType })).unwrap();

        if (!groups) return;

        dispatch(setPolygonInformation({ lateralGroup: polygonInformation.lateralGroup }));

        const laterals = await dispatch(
          getLateralsThunk({
            group: polygonInformation.lateralGroup,
            region: region,
            standard: standard,
            limited: limited,
          })
        ).unwrap();

        if (!laterals) return;

        dispatch(setPolygonInformation({ lateral: polygonInformation.lateral }));
      } catch (e) {
        console.log(e);
      }
    };

    getDefaults();
  }, [polygonInformation.isSelectProduct]);

  const filteredMasterGroups = useMemo(
    () =>
      masterGroups
        .filter((item) => item.LATERAL)
        .filter((item) =>
          isIntegrated ? item.MASTERGROUPNAME.includes('Drip') : !item.MASTERGROUPNAME.includes('Drip')
        ),
    [masterGroups, isIntegrated]
  );

  const { laterals, lateralGroups } = selectLaterals();

  const [region] = useState('Europe');
  const [standard] = useState(true);
  const [limited] = useState(true);

  const updateLaterals = async (group: string) => {
    const laterals = await dispatch(
      getLateralsThunk({
        group,
        region: region,
        standard: standard,
        limited: limited,
      })
    ).unwrap();

    if (!laterals) return;

    dispatch(setPolygonInformation({ lateral: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0] }));
  };
  const onMasterGroupChange = async (value: number) => {
    try {
      const lateralTypeText = masterGroups.find((m) => m.ID === value)?.MASTERGROUPNAME ?? '';

      dispatch(setPolygonInformation({ lateralType: value, lateralTypeText }));

      resetFilters();

      const groups = await dispatch(getLateralGroupsThunk({ masterGroup: value })).unwrap();

      if (!groups) return;

      dispatch(
        setPolygonInformation({
          lateralGroup: groups.GetGroupTypesResult.RootResults[0].GROUPS,
          polygonCopySettingsFromId: null,
        })
      );

      await updateLaterals(groups.GetGroupTypesResult.RootResults[0].GROUPS);
    } catch (e) {
      console.log(e);
    }
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setPolygonInformation({ lateralGroup: e.target.value as string, polygonCopySettingsFromId: null }));

    resetFilters();

    await updateLaterals(e.target.value as string);
  };

  const onLateralChange = (_: any, item: CatalogItem) => {
    dispatch(setPolygonInformation({ lateral: item }));
  };

  const onDiameterChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        lateralDiameter: e.target.value === ALL ? e.target.value : +(e.target.value as string),
        polygonCopySettingsFromId: null,
      })
    );
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        lateralClassType: e.target.value === ALL ? e.target.value : +(e.target.value as string),
        polygonCopySettingsFromId: null,
      })
    );
  };

  const onFlowPerUnitChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        lateralFlowPer: e.target.value === ALL ? e.target.value : +(e.target.value as string),
        polygonCopySettingsFromId: null,
      })
    );
  };

  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        lateralNominalFlow: e.target.value === ALL ? e.target.value : +(e.target.value as string),
        polygonCopySettingsFromId: null,
      })
    );
  };

  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setPolygonInformation({
        lateralSpacing: e.target.value === ALL ? e.target.value : +(e.target.value as string),
        polygonCopySettingsFromId: null,
      })
    );
  };

  const diameterFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.AltInlet !== null) {
      return polygonInformation.lateralDiameter !== ALL ? item.AltInlet === polygonInformation.lateralDiameter : true;
    }
    return polygonInformation.lateralDiameter !== ALL ? item.INLET === polygonInformation.lateralDiameter : true;
  };
  const classTypeFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.AltClass !== null) {
      return polygonInformation.lateralClassType !== ALL
        ? item.AltClass?.toString().trim() === polygonInformation.lateralClassType
        : true;
    }

    return polygonInformation.lateralClassType !== ALL
      ? item.Class.trim() === polygonInformation.lateralClassType.toString()
      : true;
  };
  const flowPerUnitFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.Q_gpm_100ft_ !== null) {
      return polygonInformation.lateralFlowPer !== ALL ? item.Q_gpm_100ft_ === polygonInformation.lateralFlowPer : true;
    }

    return polygonInformation.lateralFlowPer !== ALL ? item.Q_l_hr_100m_ === polygonInformation.lateralFlowPer : true;
  };
  const nominalFlowFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.AltQnom !== null) {
      return polygonInformation.lateralNominalFlow !== ALL
        ? item.AltQnom === polygonInformation.lateralNominalFlow
        : true;
    }
    return polygonInformation.lateralNominalFlow !== ALL ? item.Qnom === polygonInformation.lateralNominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.AltSpacing !== null) {
      return polygonInformation.lateralSpacing !== ALL ? item.AltSpacing === polygonInformation.lateralSpacing : true;
    }
    return polygonInformation.lateralSpacing !== ALL ? item.Spacing === polygonInformation.lateralSpacing : true;
  };

  const resetFilters = () => {
    dispatch(
      setPolygonInformation({
        lateralFlowPer: ALL,
        lateralClassType: ALL,
        lateralNominalFlow: ALL,
        lateralSpacing: ALL,
        lateralDiameter: ALL,
      })
    );
  };

  const lateralsFiltered = useMemo(() => {
    return laterals.filter(
      (item) =>
        diameterFilter(item) &&
        classTypeFilter(item) &&
        flowPerUnitFilter(item) &&
        nominalFlowFilter(item) &&
        spacingFilter(item)
    );
  }, [
    polygonInformation.lateralDiameter,
    polygonInformation.lateralClassType,
    polygonInformation.lateralNominalFlow,
    polygonInformation.lateralSpacing,
    polygonInformation.lateralFlowPer,
    laterals,
  ]);

  useEffect(() => {
    if (
      lateralsFiltered.length &&
      !lateralsFiltered.find((item) => item.CATLOG === polygonInformation.lateral?.CATLOG)
    ) {
      onLateralChange(null, lateralsFiltered[0]);
    }
  }, [lateralsFiltered]);

  const diameters = useMemo(
    () => [
      ALL,
      ...new Set(
        laterals
          .map((item) => (region === 'USA' && item.AltInlet !== null ? item.AltInlet ?? '' : item.INLET ?? ''))
          .sort((a, b) => +a - +b)
      ),
    ],
    [laterals]
  );

  const classTypes = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item))
          .map((item) =>
            region === 'USA' && item.AltClass !== null ? item.AltClass?.toString().trim() : Number(item.Class.trim())
          )
          .sort((a, b) => +a - +b)
      ),
    ];
  }, [polygonInformation.lateralDiameter, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === polygonInformation.lateral?.CATLOG)) {
      dispatch(
        setPolygonInformation({
          lateralFlowPer: ALL,
          lateralClassType: ALL,
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        })
      );
    }
  }, [classTypes]);

  const flowPerUnits = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item))
          .map((item) => (region === 'USA' && item.Q_gpm_100ft_ !== null ? item.Q_gpm_100ft_ ?? 0 : item.Q_l_hr_100m_))
          .sort((a, b) => a - b)
      ),
    ];
  }, [polygonInformation.lateralDiameter, polygonInformation.lateralClassType, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === polygonInformation.lateral?.CATLOG)) {
      dispatch(setPolygonInformation({ lateralFlowPer: ALL, lateralNominalFlow: ALL, lateralSpacing: ALL }));
    }
  }, [flowPerUnits]);

  const nominalFlows = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item) && flowPerUnitFilter(item))
          .map((item) => (region === 'USA' && item.AltQnom !== null ? item.AltQnom ?? 0 : item.Qnom))
          .sort((a, b) => a - b)
      ),
    ];
  }, [
    polygonInformation.lateralDiameter,
    polygonInformation.lateralClassType,
    polygonInformation.lateralFlowPer,
    laterals,
  ]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === polygonInformation.lateral?.CATLOG)) {
      dispatch(setPolygonInformation({ lateralNominalFlow: ALL, lateralSpacing: ALL }));
    }
  }, [nominalFlows]);

  const spacings = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter(
            (item) =>
              diameterFilter(item) && classTypeFilter(item) && nominalFlowFilter(item) && flowPerUnitFilter(item)
          )
          .map((item) => (region === 'USA' && item.AltQnom !== null ? item.AltSpacing ?? 0 : item.Spacing))
          .sort((a, b) => a - b)
      ),
    ];
  }, [
    polygonInformation.lateralDiameter,
    polygonInformation.lateralClassType,
    polygonInformation.lateralNominalFlow,
    polygonInformation.lateralFlowPer,
    laterals,
  ]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === polygonInformation.lateral?.CATLOG)) {
      dispatch(setPolygonInformation({ lateralSpacing: ALL }));
    }
  }, [spacings]);

  const lateralSubtype = useMemo(
    () =>
      lateralGroups.find((item) => item.GROUPS === polygonInformation.lateralGroup)
        ? polygonInformation.lateralGroup
        : '',
    [polygonInformation.lateralGroup, lateralGroups]
  );

  const lateralProduct = useMemo(() => {
    if (lateralsFiltered.length) {
      return lateralsFiltered.find((item) => item.CATLOG === polygonInformation.lateral?.CATLOG) ?? lateralsFiltered[0];
    }
    return null;
  }, [polygonInformation.lateral?.CATLOG, lateralsFiltered]);

  const diameterValue = useMemo(
    () => diameters.find((item) => item === polygonInformation.lateralDiameter) ?? ALL,
    [polygonInformation.lateralDiameter, diameters]
  );

  const classTypeValue = useMemo(
    () => classTypes.find((item) => item === polygonInformation.lateralClassType) ?? ALL,
    [polygonInformation.lateralClassType, classTypes]
  );

  const lateralFlowPerValue = useMemo(
    () => flowPerUnits.find((item) => item === polygonInformation.lateralFlowPer) ?? ALL,
    [polygonInformation.lateralFlowPer, flowPerUnits]
  );

  const nominalFlowValue = useMemo(
    () => nominalFlows.find((item) => item === polygonInformation.lateralNominalFlow) ?? ALL,
    [polygonInformation.lateralNominalFlow, nominalFlows]
  );

  const spacingValue = useMemo(
    () => spacings.find((item) => item === polygonInformation.lateralSpacing) ?? ALL,
    [polygonInformation.lateralSpacing, spacings]
  );

  const getLateralMasterGroup = () => {
    return (
      filteredMasterGroups.find((item) => item.ID === polygonInformation.lateralType)?.ID ?? filteredMasterGroups[0].ID
    );
  };

  const lateralMasterGroup = getLateralMasterGroup();

  useEffect(() => {
    if (!filteredMasterGroups.find((item) => item.ID === polygonInformation.lateralType))
      onMasterGroupChange(lateralMasterGroup);
  }, [lateralMasterGroup]);

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: lateralMasterGroup,
    onMasterGroupChange,
    lateralSubtype,
    onGroupTypeChange,
    diameter: diameterValue,
    onDiameterChange,
    classType: classTypeValue,
    onClassTypeChange,
    flowPerUnit: lateralFlowPerValue,
    onFlowPerUnitChange,
    nominalFlow: nominalFlowValue,
    onNominalFlowChange,
    spacing: spacingValue,
    onSpacingChange,
    diameters,
    classTypes,
    flowPerUnits,
    nominalFlows,
    spacings,
    lateralProduct,
    lateralsFiltered,
    lateral: polygonInformation.lateral,
    onLateralChange,
  };
};
