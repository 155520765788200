import { Button as MuiButton, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { forwardRef } from 'react';

interface Props extends ButtonProps {
  loading?: boolean;
  btnColor?: string;
  outlined?: boolean;
  borderColor?: string;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ loading, outlined, disabled, btnColor, borderColor, sx, ...props }, ref) => {
    return (
      <MuiButton
        ref={ref}
        disabled={disabled || loading}
        disableFocusRipple
        sx={{
          textTransform: 'capitalize',
          borderRadius: '16px',
          // height: '100%',
          // width: '100%',
          px: '24px',
          color: outlined ? btnColor : 'info.main',
          fontSize: 18,
          borderStyle: `solid`,
          borderWidth: `1px`,
          borderColor: borderColor ?? btnColor,
          bgcolor: outlined ? 'info.main' : btnColor,
          transition: 'all 0.3s',
          '&:hover': {
            bgcolor: outlined ? 'info.main' : btnColor,
          },
          '&:disabled': {
            opacity: loading ? 1 : 0.5,
          },
          ...sx,
        }}
        {...props}
      >
        {loading ? <CircularProgress size={25} sx={{ color: 'info.main' }} /> : props.children}
      </MuiButton>
    );
  }
);
