import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogItem, CatalogsResponse, Group, GroupsBody, GroupsResponse, LateralsBody } from '../../models';
import { useAppSelector } from '../../hooks';
import { axiosInstance } from 'shared/axios';
import { isOnline } from 'shared/lib';

interface UserState {
  lateralsLoading: boolean;
  lateralGroupsLoading: boolean;
  laterals: CatalogItem[];
  lateralsLoadedOnce: boolean;
  lateralGroupsLoadedOnce: boolean;
  lateralGroups: Group[];
}

const initialState: UserState = {
  lateralsLoading: true,
  lateralsLoadedOnce: false,
  laterals: [],
  lateralGroupsLoadedOnce: false,
  lateralGroupsLoading: true,
  lateralGroups: [],
};

export const getLateralsThunk = createAsyncThunk('getLaterals', async (params: LateralsBody, thunkAPI) => {
  if (!isOnline()) {
    return;
  }

  try {
    const options = { params };

    const response = await axiosInstance.get<CatalogsResponse>(
      '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogPerDiaClassFlowSpacing',
      options,
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getLateralGroupsThunk = createAsyncThunk(
  'groupTypes/getLateralGroupTypes',
  async (params: GroupsBody, thunkAPI) => {
    if (!isOnline()) {
      return;
    }

    try {
      const options = { params };

      const response = await axiosInstance.get<GroupsResponse>(
        '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        options,
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const lateralsSlice = createSlice({
  name: 'laterals',
  initialState,
  reducers: {
    resetLaterals: () => initialState,
  },
  selectors: {
    selectLaterals: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLateralsThunk.pending.type, (state) => {
        state.lateralsLoading = true;
      })
      .addCase(getLateralsThunk.fulfilled.type, (state, action: PayloadAction<CatalogsResponse>) => {
        state.laterals = action.payload.GetCatalogPerDiaClassFlowSpacingResult.RootResults;
        state.lateralsLoading = false;
        if (!state.lateralsLoadedOnce) state.lateralsLoadedOnce = true;
      })
      .addCase(getLateralsThunk.rejected.type, (state) => {
        state.lateralsLoading = false;
      })
      .addCase(getLateralGroupsThunk.pending.type, (state) => {
        state.lateralGroupsLoading = true;
      })
      .addCase(getLateralGroupsThunk.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        if (action.payload) {
          state.lateralGroups = action.payload.GetGroupTypesResult.RootResults;
          state.lateralGroupsLoading = false;
        }
        // state.lateralGroups = action.payload.GetGroupTypesResult.RootResults;
        // state.lateralGroupsLoading = false;
        if (!state.lateralGroupsLoadedOnce) state.lateralGroupsLoadedOnce = true;
      })
      .addCase(getLateralGroupsThunk.rejected.type, (state) => {
        state.lateralGroupsLoading = false;
      });
  },
});

export const { resetLaterals } = lateralsSlice.actions;

export const selectLaterals = () => useAppSelector(lateralsSlice.selectors.selectLaterals);

export const lateralsReducer = lateralsSlice.reducer;
