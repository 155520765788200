import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const AscSortIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_2258_8181)">
      <path
        d="M7.99902 4.96328C7.98991 4.75625 7.9196 4.56094 7.76986 4.4112L6.95475 3.5961L5.65788 2.29922L5.3623 2.00365C5.2959 1.93724 5.21908 1.88516 5.13704 1.8487C4.84928 1.71979 4.48991 1.77057 4.25684 2.00365L1.84928 4.4112C1.70736 4.55313 1.62012 4.76276 1.62012 4.96328C1.62012 5.15469 1.70475 5.38386 1.84928 5.51537C1.99772 5.65209 2.19303 5.75365 2.40137 5.74453C2.6097 5.73412 2.80632 5.6638 2.95605 5.51537L3.77116 4.70026L4.02767 4.44375V12.9997C4.02767 13.1417 4.02637 13.2836 4.02767 13.4242V13.4424C4.02767 13.643 4.11491 13.8526 4.25684 13.9945C4.39225 14.1299 4.61491 14.2315 4.80892 14.2237C5.01074 14.2146 5.22038 14.1482 5.361 13.9945C5.50163 13.8422 5.59017 13.6534 5.59017 13.4424V4.43985L6.37012 5.21979L6.66569 5.51537C6.80762 5.65729 7.01725 5.74453 7.21777 5.74453C7.40918 5.74453 7.63835 5.6599 7.76986 5.51537C7.90658 5.36693 8.00814 5.17162 7.99902 4.96328Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M7.24877 12.4753C7.03784 12.4753 6.84903 12.5638 6.69669 12.7044C6.54304 12.8451 6.47664 13.0547 6.46752 13.2565C6.45971 13.4505 6.56127 13.6732 6.69669 13.8086C6.83862 13.9505 7.04825 14.0378 7.24877 14.0378H7.25919C7.5157 14.0404 7.77351 14.0378 8.03002 14.0378H13.5964C13.8074 14.0378 13.9962 13.9492 14.1485 13.8086C14.3022 13.668 14.3686 13.4583 14.3777 13.2565C14.3855 13.0625 14.2839 12.8398 14.1485 12.7044C14.0066 12.5625 13.797 12.4753 13.5964 12.4753H13.586C13.3295 12.4727 13.0717 12.4753 12.8152 12.4753H7.24877ZM11.5899 11.7409C11.8022 11.7409 11.9897 11.6523 12.142 11.5117C12.2956 11.3711 12.3621 11.1615 12.3712 10.9596C12.379 10.7656 12.2774 10.543 12.142 10.4076C12.0001 10.2656 11.7904 10.1784 11.5899 10.1784H7.24877C7.03784 10.1784 6.84903 10.2669 6.69669 10.4076C6.54304 10.5482 6.47664 10.7578 6.46752 10.9596C6.45971 11.1536 6.56127 11.3763 6.69669 11.5117C6.83862 11.6536 7.04825 11.7409 7.24877 11.7409H11.5899ZM9.58211 9.44531C9.79435 9.44531 9.98185 9.35677 10.1342 9.21615C10.2878 9.07552 10.3542 8.86589 10.3634 8.66406C10.3712 8.47005 10.2696 8.2474 10.1342 8.11198C9.99226 7.97005 9.78263 7.88281 9.58211 7.88281H7.24877C7.03784 7.88281 6.84903 7.97135 6.69669 8.11198C6.54304 8.2526 6.47664 8.46224 6.46752 8.66406C6.45971 8.85807 6.56127 9.08073 6.69669 9.21615C6.83862 9.35807 7.04825 9.44531 7.24877 9.44531H9.58211Z"
        fill="black"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2258_8181">
        <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.33331 1.3335)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
