import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';

const initialState = {
  loading: false,
  error: null as string | null,
  isInitialized: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setAppError(state, action: PayloadAction<{ error: string | null }>) {
      state.error = action.payload.error;
    },
    setAppInitialized(state, action: PayloadAction<{ isInitialized: boolean }>) {
      state.isInitialized = action.payload.isInitialized;
    },
  },
  selectors: {
    selectAppLoading: (state) => state.loading,
  },
});

export const selectAppLoading = () => useAppSelector(appSlice.selectors.selectAppLoading);

export const { setAppLoading, setAppInitialized, setAppError } = appSlice.actions;
