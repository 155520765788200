import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import { PolygonSelectedProductType } from 'shared/enums';
import {
  LineFormState,
  LineInformation,
  LineSectionProperties,
  PointFormState,
  PointInformation,
  PolygonFormState,
  PolygonInformation,
  Unit,
} from 'shared/models';
import { LINE, POINT, POLYGON } from '../../constants';
import { useAppSelector } from '../../hooks';
import { ConvertUnit, Math_round } from '../../lib';
import { localStorageService } from '../../services';
import { setItemScreenshot } from '../mapState';
import { setUnitsValue } from '../units';

const initPolygonInformation: PolygonInformation = {
  polygonName: '',
  isIrrigationProperties: true,
  polygonDescription: '',
  polygonFieldSize: 1,
  polygonCopySettingsFromId: null,
  polygonCropType: '',
  polygonDailyConsumption: 0,
  polygonIrrigationHoursPerDay: 0,
  polygonRowSpacing: 0,
  polygonPlantSpacing: 0,
  polygonLateralsPerRow: 0,
  polygonPlantingDate: new Date().toISOString(),
  polygonFieldSizeUnit: localStorageService.units?.Area || 'Ha',
  polygonDailyConsumptionUnit: localStorageService.units?.AppnDepth || 'mm',
  polygonRowSpacingUnit: localStorageService.units?.Length || 'm',
  polygonPlantSpacingUnit: localStorageService.units?.Length || 'm',
  polygonIsSoilType: false,
  polygonSoilType: [],
  polygonRowDirection: null,
  lateralType: 1,
  lateralTypeText: '',
  lateralSubtypeText: '',
  lateralGroup: '99951',
  lateralNominalFlow: 'All',
  lateralSpacing: 'All',
  lateralDiameter: 'All',
  lateralFlowPer: 'All',
  lateralClassType: 'All',
  emitterNominalFlow: '',
  emitterType: 3,
  emitterTypeText: '',
  emitterSubtypeText: '',
  emitterGroup: '03260',
  emitterSpacing: 0,
  isSelectProduct: false,
  selectedProductType: PolygonSelectedProductType.INTEGRATED,
  emitter: null,
  lateral: null,
  polygonOtherProduct: null,
  images: [],
};

const initLineSectionProperties: Pick<
  LineSectionProperties,
  'lineMaxVelocityUnit' | 'lineLengthUnit' | 'linePipeDiameterUnit' | 'lineFlowRateUnit'
> = {
  linePipeDiameterUnit: localStorageService.units?.PipeDiameter || 'cm',
  lineLengthUnit: localStorageService.units?.Length || 'm',
  lineMaxVelocityUnit: localStorageService.units?.Velocity || '',
  lineFlowRateUnit: localStorageService.units?.TotalFlow || 'lpm',
};

const initLineInformation: LineInformation = {
  lineName: '',
  isIrrigationProperties: true,
  lineDescription: '',
  images: [],
  lineTotalLength: 0,
  lineSections: [],
  lineTotalLengthUnit: 'm',
  lineCopySettingsFromId: null,
};

const initPointInformation: PointInformation = {
  pointDiameterUnit: localStorageService.units?.ValveDiameter || 'mm',
  pointPressureUnit: localStorageService.units?.Pressure || 'm',
  pointFlowRateUnit: localStorageService.units?.TotalFlow || 'm³/h',
  pointElevationUnit: localStorageService.units?.Length || 'm',
  pointName: '',
  pointType: 'waterSource',
  pointDescription: '',
  pointElevation: 0,
  pointDiameter: 10,
  pointPressure: 40,
  pointFlowRate: 150,
  pointWaterType: '',
  isIrrigationProperties: true,
  images: [],
  isFiltration: false,
  isExistingFiltration: false,
  pointFiltrationType: '',
  pointFiltrationMode: '',

  isPumping: false,
  isExistingPump: false,
  pointPumpType: 'turbine',
  pointPumpValue: 0,
  pointPumpCapacity: 0,
  pointPumpPressure: 0,

  isFertigation: false,
  isExistingFertigation: false,
  isElectricFertigation: false,
  pointElectricFertigationType: 'boosterPump',
  pointWaterMotivatedFertigationType: 'venturi',

  isOperationAndControl: false,
  isExistingOperationAndControl: false,
  operationAndControlMode: 'manual',
  operationAndControlCommunicationType: 'radio&RTU',
  pointCopySettingsFromId: null,
};

interface FormState {
  polygonInformation: PolygonInformation;
  lineInformation: LineInformation;
  pointInformation: PointInformation;
  polygonFormState: PolygonFormState;
  lineFormState: LineFormState;
  pointFormState: PointFormState;
  openedImageId: string;
}

const initialState: FormState = {
  polygonInformation: initPolygonInformation,
  lineInformation: initLineInformation,
  pointInformation: initPointInformation,
  openedImageId: '',
  polygonFormState: {
    isValidPolygonCopySettingsFrom: true,
    isValidPolygonName: true,
    validationCount: 0,
    isValidPolygonCropType: true,
    isValidPolygonDailyConsumption: true,
    isValidPolygonIrrigationHours: true,
    isValidPolygonRowSpacing: true,
    isValidPolygonPlantSpacing: true,
    isValidPolygonPlantingDate: true,
  },
  lineFormState: {
    validationCount: 0,
    isValidLineName: true,
    isValidLineSectionLength: true,
    isValidLineSectionMaxVelocity: true,
    isValidLineSectionSlope: true,
    isValidLineSectionFlowRate: true,
  },
  pointFormState: {
    validationCount: 0,
    isValidPointName: true,
  },
};

export const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    resetFormState: () => initialState,
    setPolygonInformation(state, action: PayloadAction<Partial<PolygonInformation>>) {
      state.polygonInformation = { ...state.polygonInformation, ...action.payload };
    },
    setLineInformation(state, action: PayloadAction<Partial<LineInformation>>) {
      state.lineInformation = { ...state.lineInformation, ...action.payload };
    },
    setPointInformation(state, action: PayloadAction<Partial<PointInformation>>) {
      state.pointInformation = { ...state.pointInformation, ...action.payload };
    },
    setPolygonFormState(state, action: PayloadAction<Partial<PolygonFormState>>) {
      state.polygonFormState = { ...state.polygonFormState, ...action.payload };
    },
    setLineFormState(state, action: PayloadAction<Partial<LineFormState>>) {
      state.lineFormState = { ...state.lineFormState, ...action.payload };
    },
    setPointFormState(state, action: PayloadAction<Partial<PointFormState>>) {
      state.pointFormState = { ...state.pointFormState, ...action.payload };
    },
    setOpenedImageId(state, action: PayloadAction<string>) {
      state.openedImageId = action.payload;
    },
  },
  selectors: {
    selectFormState: (state) => state,
    selectPointInformation: (state) => state.pointInformation,
    selectLineInformation: (state) => state.lineInformation,
    selectPolygonInformation: (state) => state.polygonInformation,
  },
  extraReducers: (builder: ActionReducerMapBuilder<FormState>) => {
    builder.addCase(setUnitsValue, (state, action) => {
      handlePolygonUnitChange(state.polygonInformation, action.payload.units);
      handlePointUnitChange(state.pointInformation, action.payload.units);
      handleLineUnitChange(state.lineInformation, action.payload.units);
    });
    builder.addCase(setItemScreenshot, (state, action) => {
      const { images, isNewItem, item } = action.payload;
      if (isNewItem) {
        switch (item) {
          case POLYGON:
            state.polygonInformation.images.push(...images);
            break;
          case LINE:
            state.lineInformation.images.push(...images);
            break;
          case POINT:
            state.pointInformation.images.push(...images);
        }
      }
    });
  },
});

const handlePolygonUnitChange = (state: Draft<FormState['polygonInformation']>, action: Partial<Unit>) => {
  const {
    polygonDailyConsumptionUnit: initDCUnit,
    polygonRowSpacingUnit: initRSUnit,
    polygonPlantSpacingUnit: initPSUnit,
    polygonFieldSizeUnit: initFSUnit,
  } = initialState.polygonInformation;

  const fsUnit = action.Area || initFSUnit;
  if (state.polygonFieldSizeUnit !== fsUnit) {
    state.polygonFieldSize = Math_round(
      ConvertUnit(state.polygonFieldSize, state.polygonFieldSizeUnit, fsUnit, null),
      2
    );
    state.polygonFieldSizeUnit = fsUnit;
  }

  const dcUnit = action.AppnDepth || initDCUnit;
  if (state.polygonDailyConsumptionUnit !== dcUnit) {
    state.polygonDailyConsumption = Math_round(
      ConvertUnit(state.polygonDailyConsumption, state.polygonDailyConsumptionUnit, dcUnit, null),
      2
    );
    state.polygonDailyConsumptionUnit = dcUnit;
  }

  const psUnit = action.Length || initPSUnit;
  if (state.polygonPlantSpacingUnit !== psUnit) {
    state.polygonPlantSpacing = Math_round(
      ConvertUnit(state.polygonPlantSpacing, state.polygonPlantSpacingUnit, psUnit, null),
      2
    );
    state.polygonPlantSpacingUnit = psUnit;
  }

  const rsUnit = action.Length || initRSUnit;
  if (state.polygonRowSpacingUnit !== rsUnit) {
    state.polygonRowSpacing = Math_round(
      ConvertUnit(state.polygonRowSpacing, state.polygonRowSpacingUnit, rsUnit, null),
      2
    );
    state.polygonRowSpacingUnit = rsUnit;
  }
};

const handleLineUnitChange = (state: Draft<FormState['lineInformation']>, action: Partial<Unit>) => {
  const { lineLengthUnit, lineFlowRateUnit, lineMaxVelocityUnit, linePipeDiameterUnit } = initLineSectionProperties;
  const { lineTotalLengthUnit } = initLineInformation;

  const tlUnit = action.Length || lineTotalLengthUnit;
  if (state.lineTotalLengthUnit !== tlUnit) {
    state.lineTotalLength = Math_round(ConvertUnit(state.lineTotalLength, state.lineTotalLengthUnit, tlUnit, null), 2);
    state.lineTotalLengthUnit = tlUnit;
  }

  if (state.lineSections && state.lineSections.length > 0) {
    state.lineSections.forEach((ls) => {
      const lUnit = action.Length || lineLengthUnit;
      if (ls.lineLengthUnit !== lUnit) {
        ls.lineLength = Math_round(ConvertUnit(ls.lineLength, ls.lineLengthUnit, lUnit, null), 2);
        ls.lineLengthUnit = lUnit;
      }

      const frUnit = action.TotalFlow || lineFlowRateUnit;
      if (ls.lineFlowRateUnit !== frUnit) {
        ls.lineFlowRate = Math_round(ConvertUnit(ls.lineFlowRate, ls.lineFlowRateUnit, frUnit, null), 2);
        ls.lineFlowRateUnit = frUnit;
      }

      const mvUnit = action.Velocity || lineMaxVelocityUnit;
      if (ls.lineMaxVelocityUnit !== mvUnit) {
        ls.lineMaxVelocity = Math_round(ConvertUnit(ls.lineMaxVelocity, ls.lineMaxVelocityUnit, mvUnit, null), 2);
        ls.lineMaxVelocityUnit = mvUnit;
      }

      const pdUnit = action.PipeDiameter || linePipeDiameterUnit;
      if (ls.linePipeDiameterUnit !== pdUnit) {
        ls.linePipeDiameter = Math_round(
          ConvertUnit(Number(ls.linePipeDiameter), ls.linePipeDiameterUnit, pdUnit, null),
          2
        );
        ls.linePipeDiameterUnit = pdUnit;
      }
    });
  }
};

const handlePointUnitChange = (state: Draft<FormState['pointInformation']>, action: Partial<Unit>) => {
  const { pointPressureUnit, pointDiameterUnit, pointFlowRateUnit, pointElevationUnit } = initPointInformation;

  const elevationUnit = action.Length || pointElevationUnit;
  if (state.pointElevationUnit !== elevationUnit) {
    state.pointElevation = Math_round(
      ConvertUnit(state.pointElevation, state.pointElevationUnit, elevationUnit, null),
      2
    );
    state.pointElevationUnit = elevationUnit;
  }

  const dUnit = action.ValveDiameter || pointDiameterUnit;
  if (state.pointDiameterUnit !== dUnit) {
    state.pointDiameter = Math_round(ConvertUnit(state.pointDiameter, state.pointDiameterUnit, dUnit, null), 2);
    state.pointDiameterUnit = dUnit;
  }

  const pressUnit = action.Pressure || pointPressureUnit;
  if (state.pointPressureUnit !== pressUnit) {
    state.pointPressure = Math_round(ConvertUnit(state.pointPressure, state.pointPressureUnit, pressUnit, null), 2);
    state.pointPressureUnit = pressUnit;
  }

  const frUnit = action.TotalFlow || pointFlowRateUnit;
  if (state.pointFlowRateUnit !== frUnit) {
    state.pointFlowRate = Math_round(ConvertUnit(state.pointFlowRate, state.pointFlowRateUnit, frUnit, null), 2);
    state.pointFlowRateUnit = frUnit;
  }
};

export const {
  setPolygonInformation,
  setPointInformation,
  setLineInformation,
  resetFormState,
  setPolygonFormState,
  setLineFormState,
  setPointFormState,
  setOpenedImageId,
} = formStateSlice.actions;

export const selectFormState = () => useAppSelector(formStateSlice.selectors.selectFormState);
export const selectPointInforamtion = () =>
  useAppSelector(formStateSlice.selectors.selectPointInformation, shallowEqual);
export const selectLineInformation = () => useAppSelector(formStateSlice.selectors.selectLineInformation, shallowEqual);
export const selectPolygonInformation = () =>
  useAppSelector(formStateSlice.selectors.selectPolygonInformation, shallowEqual);

export const formStateReducer = formStateSlice.reducer;
