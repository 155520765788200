import axios from 'axios';
import { toast } from 'react-toastify';
import { ApiError } from '../../models';

export const handleApiError = (err: unknown, callback?: (message: string) => void) => {
  // console.error('####: error', err);

  let errorMessage = 'Some error occurred';

  if (axios.isAxiosError(err)) {
    const error = err as ApiError;

    if (callback && error.response?.data?.error.message) {
      callback(error.response?.data.error.message);
      return;
    }

    errorMessage = checkErrorApiMessageOptions(error) || errorMessage;
  } else if (err instanceof Error) {
    errorMessage = err.message;
  } else {
    errorMessage = JSON.stringify(err);
  }

  if (errorMessage === 'Sequence contains no elements.') {
    toast.error('User not found in system.');
    return;
  }

  toast.error(errorMessage);
};

const checkErrorApiMessageOptions = (error: ApiError) => {
  // one error option server responses with
  if (error.response?.data?.detail) {
    return error.response?.data.detail;
  }
  // another error option server reaponses with
  if (error.response?.data?.message) {
    return error.response?.data?.message;
  }
  // error option that corresponds swagger documentation
  return error.response?.data?.error?.message || error?.message;
};
