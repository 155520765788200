import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { MasterGroup, MasterGroupsResponse } from '../../models';
import { logoutUser } from '../../axios';
import { useAppSelector } from '../../hooks';

interface UserState {
  loading: boolean;
  masterGroups: MasterGroup[];
}

const initialState: UserState = {
  loading: true,
  masterGroups: [],
};

export const getMasterGroupsThunk = createAsyncThunk('getMasterGroups', async (_, thunkAPI) => {
  try {
    const response = await axios.get<MasterGroupsResponse>(
      '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetMASTERGROUPS'
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const masterGroupsSlice = createSlice({
  name: 'masterGroups',
  initialState,
  reducers: {
    resetMasterGroups: () => initialState,
  },
  selectors: {
    selectMasterGr: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMasterGroupsThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getMasterGroupsThunk.fulfilled.type, (state, action: PayloadAction<MasterGroupsResponse>) => {
        state.masterGroups = action.payload.GetMASTERGROUPSResult.RootResults;
        state.loading = false;
      })
      .addCase(getMasterGroupsThunk.rejected.type, (state) => {
        state.loading = false;
        logoutUser();
      });
  },
});

export const { resetMasterGroups } = masterGroupsSlice.actions;
export const selectMasterGr = () => useAppSelector(masterGroupsSlice.selectors.selectMasterGr);

export const masterGroupsReducer = masterGroupsSlice.reducer;
