import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosBase } from 'shared/axios';
import { CURRENT_TAB, ProjectStatus, SORT_BY } from 'shared/enums';
import { useAppNavigator } from 'shared/hooks';
import { getFirstIndexesOfSortGroups, isValidJSON, sortProjects } from 'shared/lib';
import { BaseResponse, Project, ProjectDataFromServer, ProjectFromServer } from 'shared/models';
import { localStorageService } from 'shared/services';
import { handleApiError } from '../../../shared/lib/errorHandling';

export const useProjects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isArchive, setIsArchive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.LAST_MODIFIED);
  const [isAscSorting, setIsAscSorting] = useState(false); // default sort is DESC
  const { navigateToNewProject, navigate } = useAppNavigator();
  const { pathname, state } = useLocation();

  const fetchProjects = async () => {
    try {
      setLoading(true);

      const response = await axiosBase.get<BaseResponse<ProjectFromServer[]>>('/api/Project/projects');
      const data = response.data.data;

      const filteredJSON = data.filter((i) => isValidJSON(i.project));
      const parsedData = filteredJSON.map((item) => ({
        ...item,
        project: JSON.parse(item.project) as ProjectDataFromServer,
      }));

      if (!parsedData.length) navigateToNewProject();

      setProjects(parsedData);
    } catch (e) {
      handleApiError(e);
      toast.error("Can't fetch projects. ");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const onClearSearchValue = () => setSearchValue('');

  const onChangeTab = (_: React.SyntheticEvent, value: CURRENT_TAB) => {
    navigate(pathname, { state: { currentTab: value } });
  };

  const onChangeSortBy = (e: ChangeEvent<HTMLInputElement>) => setSortBy(e.target.value as SORT_BY);

  const onToggleSortDirection = () => setIsAscSorting(!isAscSorting);
  const onToggleShowArchived = () => setIsArchive((prevState) => !prevState);

  const filteredProjects = projects
    .filter(
      ({ project: { projectName, customerName } }) =>
        projectName.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim()) ||
        customerName.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim())
    )
    .filter((p) => {
      if (state?.currentTab === CURRENT_TAB.ALL || !state?.currentTab) return true;
      if (state?.currentTab === CURRENT_TAB.MY) return p.ownerName === localStorageService.rapidUsername;
      if (state?.currentTab === CURRENT_TAB.SHARED) return p.ownerName !== localStorageService.rapidUsername;
    })
    .filter((p) => (isArchive ? p.status === ProjectStatus.Archive : p.status === ProjectStatus.Active))
    .sort((p1, p2) => sortProjects(p1, p2, sortBy));

  const sortedProjects = isAscSorting ? filteredProjects : filteredProjects.reverse();

  const firstIndexesOfSortGroups = getFirstIndexesOfSortGroups(sortedProjects, sortBy);

  const onUpdateProject = (projectId: number, projectUpdateModel: Partial<Project>) => {
    const updated = projects.map((p) => (p.id === projectId ? { ...p, ...projectUpdateModel } : p));
    setProjects(updated);
  };

  return {
    loading,
    projects: sortedProjects,
    allProjects: projects,
    searchValue,
    onChangeSearchValue,
    onClearSearchValue,
    onChangeTab,
    sortBy,
    onChangeSortBy,
    isAscSorting,
    onToggleSortDirection,
    firstIndexesOfSortGroups,
    isArchive,
    onToggleShowArchived,
    onUpdateProject,
  };
};
