import { Box, IconButton } from '@mui/material';
import { useAppNavigator } from 'shared/hooks';
import { Icon, Row } from 'shared/ui';
import { useLocation } from 'react-router-dom';

export const Header = () => {
  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  const onToggleSideMenu = () => {
    if (!state?.sideMenu) {
      navigate(pathname, { state: { sideMenu: true } });
    } else {
      navigate(pathname, { state: { sideMenu: false } });
    }
  };

  return (
    <>
      <Row justifyContent="space-between" sx={{ width: '100%', height: '48px', bgcolor: 'primary.main', px: 1 }}>
        <IconButton onClick={onToggleSideMenu} color="info">
          {state?.sideMenu ? <Icon path="close" /> : <Icon path="menu" />}
        </IconButton>

        <Box component="img" src="/images/rivulisLogo.svg" sx={{ width: '92px', mr: 1.2 }} />
      </Row>
    </>
  );
};
