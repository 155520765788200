import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppNavigator } from 'shared/hooks';
import { selectDrawMode, selectIsItemDrawing, stopItemDrawing } from 'shared/slices';
import { DrawingControlGps } from './DrawingControlGps';
import { DrawingControlManual } from './DrawingControlManual';

export const ControlContainer = styled('div')`
  padding: 24px;
`;

// const drawModeConfig = {
//   gps: DrawingControlGps,
//   manual: DrawingControlManual,
// };

export const ItemDrawingControl: FC = () => {
  const drawMode = selectDrawMode();
  const isDrawing = selectIsItemDrawing();

  // for debugging simplify polygon method(temp solution)
  // const showSimplify = useAppSelector(mapSlice.selectors.selectShowSimplifyOption);

  const dispatch = useAppDispatch();

  const { navigate } = useAppNavigator();

  // useEffect(() => {
  //   // for debugging simplify polygon method(temp solution)
  //   drawMode === 'manual' && dispatch(setShowSimplifyOption(false));
  // }, [isDrawing]);

  useEffect(() => {
    const handleBackButton = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return 'Are you sure you want to leave this page?';
    };
    window.addEventListener('beforeunload', handleBackButton);
    return () => {
      dispatch(stopItemDrawing());
      window.removeEventListener('beforeunload', handleBackButton);
    };
  }, [navigate]);

  // const DrawingControlComponent = drawModeConfig[drawMode];

  if (!isDrawing) {
    return null;
  }

  return (
    <>
      {/*{(showSimplify || (isDrawing && isItemEditing)) && <SimplifyData />}*/}
      {/*<DrawingControlComponent id={'drawing-control'} />*/}
      {(drawMode === 'manual' || drawMode === 'none') && <DrawingControlManual id={'drawing-control'} />}
      {drawMode === 'gps' && <DrawingControlGps id={'drawing-control'} />}
    </>
  );
};
