import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppNavigator } from 'shared/hooks';
import { Button, WhiteModal } from 'shared/ui';
import { CatalogUserIcon } from 'shared/ui/Icons/CatalogUserIcon';
import { ProjectPermission } from '../../../shared/enums';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (permissionType: ProjectPermission) => void;
  loading: boolean;
  userName: string;
  email: string;
  userPermission: ProjectPermission;
}

export const ProjectManagePermissionModal: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  loading,
  userName,
  email,
  userPermission,
}) => {
  const { t } = useTranslation();

  const [permissionType, setPermissionType] = useState(userPermission);
  const [canShare, setCanShare] = useState(false);

  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  useEffect(() => {
    if (open && !state?.openManagePermissionModal) {
      navigate(pathname, { state: { openManagePermissionModal: open, openProjectMenu: true } });
    }
  }, [open]);

  useEffect(() => {
    if (!state?.openManagePermissionModal && open) {
      onClose();
    }
  }, [state?.openManagePermissionModal]);

  const handleSetPermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPermissionType(Number(e.target.value));
  };

  const handleCanShare = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanShare(e.target.checked);
  };

  const permission =
    permissionType === ProjectPermission.NoAccess // if selected No Access return No Access
      ? ProjectPermission.NoAccess
      : canShare // If user can share
        ? permissionType === ProjectPermission.Viewer
          ? ProjectPermission.ViewerCanShare
          : ProjectPermission.EditorCanShare
        : permissionType; // Otherwise, use the original permissionType

  const onShareClick = () => {
    onSubmit(permission);
  };

  const onCancelClick = () => {
    !loading && onClose();
  };

  return (
    <WhiteModal open={open} onClose={onClose} hideCloseIcon margin={6}>
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack mb={'12px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <CatalogUserIcon />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {userName}
            </Typography>
          </Stack>
        </Stack>
        <TextField
          disabled
          value={email}
          sx={{
            width: '100%',
            marginBottom: '24px',
            '& .MuiInputBase-root': {
              height: '44px',
              borderRadius: '8px',
              p: '0 10px',
              fontSize: 16,
            },
          }}
        />
        <Stack>
          <FormControl
            sx={{
              mb: '16px',
              '& label.Mui-focused': {
                color: '#242731',
              },
            }}
          >
            <FormLabel
              sx={{
                color: '#242731',
                fontSize: '18px',
                lineHeight: '130%',
                letterSpacing: '0.18px',
                mb: '16px',
              }}
            >
              {t('Select Permissions')}
            </FormLabel>
            <RadioGroup
              value={permissionType}
              name="radio-buttons-group"
              sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <FormControlLabel
                value={ProjectPermission.Editor}
                control={
                  <Radio
                    sx={{ py: 0 }}
                    checked={permissionType === ProjectPermission.Editor}
                    onChange={handleSetPermission}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: '#242731',
                      fontSize: '16px',
                    }}
                  >
                    {t('Editor')}
                  </Typography>
                }
              />
              {/*<FormControlLabel*/}
              {/*  value={ProjectPermission.Viewer}*/}
              {/*  control={*/}
              {/*    <Radio*/}
              {/*      sx={{ py: 0 }}*/}
              {/*      checked={permissionType === ProjectPermission.Viewer}*/}
              {/*      onChange={handleSetPermission}*/}
              {/*    />*/}
              {/*  }*/}
              {/*  label={*/}
              {/*    <Typography*/}
              {/*      sx={{*/}
              {/*        color: '#242731',*/}
              {/*        fontSize: '16px',*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      {t('Viewer')}*/}
              {/*    </Typography>*/}
              {/*  }*/}
              {/*/>*/}
              {/*<Box sx={{ width: '100%', height: '1px', background: '#D4D4D4' }} />*/}
              {/*<FormControlLabel*/}
              {/*  disabled={!permissionType}*/}
              {/*  control={<Checkbox sx={{ py: 0 }} checked={canShare} onChange={handleCanShare} />}*/}
              {/*  label={*/}
              {/*    <Typography*/}
              {/*      sx={{*/}
              {/*        color: '#242731',*/}
              {/*        fontSize: '16px',*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      {t('Can share')}*/}
              {/*    </Typography>*/}
              {/*  }*/}
              {/*/>*/}
              <Divider />
              <FormControlLabel
                defaultChecked={
                  userPermission === ProjectPermission.EditorCanShare ||
                  userPermission === ProjectPermission.ViewerCanShare
                }
                value={ProjectPermission.NoAccess}
                control={
                  <Radio
                    sx={{ py: 0 }}
                    checked={permissionType === ProjectPermission.NoAccess}
                    onChange={handleSetPermission}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: '#CE2929',
                      fontSize: '16px',
                    }}
                  >
                    {t('Remove access')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={5}>
            <Button outlined btnColor="info.dark" fullWidth onClick={onCancelClick}>
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('new_project_cancel')}</Typography>
            </Button>
          </Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <Button loading={loading} btnColor="primary.main" fullWidth onClick={onShareClick} disabled={loading}>
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('Save')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </WhiteModal>
  );
};
