import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Stack, styled, TextField } from '@mui/material';
import { LineColorPicker, PointColorPicker, PolygonColorPicker } from 'features';
import { LatLng, LineUtil, Point } from 'leaflet';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Nullable } from 'shared';
import { useAppDispatch, useMapActions } from 'shared/hooks';
import { convertLatLngToXYPoints } from 'shared/lib';
import { LineModel, PointModel, PolygonModel } from 'shared/models';
import {
  cancelPointDrawing,
  clearItemPoints,
  deleteLinePoint,
  deletePolygonPoint,
  selectActionHistory,
  selectCurrentLinePointId,
  selectCurrentMapItem,
  selectCurrentPolygonPointId,
  selectIsColorPicker,
  selectIsLineDrawing,
  selectIsPointDrawing,
  selectIsPolygonDrawing,
  selectLineActionHistory,
  selectPointActionHistory,
  setDrawMode,
  setNewItemPoints,
  setNewPolygonPoints,
  toggleColorPicker,
  undoLineAction,
  undoPointAction,
  undoPolygonAction,
} from 'shared/slices';
import { Icon, Row } from 'shared/ui';
import { useDebounce } from 'usehooks-ts';
import { ControlContainer } from './index';
import simplify = LineUtil.simplify;

const ControlButton = styled(Button)({
  width: '100%',
  maxWidth: '300px',
  borderRadius: 0,
});

type Props = {
  id: string;
};

// TODO: refactor component logic
export const DrawingControlManual: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();

  const isLineDrawing = selectIsLineDrawing();
  const isPointDrawing = selectIsPointDrawing();
  const currentPolygonPointId = selectCurrentPolygonPointId();
  const currentLinePointId = selectCurrentLinePointId();
  const isColorPicker = selectIsColorPicker();
  const isPolygonDrawing = selectIsPolygonDrawing();
  const polygonActionHistory = selectActionHistory();
  const lineActionHistory = selectLineActionHistory();
  const pointActionHistory = selectPointActionHistory();

  const onDeletePoint = () => {
    // currentPolygonPointId && isPolygonDrawing && dispatch(deletePolygonPoint(currentPolygonPointId as string));
    if (currentPolygonPointId && isPolygonDrawing) {
      dispatch(deletePolygonPoint(currentPolygonPointId as string));
    }

    currentLinePointId && isLineDrawing && dispatch(deleteLinePoint(currentLinePointId as string));
  };

  const onUndoAction = () => {
    if (isPolygonDrawing) {
      if (polygonActionHistory.length === 1) {
        dispatch(setDrawMode('none'));
      }

      dispatch(undoPolygonAction());
    }

    if (isLineDrawing) {
      if (lineActionHistory.length === 1) {
        dispatch(setDrawMode('none'));
      }

      dispatch(undoLineAction());
    }
    if (isPointDrawing) {
      if (pointActionHistory.length === 1) {
        dispatch(setDrawMode('none'));
      }

      if (pointActionHistory.length === 0) {
        dispatch(cancelPointDrawing());
        dispatch(setDrawMode('none'));
      }

      dispatch(undoPointAction());
    }
  };

  const onColorPicker = () => {
    dispatch(toggleColorPicker());
  };

  const buttons = useMemo(
    () => [
      { icon: 'mapIcons/delete_point', callback: onDeletePoint },
      { icon: 'mapIcons/color_pick', callback: onColorPicker },
      { icon: 'mapIcons/undo', callback: onUndoAction },
    ],
    [
      currentPolygonPointId,
      currentLinePointId,
      polygonActionHistory,
      lineActionHistory,
      pointActionHistory,
      isLineDrawing,
    ]
  );

  return (
    <>
      <Stack
        id={id}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0, 0, 0, 0.68)',
          position: 'fixed',
          width: '100%',
          top: 'calc(100svh - 68px)',
          bottom: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Row sx={{ width: '100%' }}>
          {buttons.map((btn) => (
            <ControlContainer key={btn.icon}>
              {!isColorPicker && (
                <ControlButton onClick={btn.callback} disabled={isColorPicker}>
                  <Icon path={btn.icon} />
                </ControlButton>
              )}
            </ControlContainer>
          ))}
        </Row>
      </Stack>
      <PolygonColorPicker />
      <LineColorPicker />
      <PointColorPicker />
    </>
  );
};

const TOLERANCE_STEP = 0.000001;

// for debugging simplify polygon method(temp solution)
export const SimplifyData = () => {
  const [tolerance, setTolerance] = useState(0);
  const [showTolerance, setShowTolerance] = useState(false);

  const debouncedTolerance = useDebounce(tolerance, 600);
  const { createPoint } = useMapActions();
  const dispatch = useAppDispatch();

  const mapItem = selectCurrentMapItem();

  const initialItemDataRef = useRef<Nullable<PolygonModel | LineModel | PointModel>>(null);

  useEffect(() => {
    if (!showTolerance || !mapItem) return;
    // create initial data as fallback for item shape
    if (!initialItemDataRef.current) {
      initialItemDataRef.current = mapItem;
    }

    // reset to fallback shape if tolerance 0
    if (debouncedTolerance === 0) {
      dispatch(setNewItemPoints(initialItemDataRef.current.geoJson.points));
      return;
    }

    console.log('simplifyingData');

    const toleranceStepSafe = parseFloat(debouncedTolerance.toFixed(6));

    const pointCoords = initialItemDataRef.current.geoJson.points.map((point) => ({
      lng: point.coords.lng,
      lat: point.coords.lat,
    })) as LatLng[];

    const convertedPointsToXY = convertLatLngToXYPoints(pointCoords) as Point[];
    const itemSimplified = simplify(convertedPointsToXY, toleranceStepSafe);
    const convertedPointsToLatLng = itemSimplified.map((point) => ({ lng: point.x, lat: point.y })) as LatLng[];

    dispatch(clearItemPoints(initialItemDataRef.current.geoJson.id));

    convertedPointsToLatLng.forEach((point) => createPoint(point));
  }, [debouncedTolerance]);

  useEffect(() => {
    if (!showTolerance) {
      initialItemDataRef.current && dispatch(setNewPolygonPoints(initialItemDataRef.current.geoJson.points));
    }
  }, [showTolerance]);

  const increaseTolerance = () => {
    setTolerance((prev) => prev + TOLERANCE_STEP);
  };

  const decreaseTolerance = () => {
    setTolerance((prev) => Math.max(0, prev - TOLERANCE_STEP));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '20%',
        left: '40%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        bgcolor: 'rgba(0 0 0 / .6)',
        borderRadius: '5px',
        padding: '5px',
      }}
    >
      <Stack>
        <FormControlLabel
          label={'Simplify'}
          sx={{
            ml: 0,
            '& .MuiTypography-root': {
              color: '#fff',
              fontWeight: 700,
              fontSize: 18,
              userSelect: 'none',
              ml: 1,
              mt: '3px',
            },
          }}
          control={
            <Checkbox
              color={'secondary'}
              onChange={() => setShowTolerance((prev) => !prev)}
              name={'tolerance'}
              sx={{ px: '3px' }}
            />
          }
        />
        {showTolerance && (
          <Row>
            <Stack>
              <Box
                sx={{ px: '20px', cursor: 'pointer', '&:hover': { bgcolor: 'rgba(200 0 0 / .6)' } }}
                onClick={increaseTolerance}
              >
                <ChevronLeft sx={{ color: '#fff', transform: 'rotate(90deg)' }} />
              </Box>
              <Box
                sx={{ px: '20px', cursor: 'pointer', '&:hover': { bgcolor: 'rgba(200 0 0 / .6)' } }}
                onClick={decreaseTolerance}
              >
                <ChevronRight sx={{ color: '#fff', transform: 'rotate(90deg)' }} />
              </Box>
            </Stack>
            <TextField
              value={(tolerance * 1000000).toFixed(0)}
              type={'number'}
              sx={{ marginRight: '10px', color: '#fff', border: '1px solid #fff' }}
              inputProps={{
                style: { color: '#fff', fontSize: '18px' },
              }}
            />
            <Icon
              path={'mapIcons/undo'}
              onClick={() => setTolerance(0)}
              sx={{
                '&:hover': { cursor: 'pointer' },
                bgcolor: 'rgba(255 0 0 / .7)',
                padding: '10px',
                borderRadius: '50%',
              }}
            />
          </Row>
        )}
      </Stack>
    </Box>
  );
};
