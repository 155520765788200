import L, { LatLngLiteral } from 'leaflet';
import { FC } from 'react';
import { Marker } from 'react-leaflet';

const Icon = L.icon({ iconUrl: '/images/big_point.svg', iconSize: [8, 8], iconAnchor: [4, 4] });

interface Props {
  coords: LatLngLiteral;
  onAddPoint: () => void;
  disabled?: boolean;
}

export const MiddlePoint: FC<Props> = ({ coords, onAddPoint, disabled }) => {
  return (
    <Marker
      icon={Icon}
      position={coords}
      eventHandlers={{
        click() {
          !disabled && onAddPoint();
        },
      }}
    />
  );
};
