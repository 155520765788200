import { ChangeEvent, FC, useState } from 'react';
import { Button, CoordInput, Icon, ModeDrawer, Row } from '../../shared/ui';
import { Typography } from '@mui/material';
import { useMap } from 'react-leaflet';
import { useAppDispatch } from '../../shared/hooks';
import { addSourcePoint, selectIsPointDrawing, selectNewPoint } from '../../shared/slices';
import { v4 as makeId } from 'uuid';
import { Point } from '../../shared/models';
import { LatLngLiteral } from 'leaflet';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const PointPlacer: FC<Props> = ({ isOpen, onClose }) => {
  const map = useMap();
  const dispatch = useAppDispatch();
  const isPointDrawing = selectIsPointDrawing();
  const newPoint = selectNewPoint();

  const [coords, setCoords] = useState<LatLngLiteral>({ lat: 0, lng: 0 });
  const onChangeCoords = (e: ChangeEvent<HTMLInputElement>) =>
    setCoords((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handlePlacePoint = () => {
    const point: Point = { id: makeId(), coords };

    if (isPointDrawing && newPoint.geoJson.points.length === 0) {
      dispatch(addSourcePoint(point));
    }
    map.flyTo(coords as LatLngLiteral);
    onClose();
  };

  return (
    <ModeDrawer open={isOpen} onClose={onClose}>
      <Row justifyContent="center">
        <Icon path="mapIcons/gps" />
        <Typography
          sx={{
            ml: '12px',
            color: 'info.main',
            fontSize: '16px',
          }}
        >
          GPS Coordinates
        </Typography>
      </Row>

      <Row sx={{ mt: 1, mx: 'auto', width: '200px' }}>
        <Typography
          sx={{
            ml: '12px',
            color: 'info.main',
            fontSize: '16px',
          }}
        >
          Latitude
        </Typography>

        <CoordInput value={coords.lat} name="lat" onChange={onChangeCoords} />
      </Row>

      <Row sx={{ my: 2, mx: 'auto', width: '200px' }}>
        <Typography
          sx={{
            ml: '12px',
            color: 'info.main',
            fontSize: '16px',
          }}
        >
          Longitude
        </Typography>

        <CoordInput value={coords.lng} name="lng" onChange={onChangeCoords} />
      </Row>

      <Row sx={{ px: 3 }}>
        <Button onClick={handlePlacePoint} fullWidth btnColor="info.main">
          Place Point
        </Button>
      </Row>
    </ModeDrawer>
  );
};
