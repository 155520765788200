import { styled, Tab } from '@mui/material';

export const TAB = styled(Tab)(() => ({
  textTransform: 'capitalize',
  marginRight: '12px',
  minHeight: '0px',
  fontSize: 18,
  padding: '8px',
  minWidth: 'auto',
  '&.Mui-selected': {
    color: 'black',
  },
}));
