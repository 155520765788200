import { LatLng, LatLngLiteral } from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import { NEW_POLYGON_ID, POLYGON, POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { SavingStep } from 'shared/enums';
import { useFindLocation } from '../../features/MapControl/hooks/useFindLocation';
import {
  calculateAngle,
  calculateDistanceBetweenPoints,
  checkPointsIsTooClosely,
  getProjectItemsBounds,
  getRelatedPolygonId,
} from '../lib';
import {
  selectCurrentItemId,
  selectLines,
  selectNewLine,
  selectNewPoint,
  selectNewPolygon,
  selectPoints,
  selectPolygons,
  selectProjectId,
  selectSearchedPoint,
  selectSearchRequestCount,
  selectSelectedItem,
  setLines,
  setPolygons,
  setSaveProjectStep,
  setTrackingPoint,
  updatePolygonRowDirectionLineFromAngle,
} from '../slices';
import { useAppDispatch } from './useAppDispatch';

export const useMapControl = (isSearch: boolean, onToggleSearch: () => void) => {
  const lines = selectLines();
  const newLine = selectNewLine();
  const points = selectPoints();
  const newPoint = selectNewPoint();
  const projectId = selectProjectId();
  const polygons = selectPolygons();
  const newPolygon = selectNewPolygon();
  const isSelectedItem = selectCurrentItemId() || '';
  const searchedPoint = selectSearchedPoint();
  const searchRequestCount = selectSearchRequestCount();
  const currentMapItemType = selectSelectedItem();

  const dispatch = useAppDispatch();

  const map = useMap();

  const { handleFindUser, loading, checkPermissionGrant } = useFindLocation();

  const [openLocationBlockedModal, setOpenLocationBlockedModal] = useState(false);
  const [isViewportBtn, setIsViewportBtn] = useState(false);

  const previousSelectedItem = useRef<{ id: string; type: string | null | undefined }>({ id: '', type: null });
  const linesRef = useRef(lines);
  const polygonsRef = useRef(polygons);

  const isGeometryDataPresent = useCallback(() => {
    const isPolygons = polygons?.[0]?.geoJson.points.length > 0 || newPolygon.geoJson.points.length > 0;
    const isLines = lines?.[0]?.geoJson.points.length > 0 || newLine.geoJson.points.length > 0;
    const isPoints = points?.[0]?.geoJson.points.length > 0 || newPoint.geoJson.points.length > 0;

    return isPolygons || isLines || isPoints;
  }, [polygons, newPolygon, lines, newLine, newPoint, newPolygon]);

  const findUser = async () => {
    const isPermissionGranted = await checkPermissionGrant();

    if (!isPermissionGranted) {
      setOpenLocationBlockedModal(true);
      return;
    }
    handleFindUser();
  };

  const handleFitViewport = () => {
    const calcPolygons = [...polygons, newPolygon];
    const calcLines = [...lines, newLine];
    const calcPoints = [...points, newPoint];

    const bounds = getProjectItemsBounds(calcPolygons, calcLines, calcPoints);

    const hasInfinity = bounds.flat().some((val) => val === Infinity || val === -Infinity);

    if (hasInfinity) {
      // console.error(`Infinity values exist inside bounds - ${bounds.toString()}`);
      return;
    }

    map.fitBounds(bounds);
  };

  const findNearPoint = (coords: LatLngLiteral) => {
    const totalObjectsPoints = [...points, ...lines, ...polygons]
      .filter((i) => i.geoJson.id !== isSelectedItem)
      .filter((i) => !i.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID))
      .flatMap((i) => i.geoJson.points);

    const selCoords = map.latLngToContainerPoint(coords);

    let nearestPointDistance = 100;
    let nearestPoint: LatLng | null = null;

    totalObjectsPoints.forEach((p) => {
      const pointCoords = map.latLngToContainerPoint(p.coords);
      const { offsetX, offsetY } = calculateDistanceBetweenPoints(selCoords, pointCoords);
      const isNear = checkPointsIsTooClosely(selCoords, pointCoords, 13);

      if (isNear) {
        const totalDistance = offsetX + offsetY;
        if (totalDistance < nearestPointDistance) {
          nearestPointDistance = totalDistance;
          nearestPoint = p.coords as LatLng;
        }
      }
    });

    return nearestPoint;
  };

  useEffect(() => {
    if (isSelectedItem) {
      isSearch && onToggleSearch();

      dispatch(setTrackingPoint(null));

      previousSelectedItem.current = { id: isSelectedItem, type: currentMapItemType };
    }
  }, [isSelectedItem]);

  useEffect(() => {
    // if row direction line is created but polygon is not saved,
    // line should be changed to it's previous state
    const prevItem = previousSelectedItem.current;
    if (!isSelectedItem && (prevItem.type === POLYGON || prevItem.id.includes(POLYGON_ROW_DIRECTION_ID))) {
      const rowDirectionLines = lines.filter((l) => l.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID));

      rowDirectionLines.forEach((l) => {
        const lineId = l.geoJson.id;
        const polygonId = getRelatedPolygonId(lineId);
        const polygon = [...polygons, newPolygon].find((p) => p.geoJson.id === polygonId);

        const centerPoint = l.geoJson.points[0].coords;
        const endPoint = l.geoJson.points[1].coords;
        const angle = Number(calculateAngle(centerPoint, endPoint).toFixed(2));

        if (!polygon || polygon?.properties.polygonRowDirection === null) {
          const updatedLines = lines.filter((l) => l.geoJson.id !== lineId);
          dispatch(setLines(updatedLines));
        } else if (angle !== polygon.properties.polygonRowDirection) {
          dispatch(updatePolygonRowDirectionLineFromAngle({ angle: polygon.properties.polygonRowDirection, polygon }));
        }
      });
    }
  }, [isSelectedItem]);

  useEffect(() => {
    if (linesRef.current.length > lines.length) {
      const lineIds = lines.map((l) => l.geoJson.id);
      const deletedLineId = linesRef.current.find((l) => !lineIds.includes(l.geoJson.id))?.geoJson.id;

      // if row direction line was deleted, polygonRowDirection should be set to 0
      if (deletedLineId?.includes(POLYGON_ROW_DIRECTION_ID)) {
        const polygonId = getRelatedPolygonId(deletedLineId);

        const updatedPolygons = polygons.map((p) => {
          return p.geoJson.id === polygonId ? { ...p, properties: { ...p.properties, polygonRowDirection: null } } : p;
        });

        dispatch(setPolygons(updatedPolygons));
        dispatch(setSaveProjectStep(SavingStep.SAVING));
      }
    }

    linesRef.current = [...lines];
  }, [lines]);

  useEffect(() => {
    if (polygonsRef.current.length === polygons.length) return;

    if (polygonsRef.current.length > polygons.length) {
      const polygonIds = polygons.map((p) => p.geoJson.id);
      const deletedPolygon = polygonsRef.current.find((p) => !polygonIds.includes(p.geoJson.id));

      // if polygon with row direction was deleted, it's row direction line should be deleted too
      if (deletedPolygon && deletedPolygon.properties.polygonRowDirection !== null) {
        const lineId = `${deletedPolygon.geoJson.id}${POLYGON_ROW_DIRECTION_ID}`;

        const updatedLines = lines.filter((l) => l.geoJson.id !== lineId);
        dispatch(setLines(updatedLines));
        dispatch(setSaveProjectStep(SavingStep.SAVING));
      }
    } else {
      const oldPolygonIds = polygonsRef.current.map((p) => p.geoJson.id);
      const newPolygon = polygons.find((p) => !oldPolygonIds.includes(p.geoJson.id));

      // if new polygon has row direction, row direction line id should be updated
      if (newPolygon && newPolygon.properties.polygonRowDirection !== null) {
        const lineId = `${NEW_POLYGON_ID}${POLYGON_ROW_DIRECTION_ID}`;
        const updatedLineId = `${newPolygon.geoJson.id}${POLYGON_ROW_DIRECTION_ID}`;

        const updatedLines = lines.map((l) => {
          return l.geoJson.id === lineId
            ? {
                ...l,
                geoJson: { ...l.geoJson, id: updatedLineId },
                information: { ...l.information, name: `${newPolygon.information.name} - Row Direction` },
              }
            : l;
        });

        dispatch(setLines(updatedLines));
        dispatch(setSaveProjectStep(SavingStep.SAVING));
      }
    }

    polygonsRef.current = [...polygons];
  }, [polygons]);

  useEffect(() => {
    if (isGeometryDataPresent()) {
      setIsViewportBtn(true);
    } else {
      setIsViewportBtn(false);
    }
  }, [polygons, lines, points, newPolygon, newLine, newPoint]);

  useEffect(() => {
    if (searchedPoint) {
      map.flyTo(searchedPoint as LatLngLiteral, 16, { animate: false });
    }
  }, [searchRequestCount]);

  useEffect(() => {
    if (isGeometryDataPresent()) {
      handleFitViewport();
    } else {
      findUser();
    }
  }, [projectId]);

  return {
    isSelectedItem,
    isViewportBtn,
    handleFitViewport,
    findUser,
    loading,
    openLocationBlockedModal,
    setOpenLocationBlockedModal,
    findNearPoint,
  };
};
