import { FC } from 'react';
import { Button, StartMeasurement } from '../../../shared/ui';
import { Typography } from '@mui/material';
import { useGpsPlayStop } from '../../../shared/hooks/useGpsPlayStop';

type Props = {
  label: string;
};
export const TrackingStartButton: FC<Props> = ({ label }) => {
  const { startGpsTracking } = useGpsPlayStop();

  return (
    <Button sx={{ border: 'none' }}>
      <Typography
        sx={{ color: 'info.main', cursor: 'pointer', fontSize: '18px', lineHeight: '21px' }}
        onClick={startGpsTracking}
      >
        <StartMeasurement />
        {label}
      </Typography>
    </Button>
  );
};
