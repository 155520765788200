import { Box, Container, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { HEADER_HEIGHT } from 'shared/constants';

interface Props {
  children?: ReactNode;
}

export const ProjectLayout: FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        height: `calc(100svh - ${HEADER_HEIGHT}px)`,
        width: '100%',
        bgcolor: 'rgba(240, 251, 255, 1)',
      }}
    >
      <Container maxWidth="md" disableGutters sx={{ height: '100%' }}>
        <Stack sx={{ p: '20px 24px', height: '100%' }}>{children}</Stack>
      </Container>
    </Box>
  );
};
