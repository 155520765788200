import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';

interface ModalsState {
  openUnits: boolean;
  openProjectManagement: boolean;
  openLanguage: boolean;
  openNewProject: boolean;
  openAddToHome: boolean;
  openGpsPermissionBlocked: boolean;
  openShareProjectSM: boolean; // in side menu
  openSendByMailProjectSM: boolean; // in side menu
}

const initialState: ModalsState = {
  openUnits: false,
  openLanguage: false,
  openProjectManagement: false,
  openNewProject: false,
  openAddToHome: false,
  openGpsPermissionBlocked: false,
  openShareProjectSM: false,
  openSendByMailProjectSM: false,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleProjectManagement: (state) => {
      state.openProjectManagement = !state.openProjectManagement;
    },
    toggleNewProject: (state) => {
      state.openNewProject = !state.openNewProject;
    },
    closeProjectModals: (state) => {
      state.openNewProject = false;
      state.openProjectManagement = false;
    },
    toggleAddToHome: (state) => {
      state.openAddToHome = !state.openAddToHome;
    },
    showAddToHome: (state) => {
      state.openAddToHome = true;
    },
    toggleGpsPermissionBlocked: (state) => {
      state.openGpsPermissionBlocked = !state.openGpsPermissionBlocked;
    },
  },
  selectors: {
    selectModals: (state) => state,
    selectOpenGpsPermissionBlocked: (state) => state.openGpsPermissionBlocked,
  },
});

export const {
  toggleProjectManagement,
  toggleNewProject,
  closeProjectModals,
  toggleAddToHome,
  showAddToHome,
  toggleGpsPermissionBlocked,
} = modalsSlice.actions;

export const selectModals = () => useAppSelector(modalsSlice.selectors.selectModals);
export const selectOpenGpsPermissionBlocked = () =>
  useAppSelector(modalsSlice.selectors.selectOpenGpsPermissionBlocked);

export const modalsActions = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
