import { Typography, Button, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/ui';

const reloadMessageHeader = 'Dear RAPID Users,';
const reloadMessageOne = "We've just released a new version of RAPID";
const reloadMessageSecond = 'To get the new version, simply click on Reload.';

export const ReloadModal = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const onClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener('newContentAvailable', () => {
      setShow(true);
    });
  }, []);

  return (
    <Modal maxWidth="xs" open={show} title={t('reload_modal_new_version')}>
      <Typography mb={1}>{reloadMessageHeader}</Typography>
      <Typography mb={1}>{reloadMessageOne}</Typography>
      <Typography mb={2}>{reloadMessageSecond}</Typography>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={onClick}>
          {t('reload_modal_reload')}
        </Button>
      </Box>
    </Modal>
  );
};
