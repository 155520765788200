import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, useMap } from 'react-leaflet';
import { LatLng, LatLngLiteral, LeafletEventHandlerFnMap } from 'leaflet';
import { PointModel, Point } from '../../shared/models';
import { useAppDispatch } from '../../shared/hooks';
import {
  dragPoint,
  saveDragPointAction,
  selectCurrentItemId,
  selectIsItemDrawing,
  selectIsPointDrawing,
  selectIsTrackingPointDrawing,
  selectLine,
  selectLines,
  selectPoint,
  selectPointId,
  selectPolygon,
  selectPolygons,
  selectSaveProjectStep,
  setTrackingPoint,
} from '../../shared/slices';
import { checkPointsIsTooClosely, getPointItemIcon } from '../../shared/lib';
import { SavingStep } from 'shared/enums';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { useThrottle } from 'shared/hooks/useThrottle';
import { blueIcon } from 'shared/ui';

interface Props {
  pointInfo: PointModel;
}

export const PointItem: FC<Props> = ({
  pointInfo: {
    geoJson: { id, points, color, size },
    properties: { pointType },
  },
}) => {
  const pointInfo = {
    geoJson: { id, points, color, size },
    properties: { pointType },
  };
  const dispatch = useAppDispatch();
  const markerRef = useRef<any>(null);

  const map = useMap();

  const isPointDrawing = selectIsPointDrawing();
  const selectedPointId = selectPointId();
  const saveProjectStep = selectSaveProjectStep();
  const isDrawing = selectIsItemDrawing();
  const isTrackingPointDrawing = selectIsTrackingPointDrawing();
  const selectedItemId = selectCurrentItemId();

  const lines = selectLines();
  const polygons = selectPolygons();

  const [nearPoint, setNearPoint] = useState<Point | null>(null);
  const [isPointMoving, setIsPointMoving] = useState(false);
  const [totalObjectsPoints, setTotalObjectsPoints] = useState<Point[]>([]);

  const isSelected = selectedPointId === id && saveProjectStep !== SavingStep.SCREEN_SHOOTING;

  useEffect(() => {
    if (markerRef.current) {
      const markerElement = markerRef.current.getElement();
      if (markerElement) {
        // allow to place points near the POI
        markerElement.style.pointerEvents = !selectedItemId || selectedItemId === id ? 'auto' : 'none';
      }
    }
  }, [selectedItemId, id]);

  useEffect(() => {
    if (!isDrawing) {
      const filteredLines = lines.filter((line) => !line.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID));
      const allPoints = [...filteredLines, ...polygons].flatMap((i) => i.geoJson.points);
      setTotalObjectsPoints(allPoints);
    }
  }, [lines, polygons, points, isDrawing]);

  // snap to another object's point
  useEffect(() => {
    if (isSelected && isPointMoving) {
      const selCoords = map.latLngToContainerPoint(points[0].coords);
      let foundNearPoint: Point | null = null;

      for (const p of totalObjectsPoints) {
        const pointCoords = map.latLngToContainerPoint(p.coords);
        const isNear = checkPointsIsTooClosely(selCoords, pointCoords);

        if (isNear) {
          foundNearPoint = p;
          break;
        }
      }

      setNearPoint(foundNearPoint);
    }
  }, [pointInfo]);

  const pointCoords = points[0]?.coords ?? { lat: 0, lng: 0 };

  const icon = useMemo(() => {
    return getPointItemIcon(
      pointType,
      color,
      size,
      isSelected,
      isPointDrawing,
      saveProjectStep === SavingStep.SCREEN_SHOOTING
    );
  }, [pointType, color, size, isSelected, isPointDrawing, saveProjectStep]);

  const onSelectPoint = () => {
    if (isTrackingPointDrawing) {
      dispatch(setTrackingPoint({ lat: pointCoords.lat, lng: pointCoords.lng } as LatLng));
      return;
    }
    if (isDrawing) return;
    dispatch(selectPolygon(null));
    dispatch(selectLine(null));
    dispatch(selectPoint(id === selectedPointId ? null : id));
  };

  const onDragEnd = () => {
    if (nearPoint) {
      dispatch(dragPoint(nearPoint.coords));
    }
    dispatch(saveDragPointAction(nearPoint ? nearPoint.coords : markerRef.current.getLatLng()));
    setIsPointMoving(false);
    setNearPoint(null);
  };

  const throttledUpdatePos = useThrottle((newPos: LatLngLiteral) => {
    dispatch(dragPoint(newPos));
    setNearPoint(null);
  }, 30);

  const onChangePosition = (newPos: LatLngLiteral) => {
    throttledUpdatePos(newPos);
  };

  const pointEventHandlers: LeafletEventHandlerFnMap = {
    drag() {
      const marker = markerRef.current;
      if (marker != null) {
        onChangePosition(marker.getLatLng());
      }
    },
    click() {
      onSelectPoint();
    },
    dragend() {
      onDragEnd();
    },
    dragstart() {
      setIsPointMoving(true);
    },
  };

  const isHideWhenScreenShooting = selectedPointId !== id && saveProjectStep === SavingStep.SCREEN_SHOOTING;

  return (
    <>
      {points.length > 0 && !isHideWhenScreenShooting && (
        <>
          <Marker
            icon={icon}
            draggable={selectedPointId === id && isPointDrawing}
            eventHandlers={pointEventHandlers}
            position={pointCoords}
            ref={markerRef}
          />
          {selectedPointId === id && nearPoint && <Marker icon={blueIcon} position={pointCoords} />}
        </>
      )}
    </>
  );
};
