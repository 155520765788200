import CheckIcon from '@mui/icons-material/Check';
import { List, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MUIWrapperContext } from '../../app/providers/MuiThemeProvider';
import { useAppNavigator, useI18nUtils } from '../../shared/hooks';
import { selectLanguages } from '../../shared/slices';
import { Modal } from '../../shared/ui';
import { useLocation } from 'react-router-dom';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { getCurrentDirection } = useI18nUtils();
  const muiUtils = useContext(MUIWrapperContext);
  const { languages } = selectLanguages();

  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  const onClose = () => navigate(pathname, { state: { languageModal: false }, replace: true });

  const handleItemClick = async (languageCode: string) => {
    await i18n.changeLanguage(languageCode);

    const direction = getCurrentDirection();

    muiUtils.changeDirection(direction);

    onClose();
  };

  return (
    <Modal
      maxWidth="xs"
      margin={1}
      title={t('language_selector_title')}
      open={!!state?.languageModal}
      onClose={onClose}
    >
      <List>
        {languages.map((lang) => (
          <ListItemButton key={lang.code} onClick={() => handleItemClick(lang.code)}>
            <ListItemText primary={lang.name} />
            {/*{isLanguageChanging && (*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <CircularProgress size={24} />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*)}*/}
            {(lang.code === i18n.language || lang.code === i18n.language.split('-')[0]) && (
              <ListItemSecondaryAction>
                <CheckIcon color="primary" />
              </ListItemSecondaryAction>
            )}
          </ListItemButton>
        ))}
      </List>
    </Modal>
  );
};
