import { Image } from '../../../shared/models';
import {
  selectFormState,
  selectLineId,
  selectMap,
  selectPointId,
  selectPolygonId,
  setLineInformation,
  setOpenedImageId,
  setPointInformation,
  setPolygonInformation,
  toggleImagesView,
} from '../../../shared/slices';
import { v4 as makeId } from 'uuid';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useToggle } from '../../../shared/hooks';
import { projectApi } from '../../../shared/api';
import { axiosInstance } from 'shared/axios';
import imageCompression from 'browser-image-compression';
import { AxiosRequestConfig } from 'axios';


const emptyImage: Image = { id: '', url: '', title: '' };

export const useImageViewerTools = () => {
  const dispatch = useAppDispatch();

  const { isImagesView } = selectMap();
  const selectedPolygonId = selectPolygonId();
  const selectedLineId = selectLineId();
  const selectedPointId = selectPointId();

  const { polygonInformation, lineInformation, pointInformation, openedImageId } = selectFormState();

  const { open: isDeleting, toggle: toggleDeleting } = useToggle(false);
  const { open: isEditing, toggle: toggleEditing } = useToggle(false);

  const [image, setImage] = useState<Image>(emptyImage);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseView = () => dispatch(toggleImagesView());

  const getItemImages = () => {
    let images: Image[] = [];

    if (selectedPolygonId) images = [...polygonInformation.images];
    if (selectedLineId) images = [...lineInformation.images];
    if (selectedPointId) images = [...pointInformation.images];
    return images;
  };

  const onChangeImage = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    // Options for image compression
    const options = {
      maxSizeMB: 1,          // Maximum size in MB
      maxWidthOrHeight: 800, // Maximum width or height in pixels
      useWebWorker: true,     // Use web worker for better performance
    };

    try {
      // Compress the image file
      const compressedFile = await imageCompression(file, options);

      const fileReader = new FileReader();

      fileReader.addEventListener('load', async () => {
        const file = fileReader.result as string;

        setIsLoading(true);

        const url = await projectApi.uploadImage(file);

        if (url) {
          setImage((prev) => ({ ...prev, url }) as Image);
          setIsLoading(false);

          if (openedImageId) {
            if (selectedPolygonId) {
              const images = polygonInformation.images.map((img) =>
                img.id === openedImageId ? { ...img, url } : img,
              );
              dispatch(setPolygonInformation({ images }));
            }

            if (selectedLineId) {
              const images = lineInformation.images.map((img) =>
                img.id === openedImageId ? { ...img, url } : img,
              );
              dispatch(setLineInformation({ images }));
            }

            if (selectedPointId) {
              const images = pointInformation.images.map((img) =>
                img.id === openedImageId ? { ...img, url } : img,
              );
              dispatch(setPointInformation({ images }));
            }
          } else {
            const image = { id: makeId(), url, title: '' };

            if (selectedPolygonId) {
              dispatch(setPolygonInformation({ images: [...polygonInformation.images, image] }));
            }
            if (selectedLineId) {
              dispatch(setLineInformation({ images: [...lineInformation.images, image] }));
            }
            if (selectedPointId) {
              dispatch(setPointInformation({ images: [...pointInformation.images, image] }));
            }
          }
        }
      });

      // Read the compressed file as Data URL
      fileReader.readAsDataURL(compressedFile as Blob);
    } catch (error) {
      console.error('Error during image compression', error);
      setIsLoading(false); // Ensure loading state is reset in case of error
    }
  };

  const showOtherImage = (moveTo: 'next' | 'prev') => {
    const images = getItemImages();
    const imgIndex = images.findIndex((img) => img.id === openedImageId);

    let otherImgIndex = moveTo === 'next' ? imgIndex + 1 : imgIndex - 1;

    if (otherImgIndex < 0) otherImgIndex = images.length - 1;
    if (otherImgIndex > images.length - 1) otherImgIndex = 0;

    const otherImgId = images[otherImgIndex].id;

    dispatch(setOpenedImageId(otherImgId));
    isEditing && toggleEditing();
  };

  const onDeleteImg = () => {
    const oldImages = getItemImages();
    const images = oldImages.filter((img) => img.id !== openedImageId);

    selectedPolygonId && dispatch(setPolygonInformation({ images }));
    selectedLineId && dispatch(setLineInformation({ images }));
    selectedPointId && dispatch(setPointInformation({ images }));
    toggleDeleting();
    isEditing && toggleEditing();
    handleCloseView();
  };

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    setImage((prev) => ({ ...prev, title }) as Image);

    if (selectedPolygonId) {
      const images = polygonInformation.images.map((img) => (img.id === openedImageId ? { ...img, title } : img));
      dispatch(setPolygonInformation({ images }));
    }

    if (selectedLineId) {
      const images = lineInformation.images.map((img) => (img.id === openedImageId ? { ...img, title } : img));
      dispatch(setLineInformation({ images }));
    }

    if (selectedPointId) {
      const images = pointInformation.images.map((img) => (img.id === openedImageId ? { ...img, title } : img));
      dispatch(setPointInformation({ images }));
    }
  };

  const handleToggleDeleting = () => {
    const images = getItemImages();
    const image = images.find((img) => img.id === openedImageId) as Image;
    if (!image?.isSnapShot) toggleDeleting();
  };

  const onDownLoadImage = async () => {
    try {
      const url = image.url.replace(process.env.REACT_APP_IMG_STORAGE as string, '/img-download');

      const options: AxiosRequestConfig = { responseType: 'blob' };

      const res = await axiosInstance.get(url, options);
      const blob = new Blob([res.data]);

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'RAPID_IMAGE.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const images = getItemImages();
    const image = images.find((img) => img.id === openedImageId);
    image ? setImage(image) : setImage(emptyImage);
    isEditing && toggleEditing();
  }, [isImagesView, openedImageId]);

  return {
    isEditing,
    isImagesView,
    handleCloseView,
    toggleEditing,
    toggleDeleting,
    isDeleting,
    image,
    isLoading,
    onChangeImage,
    handleToggleDeleting,
    showOtherImage,
    onChangeTitle,
    onDeleteImg,
    onDownLoadImage,
  };
};
