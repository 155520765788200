import { BaseLayout } from 'shared/ui';
import { Login } from 'widgets';

export const LoginPage = () => {
  return (
    <BaseLayout>
      <Login />
    </BaseLayout>
  );
};
