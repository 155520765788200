import { Button, darken, Dialog, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  type: 'manually' | 'not-supported';
}

export const DrawFieldDelineationPopup: FC<Props> = ({ open, onClose, type }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          overflow: 'visible',
          display: 'flex',
          padding: '48px 24px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          width: '84.2666666667svw',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
      hideBackdrop
    >
      {type === 'manually' && (
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('field_delineation_not_recognized')}
        </Typography>
      )}
      {type === 'not-supported' && (
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('field_delineation_not_supported')}
        </Typography>
      )}
      {type === 'manually' && (
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontSize: '18px',
            fontStyle: 'normal',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('field_delineation_create_manually')}
        </Typography>
      )}
      {type === 'not-supported' && (
        <Typography
          sx={{
            color: '#242731',
            textAlign: 'center',
            fontSize: '18px',
            fontStyle: 'normal',
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {t('field_delineation_please_manually')}
        </Typography>
      )}

      <Button
        onClick={onClose}
        sx={{
          display: 'flex',
          width: '126px',
          padding: '8px 16px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          background: '#1D8742',
          color: '#FFF',
          textAlign: 'center',
          fontSize: '18px',
          lineHeight: '130%',
          letterSpacing: '0.18px',
          '&:hover': {
            backgroundColor: darken('#1D8742', 0.1),
            color: 'white',
          },
        }}
      >
        {t('ok')}
      </Button>
      {/* {type === 'not-supported' && (
        <Typography
          sx={{
            color: '#0038FF',
            textAlign: 'center',
            fontSize: '15px',
            fontStyle: 'italic',
            lineHeight: '130%',
            letterSpacing: '0.15px',
            textDecoration: 'underline',
          }}
        >
          {t('field_delineation_request')}
        </Typography>
      )} */}
    </Dialog>
  );
};
