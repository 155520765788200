import { useNavigate } from 'react-router-dom';

export const useAppNavigator = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => navigate('/common/login', { replace: true });
  const navigateToProject= (id: number) => navigate(`/main/home/${id}`);
  const navigateToNewProject = () => navigate('/main/new-project');
  const navigateToProjectManagement = () => {
    navigate('/main/project-management');
  };

  const navigateBack = () => navigate(-1);

  return {
    navigate,
    navigateBack,
    navigateToProjectManagement,
    navigateToNewProject,
    navigateToLogin,
    navigateToProject,
  };
};
