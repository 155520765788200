import { blue, cyan, red } from '@mui/material/colors';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { LatLngLiteral } from 'leaflet';
import { v4 as makeId } from 'uuid';
import { NEW_POINT_ID, POINT } from '../../constants';
import { PointIconType } from '../../enums';
import { useAppSelector } from '../../hooks';
import { Action, ACTION_TYPE, Image, Point, PointInformation, PointModel, PointStyles } from '../../models';
import { localStorageService } from '../../services';
import {
  deleteItemScreenshot,
  editItem,
  saveItem,
  setItemScreenshot,
  stopItemDrawing,
  unselectItems,
} from '../mapState';
import { setProject } from '../projectData';
import { Nullable } from 'shared';

const newPointTemplate: PointModel = {
  geoJson: { id: NEW_POINT_ID, points: [], color: blue[500], size: 1 },
  images: [],
  information: { description: '', name: '', isIrrigationProperties: true },
  properties: {
    pointType: PointIconType.WATER_SOURCE,
    pointDiameterUnit: localStorageService.units?.PipeDiameter || 'mm',
    pointPressureUnit: localStorageService.units?.Pressure || 'm',
    pointFlowRateUnit: localStorageService.units?.TotalFlow || 'm³/h',
    pointElevationUnit: localStorageService.units?.Length || 'm',
    pointDiameter: 0,
    pointPressure: 0,
    pointFlowRate: 0,
    pointElevation: 0,
    pointWaterType: 'drinkingWater',
    isFiltration: false,
    isExistingFiltration: false,
    pointFiltrationType: 'gravel',
    pointFiltrationMode: 'automatic',
    isPumping: false,
    isExistingPump: false,
    pointPumpType: 'turbine',
    pointPumpValue: 0,
    pointPumpCapacity: 0,
    pointPumpPressure: 0,
    isFertigation: false,
    isExistingFertigation: false,
    isElectricFertigation: false,
    pointElectricFertigationType: 'boosterPump',
    pointWaterMotivatedFertigationType: 'venturi',
    isOperationAndControl: false,
    isExistingOperationAndControl: false,
    operationAndControlMode: 'manual',
    operationAndControlCommunicationType: 'radio&RTU',
    pointCopySettingsFromId: null,
  },
};

interface PointState {
  points: PointModel[];
  isPointDrawing: boolean;
  selectedPointId: Nullable<string>;
  initialPoints: Point[];
  initialStyles: PointStyles;
  pointActionHistory: Action[];
  newPoint: PointModel;
  currentElevationInMeters: number;
}

const initialState: PointState = {
  points: [],
  isPointDrawing: false,
  selectedPointId: null,
  initialPoints: [],
  initialStyles: { color: blue[500], size: 1 },
  pointActionHistory: [],
  newPoint: newPointTemplate,
  currentElevationInMeters: 0,
};

export const pointSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
    setPoints(state, action: PayloadAction<PointModel[]>) {
      state.points = action.payload;
    },
    startPointDrawing(state) {
      state.isPointDrawing = true;
      state.selectedPointId = NEW_POINT_ID;
    },
    savePointInfo(state, action: PayloadAction<PointInformation>) {
      const {
        pointName: name,
        pointDescription: description,
        isIrrigationProperties,
        images,
        ...properties
      } = action.payload;
      if (state.selectedPointId === NEW_POINT_ID) {
        const newId = makeId();

        state.points = [
          ...state.points,
          {
            geoJson: { ...state.newPoint.geoJson, id: newId },
            information: { name, description, isIrrigationProperties },
            properties,
            images,
          },
        ];

        state.newPoint.geoJson.points = [];
        state.selectedPointId = newId;
      } else {
        const point = state.points.find((item) => item.geoJson.id === state.selectedPointId) as PointModel;

        point.information = { name, description, isIrrigationProperties };
        point.properties = properties;
        point.images = images;
      }
    },
    cancelPointDrawing(state) {
      if (state.selectedPointId === NEW_POINT_ID) {
        state.newPoint.geoJson.points = [];
      } else {
        const point = state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel;

        if (point) {
          point.geoJson.points = state.initialPoints;
          const { color, size } = state.initialStyles;

          point.geoJson = { ...point.geoJson, color, size };
        }
      }
    },
    selectPoint(state, action: PayloadAction<Nullable<string>>) {
      const id = action.payload;

      if (!state.isPointDrawing) {
        state.selectedPointId = state.selectedPointId === id ? null : id;
      }
    },
    setNewPointIcon(state, action: PayloadAction<PointIconType>) {
      const iconType = action.payload;
      if (iconType === PointIconType.WATER_SOURCE) state.newPoint.geoJson.color = blue[500];
      if (iconType === PointIconType.VALVE) state.newPoint.geoJson.color = cyan[500];
      if (iconType === PointIconType.OBSTACLE) state.newPoint.geoJson.color = red[500];

      state.newPoint.properties.pointType = iconType;
    },
    addSourcePoint(state, action: PayloadAction<Point>) {
      const point = action.payload;

      if (state.newPoint.geoJson.points.length > 0) return;

      state.newPoint.geoJson.points = [point];
    },
    dragPoint(state, action: PayloadAction<LatLngLiteral>) {
      const newPos = action.payload;

      const point =
        state.selectedPointId === NEW_POINT_ID
          ? current(state.newPoint)
          : (state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel);

      // const moveAction = { typeName: ACTION_TYPE.MOVE, point: point.geoJson.points[0] };
      // state.pointActionHistory.push(moveAction);

      if (state.selectedPointId === NEW_POINT_ID) {
        state.newPoint.geoJson.points = [{ id: NEW_POINT_ID, coords: newPos }];
      } else {
        point.geoJson.points = [{ id: (state.selectedPointId as string).toString(), coords: newPos }];
      }
    },
    saveDragPointAction(state, action: PayloadAction<LatLngLiteral>) {
      const id = state.selectedPointId as string;
      const coords = action.payload;
      const point: Point = { id, coords };

      const moveAction = { typeName: ACTION_TYPE.MOVE, point };
      state.pointActionHistory.push(moveAction);
    },
    deletePoint(state) {
      state.points = state.points.filter((p) => p.geoJson.id !== state.selectedPointId);
      state.selectedPointId = null;
    },
    undoPointAction(state) {
      if (!state.pointActionHistory.length) return;

      const { typeName, point } = state.pointActionHistory.pop() as Action;

      switch (typeName) {
        case ACTION_TYPE.MOVE:
          {
            const currentPoint =
              state.selectedPointId === NEW_POINT_ID
                ? state.newPoint
                : (state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel);

            currentPoint.geoJson.points = [point];
          }
          break;
      }
    },
    setPointStyles(state, action: PayloadAction<Partial<PointStyles>>) {
      if (state.selectedPointId === NEW_POINT_ID) {
        state.newPoint.geoJson = { ...state.newPoint.geoJson, ...action.payload };
        return;
      }

      const point = state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel;
      point.geoJson = { ...point.geoJson, ...action.payload };
    },
    setPointScreenshot(state, action: PayloadAction<Image>) {
      const screenShot = action.payload;

      const point = state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel;
      if (point) {
        const isHaveScreenShot = point?.images.find((img) => img.isSnapShot);

        if (isHaveScreenShot) {
          point.images = point.images.map((img) => (img.isSnapShot ? screenShot : img));
        } else {
          point.images = [screenShot, ...point.images];
        }
      }
    },
    setCurrentElevation(state, action: PayloadAction<number>) {
      state.currentElevationInMeters = action.payload;
    },
  },
  selectors: {
    selectPointsState: (state) => state,
    selectPoints: (state) => state.points,
    selectPointId: (state) => state.selectedPointId,
    selectPointById: (state, id: string) => state.points.find((point) => point.geoJson.id === id),
    selectNewPoint: (state) => state.newPoint,
    selectCurrentElevationInMeters: (state) => state.currentElevationInMeters,
    selectIsPointDrawing: (state) => state.isPointDrawing,
    selectPointActionHistory: (state) => state.pointActionHistory,
  },
  extraReducers: (builder) => {
    builder.addCase(setProject, () => {
      return initialState;
    });
    builder.addCase(editItem, (state) => {
      if (!state.selectedPointId) return;
      state.isPointDrawing = true;

      const point = state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel;

      if (point) {
        const { points, color, size } = point.geoJson;
        state.initialPoints = points;
        state.initialStyles = { color, size };
      }
    });
    builder.addCase(saveItem, (state, action) => {
      if (action.payload.itemType !== 'point') return;

      const {
        pointName: name,
        pointDescription: description,
        isIrrigationProperties,
        images,
        ...properties
      } = action.payload.information as PointInformation;
      if (action.payload.itemId === NEW_POINT_ID) {
        const newId = makeId();

        state.points.push({
          geoJson: { ...state.newPoint.geoJson, id: newId },
          information: { name, description, isIrrigationProperties },
          properties,
          images,
        });

        state.newPoint.geoJson.points = [];
        state.selectedPointId = newId;
      } else {
        const point = state.points.find((item) => item.geoJson.id === action.payload.itemId) as PointModel;

        point.information = { name, description, isIrrigationProperties };
        point.properties = properties;
        point.images = images;
      }
    });
    builder.addCase(unselectItems, (state) => {
      state.selectedPointId = null;
    });
    builder.addCase(stopItemDrawing, (state) => {
      state.isPointDrawing = false;
      state.selectedPointId = null;
      state.pointActionHistory = [];
      state.initialPoints = [];
    });
    builder.addCase(deleteItemScreenshot, (state) => {
      const point = state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel;

      if (!point) return;

      point.images = point.images.filter((img) => !img.isSnapShot);
    });
    builder.addCase(setItemScreenshot, (state, action) => {
      const { images, isNewItem, item } = action.payload;

      if (!isNewItem && item === POINT) {
        const screenShot = images.pop();

        const point = state.points.find((p) => p.geoJson.id === state.selectedPointId) as PointModel;
        if (point && screenShot) {
          const isHaveScreenShot = point?.images.find((img) => img.isSnapShot);

          if (isHaveScreenShot) {
            point.images = point.images.map((img) => (img.isSnapShot ? screenShot : img));
          } else {
            point.images = [screenShot, ...point.images];
          }
        }
      }
    });
  },
});

export const {
  setPoints,
  startPointDrawing,
  cancelPointDrawing,
  setNewPointIcon,
  savePointInfo,
  addSourcePoint,
  deletePoint,
  dragPoint,
  saveDragPointAction,
  undoPointAction,
  selectPoint,
  setPointStyles,
  setPointScreenshot,
  setCurrentElevation,
} = pointSlice.actions;

export const selectPointActionHistory = () => useAppSelector(pointSlice.selectors.selectPointActionHistory);
export const selectPointsState = () => useAppSelector(pointSlice.selectors.selectPointsState);
export const selectPoints = () => useAppSelector(pointSlice.selectors.selectPoints);
export const selectPointId = () => useAppSelector(pointSlice.selectors.selectPointId);
export const selectCurrentElevationInMeters = () => useAppSelector(pointSlice.selectors.selectCurrentElevationInMeters);
export const selectNewPoint = () => useAppSelector(pointSlice.selectors.selectNewPoint);
export const selectIsPointDrawing = () => useAppSelector(pointSlice.selectors.selectIsPointDrawing);
export const selectPointById = (id: string) =>
  useAppSelector((state) => {
    return id === NEW_POINT_ID ? newPointTemplate : pointSlice.selectors.selectPointById(state, id);
  });

export const pointReducer = pointSlice.reducer;
