import { Collapse, MenuItem } from '@mui/material';
import { ItemImagesForm, LinePipeSectionProperties } from 'features';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/constants';
import { AdditionalSelect, CheckBox, FreeSoloInput, ItemInfoContainer, PolygonInput, Text } from 'shared/ui';
import { useMapItemActions } from 'widgets/MapItemHeader/hooks';
import { useManageLineInfo } from './hooks';

export const LineInfo: FC = () => {
  const { t } = useTranslation();

  const { onSaveItem } = useMapItemActions();

  const {
    isLineInfo,
    lineInformation,
    line,
    onChangeInformation,
    onLineNameFocus,
    onToggleIsIrrigationProps,
    copySettingsFromOptions,
    onChangeCopySettingsFrom,
    onCopySettingsFromBlur,
    currentLineCopySettingsFrom,
  } = useManageLineInfo();

  return (
    <>
      {isLineInfo && (
        <ItemInfoContainer onSaveItem={onSaveItem}>
          <Text>{t('data_tab_information')}</Text>

          <PolygonInput
            label={t('forms_name')}
            placeholder={t('forms_name')}
            value={lineInformation.lineName}
            onChange={onChangeInformation}
            onFocus={onLineNameFocus}
            helperText={t('validation_errors_null')}
            // error={isNoValidName}
            name="lineName"
          />
          <PolygonInput
            label={t('forms_total_length')}
            placeholder="000"
            name="lineTotalLength"
            type="number"
            value={lineInformation.lineTotalLength}
            helperText={t('validation_errors_null')}
            additionalInput={
              <AdditionalSelect
                name="lineTotalLengthUnit"
                value={lineInformation.lineTotalLengthUnit}
                onChange={(e) => onChangeInformation(e as React.ChangeEvent<HTMLInputElement>)}
              >
                {UNITS.Length.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </AdditionalSelect>
            }
          />
          <PolygonInput
            label={t('forms_description')}
            placeholder={t('forms_description')}
            name="lineDescription"
            value={lineInformation.lineDescription}
            onChange={onChangeInformation}
            multiline
          />

          <CheckBox
            checked={lineInformation.isIrrigationProperties}
            onChange={onToggleIsIrrigationProps}
            label={t('data_tab_irrigation')}
            sx={{ mt: 2 }}
          />

          <Collapse in={lineInformation.isIrrigationProperties}>
            <div>
              {(copySettingsFromOptions.length > 1 ||
                (copySettingsFromOptions.length === 1 && copySettingsFromOptions[0].id !== line.geoJson.id)) && (
                <FreeSoloInput
                  options={copySettingsFromOptions}
                  name="lineCopySettingsFrom"
                  value={currentLineCopySettingsFrom ?? ''}
                  onBlur={onCopySettingsFromBlur}
                  onChange={onChangeCopySettingsFrom}
                  placeholder={t('forms_line_name')}
                  label={t('forms_copy_settings_from')}
                />
              )}

              <LinePipeSectionProperties />
            </div>
          </Collapse>

          <Text sx={{ mt: 3 }}>{t('forms_images')}</Text>
          <ItemImagesForm images={lineInformation.images} />
        </ItemInfoContainer>
      )}
    </>
  );
};
