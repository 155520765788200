import { Collapse, MenuItem } from '@mui/material';
import { ItemImagesForm, PolygonIrrigationProperties } from 'features';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/constants';
import { AdditionalSelect, CheckBox, ItemInfoContainer, PolygonInput, Text } from 'shared/ui';
import { useManagePolygonInfo } from './hooks';
import { selectIsRowDirectionForm } from 'shared/slices';

export const PolygonInfo: FC = () => {
  const { t } = useTranslation();

  const {
    isPolygonInfo,
    polygonInformation,
    handleSaveItem,
    onChangeInformation,
    onPolygonNameFocus,
    onToggleIsIrrigationProps,
    onChangeCopySettingsFrom,
    onChangeCropType,
    onChangeDailyConsumption,
    onChangePlantingDate,
    onChangeUnit,
    onChangePolygonSoilType,
    onToggleSelectProduct,
    onTogglePolygonSoilType,
    saveEmitter,
    saveLateral,
    currentPolygonCopySettingsFrom,
  } = useManagePolygonInfo();

  const isRowDirectionForm = selectIsRowDirectionForm();

  return (
    <>
      {isPolygonInfo && (
        <ItemInfoContainer onSaveItem={handleSaveItem} sx={{ display: isRowDirectionForm ? 'none' : 'block' }}>
          <Text>{t('data_tab_information')}</Text>

          <PolygonInput
            label={t('forms_name')}
            placeholder={t('forms_name')}
            value={polygonInformation.polygonName}
            onChange={onChangeInformation}
            onFocus={onPolygonNameFocus}
            helperText={t('validation_errors_null')}
            // error={isPolygonNameError}
            name="polygonName"
          />
          <PolygonInput
            label={t('forms_field_size')}
            placeholder="000"
            name="polygonFieldSize"
            type="number"
            value={polygonInformation.polygonFieldSize}
            helperText={t('validation_errors_null')}
            additionalInput={
              <AdditionalSelect
                name="polygonFieldSizeUnit"
                value={polygonInformation.polygonFieldSizeUnit}
                onChange={(e) => onChangeInformation(e as React.ChangeEvent<HTMLInputElement>)}
              >
                {UNITS.Area.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </AdditionalSelect>
            }
          />
          <PolygonInput
            label={t('forms_description')}
            placeholder={t('forms_description')}
            name="polygonDescription"
            value={polygonInformation.polygonDescription}
            onChange={onChangeInformation}
            multiline
          />

          <CheckBox
            checked={polygonInformation.isIrrigationProperties}
            onChange={onToggleIsIrrigationProps}
            label={t('data_tab_irrigation')}
            sx={{ mt: 2 }}
          />

          <Collapse in={polygonInformation.isIrrigationProperties}>
            <div>
              <PolygonIrrigationProperties
                value={polygonInformation}
                onChange={onChangeInformation}
                onChangeUnit={onChangeUnit}
                onChangePlantingDate={onChangePlantingDate}
                onChangeCopySettingsFrom={onChangeCopySettingsFrom}
                onChangeCropType={onChangeCropType}
                onChangeDailyConsumption={onChangeDailyConsumption}
                onChangePolygonSoilType={onChangePolygonSoilType}
                onToggleSelectProduct={onToggleSelectProduct}
                onTogglePolygonSoilType={onTogglePolygonSoilType}
                saveEmitter={saveEmitter}
                saveLateral={saveLateral}
                copySettingsFromObject={currentPolygonCopySettingsFrom}
                />
            </div>
          </Collapse>

          <Text sx={{ mt: 3 }}>{t('forms_images')}</Text>
          <ItemImagesForm images={polygonInformation.images} />
        </ItemInfoContainer>
      )}
    </>
  );
};
