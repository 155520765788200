import { axiosBase } from '../axios';
import { BaseResponse, UsersResponse } from '../models';

export const userApi = {
  async getUsers() {
    return axiosBase.get<BaseResponse<UsersResponse[]>>('/api/Users');
  },
  async getUsersByOrganizationId(organizationId: number) {
    return axiosBase.get<BaseResponse<UsersResponse[]>>(`/api/Users/${organizationId}`);
  },
};
