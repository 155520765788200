import { useMediaQuery } from '@mui/material';
import React from 'react';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../shared/ui';
import { useManageUnits } from './hooks';
import { useLocation } from 'react-router-dom';

export const Units = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const isMobile = useMediaQuery('(max-width:550px)');

  const {
    convertUnitValues,
    convertedValues,
    handleConvertUnitChange,
    handleUnitChange,
    handleValuesChange,
    onMetricDefaultsClick,
    onSaveClick,
    onUSDefaultsClick,
    onClose,
    unitValues,
    values,
  } = useManageUnits();

  return (
    <Modal margin={1} title={t('units_title')} open={!!state?.unitsModal} onClose={onClose}>
      {isMobile ? (
        <MobileView
          convertUnitValues={convertUnitValues}
          convertedValues={convertedValues}
          handleConvertUnitChange={handleConvertUnitChange}
          handleUnitChange={handleUnitChange}
          handleValuesChange={handleValuesChange}
          onMetricDefaultsClick={onMetricDefaultsClick}
          onSaveClick={onSaveClick}
          onUSDefaultsClick={onUSDefaultsClick}
          toggle={onClose}
          unitValues={unitValues}
          values={values}
        />
      ) : (
        <DesktopView
          convertUnitValues={convertUnitValues}
          convertedValues={convertedValues}
          handleConvertUnitChange={handleConvertUnitChange}
          handleUnitChange={handleUnitChange}
          handleValuesChange={handleValuesChange}
          onMetricDefaultsClick={onMetricDefaultsClick}
          onSaveClick={onSaveClick}
          onUSDefaultsClick={onUSDefaultsClick}
          toggle={onClose}
          unitValues={unitValues}
          values={values}
        />
      )}
    </Modal>
  );
};
