import { Action, combineSlices, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  appSlice,
  authSlice,
  emittersSlice,
  formStateSlice,
  gpsTrackingSlice,
  languageSlice,
  lateralsSlice,
  lineSlice,
  mapSlice,
  masterGroupsSlice,
  modalsSlice,
  pipesSlice,
  pointSlice,
  polygonSlice,
  projectSlice,
  unitsSlice,
} from 'shared/slices';
import { itemRelationSlice } from '../../shared/slices/itemRelation';

export const rootReducer = combineSlices(
  authSlice,
  pointSlice,
  polygonSlice,
  unitsSlice,
  languageSlice,
  mapSlice,
  lateralsSlice,
  emittersSlice,
  modalsSlice,
  projectSlice,
  formStateSlice,
  pipesSlice,
  masterGroupsSlice,
  lineSlice,
  appSlice,
  gpsTrackingSlice,
  itemRelationSlice,
);

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

const store = makeStore();

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
