import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ProjectsFilterIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M7.50307 4.26681C8.07839 3.40224 7.93382 2.2467 7.16319 1.55047C6.39256 0.85424 5.22822 0.827383 4.42627 1.48727C3.62433 2.14704 3.4265 3.2947 3.96125 4.18498C4.49599 5.07527 5.60204 5.43973 6.56136 5.04167C6.94467 4.88178 7.27222 4.61218 7.50307 4.26681ZM7.50307 16.0554C8.07839 15.1907 7.93382 14.0352 7.16319 13.3389C6.39256 12.6427 5.22822 12.6158 4.42627 13.2757C3.62433 13.9356 3.4265 15.0833 3.96125 15.9736C4.49599 16.8638 5.60204 17.2282 6.56136 16.8302C6.94467 16.6702 7.27222 16.4008 7.50307 16.0554ZM10.4972 10.1605C9.92193 9.29595 10.0665 8.14041 10.8371 7.44418C11.6078 6.74796 12.772 6.7211 13.5739 7.38087C14.376 8.04075 14.5738 9.18841 14.039 10.0787C13.5042 10.969 12.3982 11.3334 11.439 10.9354C11.0556 10.7755 10.728 10.5059 10.4972 10.1605Z"
      stroke="#1D8742"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.85714 3.09619H17M3.65943 3.09619H1M7.85714 14.8858H17M3.65943 14.8858H1M10.1429 8.99105H1M14.3406 8.99105H17"
      stroke="#1D8742"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
