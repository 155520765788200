import { Stack, StackProps } from '@mui/material';
import { FC } from 'react';

export const Row: FC<StackProps> = ({ children, direction, alignItems, justifyContent, ...props }) => {
  return (
    <Stack
      direction={direction ?? 'row'}
      alignItems={alignItems ?? 'center'}
      justifyContent={justifyContent ?? 'space-between'}
      {...props}
    >
      {children}
    </Stack>
  );
};
