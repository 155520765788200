import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Language, LanguageResponse } from '../../models';
import { useAppSelector } from '../../hooks';

export const getLanguagesThunk = createAsyncThunk('getLanguages', async (_, thunkAPI) => {
  try {
    const params = new URLSearchParams();

    params.set('api_token', process.env.REACT_APP_POEDITOR_API_KEY as string);
    params.set('id', (process.env.REACT_APP_POEDITOR_PROJECT_ID as string).toString());

    const apiResponse = await axios.post<LanguageResponse>('/poeditor/languages/list', params.toString());
    const { response } = apiResponse.data;
    if (response.status === 'fail') {
      return thunkAPI.rejectWithValue(`poedit api responsed with code: ${response.code}, message: ${response.message}`);
    }
    return apiResponse.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

interface LanguageState {
  loading: boolean;
  languages: Language[];
}

const initialState: LanguageState = {
  loading: false,
  languages: [],
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  selectors: {
    selectLanguages: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLanguagesThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getLanguagesThunk.fulfilled.type, (state, action: PayloadAction<LanguageResponse>) => {
        state.languages = action.payload.result.languages;
        state.loading = false;
      })
      .addCase(getLanguagesThunk.rejected.type, (state) => {
        state.loading = false;
      });
  },
});

// export const {} = languageSlice.actions;

export const selectLanguages = () => useAppSelector(languageSlice.selectors.selectLanguages);

export const languageReducer = languageSlice.reducer;
