import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ThreeDotsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12 10.5C10.9 10.5 10 11.4 10 12.5C10 13.6 10.9 14.5 12 14.5C13.1 14.5 14 13.6 14 12.5C14 11.4 13.1 10.5 12 10.5ZM12 7.5C13.1 7.5 14 6.6 14 5.5C14 4.4 13.1 3.5 12 3.5C10.9 3.5 10 4.4 10 5.5C10 6.6 10.9 7.5 12 7.5ZM12 17.5C10.9 17.5 10 18.4 10 19.5C10 20.6 10.9 21.5 12 21.5C13.1 21.5 14 20.6 14 19.5C14 18.4 13.1 17.5 12 17.5Z"
      fill="#1D8742"
    />
  </SvgIcon>
);
