import { Collapse, Grid, MenuItem } from '@mui/material';
import { ItemImagesForm, PointIrrigationProperties } from 'features';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/constants';
import { AdditionalSelect, CheckBox, ItemInfoContainer, PolygonInput, Select, Text } from 'shared/ui';
import { useMapItemActions } from 'widgets/MapItemHeader/hooks';
import { useManagePointInfo } from './hooks';

export const PointInfo: FC = () => {
  const {
    isPointInfo,
    pointInformation,
    point,
    onChangeInformation,
    onPointNameFocus,
    TYPES,
    onToggleIsIrrigationProps,
    onChangeUnit,
    copySettingsFromOptions,
    onChangeCopySettingsFrom,
    currentPointCopySettingsFrom,
  } = useManagePointInfo();

  const { t } = useTranslation();

  const { onSaveItem } = useMapItemActions();

  return (
    <>
      {isPointInfo && (
        <ItemInfoContainer onSaveItem={onSaveItem}>
          <Text>{t('data_tab_information')}</Text>

          <PolygonInput
            label={t('forms_name')}
            placeholder={t('forms_name')}
            value={pointInformation.pointName}
            onChange={onChangeInformation}
            onFocus={onPointNameFocus}
            helperText={t('validation_errors_null')}
            // error={isNoValidName}
            name="pointName"
          />

          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_poi_type')}
                name="pointType"
                value={pointInformation.pointType}
                onChange={(e) => onChangeInformation(e as React.ChangeEvent<HTMLInputElement>)}
              >
                {TYPES.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <PolygonInput
                label={t('forms_point_elevation')}
                placeholder={t('Enter')}
                name="pointElevation"
                type="number"
                value={pointInformation.pointElevation}
                // onChange={onChangeInformation}
                additionalInput={
                  <AdditionalSelect
                    name="pointElevationUnit"
                    withGreenBorder
                    value={pointInformation.pointElevationUnit}
                    onChange={(e) => onChangeInformation(e as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {UNITS.Length.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </AdditionalSelect>
                }
              />
            </Grid>
          </Grid>

          <PolygonInput
            label={t('forms_description')}
            placeholder={t('forms_description')}
            name="pointDescription"
            value={pointInformation.pointDescription}
            onChange={onChangeInformation}
            multiline
          />

          <CheckBox
            checked={pointInformation.isIrrigationProperties}
            onChange={onToggleIsIrrigationProps}
            label={t('data_tab_irrigation')}
            sx={{ mt: 2 }}
          />

          <Collapse in={pointInformation.isIrrigationProperties}>
            <div>
              <PointIrrigationProperties
                point={point}
                onChangeUnit={onChangeUnit}
                value={pointInformation}
                onChange={onChangeInformation}
                copySettingsFromOptions={copySettingsFromOptions}
                onChangeCopySettingsFrom={onChangeCopySettingsFrom}
                copySettingsFromObject={currentPointCopySettingsFrom}
              />
            </div>
          </Collapse>

          <Text sx={{ mt: 3 }}>{t('forms_images')}</Text>
          <ItemImagesForm images={pointInformation.images} />
        </ItemInfoContainer>
      )}
    </>
  );
};
