import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const RenameIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M13.1523 2.80981L16.1903 5.84785M10.8737 11.1644L7.07617 11.9239L7.83568 8.12639L14.6257 1.33636C14.731 1.22981 14.8563 1.14521 14.9945 1.08747C15.1327 1.02973 15.281 1 15.4308 1C15.5806 1 15.7289 1.02973 15.8671 1.08747C16.0053 1.14521 16.1306 1.22981 16.2359 1.33636L17.6638 2.76424C17.7703 2.8695 17.8549 2.99486 17.9126 3.13306C17.9704 3.27126 18.0001 3.41955 18.0001 3.56932C18.0001 3.7191 17.9704 3.86738 17.9126 4.00558C17.8549 4.14378 17.7703 4.26914 17.6638 4.3744L10.8737 11.1644Z"
      stroke="#8C8D92"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.8356 2.80981H2.89878C2.39519 2.80981 1.91223 3.00986 1.55614 3.36595C1.20005 3.72204 1 4.20501 1 4.70859V16.1013C1 16.6048 1.20005 17.0878 1.55614 17.4439C1.91223 17.8 2.39519 18 2.89878 18H14.2914C14.5408 18 14.7877 17.9509 15.0181 17.8555C15.2484 17.7601 15.4578 17.6202 15.6341 17.4439C15.8104 17.2676 15.9503 17.0583 16.0457 16.8279C16.1411 16.5975 16.1902 16.3506 16.1902 16.1013V11.1644"
      stroke="#8C8D92"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
