import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { axiosBase } from 'shared/axios';
import { useAppNavigator } from 'shared/hooks';
import { BaseResponse, Project, SharedProject, SharedWithUsers } from 'shared/models';
import { ArchiveIcon, ManageAccessIcon, OpenIcon, RenameIcon, SendByMailIcon, ShareIcon } from 'shared/ui';
import { DeleteIcon } from 'shared/ui/Icons/DeleteIcon';
import { GoBackIcon } from 'shared/ui/Icons/GoBackIcon';
import { RestoreIcon } from 'shared/ui/Icons/RestoreIcon';

export const useProjectActions = (project: Project, isArchive: boolean) => {
  const [sharedProjectData, setSharedProjectData] = useState<SharedProject>();
  const [loading, setLoading] = useState(false);

  const { navigateToProject, navigate } = useAppNavigator();
  const { pathname, state } = useLocation();

  const isCurrentProject = state?.project?.id === project.id;
  const areModalsShown = state?.projectMenu && isCurrentProject;

  const isRenameModal = state?.projectMenuRenameModal && isCurrentProject;
  const isShareModal = state?.projectMenuShareModal && isCurrentProject;
  const isManageAccess = state?.projectMenuManageAccessModal && isCurrentProject;
  const isExportModal = state?.projectMenuExportModal && isCurrentProject;
  const isArchiveModal = state?.projectMenuArchiveModal && isCurrentProject;
  const isRestoreModal = state?.projectMenuRestoreModal && isCurrentProject;

  const handleOpenProject = () => navigateToProject(project.id);

  const toggleModal = (modalName: string) => {
    if (!state?.[modalName]) {
      navigate(pathname, { state: { ...state, projectMenu: true, [modalName]: true, project } });
    } else {
      history.back();
    }
  };

  const toggleRenameModal = () => toggleModal('projectMenuRenameModal');
  const toggleExportModal = () => toggleModal('projectMenuExportModal');
  const toggleShareModal = () => toggleModal('projectMenuShareModal');
  const toggleManageAccess = () => toggleModal('projectMenuManageAccessModal');
  const toggleArchiveModal = () => toggleModal('projectMenuArchiveModal');
  const toggleRestoreModal = () => toggleModal('projectMenuRestoreModal');

  const menuItems = useMemo(() => {
    let items;

    const commonActions = [
      {
        title: 'project_open',
        icon: OpenIcon,
        callback: handleOpenProject,
      },
      {
        title: 'project_rename',
        icon: RenameIcon,
        callback: toggleRenameModal,
      },
      {
        title: 'side_menu_export',
        icon: SendByMailIcon,
        callback: toggleExportModal,
      },
      {
        title: 'side_menu_share',
        icon: ShareIcon,
        callback: toggleShareModal,
      },
      {
        title: 'project_manage_access',
        icon: ManageAccessIcon,
        callback: toggleManageAccess,
      },
      // {
      //   title: 'project_download',
      //   icon: DownloadIcon,
      //   callback: () => toast.warn('not implemented yet'),
      // },
    ];

    if (isManageAccess) {
      items = [
        {
          title: 'project_manage_access',
          icon: GoBackIcon,
          callback: toggleManageAccess,
        },
      ];
    } else if (isArchive) {
      items = [
        ...commonActions,
        {
          title: 'project_delete',
          icon: DeleteIcon,
          callback: toggleArchiveModal,
        },
        {
          title: 'project_restore',
          icon: RestoreIcon,
          callback: toggleRestoreModal,
        },
      ];
    } else {
      items = [
        ...commonActions,
        {
          title: 'project_archive',
          icon: ArchiveIcon,
          callback: toggleArchiveModal,
        },
      ];
    }

    return items;
  }, [isManageAccess, isArchive]);

  useEffect(() => {
    const getSharedUsers = async () => {
      setLoading(true);
      try {
        const { data: responseData } = await axiosBase.get<BaseResponse<SharedProject>>(
          `/api/Project/share-project/${project.id}`
        );
        setSharedProjectData(responseData.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    isManageAccess && getSharedUsers();
  }, [isManageAccess]);

  const revalidateSharedWithUsers = (data: SharedWithUsers[]) => {
    setSharedProjectData((prevState) => (prevState ? { ...prevState, sharedWithUsers: data } : prevState));
  };

  return {
    menuItems,
    areModalsShown,
    isArchiveModal,
    isExportModal,
    isRenameModal,
    isManageAccess,
    isShareModal,
    isRestoreModal,
    toggleArchiveModal,
    toggleExportModal,
    toggleRenameModal,
    toggleShareModal,
    toggleRestoreModal,
    toggleManageAccess,
    loading,
    revalidateSharedWithUsers,
    sharedProjectData,
  };
};
