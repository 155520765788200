import { Point } from 'shared/models';
import { getFeatureBounds } from './mapCalculations';
import { LatLngBoundsExpression, Map } from 'leaflet';

export const fitItemToViewPort = (map: Map, points: Point[]) => {
  map.dragging.disable();

  const featureBounds = getFeatureBounds(points);

  const currentBounds = map.getBounds();
  const currentZoom = map.getZoom();

  if (!currentBounds.equals(featureBounds as LatLngBoundsExpression)) {
    map.fitBounds(featureBounds as LatLngBoundsExpression, { animate: true });
  }

  const newZoom = map.getZoom();
  if (newZoom !== currentZoom) {
    map.setZoom(newZoom - 1, { animate: false });
  }

  map.dragging.enable();
};
