import { Box, MenuItem, Typography, Button } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { ViewProps } from './DesktopView';
import { useTranslation } from 'react-i18next';
import { UNITS } from '../../shared/constants';
import { Row, HTInput, HTSelect } from '../../shared/ui';

const AdditionalInputContainer: FC<PropsWithChildren> = ({ children }) => {
  return <Box sx={{ position: 'absolute', right: 0, bottom: 0, width: '50%' }}>{children}</Box>;
};

const formatUnitLabel = (item: string) => {
  if (!item.includes('/')) return item;

  const split = item.split('/');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          fontSize: '14px',
          display: 'flex',

          lineHeight: 1,
        }}
      >
        {split[0]}
      </Box>
      <Box sx={{ height: '0.5px', width: '100%', background: '#000', my: 0.1 }} />
      <Box
        sx={{
          fontSize: '14px',
          display: 'flex',

          lineHeight: 1,
        }}
      >
        {split[1]}
      </Box>
    </Box>
  );
};

export const MobileView: FC<ViewProps> = ({
  onSaveClick,
  toggle,
  onMetricDefaultsClick,
  onUSDefaultsClick,
  unitValues,
  convertUnitValues,
  convertedValues,
  handleConvertUnitChange,
  handleValuesChange,
  handleUnitChange,
  values,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ minHeight: '80svh', position: 'relative' }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <Box display="flex" columnGap={1} flexShrink={0}>
          <Button sx={{ width: '50%' }} variant="outlined" onClick={onMetricDefaultsClick}>
            {t('units_metric_def')}
          </Button>

          <Button sx={{ width: '50%' }} variant="outlined" onClick={onUSDefaultsClick}>
            {t('units_us_def')}
          </Button>
        </Box>

        <Box
          mt={1}
          mb={1}
          flexGrow={1}
          sx={{
            overflow: 'auto',
            '&::-webkit-scrollbar-thumb': {
              border: '4px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box',
              backgroundColor: 'primary.main',
            },
            '&::-webkit-scrollbar': {
              width: '12px',
              backgroundColor: '#F5FBFF',
            },
          }}
        >
          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_area')}`}
                  type="number"
                  name="area"
                  value={values.area}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="area" value={unitValues.area} onChange={handleUnitChange}>
                        {UNITS.Area.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="area"
                  value={convertedValues.area}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="area" value={convertUnitValues.area} onChange={handleConvertUnitChange}>
                        {UNITS.Area.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_length')}`}
                  type="number"
                  name="length"
                  value={values.length}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="length" value={unitValues.length} onChange={handleUnitChange}>
                        {UNITS.Length.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="length"
                  value={convertedValues.length}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="length" value={convertUnitValues.length} onChange={handleConvertUnitChange}>
                        {UNITS.Length.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_emitter_spacing')}`}
                  type="number"
                  name="emitterSpacing"
                  value={values.emitterSpacing}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="emitterSpacing" value={unitValues.emitterSpacing} onChange={handleUnitChange}>
                        {UNITS.EmitterSpacing.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="emitterSpacing"
                  value={convertedValues.emitterSpacing}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect
                        name="emitterSpacing"
                        value={convertUnitValues.emitterSpacing}
                        onChange={handleConvertUnitChange}
                      >
                        {UNITS.EmitterSpacing.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>
          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_lateral_spacing')}`}
                  type="number"
                  name="lateralSpacing"
                  value={values.lateralSpacing}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="lateralSpacing" value={unitValues.lateralSpacing} onChange={handleUnitChange}>
                        {UNITS.LateralSpacing.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="lateralSpacing"
                  value={convertedValues.lateralSpacing}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect
                        name="lateralSpacing"
                        value={convertUnitValues.lateralSpacing}
                        onChange={handleConvertUnitChange}
                      >
                        {UNITS.LateralSpacing.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_pipe_diameter')}`}
                  type="number"
                  name="pipeDiameter"
                  value={values.pipeDiameter}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="pipeDiameter" value={unitValues.pipeDiameter} onChange={handleUnitChange}>
                        {UNITS.PipeDiameter.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="pipeDiameter"
                  value={convertedValues.pipeDiameter}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect
                        name="pipeDiameter"
                        value={convertUnitValues.pipeDiameter}
                        onChange={handleConvertUnitChange}
                      >
                        {UNITS.PipeDiameter.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_valve_diameter')}`}
                  type="number"
                  name="valveDiameter"
                  value={values.valveDiameter}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="valveDiameter" value={unitValues.valveDiameter} onChange={handleUnitChange}>
                        {UNITS.ValveDiameter.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="valveDiameter"
                  value={convertedValues.valveDiameter}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect
                        name="valveDiameter"
                        value={convertUnitValues.valveDiameter}
                        onChange={handleConvertUnitChange}
                      >
                        {UNITS.ValveDiameter.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_pressure')}`}
                  type="number"
                  name="pressure"
                  value={values.pressure}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="pressure" value={unitValues.pressure} onChange={handleUnitChange}>
                        {UNITS.Pressure.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="pressure"
                  value={convertedValues.pressure}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="pressure" value={convertUnitValues.pressure} onChange={handleConvertUnitChange}>
                        {UNITS.Pressure.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_emitter_flow')}`}
                  type="number"
                  name="flow"
                  value={values.flow}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="flow" value={unitValues.flow} onChange={handleUnitChange}>
                        {UNITS.Flow.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="flow"
                  value={convertedValues.flow}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="flow" value={convertUnitValues.flow} onChange={handleConvertUnitChange}>
                        {UNITS.Flow.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>
          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_lateral_flow')}`}
                  type="number"
                  name="flow"
                  value={values.flow}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="totalFlow" value={unitValues.totalFlow} onChange={handleUnitChange}>
                        {UNITS.TotalFlow.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="totalFlow"
                  value={convertedValues.totalFlow}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="totalFlow" value={convertUnitValues.totalFlow} onChange={handleConvertUnitChange}>
                        {UNITS.TotalFlow.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_flow_per_length')}`}
                  type="number"
                  name="flowPerLength"
                  value={values.flowPerLength}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="flowPerLength" value={unitValues.flowPerLength} onChange={handleUnitChange}>
                        {UNITS.FlowPerLength.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="flowPerLength"
                  value={convertedValues.flowPerLength}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect
                        name="flowPerLength"
                        value={convertUnitValues.flowPerLength}
                        onChange={handleConvertUnitChange}
                      >
                        {UNITS.FlowPerLength.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_velocity')}`}
                  type="number"
                  name="velocity"
                  value={values.velocity}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="velocity" value={unitValues.velocity} onChange={handleUnitChange}>
                        {UNITS.Velocity.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="velocity"
                  value={convertedValues.velocity}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="velocity" value={convertUnitValues.velocity} onChange={handleConvertUnitChange}>
                        {UNITS.Velocity.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>

          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_appn_depth')}`}
                  type="number"
                  name="appnDepth"
                  value={values.appnDepth}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="appnDepth" value={unitValues.appnDepth} onChange={handleUnitChange}>
                        {UNITS.AppnDepth.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="appnDepth"
                  value={convertedValues.appnDepth}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="appnDepth" value={convertUnitValues.appnDepth} onChange={handleConvertUnitChange}>
                        {UNITS.AppnDepth.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>
          <Row alignItems="flex-end">
            <Row alignItems="flex-end" width="100%">
              <Box flexGrow={1}>
                <HTInput
                  label={`${t('units_appn_rate')}`}
                  type="number"
                  name="appnRate"
                  value={values.appnRate}
                  onChange={handleValuesChange}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="appnRate" value={unitValues.appnRate} onChange={handleUnitChange}>
                        {UNITS.AppnRate.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
            <Row>
              <Box mx={1}>
                <Typography align="center" sx={{ lineHeight: 2.5 }}>
                  =
                </Typography>
              </Box>
            </Row>
            <Row width="100%">
              <Box flexGrow={1}>
                <HTInput
                  disabled
                  isResultBox
                  type="number"
                  name="appnRate"
                  value={convertedValues.appnRate}
                  additionalInput={
                    <AdditionalInputContainer>
                      <HTSelect name="appnRate" value={convertUnitValues.appnRate} onChange={handleConvertUnitChange}>
                        {UNITS.AppnRate.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatUnitLabel(item)}
                          </MenuItem>
                        ))}
                      </HTSelect>
                    </AdditionalInputContainer>
                  }
                />
              </Box>
            </Row>
          </Row>
        </Box>
        <Box display="flex" columnGap={1} flexShrink={0}>
          <Button onClick={onSaveClick} variant="contained" sx={{ flexBasis: '50%' }}>
            {t('units_save')}
          </Button>

          <Button onClick={toggle} variant="outlined" sx={{ flexBasis: '50%' }}>
            {t('units_cancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
