import { Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = TypographyProps & {
  children: ReactNode;
};

export const Text: FC<Props> = ({ children, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontWeight: 600,
        color: 'primary.main',
        fontSize: ' 18px',
        lineHeight: ' 20px',
        mb: '12px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
