import { IconButton, Stack, styled, Typography } from '@mui/material';
import axios from 'axios';
import { LatLng } from 'leaflet';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppNavigator } from 'shared/hooks';
import { selectTrackingPoint, setTrackingPoint, toggleTrackingPointDrawing } from 'shared/slices';
import { Row } from 'shared/ui';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

const CoordText = styled(Typography)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: theme.palette.info.main,
  fontSize: 18,
  margin: 10,
  fontWeight: 600,
}));

export const TrackingPointTooltip: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { navigate } = useAppNavigator();
  const { pathname } = useLocation();

  const trackingPoint = selectTrackingPoint();

  const getPointElevation = async (lat: number, lng: number) => {
    try {
      const res = await axios.get(
        `/google/elevation/json?locations=${lat}%2C${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      const alt = res.data.results[0].elevation as number;
      const trackPoint = { lat, lng, alt } as LatLng;

      dispatch(setTrackingPoint(trackPoint));
    } catch (error) {
      console.log(error);
      toast.error(t('errors_something_went_wrong'));
    }
  };

  useEffect(() => {
    if (trackingPoint) {
      const { lat, lng, alt } = trackingPoint;
      alt === undefined && getPointElevation(lat, lng);
    }
  }, [trackingPoint]);

  const onClose = () => {
    dispatch(toggleTrackingPointDrawing());
    navigate(pathname, { state: { heightValueTool: false }, replace: true });
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        position: 'fixed',
        top: '90px',
        left: 'calc(50% - 140px)',
      }}
    >
      <Row
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.68)',
          borderRadius: '16px',
          width: 280,
          px: 0.7,
        }}
      >
        <Row sx={{ mr: 1 }}>
          <CoordText sx={{ fontWeight: 400, mr: 0 }}>X</CoordText>
          <CoordText>{trackingPoint?.lng.toFixed(6)}</CoordText>
        </Row>
        <Row>
          <CoordText sx={{ fontWeight: 400, mr: 0 }}>Y</CoordText>
          <CoordText>{trackingPoint?.lat.toFixed(6)}</CoordText>
        </Row>
      </Row>

      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          bottom: '-44px',
          left: 'calc(50% - 16px)',
          bgcolor: 'white',
          '&:hover': { bgcolor: 'white' },
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
