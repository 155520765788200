import { Box, Link, Stack, styled, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthInput, BigTitle, Button, Icon, Row } from 'shared/ui';
import { axiosBase } from '../../shared/axios';
import { useAppNavigator } from '../../shared/hooks';

const emailRegex = /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/;

const loginPath = '/common/login';

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.dark,
  fontSize: '12px',
  fontWeight: 400,
  '&:before': {
    content: '"•"',
  },
}));

export const RequestAccess = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const { navigateToLogin } = useAppNavigator();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const requestAccess = async () => {
    try {
      setLoading(true);

      await axiosBase.post('/api/Rapid/request-access', {
        firstName,
        lastName,
        email,
        organization,
      });
      toast.success('Email sent.');
      timeoutRef.current = setTimeout(navigateToLogin, 2000);
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong.');
      timeoutRef.current && clearTimeout(timeoutRef.current);
    } finally {
      setLoading(false);
    }
  };

  const isEmailValid = emailRegex.test(email);

  const isRequestAccessDisabled = !email.length || !isEmailValid || !!timeoutRef.current;

  return (
    <Stack justifyContent="space-between" height="100%">
      <Box mt={3} display="flex" flexDirection="column" height="100%" overflow="hidden">
        <Row justifyContent="flex-start" sx={{ a: { textDecoration: 'none' } }}>
          <Icon path="blue_arrow" />
          <Link component={RouterLink} to={loginPath}>
            <Text
              sx={{
                color: '#007AFF',
                m: 0,
                ml: 0.5,
                cursor: 'pointer',
                '&:before': { content: '""' },
              }}
            >
              {t('reset_back_to_login')}
            </Text>
          </Link>
        </Row>

        <BigTitle sx={{ mt: '30px', fontSize: '20px' }}>{t('login_request_access')}</BigTitle>

        <Box flex={1} sx={{ mt: '10px', overflow: 'auto' }}>
          <AuthInput
            label={t('request_access_first_name')}
            name="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="new-password"
          />
          <AuthInput
            label={t('request_access_last_name')}
            name="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="new-password"
          />
          <AuthInput
            required
            label={`${t('request_access_email')} *`}
            name="email"
            error={!!email.length && !isEmailValid}
            helperText={'Email is not valid.'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="new-password"
          />

          <AuthInput
            required
            label={t('request_access_organization')}
            name="organization"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            autoComplete="new-password"
          />

          <Box sx={{ height: '40px', mt: '30px' }}>
            <Button
              disabled={isRequestAccessDisabled}
              btnColor="primary.main"
              fullWidth
              loading={loading}
              onClick={requestAccess}
            >
              <Typography sx={{ fontWeight: 700 }}>{t('login_request_access')}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
