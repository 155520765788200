import { FC } from 'react';
import { useAnchorEl, useAppDispatch } from '../../shared/hooks';
import {
  selectCurrentMapItem,
  selectIsColorPicker,
  selectLineId,
  setLineStyles,
  toggleColorPicker,
} from '../../shared/slices';
import { ColorPickSnackbar, ColorsPanel, Icon, Row } from '../../shared/ui';
import { Box, Divider, IconButton, List, ListItemButton, Popover, styled, Typography } from '@mui/material';
import { LineModel } from '../../shared/models';
import DoneIcon from '@mui/icons-material/Done';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import { blue } from '@mui/material/colors';

const Text = styled(Typography)({
  fontSize: '18px',
  lineHeight: '25px',
  color: 'white',
});
const Border = styled(Box)({
  height: '1px',
  width: '20px',
  border: '1px solid white',
  margin: '0 6px',
});

const borderStyles = [
  { title: 'Dash', value: '10', icon: 'mapIcons/line_style_dash' },
  { title: 'Solid', value: '0', icon: 'mapIcons/line_style_solid' },
  { title: 'End', value: 'endArrowheads', icon: 'mapIcons/line_style_end' },
  { title: 'Start', value: 'startArrowheads', icon: 'mapIcons/line_style_start' },
];
const borderWeights = [1, 2, 3, 4, 5];

export const LineColorPicker: FC = () => {
  const dispatch = useAppDispatch();

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const {
    open: openWeights,
    anchorEl: weightAnch,
    onOpenMenu: onOpenWeights,
    onCloseMenu: onCloseWeights,
  } = useAnchorEl();

  const selectedLineId = selectLineId();
  const isColorPicker = selectIsColorPicker();

  const openedLine = selectCurrentMapItem() as LineModel;

  const color = openedLine?.geoJson.color ?? blue[500];
  const dashArray = openedLine?.geoJson.dashArray ?? '0';

  const weight = openedLine?.geoJson.weight ?? 2;
  const handleClose = () => dispatch(toggleColorPicker());
  const onSelectLineColor = (color: string) => dispatch(setLineStyles({ color }));
  const onSelectLineWeight = (weight: number) => dispatch(setLineStyles({ weight }));

  const onSetLineStyle = (dashArray: string[]) => dispatch(setLineStyles({ dashArray }));

  const onSelectLineStyle = (newItem: string) => {
    // 'Dash' - '10',
    //  'Solid' - '0',
    //  'EndArrowheads' - '12',
    //  'StartArrowheads' - '11',
    let newDashArray: string[] = [...dashArray];
    const oneCanBeSelected = ['10', '0'];
    if (oneCanBeSelected.includes(newItem)) {
      if (!Array.isArray(dashArray)) {
        newDashArray = [dashArray].filter((item) => !oneCanBeSelected.includes(item));
      } else {
        newDashArray = dashArray.filter((item) => !oneCanBeSelected.includes(item));
      }
    }
    const index = newDashArray.findIndex((item) => item === newItem);
    if (index < 0) {
      newDashArray.push(newItem);
      onSetLineStyle(newDashArray);
      return;
    }
    newDashArray.splice(index, 1);
    onSetLineStyle(newDashArray);
  };
  return (
    <>
      <ColorPickSnackbar open={isColorPicker && !!selectedLineId} onClose={handleClose}>
        <>
          <ColorsPanel color={color} onSelect={onSelectLineColor} />

          <Row sx={{ pt: 2 }}>
            <Row onClick={onOpenWeights} sx={{ cursor: 'pointer' }}>
              <Icon path="/mapIcons/border_weights" />
              <Text sx={{ ml: 0.5 }}>{weight}</Text>
              <IconButton sx={{ p: 0 }}>
                <Arrow sx={{ transform: `rotate(${openWeights ? 180 : 0}deg)`, color: 'white' }} />
              </IconButton>
            </Row>

            <Row onClick={onOpenMenu} sx={{ cursor: 'pointer' }}>
              <Text>Style</Text>
              <Border sx={{ borderStyle: dashArray.includes('0') ? 'solid' : 'dashed' }} />
              <IconButton sx={{ p: 0 }}>
                <Arrow sx={{ transform: `rotate(${open ? 180 : 0}deg)`, color: 'white' }} />
              </IconButton>
            </Row>
          </Row>
        </>
      </ColorPickSnackbar>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: -70,
          horizontal: 'left',
        }}
        sx={{
          zIndex: 1401,
          '& .MuiPaper-root': {
            borderRadius: '10px',
          },
        }}
      >
        <List>
          {borderStyles.map(({ title, value, icon }, index) => (
            <Box key={`${title}-${index}`}>
              <ListItemButton
                onClick={() => onSelectLineStyle(value)}
                sx={{
                  py: 0,
                  bgcolor: dashArray.includes(value) ? '#1D874229' : 'white',
                  '&:hover': {
                    bgcolor: dashArray.includes(value) ? '#1D874229' : 'white',
                  },
                }}
              >
                {/* <DoneIcon sx={{ color: dashArray.includes(value) ? 'black' : 'white', mr: '4px' }} /> */}
                {/* <Border sx={{ borderStyle: value === '0' ? 'solid' : 'dashed', borderColor: 'black' }} /> */}
                <Icon path={icon} />
                <Text sx={{ color: 'black', ml: '4px' }}>{title}</Text>
              </ListItemButton>
              {index === 1 && <Divider />}
            </Box>
          ))}
        </List>
      </Popover>

      <Popover
        open={openWeights}
        anchorEl={weightAnch}
        onClose={onCloseWeights}
        anchorOrigin={{
          vertical: -140,
          horizontal: 'left',
        }}
        sx={{
          zIndex: 1401,
          '& .MuiPaper-root': {
            borderRadius: '10px',
          },
        }}
      >
        <List>
          {borderWeights.map((w) => (
            <ListItemButton
              key={w}
              onClick={() => onSelectLineWeight(w)}
              sx={{
                py: 0,
                bgcolor: weight === w ? '#1D874229' : 'white',
                '&:hover': {
                  bgcolor: weight === w ? '#1D874229' : 'white',
                },
              }}
            >
              <DoneIcon sx={{ color: weight === w ? 'black' : 'white' }} />
              <Text sx={{ ml: 1, color: 'black' }}>{w}</Text>
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
};
