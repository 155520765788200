import { Button, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { DeleteItemModal, FigureSizes } from 'features';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppNavigator } from 'shared/hooks';
import { useGpsPlayStop } from 'shared/hooks/useGpsPlayStop';
import { Icon, Row } from 'shared/ui';
import {
  resetDrawMode,
  selectDrawMode,
  selectIsColorPicker,
  selectLineId,
  selectUnitsChanged,
  setIsRowDirectionForm,
  setNewPolygonPoints,
  setSaveProjectStep,
  stopItemDrawing,
} from '../../shared/slices';
import { useMapItemActions } from './hooks';
import { SavingStep } from 'shared/enums';
import { NEW_POLYGON_ID, POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { useLocation } from 'react-router-dom';

const ControlButton = styled(Button)({
  width: '50%',
  height: '68px',
  borderRadius: 0,
  textTransform: 'capitalize',
  backgroundColor: 'white',
  fontSize: 18,
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'white',
  },
});

export const MapItemHeader: FC = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:550px)');

  const { navigate } = useAppNavigator();
  const { pathname, state } = useLocation();

  const unitsChanged = selectUnitsChanged();
  const selectedLineId = selectLineId();

  const {
    itemName,
    isLoading,
    isDrawing,
    isItemShowedInfo,
    isSelectedItem,
    isItemEditing,
    onOpenItemInfo,
    onSaveItem,
    onCancelDrawing,
    onStartEdit,
    unselectItem,
    onCloseInfo,
  } = useMapItemActions();

  const shouldSaveItem = isItemEditing || isItemShowedInfo;
  const shouldCloseItem = isItemShowedInfo;

  const isDeleteItemModalOpen = !!state?.deleteItemModal;
  const toggleDeleteItemModal = () => {
    if (!state?.deleteItemModal) {
      navigate(pathname, { state: { ...state, deleteItemModal: true } });
    } else {
      navigate(pathname, { state: { ...state, deleteItemModal: false }, replace: true });
    }
  };

  useEffect(() => {
    if (isSelectedItem && !state?.selectedObject) {
      navigate(pathname, { state: { selectedObject: true }, replace: true });
    } else if (!isSelectedItem && state?.selectedObject) {
      navigate(pathname, { state: { selectedObject: false }, replace: true });
    }
  }, [isSelectedItem]);

  useEffect(() => {
    if (!state?.selectedObject && isSelectedItem) {
      if (isDrawing && isSelectedItem.includes(POLYGON_ROW_DIRECTION_ID)) {
        dispatch(setIsRowDirectionForm(false));
        onCloseInfo();
        dispatch(stopItemDrawing());

        if (isSelectedItem.includes(NEW_POLYGON_ID)) {
          dispatch(setNewPolygonPoints([]));
        }
      } else if (isItemShowedInfo) {
        onCloseInfo();
        isDrawing && onCancelDrawing();
      } else if (isDrawing) {
        onCancelDrawing();
      }
      unselectItem();
    }
  }, [state?.selectedObject]);

  const saveItem = shouldSaveItem ? onSaveItem : onOpenItemInfo;

  const closeItem = () => {
    if (shouldCloseItem) {
      onCloseInfo();
    } else {
      if (unitsChanged) {
        dispatch(setSaveProjectStep(SavingStep.SAVING));
        dispatch(resetDrawMode());
      }

      onCancelDrawing();
    }
  };

  const { stopGpsTracking, isGpsMeasurementInitialized } = useGpsPlayStop();

  const saveButton = isGpsMeasurementInitialized ? stopGpsTracking : saveItem;

  const isColorPicker = selectIsColorPicker();
  const drawMode = selectDrawMode();

  const isPolygonRowDirectionLine = selectedLineId?.includes(POLYGON_ROW_DIRECTION_ID);

  const isHeaderShown = (isSelectedItem && !isPolygonRowDirectionLine) || (isPolygonRowDirectionLine && !isDrawing);

  return (
    <>
      {isHeaderShown && (
        <>
          <Row
            justifyContent="center"
            sx={{
              position: 'fixed',
              top: '48px',
              left: 0,
              zIndex: 10,
              bgcolor: 'rgba(0, 0, 0, 0.68)',
              height: '56px',
              width: '100%',
              px: '10px',
            }}
          >
            <Row sx={{ width: isItemShowedInfo ? 600 : '100%' }}>
              <Row>
                {!isDrawing && !isItemShowedInfo && (
                  <IconButton onClick={unselectItem} sx={{ pl: 0 }}>
                    <Icon path="mapIcons/Close" />
                  </IconButton>
                )}
                <Typography
                  sx={{
                    color: 'info.main',
                    maxWidth: isMobile ? '75svw' : '500px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {itemName}
                </Typography>
              </Row>

              <Row sx={{ mr: isItemShowedInfo ? -1.5 : 0 }}>
                {/* {isLoading && <CircularProgress size={20} sx={{ color: 'info.main', mr: 1 }} />} */}

                {isItemShowedInfo && isItemEditing && (
                  <IconButton onClick={onCloseInfo}>
                    <Icon path="mapIcons/Close" />
                  </IconButton>
                )}

                {isDrawing && !isLoading && (
                  <>
                    {!isItemShowedInfo && (
                      <IconButton onClick={saveButton}>
                        <Icon path="mapIcons/save_draw" />
                      </IconButton>
                    )}
                    <IconButton onClick={closeItem} disabled={isLoading}>
                      <Icon path="mapIcons/Close" />
                    </IconButton>
                  </>
                )}

                {isSelectedItem && !isDrawing && !isItemShowedInfo && (
                  <>
                    <IconButton onClick={toggleDeleteItemModal}>
                      <Icon path="mapIcons/trash_draw" />
                    </IconButton>
                  </>
                )}
              </Row>
            </Row>
          </Row>

          <Row
            sx={{
              position: 'fixed',
              width: '100%',
              top: 'calc(100svh - 68px)',
              left: 0,
            }}
          >
            {!isColorPicker && !isPolygonRowDirectionLine && <FigureSizes />}
            {!isDrawing && (
              <Row sx={{ width: '100%' }}>
                <ControlButton
                  onClick={onStartEdit}
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'info.main',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                  }}
                >
                  <Icon path="mapIcons/Edit" sx={{ mr: 1 }} />
                  {isPolygonRowDirectionLine ? t('edit') : t('layout')}
                </ControlButton>
                <ControlButton onClick={onOpenItemInfo}>
                  <Icon path="mapIcons/Form" sx={{ mr: 1 }} />
                  {t('form')}
                </ControlButton>
              </Row>
            )}
          </Row>
        </>
      )}

      <DeleteItemModal open={isDeleteItemModalOpen} onClose={toggleDeleteItemModal} />
    </>
  );
};
