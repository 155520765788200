import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';

interface Props {
  isAuthenticated: boolean | null;
  redirectPath?: string;
}

export const ProtectedRoute: React.FC<Props> = ({ isAuthenticated, redirectPath = '/common/login' }) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
