import React, { FC } from 'react';
import { Project } from 'shared/models';
import { ProjectRenameModal } from './ProjectRenameModal';
import { ProjectShareModal } from './ProjectShareModal';
import { ProjectExportModal } from './ProjectExportModal';
import { ProjectArchiveModal } from './ProjectArchiveModal';
import { ProjectActionsItems } from './ProjectActionsItems';
import { List } from '@mui/material';
import { ManageAccessItem } from './ManageAccessItem/ManageAccessItem';
import { useProjectActions } from './hooks/useProjectActions';
import { ProjectStatus } from '../../../shared/enums';
import { ProjectRestoreModal } from './ProjectRestoreModal';

interface Props {
  project: Project;
  isArchive: boolean;
  onUpdateProject: (projectId: number, p: Partial<Project>) => void;
}

export const ProjectActions: FC<Props> = ({ project, onUpdateProject, isArchive }) => {
  const {
    menuItems,
    areModalsShown,
    isArchiveModal,
    isExportModal,
    isShareModal,
    isRenameModal,
    isManageAccess,
    isRestoreModal,
    toggleRestoreModal,
    toggleExportModal,
    toggleShareModal,
    toggleRenameModal,
    toggleArchiveModal,
    loading,
    sharedProjectData,
    revalidateSharedWithUsers,
  } = useProjectActions(project, isArchive);

  const sharedActiveProjects =
    sharedProjectData?.sharedWithUsers.filter((pr) => pr.status === ProjectStatus.Active) || [];

  return (
    <List sx={{ width: '100%' }}>
      <ProjectActionsItems menuItems={menuItems} />

      {isManageAccess && (
        <ManageAccessItem
          sharedUsers={sharedActiveProjects}
          projectId={project.id}
          loading={loading}
          revalidateSharedWithUsers={revalidateSharedWithUsers}
          toggleShareModal={toggleShareModal}
        />
      )}

      {/*Modals*/}
      {areModalsShown && (
        <>
          <ProjectRenameModal
            open={isRenameModal}
            onClose={toggleRenameModal}
            project={project}
            onUpdateProject={onUpdateProject}
          />
          <ProjectShareModal
            revalidateSharedWithUsers={revalidateSharedWithUsers}
            open={isShareModal}
            onClose={toggleShareModal}
            project={project}
          />
          <ProjectExportModal open={isExportModal} onClose={toggleExportModal} project={project} />
          <ProjectArchiveModal
            open={isArchiveModal}
            onClose={toggleArchiveModal}
            isArchive={isArchive}
            project={project}
            onUpdateProject={onUpdateProject}
          />
          <ProjectRestoreModal
            open={isRestoreModal}
            onClose={toggleRestoreModal}
            project={project}
            onUpdateProject={onUpdateProject}
          />
        </>
      )}
    </List>
  );
};
