import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hooks';

interface Relations {
  [key: string]: string[];
}
interface InitialState {
  polygons: Relations;
  points: Relations;
  lines: Relations;
}

const initialState: InitialState = {
  polygons: {},
  points: {},
  lines: {},
};

export const itemRelationSlice = createSlice({
  name: 'itemRelation',
  initialState,
  reducers: {
    setPolygonRelation(state, action: PayloadAction<{ referenceId: string; polygonId: string }>) {
      state.polygons[action.payload.referenceId].push(action.payload.polygonId);
    },
  },
  selectors: {
    selectPolygonRelations: (state) => state.polygons,
  },
});

export const selectPolygonRelations = () => useAppSelector(itemRelationSlice.selectors.selectPolygonRelations);

export const { setPolygonRelation } = itemRelationSlice.actions;
