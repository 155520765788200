import { Box, Grid, MenuItem, RadioGroup } from '@mui/material';
import { HTProductSelect, PolygonInput, Radio, Row, Select, Text } from 'shared/ui';
import { PolygonSelectedProductType } from 'shared/enums';
import React, { FC, useEffect, useMemo } from 'react';
import { CatalogItem, PolygonInformation } from 'shared/models';
import { selectEmitters, selectLaterals } from 'shared/slices';
import { useEmitterFilters, useLateralFilters } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

const DRIP_TAPE_TYPE = 1;

interface Props {
  value: PolygonInformation;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  saveEmitter: (item: CatalogItem) => void;
  saveLateral: (item: CatalogItem) => void;
}
export const PolygonProductProperties: FC<Props> = ({ value, onChange, saveLateral, saveEmitter }) => {
  const { t, i18n } = useTranslation();

  const {
    masterGroups: emitterMasterGroups,
    masterGroupId: emitterMasterGroupId,
    onMasterGroupChange: emitterOnMasterGroupChange,
    emitterSubtype,
    onGroupTypeChange: emitterOnGroupTypeChange,
    nominalFlow: emitterNominalFlow,
    onNominalFlowChange: emitterOnNominalFlowChange,
    nominalFlows: emitterNominalFlows,
    onSpacingChange: emitterOnSpacingChange,
    onEmitterChange,
    emitterProduct,
    emittersFiltered,
    emitter,
  } = useEmitterFilters({
    isIntegrated: value.selectedProductType === PolygonSelectedProductType.INTEGRATED,
  });

  const {
    masterGroups: lateralMasterGroups,
    masterGroupId: lateralMasterGroupId,
    onMasterGroupChange: lateralOnMasterGroupChange,
    lateralSubtype: lateralSubtype,
    onGroupTypeChange: lateralOnGroupTypeChange,
    diameter: lateralDiameter,
    onDiameterChange: lateralOnDiameterChange,
    classType: lateralClassType,
    onClassTypeChange: lateralOnClassTypeChange,
    diameters: lateralDiameters,
    classTypes: lateralClassTypes,
    lateralProduct,
    lateralsFiltered,
    onLateralChange,
    lateral,
    flowPerUnit,
    flowPerUnits,
    onFlowPerUnitChange,
    spacing,
    spacings,
    onSpacingChange,
    nominalFlow,
    nominalFlows,
    onNominalFlowChange,
  } = useLateralFilters({
    isIntegrated: value.selectedProductType === PolygonSelectedProductType.INTEGRATED,
  });

  const { emitterGroups, emitterGroupsLoading, emittersLoading } = selectEmitters();
  const { lateralGroups, lateralGroupsLoading, lateralsLoading } = selectLaterals();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (emitter) saveEmitter(emitter);
  }, [emitter]);

  useEffect(() => {
    if (lateral) saveLateral(lateral);
  }, [lateral]);

  const PRODUCT_RADIO_VALUES = useMemo(
    () => [
      { value: PolygonSelectedProductType.INTEGRATED, label: t('forms_integrated') },
      { value: PolygonSelectedProductType.ON_LINE, label: t('forms_on_line') },
      { value: PolygonSelectedProductType.OTHER, label: t('forms_other') },
    ],
    [t, i18n.language]
  );

  return (
    <div>
      <Box>
        <RadioGroup
          value={value.selectedProductType}
          onChange={(e) => onChange(e)}
          row
          name="selectedProductType"
          sx={{ flexWrap: 'nowrap', gap: '24px' }}
        >
          {PRODUCT_RADIO_VALUES.map(({ value: v, label }) => (
            <Radio key={v} value={v} label={label} checked={(value.selectedProductType as string) === v} />
          ))}
        </RadioGroup>
      </Box>

      {value.selectedProductType === PolygonSelectedProductType.ON_LINE && (
        <>
          <Row justifyContent="flex-start" mt={2}>
            <Text>{t('forms_emitter')}</Text>
            <Text sx={{ ml: 2, fontWeight: 100, fontSize: 16 }}>
              {emitterProduct ? emitterProduct.CATLOGDESC.trim() : '...'}
            </Text>
          </Row>

          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_emitter_type')}
                value={emitterMasterGroupId}
                onChange={(e) => emitterOnMasterGroupChange(+(e.target.value as string))}
              >
                {/* TODO: need to change in DB! Temporary solution for the moment */}
                {emitterMasterGroups?.map((item) => (
                  <MenuItem key={item.ID} value={item.ID}>
                    {item.MASTERGROUPNAME === 'Sprayer' ? 'Micro Sprinkler' : item.MASTERGROUPNAME}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                label={t('forms_emitter_subtype')}
                value={emitterSubtype}
                onChange={emitterOnGroupTypeChange}
                loading={emitterGroupsLoading}
                disabled={emitterGroupsLoading}
              >
                {emitterGroups?.map((item) => (
                  <MenuItem key={item.GROUPS} value={item.GROUPS}>
                    {item.GROUPTYPENAME}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_nominal_flow')}
                value={emitterNominalFlow}
                onChange={emitterOnNominalFlowChange}
                disabled={emitterGroupsLoading || emittersLoading}
              >
                {emitterNominalFlows?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <PolygonInput
                placeholder={t('Enter')}
                type={'number'}
                value={value.emitterSpacing || ''}
                onChange={emitterOnSpacingChange}
                onKeyDown={handleKeyDown}
                label={t('forms_spacing')}
                name="polygonEmitterSpacing"
              />
            </Grid>
          </Grid>

          <HTProductSelect
            label={t('forms_product')}
            value={emitterProduct}
            onChange={onEmitterChange}
            options={emittersFiltered}
            loading={emittersLoading}
            disabled={emittersLoading || emitterGroupsLoading}
          />
        </>
      )}

      {value.selectedProductType !== PolygonSelectedProductType.OTHER && (
        <>
          <Row justifyContent="flex-start" mt={2}>
            <Text>{t('forms_lateral')}</Text>
            <Text sx={{ ml: 2, fontWeight: 100, fontSize: 16 }}>
              {lateralProduct ? lateralProduct.CATLOGDESC.trim() : '...'}
            </Text>
          </Row>
          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_lateral_type')}
                placeholder="_"
                value={lateralMasterGroupId}
                onChange={(e) => lateralOnMasterGroupChange(+(e.target.value as string))}
              >
                {lateralMasterGroups?.map((item) => (
                  <MenuItem key={item.ID} value={item.ID}>
                    {item.MASTERGROUPNAME}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                label={t('forms_lateral_subtype')}
                value={lateralSubtype ?? ''}
                onChange={lateralOnGroupTypeChange}
                loading={lateralGroupsLoading}
                disabled={lateralGroupsLoading}
              >
                {lateralGroups?.map((item) => (
                  <MenuItem key={item.GROUPS} value={item.GROUPS}>
                    {item.GROUPTYPENAME}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_diameter')}
                value={lateralDiameter}
                onChange={lateralOnDiameterChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {lateralDiameters?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                label={t('forms_class')}
                value={lateralClassType}
                onChange={lateralOnClassTypeChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {lateralClassTypes?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </>
      )}

      {value.selectedProductType === PolygonSelectedProductType.INTEGRATED && (
        <>
          <Grid container spacing={1}>
            {lateralMasterGroupId === DRIP_TAPE_TYPE && (
              <Grid item xs={6}>
                <Select
                  label={`${t('forms_flow_per')}`}
                  value={flowPerUnit}
                  onChange={onFlowPerUnitChange}
                  disabled={lateralGroupsLoading || lateralsLoading}
                >
                  {flowPerUnits?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={6}>
              <Select
                label={`${t('forms_nominal_flow')}`}
                value={nominalFlow}
                onChange={onNominalFlowChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {nominalFlows?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {lateralMasterGroupId !== DRIP_TAPE_TYPE && (
              <Grid item xs={6}>
                <Select
                  label={`${t('forms_spacing')}`}
                  value={spacing}
                  onChange={onSpacingChange}
                  disabled={lateralGroupsLoading || lateralsLoading}
                >
                  {spacings?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
          {lateralMasterGroupId === DRIP_TAPE_TYPE && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Select
                  label={`${t('forms_spacing')}`}
                  value={spacing}
                  onChange={onSpacingChange}
                  disabled={lateralGroupsLoading || lateralsLoading}
                >
                  {spacings?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {value.selectedProductType !== PolygonSelectedProductType.OTHER && (
        <>
          <HTProductSelect
            label={t('forms_product')}
            value={lateralProduct}
            onChange={onLateralChange}
            options={lateralsFiltered}
            loading={lateralsLoading}
            disabled={lateralsLoading || lateralGroupsLoading}
          />
        </>
      )}

      {value.selectedProductType === PolygonSelectedProductType.OTHER && (
        <>
          <PolygonInput
            value={value.polygonOtherProduct}
            onChange={onChange}
            placeholder="Describe Product"
            name="polygonOtherProduct"
          />
        </>
      )}
    </div>
  );
};
