import { Autocomplete, Box, createFilterOptions, Grid, Stack, TextField, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosBase } from 'shared/axios';
import { ProjectPermission } from 'shared/enums';
import { BaseResponse, Project, SharedProject, SharedWithUsers } from 'shared/models';
import { Button, ShareIcon, WhiteModal } from 'shared/ui';
import { useSharingEmails } from '../../TransferProjectModal/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  project: Project;
  revalidateSharedWithUsers: (data: SharedWithUsers[]) => void;
}

export const ProjectShareModal: FC<Props> = ({ open, onClose, project, revalidateSharedWithUsers }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [permissionType, setPermissionType] = useState(ProjectPermission.Editor);
  const [canShare, setCanShare] = useState(false);

  const { email, onEmailChange, emails, isLoading } = useSharingEmails(open, '');

  const handleSetPermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPermissionType(Number(e.target.value));
  };

  const handleCanShare = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanShare(e.target.checked);
  };

  const permission = canShare // If user can share
    ? permissionType === ProjectPermission.Viewer
      ? ProjectPermission.ViewerCanShare
      : ProjectPermission.EditorCanShare
    : permissionType; // Otherwise, use the original permissionType

  const onShareClick = async () => {
    setLoading(true);
    try {
      const payload = {
        email,
        organizationId: null,
        permissionType: permission,
        projectId: project.id,
      };
      const { data: updatedProject } = await axiosBase.post<BaseResponse<SharedProject>>(
        '/api/Project/share-project',
        payload
      );
      revalidateSharedWithUsers(updatedProject.data.sharedWithUsers);
      toast.success(`project: ${project.project.projectName} is shared with ${email}`);
    } catch (err) {
      console.error('share error:', err);
      isAxiosError(err) && toast.error(err.response?.data?.error?.message || err.message);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const onCancelClick = () => {
    !loading && onClose();
  };

  return (
    <WhiteModal open={open} onClose={onClose} hideCloseIcon margin={6}>
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack mb={'12px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <ShareIcon />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t('Share Catalog')}
            </Typography>
          </Stack>
        </Stack>
        <Box mb={'24px'}>
          <Typography
            noWrap
            sx={{
              color: '#242731',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '130%',
              letterSpacing: '0.18px',
              marginBottom: '8px',
            }}
          >
            {t('Share with user')}
          </Typography>
          <Autocomplete
            onChange={(_, value) => onEmailChange(value ?? '')}
            value={email}
            options={emails ?? []}
            autoSelect
            getOptionLabel={(item) => item}
            isOptionEqualToValue={(option, value) => option == value}
            filterOptions={createFilterOptions({ matchFrom: 'any' })}
            disabled={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter email"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '44px',
                    borderRadius: '8px',
                    p: '0 10px',
                    fontSize: 16,
                  },
                }}
              />
            )}
            noOptionsText={'No such email'}
          />
        </Box>
        {/*<Stack mb={'48px'}>*/}
        {/*  <FormControl*/}
        {/*    sx={{*/}
        {/*      mb: '16px',*/}
        {/*      '& label.Mui-focused': {*/}
        {/*        color: '#242731',*/}
        {/*      },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <FormLabel*/}
        {/*      sx={{*/}
        {/*        color: '#242731',*/}
        {/*        fontSize: '18px',*/}
        {/*        lineHeight: '130%',*/}
        {/*        letterSpacing: '0.18px',*/}
        {/*        mb: '16px',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t('Select Permissions')}*/}
        {/*    </FormLabel>*/}
        {/*    <RadioGroup*/}
        {/*      defaultValue={ProjectPermission.Editor}*/}
        {/*      name="radio-buttons-group"*/}
        {/*      sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}*/}
        {/*    >*/}
        {/*      <FormControlLabel*/}
        {/*        value={ProjectPermission.Editor}*/}
        {/*        control={*/}
        {/*          <Radio*/}
        {/*            sx={{ py: 0 }}*/}
        {/*            checked={permissionType === ProjectPermission.Editor}*/}
        {/*            onChange={handleSetPermission}*/}
        {/*          />*/}
        {/*        }*/}
        {/*        label={*/}
        {/*          <Typography*/}
        {/*            sx={{*/}
        {/*              color: '#242731',*/}
        {/*              fontSize: '16px',*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            {t('Editor')}*/}
        {/*          </Typography>*/}
        {/*        }*/}
        {/*      />*/}
        {/*      <FormControlLabel*/}
        {/*        value={ProjectPermission.Viewer}*/}
        {/*        control={*/}
        {/*          <Radio*/}
        {/*            sx={{ py: 0 }}*/}
        {/*            checked={permissionType === ProjectPermission.Viewer}*/}
        {/*            onChange={handleSetPermission}*/}
        {/*          />*/}
        {/*        }*/}
        {/*        label={*/}
        {/*          <Typography*/}
        {/*            sx={{*/}
        {/*              color: '#242731',*/}
        {/*              fontSize: '16px',*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            {t('Viewer')}*/}
        {/*          </Typography>*/}
        {/*        }*/}
        {/*      />*/}
        {/*    </RadioGroup>*/}
        {/*  </FormControl>*/}
        {/*  <Box sx={{ width: '100%', height: '1px', background: '#D4D4D4', mb: '16px' }} />*/}
        {/*  <FormControlLabel*/}
        {/*    control={<Checkbox sx={{ py: 0 }} checked={canShare} onChange={handleCanShare} />}*/}
        {/*    label={*/}
        {/*      <Typography*/}
        {/*        sx={{*/}
        {/*          color: '#242731',*/}
        {/*          fontSize: '16px',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {t('Can share')}*/}
        {/*      </Typography>*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Stack>*/}
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={5}>
            <Button outlined btnColor="info.dark" fullWidth onClick={onCancelClick}>
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('new_project_cancel')}</Typography>
            </Button>
          </Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <Button
              loading={loading}
              btnColor="primary.main"
              fullWidth
              onClick={onShareClick}
              disabled={loading || !email}
            >
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('Share')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </WhiteModal>
  );
};
