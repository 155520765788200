import { Box, Container, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { POLYGON } from 'shared/constants';
import { SavingStep } from 'shared/enums';
import { useAppDispatch } from 'shared/hooks';
import { calculateAngle, getPolygonCenter, getRelatedPolygonId } from 'shared/lib';
import { PolygonInformation, PolygonModel } from 'shared/models';
import {
  RowDirectionData,
  saveItem,
  selectLineById,
  selectLineId,
  selectPolygonById,
  selectRowDirectiondata,
  setCurrentLinePointId,
  setIsRowDirectionForm,
  setPolygonInformation,
  setSaveProjectStep,
  stopPolygonRowDirectionLineDrawing,
  unselectItems,
  updatePolygonRowDirectionLineFromAngle,
} from 'shared/slices';
import { Button } from 'shared/ui';
import { useManagePolygonInfo } from 'widgets/PolygonInfo/hooks';

export const RowDirectionForm = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const selectedLineId = selectLineId();
  const selectedLine = selectLineById(selectedLineId ?? '');

  const polygonId = getRelatedPolygonId(selectedLineId ?? '');
  const relatedPolygon = selectPolygonById(polygonId ?? '');

  const rowDirectionData =
    selectRowDirectiondata() ??
    ({
      relatedPolygon,
      isPolygonDrawing: false,
      isPolygonEditing: true,
    } as RowDirectionData);

  const {
    polygonInformation: { polygonRowDirection },
    isPolygonInfo,
  } = useManagePolygonInfo();

  const calculateRowDirectionAngle = () => {
    if (selectedLine && relatedPolygon) {
      const polygonBoundary = relatedPolygon.geoJson.points.map((p) => p.coords);
      const polygonCenter = getPolygonCenter(polygonBoundary);
      const newPoint = selectedLine.geoJson.points[1].coords;
      const angle = calculateAngle(polygonCenter, newPoint);

      return Number(angle.toFixed(2));
    }

    return 0;
  };

  useEffect(() => {
    if (selectedLine) {
      dispatch(setCurrentLinePointId(selectedLine.geoJson.points[1].id));
    }
  }, []);

  const closeForm = () => {
    dispatch(setCurrentLinePointId(null));
    dispatch(setIsRowDirectionForm(false));
  };

  const handleApply = () => {
    const angle = calculateRowDirectionAngle();

    // if form was opened from arrow selection
    if (!isPolygonInfo && relatedPolygon) {
      const polygonInformation: PolygonInformation = {
        ...relatedPolygon.properties,
        polygonRowDirection: angle,
        polygonName: relatedPolygon.information.name,
        polygonDescription: relatedPolygon.information.description,
        isIrrigationProperties: relatedPolygon.information.isIrrigationProperties,
        images: relatedPolygon.images,
      };

      dispatch(
        saveItem({
          itemId: relatedPolygon?.geoJson.id,
          information: polygonInformation,
          itemType: POLYGON,
        })
      );

      dispatch(setSaveProjectStep(SavingStep.SAVING));
      dispatch(unselectItems());
    } else {
      dispatch(stopPolygonRowDirectionLineDrawing(rowDirectionData));

      dispatch(setPolygonInformation({ polygonRowDirection: angle }));
    }

    closeForm();
  };

  const handleCancel = () => {
    dispatch(stopPolygonRowDirectionLineDrawing(rowDirectionData));

    // change line direction to the previous state
    dispatch(
      updatePolygonRowDirectionLineFromAngle({
        angle: polygonRowDirection ?? 0,
        polygon: relatedPolygon as PolygonModel,
      })
    );

    if (!isPolygonInfo) {
      dispatch(unselectItems());
    }

    closeForm();
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', bgcolor: 'rgba(0, 0, 0, 0.68)', zIndex: 2 }}>
      <Container
        maxWidth="sm"
        disableGutters
        sx={{
          p: 3,
          pb: '41px',
          backgroundColor: 'rgba(240, 251, 255, 1)',
        }}
      >
        <Stack direction="row" spacing={5}>
          <Button
            onClick={handleCancel}
            fullWidth
            btnColor="rgba(0, 0, 0, 0.1)"
            sx={{ borderRadius: '16px', color: 'rgba(0, 0, 0, 0.5)', borderColor: 'rgba(0, 0, 0, 0.1)' }}
          >
            {t('forms_cancel')}
          </Button>

          <Button onClick={handleApply} fullWidth btnColor="primary.main" sx={{ borderRadius: '16px' }}>
            {t('forms_apply')}
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};
