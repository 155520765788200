import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const RestoreIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M15.06 21.9999H4.22667C3.63612 21.9999 3.06976 21.7653 2.65218 21.3477C2.23459 20.9301 2 20.3638 2 19.7732V8.93988C2 8.34871 2.23438 7.78166 2.65177 7.36302C3.06916 6.94438 3.63551 6.70831 4.22667 6.70654H15.06C15.6523 6.70654 16.2204 6.94184 16.6392 7.36067C17.058 7.7795 17.2933 8.34756 17.2933 8.93988V19.7732C17.2916 20.3644 17.0555 20.9307 16.6369 21.3481C16.2182 21.7655 15.6512 21.9999 15.06 21.9999ZM4.22667 8.03988C3.98913 8.04164 3.76192 8.13723 3.59458 8.30583C3.42723 8.47442 3.33333 8.70233 3.33333 8.93988V19.7732C3.33333 20.0101 3.42745 20.2374 3.59498 20.4049C3.76252 20.5724 3.98974 20.6665 4.22667 20.6665H15.06C15.2975 20.6665 15.5255 20.5726 15.6941 20.4053C15.8626 20.238 15.9582 20.0107 15.96 19.7732V8.93988C15.96 8.82169 15.9367 8.70465 15.8915 8.59546C15.8463 8.48627 15.78 8.38705 15.6964 8.30348C15.6128 8.21991 15.5136 8.15361 15.4044 8.10838C15.2952 8.06316 15.1782 8.03988 15.06 8.03988H4.22667Z"
      fill="#808080"
    />
    <path
      d="M19.7737 17.2933H15.9604V8.94C15.9604 8.82181 15.9371 8.70478 15.8919 8.59558C15.8466 8.48639 15.7803 8.38718 15.6968 8.3036C15.6132 8.22003 15.514 8.15374 15.4048 8.10851C15.2956 8.06328 15.1786 8.04 15.0604 8.04H6.70703V4.22667C6.7088 3.63551 6.94487 3.06916 7.36351 2.65177C7.78215 2.23438 8.3492 2 8.94036 2H19.7737C20.3642 2 20.9306 2.23459 21.3482 2.65218C21.7658 3.06976 22.0004 3.63612 22.0004 4.22667V15.06C22.0004 15.6512 21.766 16.2182 21.3486 16.6369C20.9312 17.0555 20.3649 17.2916 19.7737 17.2933ZM17.2937 15.96H19.7737C20.0112 15.9582 20.2384 15.8626 20.4058 15.6941C20.5731 15.5255 20.667 15.2975 20.667 15.06V4.22667C20.667 3.98974 20.5729 3.76252 20.4054 3.59498C20.2378 3.42745 20.0106 3.33333 19.7737 3.33333H8.94036C8.70282 3.33333 8.47491 3.42723 8.30631 3.59458C8.13772 3.76192 8.04212 3.98913 8.04036 4.22667V6.70667H15.0604C15.6527 6.70667 16.2207 6.94196 16.6396 7.3608C17.0584 7.77963 17.2937 8.34768 17.2937 8.94V15.96Z"
      fill="#808080"
    />
  </SvgIcon>
);
