// export const locales = [{ 'key': 'af', 'name': 'Afrikaans' }, { 'key': 'am', 'name': 'Amharic' }, {
//   'key': 'ar-dz',
//   'name': 'Arabic (Algeria)',
// }, { 'key': 'ar-iq', 'name': ' Arabic (Iraq)' }, { 'key': 'ar-kw', 'name': 'Arabic (Kuwait)' }, {
//   'key': 'ar-ly',
//   'name': 'Arabic (Lybia)',
// }, { 'key': 'ar-ma', 'name': 'Arabic (Morocco)' }, {
//   'key': 'ar-sa',
//   'name': 'Arabic (Saudi Arabia)',
// }, { 'key': 'ar-tn', 'name': ' Arabic (Tunisia)' }, { 'key': 'ar', 'name': 'Arabic' }, {
//   'key': 'az',
//   'name': 'Azerbaijani',
// }, { 'key': 'be', 'name': 'Belarusian' }, { 'key': 'bg', 'name': 'Bulgarian' }, {
//   'key': 'bi',
//   'name': 'Bislama',
// }, { 'key': 'bm', 'name': 'Bambara' }, { 'key': 'bn-bd', 'name': 'Bengali (Bangladesh)' }, {
//   'key': 'bn',
//   'name': 'Bengali',
// }, { 'key': 'bo', 'name': 'Tibetan' }, { 'key': 'br', 'name': 'Breton' }, {
//   'key': 'bs',
//   'name': 'Bosnian',
// }, { 'key': 'ca', 'name': 'Catalan' }, { 'key': 'cs', 'name': 'Czech' }, {
//   'key': 'cv',
//   'name': 'Chuvash',
// }, { 'key': 'cy', 'name': 'Welsh' }, { 'key': 'da', 'name': 'Danish' }, {
//   'key': 'de-at',
//   'name': 'German (Austria)',
// }, { 'key': 'de-ch', 'name': 'German (Switzerland)' }, { 'key': 'de', 'name': 'German' }, {
//   'key': 'dv',
//   'name': 'Maldivian',
// }, { 'key': 'el', 'name': 'Greek' }, { 'key': 'en-au', 'name': 'English (Australia)' }, {
//   'key': 'en-ca',
//   'name': 'English (Canada)',
// }, { 'key': 'en-gb', 'name': 'English (United Kingdom)' }, {
//   'key': 'en-ie',
//   'name': 'English (Ireland)',
// }, { 'key': 'en-il', 'name': 'English (Israel)' }, { 'key': 'en-in', 'name': 'English (India)' }, {
//   'key': 'en-nz',
//   'name': 'English (New Zealand)',
// }, { 'key': 'en-sg', 'name': 'English (Singapore)' }, {
//   'key': 'en-tt',
//   'name': 'English (Trinidad & Tobago)',
// }, { 'key': 'en', 'name': 'English' }, { 'key': 'eo', 'name': 'Esperanto' }, {
//   'key': 'es-do',
//   'name': 'Spanish (Dominican Republic)',
// }, { 'key': 'es-mx', 'name': 'Spanish (Mexico)' }, {
//   'key': 'es-pr',
//   'name': 'Spanish (Puerto Rico)',
// }, { 'key': 'es-us', 'name': 'Spanish (United States)' }, { 'key': 'es', 'name': 'Spanish' }, {
//   'key': 'et',
//   'name': 'Estonian',
// }, { 'key': 'eu', 'name': 'Basque' }, { 'key': 'fa', 'name': 'Persian' }, {
//   'key': 'fi',
//   'name': 'Finnish',
// }, { 'key': 'fo', 'name': 'Faroese' }, { 'key': 'fr-ca', 'name': 'French (Canada)' }, {
//   'key': 'fr-ch',
//   'name': 'French (Switzerland)',
// }, { 'key': 'fr', 'name': 'French' }, { 'key': 'fy', 'name': 'Frisian' }, {
//   'key': 'ga',
//   'name': 'Irish or Irish Gaelic',
// }, { 'key': 'gd', 'name': 'Scottish Gaelic' }, { 'key': 'gl', 'name': 'Galician' }, {
//   'key': 'gom-latn',
//   'name': 'Konkani Latin script',
// }, { 'key': 'gu', 'name': 'Gujarati' }, { 'key': 'hi', 'name': 'Hindi' }, {
//   'key': 'he',
//   'name': 'Hebrew',
// }, { 'key': 'hr', 'name': 'Croatian' }, { 'key': 'ht', 'name': 'Haitian Creole (Haiti)' }, {
//   'key': 'hu',
//   'name': 'Hungarian',
// }, { 'key': 'hy-am', 'name': 'Armenian' }, { 'key': 'id', 'name': 'Indonesian' }, {
//   'key': 'is',
//   'name': 'Icelandic',
// }, { 'key': 'it-ch', 'name': 'Italian (Switzerland)' }, { 'key': 'it', 'name': 'Italian' }, {
//   'key': 'ja',
//   'name': 'Japanese',
// }, { 'key': 'jv', 'name': 'Javanese' }, { 'key': 'ka', 'name': 'Georgian' }, {
//   'key': 'kk',
//   'name': 'Kazakh',
// }, { 'key': 'km', 'name': 'Cambodian' }, { 'key': 'kn', 'name': 'Kannada' }, {
//   'key': 'ko',
//   'name': 'Korean',
// }, { 'key': 'ku', 'name': 'Kurdish' }, { 'key': 'ky', 'name': 'Kyrgyz' }, {
//   'key': 'lb',
//   'name': 'Luxembourgish',
// }, { 'key': 'lo', 'name': 'Lao' }, { 'key': 'lt', 'name': 'Lithuanian' }, {
//   'key': 'lv',
//   'name': 'Latvian',
// }, { 'key': 'me', 'name': 'Montenegrin' }, { 'key': 'mi', 'name': 'Maori' }, {
//   'key': 'mk',
//   'name': 'Macedonian',
// }, { 'key': 'ml', 'name': 'Malayalam' }, { 'key': 'mn', 'name': 'Mongolian' }, {
//   'key': 'mr',
//   'name': 'Marathi',
// }, { 'key': 'ms-my', 'name': 'Malay' }, { 'key': 'ms', 'name': 'Malay' }, {
//   'key': 'mt',
//   'name': 'Maltese (Malta)',
// }, { 'key': 'my', 'name': 'Burmese' }, { 'key': 'nb', 'name': 'Norwegian Bokmål' }, {
//   'key': 'ne',
//   'name': 'Nepalese',
// }, { 'key': 'nl-be', 'name': 'Dutch (Belgium)' }, { 'key': 'nl', 'name': 'Dutch' }, {
//   'key': 'nn',
//   'name': 'Nynorsk',
// }, { 'key': 'oc-lnc', 'name': 'Occitan, lengadocian dialecte' }, {
//   'key': 'pa-in',
//   'name': 'Punjabi (India)',
// }, { 'key': 'pl', 'name': 'Polish' }, { 'key': 'pt-br', 'name': 'Portuguese (Brazil)' }, {
//   'key': 'pt',
//   'name': 'Portuguese',
// }, { 'key': 'rn', 'name': 'Kirundi' }, { 'key': 'ro', 'name': 'Romanian' }, {
//   'key': 'sd',
//   'name': 'Sindhi',
// }, { 'key': 'si', 'name': 'Sinhalese' }, { 'key': 'se', 'name': 'Northern Sami' }, {
//   'key': 'sk',
//   'name': 'Slovak',
// }, { 'key': 'sl', 'name': 'Slovenian' }, { 'key': 'sq', 'name': 'Albanian' }, {
//   'key': 'sr-cyrl',
//   'name': 'Serbian Cyrillic',
// }, { 'key': 'sr', 'name': 'Serbian' }, { 'key': 'ss', 'name': 'siSwati' }, {
//   'key': 'sv-fi',
//   'name': 'Finland Swedish',
// }, { 'key': 'sv', 'name': 'Swedish' }, { 'key': 'sw', 'name': 'Swahili' }, {
//   'key': 'ta',
//   'name': 'Tamil',
// }, { 'key': 'te', 'name': 'Telugu' }, { 'key': 'tg', 'name': 'Tajik' }, {
//   'key': 'tet',
//   'name': 'Tetun Dili (East Timor)',
// }, { 'key': 'th', 'name': 'Thai' }, { 'key': 'tk', 'name': 'Turkmen' }, {
//   'key': 'tl-ph',
//   'name': 'Tagalog (Philippines)',
// }, { 'key': 'tlh', 'name': 'Klingon' }, { 'key': 'tr', 'name': 'Turkish' }, {
//   'key': 'tzl',
//   'name': 'Talossan',
// }, { 'key': 'tzm-latn', 'name': 'Central Atlas Tamazight Latin' }, {
//   'key': 'ug-cn',
//   'name': 'Uyghur (China)',
// }, { 'key': 'tzm', 'name': 'Central Atlas Tamazight' }, { 'key': 'uk', 'name': 'Ukrainian' }, {
//   'key': 'ur',
//   'name': 'Urdu',
// }, { 'key': 'uz-latn', 'name': 'Uzbek Latin' }, { 'key': 'vi', 'name': 'Vietnamese' }, {
//   'key': 'uz',
//   'name': 'Uzbek',
// }, { 'key': 'yo', 'name': 'Yoruba Nigeria' }, { 'key': 'x-pseudo', 'name': 'Pseudo' }, {
//   'key': 'zh-cn',
//   'name': 'Chinese (China)',
// }, { 'key': 'zh-hk', 'name': 'Chinese (Hong Kong)' }, { 'key': 'zh-tw', 'name': 'Chinese (Taiwan)' }, {
//   'key': 'zh',
//   'name': 'Chinese',
// }, { 'key': 'rw', 'name': 'Kinyarwanda (Rwanda)' }, { 'key': 'ru', 'name': 'Russian' }];


const importWithHandler = (locale: string) => 
  import(locale)
    .catch((e) => console.log(e));

export const importLocales = {
  af: () => importWithHandler('dayjs/locale/af'),
  am: () => importWithHandler('dayjs/locale/am'),
  'ar-dz': () => importWithHandler('dayjs/locale/ar-dz'),
  'ar-iq': () => importWithHandler('dayjs/locale/ar-iq'),
  'ar-kw': () => importWithHandler('dayjs/locale/ar-kw'),
  'ar-ly': () => importWithHandler('dayjs/locale/ar-ly'),
  'ar-ma': () => importWithHandler('dayjs/locale/ar-ma'),
  'ar-sa': () => importWithHandler('dayjs/locale/ar-sa'),
  'ar-tn': () => importWithHandler('dayjs/locale/ar-tn'),
  ar: () => importWithHandler('dayjs/locale/ar'),
  az: () => importWithHandler('dayjs/locale/az'),
  be: () => importWithHandler('dayjs/locale/be'),
  bg: () => importWithHandler('dayjs/locale/bg'),
  bi: () => importWithHandler('dayjs/locale/bi'),
  bm: () => importWithHandler('dayjs/locale/bm'),
  'bn-bd': () => importWithHandler('dayjs/locale/bn-bd'),
  bn: () => importWithHandler('dayjs/locale/bn'),
  bo: () => importWithHandler('dayjs/locale/bo'),
  br: () => importWithHandler('dayjs/locale/br'),
  bs: () => importWithHandler('dayjs/locale/bs'),
  ca: () => importWithHandler('dayjs/locale/ca'),
  cs: () => importWithHandler('dayjs/locale/cs'),
  cv: () => importWithHandler('dayjs/locale/cv'),
  cy: () => importWithHandler('dayjs/locale/cy'),
  da: () => importWithHandler('dayjs/locale/da'),
  'de-at': () => importWithHandler('dayjs/locale/de-at'),
  'de-ch': () => importWithHandler('dayjs/locale/de-ch'),
  de: () => importWithHandler('dayjs/locale/de'),
  dv: () => importWithHandler('dayjs/locale/dv'),
  el: () => importWithHandler('dayjs/locale/el'),
  'en-au': () => importWithHandler('dayjs/locale/en-au'),
  'en-ca': () => importWithHandler('dayjs/locale/en-ca'),
  'en-gb': () => importWithHandler('dayjs/locale/en-gb'),
  'en-ie': () => importWithHandler('dayjs/locale/en-ie'),
  'en-il': () => importWithHandler('dayjs/locale/en-il'),
  'en-in': () => importWithHandler('dayjs/locale/en-in'),
  'en-nz': () => importWithHandler('dayjs/locale/en-nz'),
  'en-sg': () => importWithHandler('dayjs/locale/en-sg'),
  'en-tt': () => importWithHandler('dayjs/locale/en-tt'),
  en: () => importWithHandler('dayjs/locale/en'),
  eo: () => importWithHandler('dayjs/locale/eo'),
  'es-do': () => importWithHandler('dayjs/locale/es-do'),
  'es-mx': () => importWithHandler('dayjs/locale/es-mx'),
  'es-pr': () => importWithHandler('dayjs/locale/es-pr'),
  'es-us': () => importWithHandler('dayjs/locale/es-us'),
  es: () => importWithHandler('dayjs/locale/es'),
  et: () => importWithHandler('dayjs/locale/et'),
  eu: () => importWithHandler('dayjs/locale/eu'),
  fa: () => importWithHandler('dayjs/locale/fa'),
  fi: () => importWithHandler('dayjs/locale/fi'),
  fo: () => importWithHandler('dayjs/locale/fo'),
  'fr-ca': () => importWithHandler('dayjs/locale/fr-ca'),
  'fr-ch': () => importWithHandler('dayjs/locale/fr-ch'),
  fr: () => importWithHandler('dayjs/locale/fr'),
  fy: () => importWithHandler('dayjs/locale/fy'),
  ga: () => importWithHandler('dayjs/locale/ga'),
  gd: () => importWithHandler('dayjs/locale/gd'),
  gl: () => importWithHandler('dayjs/locale/gl'),
  'gom-latn': () => importWithHandler('dayjs/locale/gom-latn'),
  gu: () => importWithHandler('dayjs/locale/gu'),
  hi: () => importWithHandler('dayjs/locale/hi'),
  he: () => importWithHandler('dayjs/locale/he'),
  hr: () => importWithHandler('dayjs/locale/hr'),
  ht: () => importWithHandler('dayjs/locale/ht'),
  hu: () => importWithHandler('dayjs/locale/hu'),
  'hy-am': () => importWithHandler('dayjs/locale/hy-am'),
  id: () => importWithHandler('dayjs/locale/id'),
  is: () => importWithHandler('dayjs/locale/is'),
  'it-ch': () => importWithHandler('dayjs/locale/it-ch'),
  it: () => importWithHandler('dayjs/locale/it'),
  ja: () => importWithHandler('dayjs/locale/ja'),
  jv: () => importWithHandler('dayjs/locale/jv'),
  ka: () => importWithHandler('dayjs/locale/ka'),
  kk: () => importWithHandler('dayjs/locale/kk'),
  km: () => importWithHandler('dayjs/locale/km'),
  kn: () => importWithHandler('dayjs/locale/kn'),
  ko: () => importWithHandler('dayjs/locale/ko'),
  ku: () => importWithHandler('dayjs/locale/ku'),
  ky: () => importWithHandler('dayjs/locale/ky'),
  lb: () => importWithHandler('dayjs/locale/lb'),
  lo: () => importWithHandler('dayjs/locale/lo'),
  lt: () => importWithHandler('dayjs/locale/lt'),
  lv: () => importWithHandler('dayjs/locale/lv'),
  me: () => importWithHandler('dayjs/locale/me'),
  mi: () => importWithHandler('dayjs/locale/mi'),
  mk: () => importWithHandler('dayjs/locale/mk'),
  ml: () => importWithHandler('dayjs/locale/ml'),
  mn: () => importWithHandler('dayjs/locale/mn'),
  mr: () => importWithHandler('dayjs/locale/mr'),
  'ms-my': () => importWithHandler('dayjs/locale/ms-my'),
  ms: () => importWithHandler('dayjs/locale/ms'),
  mt: () => importWithHandler('dayjs/locale/mt'),
  my: () => importWithHandler('dayjs/locale/my'),
  nb: () => importWithHandler('dayjs/locale/nb'),
  ne: () => importWithHandler('dayjs/locale/ne'),
  'nl-be': () => importWithHandler('dayjs/locale/nl-be'),
  nl: () => importWithHandler('dayjs/locale/nl'),
  nn: () => importWithHandler('dayjs/locale/nn'),
  'oc-lnc': () => importWithHandler('dayjs/locale/oc-lnc'),
  'pa-in': () => importWithHandler('dayjs/locale/pa-in'),
  pl: () => importWithHandler('dayjs/locale/pl'),
  'pt-br': () => importWithHandler('dayjs/locale/pt-br'),
  pt: () => importWithHandler('dayjs/locale/pt'),
  rn: () => importWithHandler('dayjs/locale/rn'),
  ro: () => importWithHandler('dayjs/locale/ro'),
  sd: () => importWithHandler('dayjs/locale/sd'),
  si: () => importWithHandler('dayjs/locale/si'),
  se: () => importWithHandler('dayjs/locale/se'),
  sk: () => importWithHandler('dayjs/locale/sk'),
  sl: () => importWithHandler('dayjs/locale/sl'),
  sq: () => importWithHandler('dayjs/locale/sq'),
  'sr-cyrl': () => importWithHandler('dayjs/locale/sr-cyrl'),
  sr: () => importWithHandler('dayjs/locale/sr'),
  ss: () => importWithHandler('dayjs/locale/ss'),
  'sv-fi': () => importWithHandler('dayjs/locale/sv-fi'),
  sv: () => importWithHandler('dayjs/locale/sv'),
  sw: () => importWithHandler('dayjs/locale/sw'),
  ta: () => importWithHandler('dayjs/locale/ta'),
  te: () => importWithHandler('dayjs/locale/te'),
  tg: () => importWithHandler('dayjs/locale/tg'),
  tet: () => importWithHandler('dayjs/locale/tet'),
  th: () => importWithHandler('dayjs/locale/th'),
  tk: () => importWithHandler('dayjs/locale/tk'),
  'tl-ph': () => importWithHandler('dayjs/locale/tl-ph'),
  tlh: () => importWithHandler('dayjs/locale/tlh'),
  tr: () => importWithHandler('dayjs/locale/tr'),
  tzl: () => importWithHandler('dayjs/locale/tzl'),
  'tzm-latn': () => importWithHandler('dayjs/locale/tzm-latn'),
  'ug-cn': () => importWithHandler('dayjs/locale/ug-cn'),
  tzm: () => importWithHandler('dayjs/locale/tzm'),
  uk: () => importWithHandler('dayjs/locale/uk'),
  ur: () => importWithHandler('dayjs/locale/ur'),
  'uz-latn': () => importWithHandler('dayjs/locale/uz-latn'),
  vi: () => importWithHandler('dayjs/locale/vi'),
  uz: () => importWithHandler('dayjs/locale/uz'),
  yo: () => importWithHandler('dayjs/locale/yo'),
  'x-pseudo': () => importWithHandler('dayjs/locale/x-pseudo'),
  'zh-cn': () => importWithHandler('dayjs/locale/zh-cn'),
  'zh-hk': () => importWithHandler('dayjs/locale/zh-hk'),
  'zh-tw': () => importWithHandler('dayjs/locale/zh-tw'),
  zh: () => importWithHandler('dayjs/locale/zh'),
  rw: () => importWithHandler('dayjs/locale/rw'),
  ru: () => importWithHandler('dayjs/locale/ru'),
};