import L, { LatLng } from 'leaflet';
import { FC, useMemo } from 'react';
import { Marker } from 'react-leaflet';
import { getLengthByUnit } from '../../lib';
import { selectUnitsLength } from '../../slices';

interface Props {
  coords: LatLng | null;
}

export const TrackingPoint: FC<Props> = ({ coords }) => {
  const unitsLength = selectUnitsLength();
  const pointElevation = getLengthByUnit(coords?.alt || 0, unitsLength);

  const cssIcon = useMemo(
    () =>
      L.divIcon({
        html: `
    <div class='container'>
      <div class='point'></div>
      <div class='pulse_ring'></div>

      <div class='label_box'>  
        <div class='label'>Z: <b>${pointElevation.toFixed(1) || '...'} ${unitsLength}</b></div>
      </div>
    </div>
  `,
        iconSize: [22, 22],
        className: 'track_icon',
      }),
    [pointElevation, unitsLength]
  );

  return <>{coords && <Marker icon={cssIcon} position={coords} />}</>;
};
