import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLngExpression } from 'leaflet';
import { useAppSelector } from '../../hooks';
import { resetDrawMode, setDrawMode } from '../mapState';

const initialState = {
  isGpsMeasurementInitialized: false,
  isGpsTrackingStarted: false,
  gpsTrackingPoints: [] as LatLngExpression[],
};

export const gpsTrackingSlice = createSlice({
  name: 'gps',
  initialState,
  reducers: {
    addGpsTrackingPoint(state, action: PayloadAction<LatLngExpression | null>) {
      if (!action.payload) return;
      state.gpsTrackingPoints.push(action.payload);
    },
    resetGpsTrackingPoints(state) {
      state.gpsTrackingPoints = [];
    },
    setIsGpsMeasurementInitialized(state, action: PayloadAction<boolean>) {
      state.isGpsMeasurementInitialized = action.payload;
    },
    setGpsTrackingState(state, action: PayloadAction<boolean>) {
      state.isGpsTrackingStarted = action.payload;
    },
    cancelGpsTracking(state) {
      state.isGpsTrackingStarted = false;
      state.isGpsMeasurementInitialized = false;
      state.gpsTrackingPoints = [];
    },
  },
  selectors: {
    selectIsGpsTrackingStarted: (state) => state.isGpsTrackingStarted,
    selectGpsTrackingPoints: (state) => state.gpsTrackingPoints,
    selectIsGpsMeasurementInitialized: (state) => state.isGpsMeasurementInitialized,
  },
  extraReducers: (builder) => {
    builder.addCase(setDrawMode, (state, action) => {
      // if switch to manual/none mode then stop gps tracking
      if (action.payload === 'none') {
        state.isGpsTrackingStarted = false;
      }
    });
    builder.addCase(resetDrawMode, (state) => {
      state.gpsTrackingPoints = [];
    });
  },
});

export const selectIsGpsTrackingStarted = () => useAppSelector(gpsTrackingSlice.selectors.selectIsGpsTrackingStarted);
export const selectGpsTrackingPoints = () => useAppSelector(gpsTrackingSlice.selectors.selectGpsTrackingPoints);
export const selectIsGpsMeasurementInitialized = () =>
  useAppSelector(gpsTrackingSlice.selectors.selectIsGpsMeasurementInitialized);

export const {
  setGpsTrackingState,
  addGpsTrackingPoint,
  resetGpsTrackingPoints,
  cancelGpsTracking,
  setIsGpsMeasurementInitialized,
} = gpsTrackingSlice.actions;
