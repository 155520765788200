import { Box, Checkbox, FormControlLabel, Link, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Organization, PermissionsResponse } from 'shared/models';
import z from 'zod';
import { authApi } from '../../shared/api/AuthApi';
import { axiosBase } from '../../shared/axios';
import { useAppDispatch, useAppNavigator } from '../../shared/hooks';
import { handleApiError } from '../../shared/lib/errorHandling';
import { localStorageService } from '../../shared/services';
import { setToken, toggleProjectManagement } from '../../shared/slices';
import { AuthInput, BigTitle, Button, Row } from '../../shared/ui';

const forgotPasswordUrl = '/common/forgot-password';
const requestAccessUrl = '/common/request-access';

const checkRapidAccess = (organizations: Organization[]): boolean => {
  const checkOrganization = (org: Organization): boolean => {
    // Check the current organization's permissions
    if (org.permissions.applications['rapid-access']) {
      return true;
    }

    // Recursively check subOrganizations
    for (const subOrg of org.subOrganizations) {
      if (checkOrganization(subOrg)) {
        return true;
      }
    }

    return false;
  };

  // Check each organization in the given array
  for (const org of organizations) {
    if (checkOrganization(org)) {
      return true;
    }
  }

  return false;
};

const BlueText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '12px',
  fontWeight: 400,
  margin: '16px 0 8px 0px',
}));

export const Login = () => {
  const { t } = useTranslation();
  const { navigateToProjectManagement } = useAppNavigator();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isCheckPermissionLoading, setIsCheckPermissionLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const userLogin = localStorageService.userLogin;
    const userPassword = localStorageService.userPassword;
    setUsername(userLogin);
    setPassword(userPassword);
    setRememberMe(!!userPassword);
  }, []);

  const login = async () => {
    try {
      setLoading(true);

      const { data: response } = await authApi.login(username, password);

      if (rememberMe) {
        localStorageService.userLogin = username;
        localStorageService.userPassword = password;
      } else {
        localStorageService.removeUserCredentials();
      }

      checkPermission();
      dispatch(setToken(response.data.token));
      localStorageService.rapidToken = response.data.token;
      localStorageService.rapidUsername = response.data.name;
      localStorageService.userOrganizationId = response.data.organizationId.toString();
      dispatch(toggleProjectManagement());
    } catch (e) {
      handleApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const checkPermission = async () => {
    setIsCheckPermissionLoading(true);

    try {
      const permissionResponse = await axiosBase.get<PermissionsResponse>('/api/Organizations');

      if (!permissionResponse.data.data.length) {
        return;
      }

      const isRapidPermissionAble = checkRapidAccess(permissionResponse.data.data);

      // const isRapidPermissionAble = Object.values(permissions).some((p) => p.applications['rapid-access']);

      if (isRapidPermissionAble) {
        navigateToProjectManagement();
      } else {
        toast.error(t('errors_no_permission'));
      }
    } catch (error) {
      setIsCheckPermissionLoading(false);
      console.log('#### error:', error);
    } finally {
      setIsCheckPermissionLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      console.log('enter');
      event.preventDefault();

      const inputElement = event.target as HTMLInputElement;
      inputElement.blur();
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setUsername(value);
    const parsedEmail = z.string().email().safeParse(value);
    if (!parsedEmail.success) {
      setEmailError('Invalid email');
    } else {
      emailError && setEmailError('');
    }
  };

  const isSubmittable = !(username && password && !emailError);

  return (
    <Stack justifyContent="space-between" height="100%">
      <Box mt="60px">
        <BigTitle>{t('login_log_in')}</BigTitle>

        <Box sx={{ mt: '10px' }}>
          <AuthInput
            label={t('login_username')}
            name="username"
            placeholder={t('login_email')}
            value={username ?? ''}
            onChange={handleEmailChange}
            autoComplete="off"
            autoSave="off"
            error={!!emailError}
            helperText={emailError}
          />
          <AuthInput
            label={t('login_password')}
            isPassword
            name="password"
            placeholder={t('login_enter_password')}
            value={password ?? ''}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            autoSave="off"
          />

          <Row flexWrap="wrap">
            <Box sx={{ mt: '10px' }}>
              <FormControlLabel
                control={<Checkbox checked={rememberMe} onChange={(_, value) => setRememberMe(value)} />}
                label={
                  <Typography noWrap fontSize={12}>
                    {t('login_remember_me')}
                  </Typography>
                }
              />
            </Box>
            <Link component={RouterLink} to={forgotPasswordUrl}>
              <BlueText noWrap sx={{ color: '#007AFF', cursor: 'pointer' }}>
                {t('login_forgot_password')} ?
              </BlueText>
            </Link>
          </Row>

          <Box sx={{ height: '40px', my: '10px' }}>
            <Button
              loading={loading || isCheckPermissionLoading}
              btnColor="primary.main"
              fullWidth
              onClick={login}
              disabled={isSubmittable}
            >
              <Typography sx={{ fontWeight: 700 }}>{t('login_login')}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Row justifyContent="center" flexWrap="wrap" sx={{ mb: '30px' }}>
        <Typography sx={{ fontSize: '14px', mr: '10px' }}>{t('login_no_account')}</Typography>
        <Link component={RouterLink} to={requestAccessUrl}>
          <BlueText sx={{ color: '#007AFF', cursor: 'pointer', m: 0 }}>{t('login_request_access')}</BlueText>
        </Link>
      </Row>
    </Stack>
  );
};
