import { Button, Dialog, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface SpanProps extends PropsWithChildren {
  color: 'blue' | 'orange' | 'green';
}

const typographyStyles = {
  color: '#242731',
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '0.18px',
};

const SPAN_COLOR_DICTIONARY = {
  blue: '#0038FF',
  orange: '#FF9400',
  green: '#008766',
};

const SpanWithColor: FC<SpanProps> = ({ color, children }) => {
  return <span style={{ color: SPAN_COLOR_DICTIONARY[color] }}>{children}</span>;
};

export const LocationBlockedModal: FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          display: 'flex',
          padding: '48px 24px 40px 24px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          width: '86.4svw',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
      hideBackdrop
    >
      <Typography sx={typographyStyles}>
        ❗ Location Denied ❗<br />
        Grant location access in your phone settings.
      </Typography>

      <div>
        <Typography sx={{ ...typographyStyles, textAlign: 'left', fontWeight: 400 }}>
          How <SpanWithColor color={'blue'}>to</SpanWithColor> fix: <br />
        </Typography>
        <ol
          style={{
            ...typographyStyles,
            textAlign: 'left',
            fontWeight: 400,
            paddingLeft: '25px',
            margin: 0,
          }}
        >
          <li>Go to phone Settings</li>
          <li>
            Scroll down <SpanWithColor color={'orange'}>and</SpanWithColor>{' '}
            <SpanWithColor color={'blue'}>select</SpanWithColor>{' '}
            <SpanWithColor color={'green'}>”App permissions”</SpanWithColor>
          </li>
          <li>
            Find the Browser you are using <SpanWithColor color={'orange'}>and</SpanWithColor>{' '}
            <SpanWithColor color={'blue'}>enable</SpanWithColor>{' '}
            <SpanWithColor color={'green'}>”Location”</SpanWithColor>
          </li>
          <li>
            It is highly recommended to <SpanWithColor color={'blue'}>enable</SpanWithColor>{' '}
            <SpanWithColor color={'green'}>“Precise location”</SpanWithColor>
          </li>
        </ol>
      </div>

      <Button
        onClick={onClose}
        sx={{
          display: 'flex',
          width: '126px',
          padding: '8px 16px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          border: '1px solid #000',
          background: '#FFF',
          color: '#000',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '130%',
          letterSpacing: '0.18px',
        }}
      >
        Close
      </Button>
    </Dialog>
  );
};
