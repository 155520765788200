import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type ErrorBoundaryProps = {
  children?: React.ReactNode;
  error: string;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children, error } = this.props;

    if (hasError) {
      console.error('App error is happened: ', error);
      toast.error('App error is happened: ' + error);
      return <Navigate to={'/main/home'} />;
    }

    return children;
  }
}
