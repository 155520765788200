import { GenericAbortSignal } from 'axios';
import { toast } from 'react-toastify';
import { NO_CONNECTION_MESSAGE } from 'shared/constants';
import { isOnline } from 'shared/lib';
import { axiosBase } from '../axios';
import { Nullable } from '../index';
import {
  BaseResponse,
  Project,
  ProjectDataFromServer,
  ProjectFromServer,
  SharedProject,
  SharedProjectArgs,
} from '../models';

export const projectApi = {
  async exportProjectByEmail(projectId: Nullable<number>, email: string, language?: string) {
    return await axiosBase.post<BaseResponse<boolean>>('/api/Rapid/pdf', {
      projectId: projectId,
      language,
      email,
    });
  },

  async shareProjectByEmail(args: SharedProjectArgs) {
    return await axiosBase.post<BaseResponse<SharedProject>>('/api/Project/share-project', args);
  },

  async getProjects() {
    return await axiosBase.get<BaseResponse<ProjectFromServer[]>>('/api/Project/projects');
  },

  async createProject(args: Omit<ProjectDataFromServer, 'geoJSON'>) {
    const { projectName, customerName, polygonCount, lineCount, pointCount } = args;
    return await axiosBase.post<BaseResponse<Project>>('/api/Project/projects', {
      project: JSON.stringify({
        projectName,
        customerName,
        polygonCount,
        lineCount,
        pointCount,
        geoJSON: { type: 'FeatureCollection', features: [] },
      }),
    });
  },

  async updateProject(id: Nullable<number>, updateModel: ProjectDataFromServer) {
    const project = JSON.stringify({
      projectName: updateModel.projectName,
      customerName: updateModel.customerName,
      geoJSON: updateModel.geoJSON,
      polygonCount: updateModel.polygonCount,
      lineCount: updateModel.lineCount,
      pointCount: updateModel.pointCount,
    });

    return await axiosBase.put<BaseResponse<ProjectFromServer>>('/api/Project/projects', { project, id });
  },

  async deleteProject(id: number) {
    const body = { data: { id } };

    return await axiosBase.delete<BaseResponse<Project>>('/api/Project/projects', body);
  },

  async uploadImage(imageSource: string, signal?: GenericAbortSignal) {
    if (!isOnline()) {
      toast.error(NO_CONNECTION_MESSAGE);
      return;
    }

    const { data: responseData } = await axiosBase.post<BaseResponse<{ imageURL: string }>>(
      '/api/Project/projects/uploadimage',
      { imageSource },
      { signal }
    );
    return responseData.data.imageURL;
  },
};
