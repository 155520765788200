import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/he';
import 'dayjs/locale/ro';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import { SORT_BY } from 'shared/enums';
import { Project } from 'shared/models';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';

export type METRIC_DEFAULTS = {
  Pressure: 'm';
  FlowPerLength: 'lph/100m';
  Flow: 'lph';
  TotalFlow: 'm³/h';
  Length: 'm';
  Velocity: 'm/s';
  PipeDiameter: 'mm';
  ValveDiameter: 'mm';
  EmitterSpacing: 'm';
  LateralSpacing: 'm';
  Area: 'Ha';
  AppnDepth: 'mm';
  AppnRate: 'mm/h';
};

// commit

export const isOnline = () => {
  return navigator.onLine;
};

export function ConvertUnit(value: number, sourceUnit: string, destUnit: string, unitType: string | null) {
  const f1 = GetMetricFactor(unitType, sourceUnit);
  const f2 = GetMetricFactor(unitType, destUnit);
  return (Math.fround(value) * Math.fround(f2)) / Math.fround(f1);
}

export function Math_round(val: number, places: number) {
  if (places == null) places = 0;
  return Math.round(val * Math.pow(10, places)) / Math.pow(10, places);
}

function GetMetricFactor(unitType: string | null, unit: string) {
  let factor = 1.0;
  switch (unitType) {
    case 'Flow':
      switch (unit) {
        case 'lpm':
          factor = 1.0 / 60.0;
          break;
        case 'gph':
          factor = 0.2642;
          break;
        case 'gpm':
          factor = 0.004403;
          break;
      }
      break;
    case 'PipeDiameter':
    case 'ValveDiameter':
      switch (unit) {
        case 'in':
          factor = 0.03937;
          break;
        case 'mm':
          factor = 1;
          break;
        case 'cm':
          factor = 0.1;
          break;
        case 'm':
          factor = 0.001;
          break;
        case 'ft':
          factor = 0.003281;
          break;
      }
      break;
    default:
      switch (unit) {
        case 'psi':
          factor = 1.422;
          break;
        case 'ft':
          factor = 3.28083;
          break;
        case 'bar':
          factor = 0.09804;
          break;
        case 'kpa':
          factor = 9.804;
          break;
        case 'kg/cm²':
          factor = 0.1;
          break;
        case 'atm':
          factor = 0.09678;
          break;
        case 'lph/m':
          factor = 0.01;
          break;
        case 'gpm/100ft':
          factor = 0.00134;
          break;
        case 'gpm/ft':
          factor = 1.34e-5;
          break;
        case 'm³/m':
          factor = 1.0 / 60.0;
          break;
        case 'm³/s':
          factor = 1.0 / 3600.0;
          break;
        case 'lps':
          factor = 1.0 / 3.6;
          break;
        case 'lpm':
          factor = 60.0 / 3.6;
          break;
        case 'lph':
          factor = 1000;
          break;
        case 'gpm':
          factor = 4.403;
          break;
        case 'gps':
          factor = 0.07338;
          break;
        case 'gph':
          factor = 264.2;
          break;
        //m
        case 'km':
          factor = 1.0 / 1000.0;
          break;
        case 'cm':
          factor = 100.0;
          break;
        case 'miles':
          factor = 0.0006214;
          break;
        case 'in':
          factor = 3.28083 * 12;
          break;
        case 'mm':
          factor = 1000.0;
          break;
        //"m/s"
        case 'ft/s':
          factor = 3.28083;
          break;
        case 'kph':
          factor = 3.6;
          break;
        case 'mph':
          factor = 2.237;
          break;
        //ha
        case 'm²':
          factor = 10000.0;
          break;
        case 'dunam':
          factor = 10.0;

          break;
        case 'acres':
          factor = 2.471;
          break;
        case 'ft²':
          factor = 107600.0;
          break;
        //"mm/h"
        case 'in/h':
          factor = 0.03937;
          break;
        default:
          factor = 1.0;
          break;
      }
      break;
  }
  return factor;
}

export const textFormatter = (template: string, args: string[] | number[]) => {
  let formatted = template;

  const regexp = new RegExp(/%\w+%/g);
  const matchResult = formatted.match(regexp);
  const inputData: {
    [value: string]: string | number;
  } = {};

  if (matchResult && matchResult.length > 0) {
    for (let i = 0; i < matchResult.length; i++) {
      formatted = formatted.replace(matchResult[i], `${args[i]}`);
      const newLocal = matchResult[i].replace(/%/gi, '');
      inputData[newLocal] = args[i];
    }
    return { output: formatted, input: inputData, template };
  }
  for (let i = 0; i < args.length; i++) {
    inputData[i + 1] = args[i];
  }
  for (let i = 0; i < args.length; i++) {
    const regexp = new RegExp(`\\{{${i + 1}\\}}`, 'gi');
    formatted = formatted.replace(regexp, `${args[i]}`);
  }
  return { output: formatted, input: inputData, template };
};

export const getStringWithoutFirstZero = (value: string, type: string) => {
  if (type !== 'number') return value;

  const input = value.split('');
  if (input?.[0] === '0' && input?.[1] !== '.' && input.length > 1) {
    input[0] = '';
    return input.join('');
  }
  return value;
};

export const isValidJSON = (jsonValue: string) => {
  try {
    const json = JSON.parse(jsonValue);
    return !!json;
  } catch (e) {
    return false;
  }
};

export const sortProjects = (p1: Project, p2: Project, sortBy: SORT_BY) => {
  let value1: number | string;
  let value2: number | string;

  switch (sortBy) {
    case SORT_BY.NAME:
      value1 = p1.project.projectName;
      value2 = p2.project.projectName;

      break;
    case SORT_BY.DATE_CREATED:
      value1 = new Date(p1.createDate).getTime();
      value2 = new Date(p2.createDate).getTime();
      break;
    case SORT_BY.LAST_MODIFIED:
      value1 = p1.modifyDate ? new Date(p1.modifyDate).getTime() : 0;
      value2 = p2.modifyDate ? new Date(p2.modifyDate).getTime() : 0;
      break;
  }

  if (value1 > value2) return 1;
  if (value1 < value2) return -1;
  return 0;
};

export const getFirstIndexesOfSortGroups = (projects: Project[], sortBy: SORT_BY) => {
  const indexes: number[] = [];
  const uniqLabels: string[] = [];

  projects.forEach((p, i) => {
    const value = getSortedByProjectValue(p, sortBy);
    if (!uniqLabels.includes(value)) {
      uniqLabels.push(value);
      indexes.push(i);
    }
  });

  return indexes;
};

export const getSortedByProjectValue = (p: Project, sortBy: SORT_BY, locale = 'en') => {
  switch (sortBy) {
    case SORT_BY.NAME:
      return `${p.project.projectName[0]}...`;

    case SORT_BY.DATE_CREATED:
      return dayjs(p.createDate).locale(locale).format('MMM DD, YYYY');

    case SORT_BY.LAST_MODIFIED:
      return p.modifyDate ? dayjs(p.modifyDate).locale(locale).format('MMM DD, YYYY') : 'not_yet_modified';
  }
};

export const capitalize = (string: string) => {
  if (!string) return string; // Return if the string is empty or null
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Function to filter unique objects by a specific field using Map
export const filterUniqueByField = <T>(arr: T[], field: keyof T): T[] => {
  const map = new Map();
  arr.forEach((item) => {
    if (!map.has(item[field])) {
      map.set(item[field], item);
    }
  });
  return Array.from(map.values());
};

export const isAddressInCoordinates = (input: string) => {
  const coordRegex = /^-?\d{1,2}(\.\d+)?,\s*-?\d{1,3}(\.\d+)?$/;
  return coordRegex.test(input.trim());
};

export const getRelatedPolygonId = (rowDirectionLineId: string) => {
  const removeRowDirectionIdRegex = new RegExp(POLYGON_ROW_DIRECTION_ID + '\\S*', 'g');
  const relatedPolygonId = rowDirectionLineId.replace(removeRowDirectionIdRegex, '').trim();
  return relatedPolygonId;
};
