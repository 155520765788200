import { ProjectInfo } from 'shared/models';
import { localStorageService } from '../services';

const MAX_RECENT_PROJECTS = 3;

export const addRecentProjectToLS = (recentProject: ProjectInfo) => {
  const recentProjects = JSON.parse(localStorageService.recentProjects ?? '[]') as ProjectInfo[];

  const projectIndex = recentProjects.findIndex((project) => project.id === recentProject.id);

  if (projectIndex !== -1) {
    const filteredProjects = [...recentProjects.slice(0, projectIndex), ...recentProjects.slice(projectIndex + 1)];

    const updatedProjects = [recentProject, ...filteredProjects];

    localStorageService.recentProjects = JSON.stringify(updatedProjects);

    return;
  }

  const formattedProjects =
    recentProjects.length >= MAX_RECENT_PROJECTS ? [...recentProjects.slice(0, -1)] : recentProjects;

  const updatedProjects = [recentProject, ...formattedProjects];

  localStorageService.recentProjects = JSON.stringify(updatedProjects);
};

export const getRecentProjectsFromLS = (projects: ProjectInfo[]) => {
  const projectsFromLS = JSON.parse(localStorageService.recentProjects ?? '[]') as ProjectInfo[];

  return projectsFromLS.filter((projectLS) => projects.some((p) => p.id === projectLS.id && projectLS.name));
};
