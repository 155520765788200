import { axiosBase } from '../axios';
import { Auth, BaseResponse, UserData } from '../models';

export const authApi = {
  async me() {
    return axiosBase.get<BaseResponse<UserData>>('/api/Auth/me');
  },
  async login(username: string, password: string) {
    return await axiosBase.post<BaseResponse<Auth>>('/api/Auth/login', { username, password });
  },
};
