import { Box, Button, CircularProgress, darken, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from 'shared/models';
import { Icon, WhiteModal } from 'shared/ui';
import { useMediaQuery } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';
import z from 'zod';
import { useExportProject } from '../../../widgets/SideMenu/hooks';
import { EmailAutocomplete } from '../../EmailAutocomplete';
import { useEmailSuggestion } from '../../TransferProjectModal/hooks/useEmailSuggestion';

interface Props {
  open: boolean;
  onClose: () => void;
  project: Project;
}

type EmailInput = {
  id: string;
  value: string;
  error?: string;
};

export const ProjectExportModal: FC<Props> = ({ open, onClose, project }) => {
  const { t } = useTranslation();
  const { addTransferEmailSuggestion } = useEmailSuggestion();

  const shouldReduceSpacing = useMediaQuery('(max-width:370px)');

  const [emailList, setEmailList] = useState<EmailInput[]>([{ id: uuid(), value: '' }]);
  const { onExportProjectBulk, loading } = useExportProject(
    () => setEmailList([{ id: uuid(), value: '' }]),
    project.id
  );

  const onExportClick = async () => {
    const checkedEmails = emailList.map((email) => {
      const parsedEmail = z.string().email('Invalid email').safeParse(email.value);
      if (!parsedEmail.success) {
        return { ...email, error: parsedEmail.error.flatten().formErrors.pop() || 'Input error occurred' };
      }
      return email;
    });

    if (checkedEmails.some((checked) => checked?.error?.length)) {
      setEmailList(checkedEmails);
    } else {
      const emails = checkedEmails.map((email) => {
        addTransferEmailSuggestion(email.value);
        return email.value;
      });
      await onExportProjectBulk(emails);
      onClose();
    }
  };

  // const handleAddEmaiil = () => {
  //   setEmailList([...emailList, { id: uuid(), value: '' }]);
  // };

  const handleEmailChange = (id: string, value: string) => {
    setEmailList(
      emailList.map((email) =>
        email.id === id ? (email.error ? { ...email, value, error: '' } : { ...email, value }) : email
      )
    );
  };

  const handleDeleteEmail = (id: string) => {
    if (emailList.length < 2) return;
    setEmailList(emailList.filter((email) => email.id !== id));
  };

  const onHandleClose = () => {
    !loading && onClose();
  };

  return (
    <WhiteModal open={open} onClose={onHandleClose} hideCloseIcon sx={{ width: '84.2666666667svw', my: 6, mx: 'auto' }}>
      <Stack direction="row" spacing={shouldReduceSpacing ? 0.5 : 1.5}>
        <Box sx={{ display: 'flex', width: '22px', justifyContent: 'center' }}>
          <Icon sx={{ width: 22 }} path={'email-green-icon'} />
        </Box>
        <Typography sx={{ fontSize: 18 }}>{t('send_by_mail_modal_send_project_by_mail')}</Typography>
      </Stack>

      {emailList.length > 0 &&
        emailList.map((email) => (
          <EmailAutocomplete
            id={email.id}
            key={email.id}
            open={open}
            email={email.value}
            onEmailChange={handleEmailChange}
            error={email.error}
            onDelete={handleDeleteEmail}
            showDelete={emailList.length > 1}
          />
        ))}

      <Button
        disabled={!emailList.length}
        onClick={onExportClick}
        sx={{
          display: 'flex',
          width: '126px',
          height: '38px',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 1.5,
          mx: 'auto',
          borderRadius: '8px',
          background: '#1D8742',
          color: '#FFF',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          '&:hover': {
            backgroundColor: darken('#1D8742', 0.1),
            color: 'white',
          },
        }}
      >
        {loading ? <CircularProgress size={20} color={'info'} /> : t('export_project_send')}
      </Button>
    </WhiteModal>
  );
};
