import {
  addGpsTrackingPoint,
  selectDrawMode,
  selectGpsTrackingPoints,
  selectIsGpsMeasurementInitialized,
  selectIsGpsTrackingStarted,
} from '../slices';
import { useAppDispatch } from './useAppDispatch';
import { useGeolocated } from 'react-geolocated';
import { useEffect, useState } from 'react';
import { LatLng, LatLngExpression } from 'leaflet';
import { useMap } from 'react-leaflet';
import { Nullable } from '../index';
import { checkIfPointExistsInItem } from '../lib';

export const useGpsTracking = () => {
  const [trackingPoint, setTrackingPoint] = useState<Nullable<LatLngExpression>>();
  const map = useMap();

  const gpsTrackingPoints = selectGpsTrackingPoints();
  const isGpsTrackingStarted = selectIsGpsTrackingStarted();
  const isGpsMeasurementInitialized = selectIsGpsMeasurementInitialized();

  const drawMode = selectDrawMode();
  const isGpsEnabled = drawMode === 'gps';

  const dispatch = useAppDispatch();

  const isShowTrackingLine = isGpsMeasurementInitialized && gpsTrackingPoints.length;

  const { coords: geoCoords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000,
    },
    suppressLocationOnMount: false,
    watchPosition: true,
  });

  const isMoving = !!(geoCoords?.speed && geoCoords.speed > 0);

  useEffect(() => {
    if (!isGpsTrackingStarted) {
      return;
    }
    if (geoCoords?.longitude && geoCoords?.latitude) {
      const convertedCoords = { lat: geoCoords.latitude, lng: geoCoords.longitude } as LatLng;
      const isPointExists = checkIfPointExistsInItem(convertedCoords, gpsTrackingPoints as LatLng[]);
      if (!isPointExists && isMoving) {
        setTrackingPoint(convertedCoords);
        map.setView(convertedCoords);
        dispatch(addGpsTrackingPoint(convertedCoords));
      }
    }

    //// Fake Movement //////
    // let index = 0;
    // const timer = setInterval(() => {
    //   if (index === fakeMovement.length - 1) {
    //     index = 0;
    //     dispatch(resetGpsTrackingPoints());
    //   }
    //   const convertedCoords = { lat: fakeMovement[index].latitude, lng: fakeMovement[index].longitude } as LatLng;
    //   dispatch(addGpsTrackingPoint(convertedCoords));
    //   setTrackingPoint(convertedCoords);
    //   map.setView(convertedCoords);
    //   index++;
    // }, 700);
    // return () => {
    //   clearInterval(timer);
    // };
    //// Fake Movement //////
  }, [isGpsTrackingStarted, geoCoords]);

  return {
    trackingPoint,
    setTrackingPoint,
    isGpsEnabled,
    isShowTrackingLine,
    isGpsTrackingStarted,
    gpsTrackingPoints,
    geoCoords,
  };
};
