import { InputBase, InputBaseProps } from '@mui/material';
import { FC } from 'react';

export const CoordInput: FC<InputBaseProps> = (props) => {
  return (
    <InputBase
      type="number"
      sx={{
        borderRadius: '10px',
        border: '1px solid white',
        width: '110px',
        color: 'white',
        px: '10px',
      }}
      {...props}
    />
  );
};
