import { LatLngLiteral, PathOptions } from 'leaflet';
import { FC, useEffect, useMemo, useState } from 'react';
import { Polyline, useMap } from 'react-leaflet';

import { LineModel, LinePoint, Point } from '../../../shared/models';
import { useAppDispatch } from '../../../shared/hooks';
import {
  deleteLinePoint,
  dragLinePoint,
  saveDragLinePointAction,
  selectBasePointId,
  selectCurrentLinePointId,
  selectIsColorPicker,
  selectIsItemDrawing,
  selectIsLineDrawing,
  selectLineId,
  selectLines,
  selectNewLine,
  selectPoints,
  selectPolygons,
  selectSaveProjectStep,
  setCurrentLinePointId,
} from '../../../shared/slices';
import { DistanceLine, DraggablePoint } from '../../../shared/ui';
import { SavingStep } from 'shared/enums';
import { useThrottle } from 'shared/hooks/useThrottle';
import { checkPointsIsTooClosely, getRefLinePointByCounterClockWise } from 'shared/lib';
import { ArrowheadsPolyline } from '../components/ArrowheadsPolyline';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';

interface Props {
  lineInfo: LineModel;
  eventHandlers: {
    click(): void;
  };
}

export const BaseLineItem: FC<Props> = ({ lineInfo, eventHandlers }) => {
  const dispatch = useAppDispatch();
  const map = useMap();

  const isDrawing = selectIsItemDrawing();
  const isColorPicker = selectIsColorPicker();
  const saveProjectStep = selectSaveProjectStep();
  const isLineDrawing = selectIsLineDrawing();
  const selectedLineId = selectLineId();
  const lines = selectLines();
  const newLine = selectNewLine();
  const currentLinePointId = selectCurrentLinePointId();
  const basePointId = selectBasePointId();
  const points = selectPoints();
  const polygons = selectPolygons();

  const [tooNearPointId, setTooNearPointId] = useState<string | null>(null);
  const [nearPoint, setNearPoint] = useState<Point | null>(null);
  const [isPointMoving, setIsPointMoving] = useState(false);
  const [totalObjectsPoints, setTotalObjectsPoints] = useState<Point[]>([]);

  const options: PathOptions = useMemo(() => {
    const { id, color, dashArray, weight } = lineInfo.geoJson;

    if (selectedLineId !== id && saveProjectStep === SavingStep.SCREEN_SHOOTING) {
      return { opacity: 0 };
    }
    let dashNumber = null;

    if (Array.isArray(dashArray)) {
      const result = dashArray.find((item) => parseInt(item));
      dashNumber = result ? result : '0';
    }
    const lineOptions = {
      color,
      weight,
      opacity: 1,
      lineCap: 'round',
      dashArray: dashNumber ? dashNumber : dashArray[0],
    };
    const selectedOptions = {
      color: 'white',
      opacity: 1,
      weight: 3,
      dashArray: dashNumber ? dashNumber : dashArray[0],
    };

    return selectedLineId === id && !isLineDrawing && saveProjectStep !== SavingStep.SCREEN_SHOOTING
      ? selectedOptions
      : lineOptions;
  }, [isLineDrawing, selectedLineId, lines, newLine, saveProjectStep]);

  const isVisibleElements =
    selectedLineId === lineInfo.geoJson.id && isLineDrawing && saveProjectStep !== SavingStep.SCREEN_SHOOTING;

  const onSelectPoint = (pointId: string) => dispatch(setCurrentLinePointId(pointId));
  const onSaveStartDragPosition = (oldPos: LatLngLiteral) => {
    dispatch(saveDragLinePointAction(oldPos));
    setIsPointMoving(true);
  };
  const throttledUpdatePos = useThrottle((newPos: LatLngLiteral) => dispatch(dragLinePoint(newPos)), 30);
  const onChangePosition = (newPos: LatLngLiteral) => throttledUpdatePos(newPos);
  const onDeletePoint = () => {
    if (tooNearPointId) {
      dispatch(deleteLinePoint(currentLinePointId ?? ''));
    }
    setTooNearPointId(null);
  };

  useEffect(() => {
    if (!isDrawing) {
      const filteredLines = lines
        .filter((l) => l.geoJson.id !== lineInfo.geoJson.id)
        .filter((l) => !l.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID));

      const allPoints = [...points, ...filteredLines, ...polygons].flatMap((i) => i.geoJson.points);
      setTotalObjectsPoints(allPoints);
    }
  }, [lines, polygons, points, isDrawing]);

  // snap to another object's point
  useEffect(() => {
    if (selectedLineId === lineInfo.geoJson.id && currentLinePointId && isPointMoving) {
      const linePoints = lineInfo.geoJson.points;
      const selectedPoint = linePoints.find((p) => p.id === currentLinePointId) as Point;

      if (selectedPoint) {
        const selCoords = map.latLngToContainerPoint(selectedPoint.coords);

        let foundNearPoint: Point | null = null;

        for (const p of totalObjectsPoints) {
          const pointCoords = map.latLngToContainerPoint(p.coords);
          const isNear = checkPointsIsTooClosely(selCoords, pointCoords);

          if (isNear) {
            foundNearPoint = p;
            break;
          }
        }

        setNearPoint(foundNearPoint);
      }
    }
  }, [lineInfo]);

  // line points
  useEffect(() => {
    if (selectedLineId === lineInfo.geoJson.id && currentLinePointId) {
      const points = lineInfo.geoJson.points;
      const selectedPoint = points.find((p) => p.id === currentLinePointId) as Point;

      if (selectedPoint) {
        const selCoords = map.latLngToContainerPoint(selectedPoint.coords);

        points.forEach((p) => {
          if (p.id === currentLinePointId) return;

          const pointCoords = map.latLngToContainerPoint(p.coords);
          const isNear = checkPointsIsTooClosely(selCoords, pointCoords);

          if (isNear) {
            setTooNearPointId(p.id);
          } else {
            if (tooNearPointId === p.id) setTooNearPointId(null);
          }
        });
      }
    }
  }, [lineInfo]);

  const referencePoint = useMemo(() => {
    if (selectedLineId === lineInfo.geoJson.id && lineInfo.geoJson.points.length > 1 && currentLinePointId) {
      const points = lineInfo.geoJson.points;

      return getRefLinePointByCounterClockWise(currentLinePointId, points);
    }
  }, [currentLinePointId, tooNearPointId]);

  const selectedPoint = useMemo(() => {
    if (selectedLineId === lineInfo.geoJson.id && currentLinePointId) {
      const points = lineInfo.geoJson.points;
      return points.find((p) => p.id === currentLinePointId) as Point;
    }
  }, [lineInfo, currentLinePointId, tooNearPointId]);

  const calculateThirdPoint = (point1: LatLngLiteral, point2: LatLngLiteral) => {
    const latDiff = point2.lat - point1.lat;
    const lngDiff = point2.lng - point1.lng;

    const newLat = point1.lat + 0.03 * latDiff;
    const newLng = point1.lng + 0.03 * lngDiff;

    return { lat: newLat, lng: newLng };
  };
  const getEndPosition = (points: Point[]) => {
    return [
      calculateThirdPoint(points[points.length - 1].coords, points[points.length - 2].coords),
      points[points.length - 1].coords,
    ];
  };
  const getStartPosition = (points: Point[]) => {
    return [calculateThirdPoint(points[0].coords, points[1].coords), points[0].coords];
  };
  const getOptionsForArrow = (option: PathOptions) => {
    const optionsForArrow = { ...option };
    optionsForArrow.dashArray = [0];
    return optionsForArrow;
  };
  const isSetStyleOption = (option: string) => {
    const dashArray = lineInfo.geoJson.dashArray;
    return dashArray.includes(option);
  };

  const snapToObject = () => {
    if (nearPoint) {
      dispatch(dragLinePoint(nearPoint.coords));
    }
    setIsPointMoving(false);
    setNearPoint(null);
  };

  const onDragEnd = () => {
    onDeletePoint();
    snapToObject();
  };

  const linearPoints = lineInfo.geoJson.points.filter((p) => !p.parentPointId);

  const additionalPoints = lineInfo.geoJson.points.filter(
    (p): p is LinePoint & { connectedWith: string[] } => p.parentPointId !== undefined
  );

  return (
    <>
      <Polyline pathOptions={options} positions={linearPoints.map((p) => p.coords)} eventHandlers={eventHandlers} />

      {lineInfo.geoJson.points[0] && lineInfo.geoJson.points[1] && (
        <>
          {isSetStyleOption('startArrowheads') && (
            <ArrowheadsPolyline
              key={`start-${options.color}`}
              pathOptions={getOptionsForArrow(options)}
              positions={getStartPosition(lineInfo.geoJson.points)}
              eventHandlers={eventHandlers}
              arrowheads={{ size: '10px', frequency: 1 }}
            />
          )}

          {isSetStyleOption('endArrowheads') && (
            <ArrowheadsPolyline
              key={`end-${options.color}`}
              pathOptions={getOptionsForArrow(options)}
              positions={getEndPosition(lineInfo.geoJson.points)}
              eventHandlers={eventHandlers}
              arrowheads={{ size: '10px', frequency: 1 }}
            />
          )}
        </>
      )}

      <Polyline
        pathOptions={{ weight: 20, opacity: 0 }}
        positions={linearPoints.map((p) => p.coords)}
        eventHandlers={eventHandlers}
      />

      {/* non linear points */}
      {additionalPoints.map((p) => {
        const firstPoint = lineInfo.geoJson.points.find((ap) => ap.id === p.parentPointId);

        if (firstPoint) {
          return (
            <div key={p.id}>
              <Polyline pathOptions={options} positions={[firstPoint.coords, p.coords]} eventHandlers={eventHandlers} />

              <Polyline
                pathOptions={{ weight: 20, opacity: 0 }}
                positions={[firstPoint.coords, p.coords]}
                eventHandlers={eventHandlers}
              />
            </div>
          );
        }
      })}

      {isVisibleElements &&
        [...lineInfo.geoJson.points].map((p) => (
          <DraggablePoint
            key={p.id}
            pointInfo={p}
            currentPointId={currentLinePointId}
            basePointId={basePointId}
            onSelect={() => onSelectPoint(p.id)}
            onChangePosition={onChangePosition}
            onSaveStartDragPosition={onSaveStartDragPosition}
            disabled={isColorPicker}
            tooNearPointId={tooNearPointId}
            isSnapTargetPointId={Boolean(nearPoint)}
            onDragEnd={onDragEnd}
            isReferencePoint={p.id === referencePoint?.id}
          />
        ))}

      {isVisibleElements && currentLinePointId && lineInfo.geoJson.points.length > 1 && (
        <DistanceLine
          points={[selectedPoint as Point, referencePoint as Point]}
          onClose={() => onSelectPoint(currentLinePointId)}
        />
      )}
    </>
  );
};
