import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { projectApi } from 'shared/api';
import { axiosBase } from 'shared/axios';
import { handleApiError } from 'shared/lib/errorHandling';
import { selectProject } from 'shared/slices';

export const useShareProject = (callback: () => void) => {
  const { t, i18n } = useTranslation();
  const { id } = selectProject();
  const [loading, setLoading] = useState(false);

  const shareErrorMessageConfig = useMemo(
    () => ({
      already_exist: t('notifications_already_shared'),
      unknown_share_error: t('notifications_share_error'),
    }),
    [t, i18n]
  );

  const onShareProject = async (email: string) => {
    try {
      setLoading(true);

      const { data: responseData } = await projectApi.shareProjectByEmail({
        permissionType: 3,
        organizationId: null,
        projectId: id,
        email,
      });

      const { succeeded, error } = responseData;

      if (succeeded) {
        toast.success(t('notifications_project_shared'));
        callback();
      } else {
        const errorMessage = error?.message || 'unknown_share_error';
        toast.warn(shareErrorMessageConfig[errorMessage as keyof typeof shareErrorMessageConfig]);
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setLoading(false);
    }
  };

  const isUserExist = async (email: string) => {
    const body = { organizationId: 0, email };

    try {
      setLoading(true);

      const { data } = await axiosBase.post('/api/User/exist', body);

      const { userExistIn }: { userExistIn: boolean } = data?.data ?? {};

      return userExistIn;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { loading, onShareProject, isUserExist };
};
