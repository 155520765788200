import { Box, BoxProps } from '@mui/material';
import { SVGProps } from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
} & SVGProps<SVGElement> &
  BoxProps;

export const PauseMeasurementIcon = ({ fill = '#5E6662', width = 16, height = 19, ...rest }: Props) => {
  return (
    <Box component={'a'} sx={{ display: 'inline-block', verticalAlign: 'top', marginRight: '7px', ...rest.sx }}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.54167 0.017984H1.45833C0.652918 0.017984 0 -0.303084 0 1.76798V16.268C0 18.3391 0.652918 18.018 1.45833 18.018H5.54167C6.34708 18.018 7 18.3391 7 16.268V1.76798C7 -0.303084 6.34708 0.017984 5.54167 0.017984Z"
          fill={fill}
        />
        <path
          d="M14.5417 0.017984H10.4583C9.65292 0.017984 9 -0.303084 9 1.76798V16.268C9 18.3391 9.65292 18.018 10.4583 18.018H14.5417C15.3471 18.018 16 18.3391 16 16.268V1.76798C16 -0.303084 15.3471 0.017984 14.5417 0.017984Z"
          fill={fill}
        />
      </svg>
    </Box>
  );
};
