import { Box } from '@mui/material';
import { FC } from 'react';
import { COLORS } from '../../constants';
import { Icon } from '../Icon';
import { Row } from '../Row';

interface Props {
  color: string;
  onSelect: (color: string) => void;
  isBorders?: boolean;
  isOpacityToggler?: boolean;
  onToggleOpacity?: () => void;
  fillOpacity?: number;
}

export const ColorsPanel: FC<Props> = ({
  color,
  onSelect,
  isBorders,
  isOpacityToggler,
  onToggleOpacity,
  fillOpacity,
}) => {
  return (
    <Row justifyContent="center">
      {isOpacityToggler && (
        <Box
          onClick={onToggleOpacity}
          sx={{
            m: '2px',
            mt: 2,
            height: '26px',
            width: '28px',
            position: 'relative',
          }}
        >
          <Icon path="mapIcons/opacity_toggle" sx={{ height: '28px', width: '28px' }} />

          {fillOpacity === 0 && (
            <Box
              sx={{
                border: '3px solid #fff',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '28px',
                height: '28px',
                borderRadius: '3px',
              }}
            />
          )}
        </Box>
      )}

      <Row sx={{ mt: 2 }}>
        {COLORS.map((c) => (
          <Box
            onClick={() => onSelect(c)}
            key={c}
            sx={{
              position: 'relative',
              borderRadius: '4px',

              height: '28px',
              width: '28px',

              ...(isBorders
                ? {
                    m: '2px 4px',
                    border: '3px solid #fff',

                    ...(c === color ? { borderWidth: '6px' } : { borderColor: c }),
                  }
                : { m: '2px', bgcolor: c, ...(c === color && fillOpacity !== 0 && { border: '3px solid #fff' }) }),
            }}
          >
            {isBorders && c === color && (
              <Box
                sx={{
                  border: '4px solid #fff',
                  borderColor: c,
                  position: 'absolute',
                  top: -6,
                  left: -6,
                  width: '29px',
                  height: '29px',
                  borderRadius: '3px',
                }}
              />
            )}
          </Box>
        ))}
      </Row>
    </Row>
  );
};
