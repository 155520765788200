import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { handleApiError } from '../lib/errorHandling';
import { selectIsGpsTrackingStarted } from '../slices';

export const useWakeLock = () => {
  const isGpsTrackingStarted = selectIsGpsTrackingStarted();

  useEffect(() => {
    let wakeLock: WakeLockSentinel | null = null;
    const enableWakeLock = async () => {
      if ('wakeLock' in navigator) {
        try {
          wakeLock = await navigator.wakeLock.request('screen');
          console.log('Wake Lock is working');
        } catch (err) {
          handleApiError(err);
          toast.error("Can't lock device screen from dimming");
        }
      } else {
        toast.warn('WakeLock Api is not supported by this browser');
      }
    };

    const disableWakeLock = async () => {
      if (wakeLock && !wakeLock.released) {
        await wakeLock.release();
        console.log('Wake Lock has been released');
      }
    };

    if (isGpsTrackingStarted) {
      enableWakeLock();
    } else {
      disableWakeLock();
    }

    return () => {
      if (wakeLock) {
        wakeLock.release();
        console.log('Wake Lock has been released');
      }
    };
  }, [isGpsTrackingStarted]);
};
