import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { CatalogItem, Group, GroupsBody, GroupsResponse, PipesData, PipesResponse } from '../../models';
import { useAppSelector } from '../../hooks';

interface PipeState {
  pipesLoading: boolean;
  pipeGroupsLoading: boolean;
  pipes: CatalogItem[];
  pipesLoadedOnce: boolean;
  pipeGroupsLoadedOnce: boolean;
  pipeGroups: Group[];
}

const initialState: PipeState = {
  pipesLoading: true,
  pipeGroupsLoading: true,
  pipes: [],
  pipeGroupsLoadedOnce: false,
  pipesLoadedOnce: false,
  pipeGroups: [],
};

export const getPipesThunk = createAsyncThunk('catalogs/getPipes', async (params: PipesData, thunkAPI) => {
  const options = { params };

  try {
    const response = await axios.get<PipesResponse>(
      '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass', options,
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getPipeGroupsThunk = createAsyncThunk(
  'groupTypes/getPipeGroupTypes',
  async (params: GroupsBody, thunkAPI) => {
    const options = { params };

    try {
      const response = await axios.get<GroupsResponse>(
        '/ht/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        options,
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  },
);

export const pipesSlice = createSlice({
  name: 'pipes',
  initialState,
  reducers: {
    resetPipes: () => initialState,
  },
  selectors: {
    selectPipes: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPipesThunk.pending.type, (state) => {
        state.pipesLoading = true;
      })
      .addCase(getPipesThunk.fulfilled.type, (state, action: PayloadAction<PipesResponse>) => {
        state.pipes = action.payload.GetCatalogsByClassResult.RootResults;
        state.pipesLoading = false;
        if (!state.pipesLoadedOnce) state.pipesLoadedOnce = true;
      })
      .addCase(getPipesThunk.rejected.type, (state) => {
        state.pipesLoading = false;
      })
      .addCase(getPipeGroupsThunk.pending.type, (state) => {
        state.pipeGroupsLoading = true;
      })
      .addCase(getPipeGroupsThunk.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.pipeGroups = action.payload.GetGroupTypesResult.RootResults;
        state.pipeGroupsLoading = false;
        if (!state.pipeGroupsLoadedOnce) state.pipeGroupsLoadedOnce = true;
      })
      .addCase(getPipeGroupsThunk.rejected.type, (state) => {
        state.pipeGroupsLoading = false;
      });
  },
});

export const { resetPipes } = pipesSlice.actions;
export const selectPipes = () => useAppSelector(pipesSlice.selectors.selectPipes);

export const pipesReducer = pipesSlice.reducer;
