import { CircularProgress, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'shared/ui';
import { useAppDispatch } from 'shared/hooks';
import { Point } from 'shared/models';
import { v4 as makeId } from 'uuid';
import { selectCurrentMapItem, setDelineationPoints } from 'shared/slices';
import { fitItemToViewPort } from 'shared/lib';
import { useMap } from 'react-leaflet';
import { DrawFieldDelineationPopup } from '../DrawFieldDelineationPopup';
import { farmApi } from 'shared/api/FarmApi';

export const DrawModeFieldDelineation: FC = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'manually' | 'not-supported'>('manually');
  const map = useMap();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const currentMapItem = selectCurrentMapItem();

  const fetchField = async () => {
    try {
      if (!currentMapItem) return;

      setLoading(true);

      const { lat, lng } = currentMapItem.geoJson.points[0].coords;

      const response = await farmApi.getLocationData({ lat, lon: lng });

      const formattedPoints: Point[] = [];

      if (response.data?.features.length) {
        const locationData = response.data.features[0];

        /** responses could be as object with Feature[] or Feature[][] so we handle two cases @see {@link FarmAPILocationDataResponse} */

        if (Array.isArray(locationData)) {
          locationData.forEach((data) => {
            data.geometry.coordinates.forEach((coords) => {
              coords.forEach((coord) =>
                formattedPoints.push({ id: makeId(), coords: { lat: coord[1], lng: coord[0] } })
              );
            });
          });
        } else {
          locationData.geometry.coordinates.forEach((coords) => {
            coords.forEach((coord) => formattedPoints.push({ id: makeId(), coords: { lat: coord[1], lng: coord[0] } }));
          });
        }

        dispatch(setDelineationPoints(formattedPoints));

        fitItemToViewPort(map, formattedPoints);

        return;
      }
      setType('manually');
      setOpen(true);
    } catch (e) {
      setType('not-supported');
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        position: 'fixed',
        zIndex: 400,
        width: '100%',
        bottom: '150px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {!open && (
        <Stack
          sx={{
            justifyContent: 'center',
            bgcolor: 'primary.main',
            padding: '8px 42px',
            borderRadius: '16px',
            border: '1px solid #FFF',
          }}
        >
          <Row onClick={fetchField} sx={{ cursor: 'pointer', alignItems: 'center', gap: 2 }}>
            {loading ? (
              <CircularProgress color={'info'} size={20} />
            ) : (
              <Typography sx={{ fontSize: 18, lineHeight: '130%', color: '#fff' }}>
                {t('field_delineation_get_points')}
              </Typography>
            )}
          </Row>
        </Stack>
      )}

      <DrawFieldDelineationPopup type={type} open={open} onClose={() => setOpen(false)} />
    </Stack>
  );
};
