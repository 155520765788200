import { Box, Collapse, Grid, MenuItem, RadioGroup, Stack, Typography } from '@mui/material';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalSelect, CheckBox, FreeSoloInput, PolygonInput, Radio, Select } from 'shared/ui';
import { UNITS } from 'shared/constants';
import { PointInformation, PointModel, PointProperties } from 'shared/models';
import { usePointSelectItems } from './hooks';
import { textFormatter } from 'shared/lib';
import { useChangeUnit } from '../../shared/hooks/useChangeUnit';
import { selectUnits } from '../../shared/slices';

interface Props {
  point: PointModel;
  value: PointInformation;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUnit: (value: number, unit: string, fieldName: keyof PointProperties) => void;
  copySettingsFromOptions: { id: string; name: string }[];
  onChangeCopySettingsFrom: (e: any, item: { id: number | string; name: string } | string) => void;
  copySettingsFromObject: string | null;
}

export const PointIrrigationProperties: FC<Props> = ({
  point,
  value,
  onChange,
  onChangeUnit,
  copySettingsFromOptions,
  onChangeCopySettingsFrom,
  copySettingsFromObject,
}) => {
  const { t } = useTranslation();

  const {
    units: { Pressure },
  } = selectUnits();

  const {
    waterTypes,
    filtrationTypes,
    filtrationModes,
    pumpTypes,
    fertigationElectricTypes,
    fertigationWaterMotivatedTypes,
    operationAndControlCommunicationType,
  } = usePointSelectItems();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  const unitRefs: Record<string, React.RefObject<HTMLInputElement>> = {
    pointDiameter: useRef(null),
    pointPressure: useRef(null),
    pointFlowRate: useRef(null),
  };

  const { handleChangeUnit } = useChangeUnit(onChangeUnit, unitRefs);

  const onCopySettingsFromBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const settings = copySettingsFromOptions.find((csf) => csf.name === value);

    if (settings) {
      onChangeCopySettingsFrom(null, settings);
    }
  };

  return (
    <Box>
      {(copySettingsFromOptions.length > 1 ||
        (copySettingsFromOptions.length === 1 && copySettingsFromOptions[0].id !== point.geoJson.id)) && (
        <FreeSoloInput
          options={copySettingsFromOptions}
          name="lineCopySettingsFrom"
          value={copySettingsFromObject ?? ''}
          onBlur={onCopySettingsFromBlur}
          onChange={onChangeCopySettingsFrom}
          placeholder={t('forms_point_name')}
          label={t('forms_copy_settings_from')}
        />
      )}

      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <PolygonInput
            inputRef={unitRefs.pointDiameter}
            label={t('forms_diameter')}
            placeholder={t('Enter')}
            name="pointDiameter"
            type="number"
            value={value.pointDiameter || ''}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            // helperText={t('validation_errors_null')}
            // error={isNoValidPolygonDailyConsumption}
            additionalInput={
              <AdditionalSelect
                name="pointDiameterUnit"
                withGreenBorder
                value={value.pointDiameterUnit}
                onChange={handleChangeUnit}
              >
                {UNITS.PipeDiameter.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </AdditionalSelect>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <PolygonInput
            inputRef={unitRefs.pointPressure}
            label={t('forms_pressure')}
            value={value.pointPressure || ''}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            placeholder={t('Enter')}
            name="pointPressure"
            type="number"
            helperText={t('validation_errors_null')}
            additionalInput={
              <AdditionalSelect
                withGreenBorder
                name="pointPressureUnit"
                value={value.pointPressureUnit}
                onChange={handleChangeUnit}
              >
                {UNITS.Pressure.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </AdditionalSelect>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <PolygonInput
            inputRef={unitRefs.pointFlowRate}
            label={t('forms_flow_rate')}
            placeholder={t('Enter')}
            value={value.pointFlowRate || ''}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            name="pointFlowRate"
            type="number"
            helperText={t('validation_errors_null')}
            // error={isNoValidPolygonRowSpacing}
            additionalInput={
              <AdditionalSelect
                withGreenBorder
                name="pointFlowRateUnit"
                value={value.pointFlowRateUnit}
                onChange={handleChangeUnit}
              >
                {UNITS.TotalFlow.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </AdditionalSelect>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Select
            label={t('forms_water_type')}
            placeholder="Select"
            value={value.pointWaterType}
            onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
            name="pointWaterType"
          >
            {waterTypes.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <CheckBox
        label={t('forms_filtration')}
        small
        name="isFiltration"
        checked={value.isFiltration}
        onChange={onChange}
        sx={{ mt: 1 }}
      />
      <Collapse in={value.isFiltration}>
        <div>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1 }}>{`${t('is_existing')}:`}</Typography>

            <RadioGroup
              value={value.isExistingFiltration}
              onChange={(e) => onChange(e)}
              name="isExistingFiltration"
              row
              sx={{ flexWrap: 'nowrap', mt: 0.5 }}
            >
              <Radio value={true} label={t('yes')} />
              <Radio value={false} label={t('no')} />
            </RadioGroup>
          </Stack>

          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_type')}
                placeholder="Enter"
                name="pointFiltrationType"
                value={value.pointFiltrationType}
                onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
              >
                {filtrationTypes.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Select
                label={t('forms_mode')}
                placeholder="Enter"
                name="pointFiltrationMode"
                value={value.pointFiltrationMode}
                onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
              >
                {filtrationModes.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </div>
      </Collapse>

      <CheckBox
        label={t('forms_pumping')}
        small
        name="isPumping"
        checked={value.isPumping}
        onChange={onChange}
        sx={{ mt: 1 }}
      />
      <Collapse in={value.isPumping}>
        <div>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1 }}>{`${t('is_existing')}:`}</Typography>

            <RadioGroup
              value={value.isExistingPump}
              onChange={(e) => onChange(e)}
              name="isExistingPump"
              row
              sx={{ flexWrap: 'nowrap', mt: 0.5 }}
            >
              <Radio value={true} label={t('yes')} />
              <Radio value={false} label={t('no')} />
            </RadioGroup>
          </Stack>

          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Select
                label={t('forms_type')}
                placeholder="Enter"
                name="pointPumpType"
                value={value.pointPumpType}
                onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
              >
                {pumpTypes.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <PolygonInput
                label={t('forms_horse_power')}
                placeholder={t('Enter')}
                value={value.pointPumpValue || ''}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                name="pointPumpValue"
                type="number"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <PolygonInput
                label={textFormatter(t('forms_pump_capacity'), ['m³/hr']).output}
                placeholder={t('Enter')}
                value={value.pointPumpCapacity || ''}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                name="pointPumpCapacity"
                type="number"
              />
            </Grid>

            <Grid item xs={6}>
              <PolygonInput
                label={textFormatter(t('forms_pump_pressure'), [Pressure]).output}
                placeholder={t('Enter')}
                value={value.pointPumpPressure || ''}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                name="pointPumpPressure"
                type="number"
              />
            </Grid>
          </Grid>
        </div>
      </Collapse>

      <CheckBox
        label={t('forms_fertigation')}
        small
        name="isFertigation"
        checked={value.isFertigation}
        onChange={onChange}
        sx={{ mt: 1 }}
      />
      <Collapse in={value.isFertigation}>
        <div>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1 }}>{`${t('is_existing')}:`}</Typography>

            <RadioGroup
              value={value.isExistingFertigation}
              onChange={(e) => onChange(e)}
              name="isExistingFertigation"
              row
              sx={{ flexWrap: 'nowrap', mt: 0.5 }}
            >
              <Radio value={true} label={t('yes')} />
              <Radio value={false} label={t('no')} />
            </RadioGroup>
          </Stack>

          <RadioGroup
            value={value.isElectricFertigation}
            onChange={(e) => onChange(e)}
            row
            name="isElectricFertigation"
            sx={{ flexWrap: 'nowrap', mt: 1 }}
          >
            <Radio value={true} label={t('electric')} />
            <Radio value={false} label={t('water_motivated')} />
          </RadioGroup>

          {value.isElectricFertigation && (
            <Select
              label={t('forms_type')}
              name="pointElectricFertigationType"
              value={value.pointElectricFertigationType}
              onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
            >
              {fertigationElectricTypes.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}

          {!value.isElectricFertigation && (
            <Select
              label={t('forms_type')}
              name="pointWaterMotivatedFertigationType"
              value={value.pointWaterMotivatedFertigationType}
              onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
            >
              {fertigationWaterMotivatedTypes.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </Collapse>
      <CheckBox
        label={t('forms_operation_and_control')}
        small
        name="isOperationAndControl"
        checked={value.isOperationAndControl}
        onChange={onChange}
        sx={{ mt: 1 }}
      />
      <Collapse in={value.isOperationAndControl}>
        <Box>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1 }}>{`${t('is_existing')}:`}</Typography>

            <RadioGroup
              value={value.isExistingOperationAndControl}
              onChange={(e) => onChange(e)}
              name="isExistingOperationAndControl"
              row
              sx={{ flexWrap: 'nowrap', mt: 0.5 }}
            >
              <Radio value={true} label={t('yes')} />
              <Radio value={false} label={t('no')} />
            </RadioGroup>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1 }}>{`${t('forms_mode')}:`}</Typography>

            <RadioGroup
              value={value.operationAndControlMode}
              onChange={(e) => onChange(e)}
              name="operationAndControlMode"
              row
              sx={{ flexWrap: 'nowrap', mt: 0.5 }}
            >
              {/* use translate from point_filtration_modes_automatic to prevent transl. duplicates */}
              <Radio
                value={'automatic'}
                checked={value.operationAndControlMode === 'automatic'}
                label={t('point_filtration_modes_automatic')}
              />
              {/* use translate from point_filtration_modes_manual to prevent transl. duplicates */}
              <Radio
                value={'manual'}
                checked={value.operationAndControlMode === 'manual'}
                label={t('point_filtration_modes_manual')}
              />
            </RadioGroup>
          </Stack>
          <Select
            label={t('forms_communication_type')}
            name="operationAndControlCommunicationType"
            value={value.operationAndControlCommunicationType}
            onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
          >
            {operationAndControlCommunicationType.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Collapse>
    </Box>
  );
};
