import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import z from 'zod';
import { TransferProjectModal } from '../../features';
import { useEmailSuggestion } from '../../features/TransferProjectModal/hooks/useEmailSuggestion';
import { useShareProject } from '../SideMenu/hooks/useShareProject';

interface Props {
  open: boolean;
  onClose: () => void;
}

const shareFieldsSchema = z.string().email('Invalid email');

export const ShareProject: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { loading, onShareProject, isUserExist } = useShareProject(onClose);

  const { transferEmailSuggestions, addTransferEmailSuggestion } = useEmailSuggestion();

  const onSubmit = (email: string) => {
    const parsedEmail = shareFieldsSchema.safeParse(email);
    if (!parsedEmail.success) {
      setError(parsedEmail.error.flatten().formErrors.pop() || 'Input error occurred');
    } else {
      onShareProject(email);
      addTransferEmailSuggestion(email);
    }
  };

  return (
    <TransferProjectModal
      open={open}
      loading={loading}
      onSubmit={onSubmit}
      onClose={onClose}
      title={t('share_project_rapid_user')}
      buttonText={t('share_project_share')}
      iconType={'share'}
      inputError={error}
      clearError={() => setError('')}
      isUserExist={isUserExist}
      transferEmailSuggestions={transferEmailSuggestions}
    />
  );
};
