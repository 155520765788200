import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../../models';
import { localStorageService } from '../../services';
import { useAppSelector } from '../../hooks';
import { authApi } from '../../api/AuthApi';
import { handleApiError } from '../../lib/errorHandling';

interface AuthState {
  loading: boolean;
  token: string | null;
  userData: UserData;
}

const initialState: AuthState = {
  token: null,
  userData: {} as UserData,
  loading: false,
};

export const getMeThunk = createAsyncThunk('getMe', async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await authApi.me();
    if (!responseData.succeeded || responseData.error) {
      return rejectWithValue(responseData.error);
    }
    localStorageService.userOrganizationsIds = JSON.stringify(responseData.data.relatedOrganizationsIds);
    return responseData.data;
  } catch (e) {
    handleApiError(e);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = null;
      localStorageService.removeRapidUsername();
      localStorageService.removeRapidToken();

      // temporary solution for api (waiting for proper documentation for farm-api)
      localStorageService.removeFarmToken();
    },
  },
  selectors: {
    selectAuth: (state) => state,
    selectAuthToken: (state) => state.token,
    selectAuthUserData: (state) => state.userData,
    selectAuthLoading: (state) => state.loading,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMeThunk.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.userData = action.payload;
        state.loading = false;
      })
      .addCase(getMeThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setToken, logout } = authSlice.actions;
export const selectAuth = () => useAppSelector(authSlice.selectors.selectAuth);
export const selectAuthToken = () => useAppSelector(authSlice.selectors.selectAuthToken);

export const authReducer = authSlice.reducer;
