import { Box, styled } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { FC, useState } from 'react';
import { v4 as makeId } from 'uuid';
import { projectApi } from '../../shared/api';
import { useAppDispatch } from '../../shared/hooks';
import { Image } from '../../shared/models';
import {
  selectFormState,
  selectLineId,
  selectPointId,
  selectPolygonId,
  setLineInformation,
  setOpenedImageId,
  setPointInformation,
  setPolygonInformation,
  toggleImagesView,
} from '../../shared/slices';
import { ImageInput, Row } from '../../shared/ui';

const BoxImage = styled(Box)({
  width: '88px',
  height: '88px',
  minWidth: '88px',
  border: '1px solid grey',
  borderRadius: '16px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

interface Props {
  images: Image[];
}

export const ItemImagesForm: FC<Props> = ({ images }) => {
  const dispatch = useAppDispatch();
  {
    /* Disable screenshotting temporary */
  }
  // const { saveProjectStep } = selectMap();
  const [isLoading, setIsLoading] = useState(false);

  const selectedPolygonId = selectPolygonId();
  const selectedLineId = selectLineId();
  const selectedPointId = selectPointId();

  const { polygonInformation, lineInformation, pointInformation } = selectFormState();

  {
    /* Disable screenshotting temporary */
  }
  // const isSnapShot = images.find((image) => image.isSnapShot);

  const onOpenViewerWithImg = (id: string) => {
    dispatch(setOpenedImageId(id));
    dispatch(toggleImagesView());
  };

  const handleAddImage = async (e: any) => {
    setIsLoading(true);
    const file = e.target.files[0];

    // Options for image compression
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 800, // Maximum width or height in pixels
      useWebWorker: true, // Use web worker for better performance
    };

    try {
      const compressedFile = await imageCompression(file, options);

      const fileReader = new FileReader();
      fileReader.addEventListener('load', async () => {
        const file = fileReader.result as string;

        const url = await projectApi.uploadImage(file);

        if (url) {
          const image = { id: makeId(), url, title: '' };

          if (selectedPolygonId) {
            dispatch(setPolygonInformation({ images: [...polygonInformation.images, image] }));
          }
          if (selectedLineId) {
            dispatch(setLineInformation({ images: [...lineInformation.images, image] }));
          }
          if (selectedPointId) {
            dispatch(setPointInformation({ images: [...pointInformation.images, image] }));
          }
        }
      });
      fileReader.readAsDataURL(compressedFile as Blob);
    } catch (error) {
      console.error('Error during image compression', error);
    } finally {
      setIsLoading(false);
    }
  };

  {
    /* Disable screenshotting temporary */
  }
  // const takeScreenShot = async () => {
  //   dispatch(setShouldTakeScreenshot(true));
  //   dispatch(setSaveProjectStep(SavingStep.SCREEN_SHOOTING));
  // };

  return (
    <Row
      justifyContent="flex-start"
      sx={{
        width: '100%',
        gap: '12px',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': { height: 0 },
      }}
    >
      {images.map(({ id, url }) => (
        <BoxImage onClick={() => onOpenViewerWithImg(id)} key={id} sx={{ backgroundImage: `url(${url})` }} />
      ))}

      {/* Disable screenshotting temporary */}
      {/*{!isSnapShot && (*/}
      {/*  <BoxImage*/}
      {/*    sx={{*/}
      {/*      position: 'relative',*/}
      {/*      overflow: 'hidden',*/}
      {/*    }}*/}
      {/*    onClick={takeScreenShot}*/}
      {/*  >*/}
      {/*    {saveProjectStep === SavingStep.SCREEN_SHOOTING && (*/}
      {/*      <CircularProgress size={40} sx={{ color: 'primary.main' }} />*/}
      {/*    )}*/}

      {/*    {saveProjectStep !== SavingStep.SCREEN_SHOOTING && (*/}
      {/*      <Text*/}
      {/*        sx={{*/}
      {/*          position: 'absolute',*/}
      {/*          top: '50%',*/}
      {/*          left: '50%',*/}
      {/*          transform: 'translate(-50%,-50%)',*/}
      {/*          fontSize: '16px',*/}
      {/*          textAlign: 'center',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Generate Snapshot*/}
      {/*      </Text>*/}
      {/*    )}*/}
      {/*  </BoxImage>*/}
      {/*)}*/}

      <BoxImage
        sx={{
          backgroundImage: isLoading ? 'none' : `url(/images/big_green_plus.svg) `,
          backgroundSize: 'auto auto',
          overflow: 'hidden',
        }}
      >
        <ImageInput image={''} onChange={handleAddImage} loading={isLoading} />
      </BoxImage>
    </Row>
  );
};
