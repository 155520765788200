import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface Props extends BoxProps {
  path: string;
}

export const Icon: React.FC<Props> = ({ path, ...props }) => {
  return <Box component="img" src={`/images/${path}.svg`} alt={path} {...props} />;
};
