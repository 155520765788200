import getArea from '@turf/area';
import getBoolClockWise from '@turf/boolean-clockwise';
import getCenter from '@turf/center-of-mass';
import getDistance from '@turf/distance';
import getEnvelope from '@turf/envelope';
import {
  lineString,
  LineString,
  point as turfPoint,
  Point as tPoint,
  points as getPoints,
  Polygon,
  polygon as turfPolygon,
  Properties,
} from '@turf/helpers';
import getMidPoint from '@turf/midpoint';
import getNearest from '@turf/nearest-point-on-line';
import {
  bbox,
  bearing,
  booleanPointInPolygon,
  centroid,
  destination,
  distance,
  explode,
  Feature,
  featureCollection,
  lineIntersect,
  pointOnFeature,
  Position,
  randomPoint,
} from '@turf/turf';
import { LatLng, LatLngBoundsLiteral, LatLngLiteral, Point as LeafletPoint, PointExpression } from 'leaflet';
import { LineModel, LinePoint, Point, PointModel, PolygonModel } from '../../models';

export const getPolygonArea = (calcPoints: Position[], unit: string) => {
  const jsonArea = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [[...calcPoints, calcPoints[0]]],
    },
  };

  const areaInHa = +(getArea(jsonArea as Feature) / 10000).toFixed(2);
  return getPolygonAreaByUnit(areaInHa, unit);
};

export const getPolygonAreaByUnit = (valueInHa: number, unit: string) => {
  let koef = 1;

  switch (unit) {
    case 'm²':
      koef = 10000;
      break;
    case 'ft²':
      koef = 107639;
      break;

    case 'acres':
      koef = 2.47105;
      break;

    case 'dunam':
      koef = 10;
      break;
  }

  return +(valueInHa * koef).toFixed(2);
};

export const getPerimeter = (calcPoints: Position[], unit: string, isLine?: boolean) => {
  const pointPairs: Position[][] = [];

  if (calcPoints.length < 2) {
    return 0;
  }

  if (calcPoints.length === 2) {
    const pair = [calcPoints[0], calcPoints[1]];
    pointPairs.push(pair);
  } else {
    for (let i = 0; i < calcPoints.length; i++) {
      if (i + 1 === calcPoints.length && isLine) {
        break;
      }
      const first = i;
      const second = i + 1 === calcPoints.length ? 0 : i + 1;

      const pair = [calcPoints[first], calcPoints[second]];
      pointPairs.push(pair);
    }
  }
  let perimeter = 0;

  for (let i = 0; i < pointPairs.length; i++) {
    const from = pointPairs[i][0];
    const to = pointPairs[i][1];
    perimeter += getDistance(from, to, { units: 'kilometers' });
  }

  const perimeterImMeters = +(perimeter * 1000).toFixed(1);
  return getLengthByUnit(perimeterImMeters, unit);
};

export const getLengthByUnit = (valueInMeters: number, unit: string) => {
  let koef = 1;

  switch (unit) {
    case 'mm':
      koef = 1000;
      break;
    case 'cm':
      koef = 100;
      break;
    case 'in':
      koef = 39.3701;
      break;
    case 'ft':
      koef = 3.28084;
      break;
    case 'miles':
      koef = 0.000621371;
      break;
    case 'km':
      koef = 0.001;
      break;
  }

  return +(valueInMeters * koef).toFixed(2);
};

export const getMiddlePoints = (calcPoints: Position[], isLine?: boolean): LatLngLiteral[] => {
  const pointPairs: Position[][] = [];

  if (calcPoints.length < 2) {
    return [];
  } else if (calcPoints.length === 2) {
    const pair = [calcPoints[0], calcPoints[1]];
    pointPairs.push(pair);
  } else {
    for (let i = 0; i < calcPoints.length; i++) {
      if (i + 1 === calcPoints.length && isLine) {
        break;
      }
      const first = i;
      const second = i + 1 === calcPoints.length ? 0 : i + 1;

      const pair = [calcPoints[first], calcPoints[second]];
      pointPairs.push(pair);
    }
  }
  const calculated: Position[] = [];

  for (let i = 0; i < pointPairs.length; i++) {
    const point1 = pointPairs[i][0];
    const point2 = pointPairs[i][1];
    const midPoint = getMidPoint(point1, point2).geometry.coordinates;

    calculated.push(midPoint);
  }

  return calculated.map((p) => ({ lat: p[0], lng: p[1] }) as LatLngLiteral);
};

export const getProjectItemsBounds = (
  polygons: PolygonModel[],
  lines: LineModel[],
  points: PointModel[]
): LatLngBoundsLiteral => {
  const allPolygonPositions = polygons
    .map((pol) => pol.geoJson.points)
    .flat()
    .map((p) => getPositionFromLatLng(p.coords));
  const allLinePositions = lines
    .map((l) => l.geoJson.points)
    .flat()
    .map((p) => getPositionFromLatLng(p.coords));
  const allPointPositions = points
    .map((p) => p.geoJson.points)
    .flat()
    .map((p) => getPositionFromLatLng(p.coords));

  const positions: Position[] = [...allPolygonPositions, ...allLinePositions, ...allPointPositions];

  const collection = featureCollection(positions.map((pos) => turfPoint(pos)));

  const envelop = getEnvelope(collection);

  if (!envelop.bbox) {
    throw Error('No envelope found.');
  }

  return [
    [envelop.bbox[0], envelop.bbox[1]],
    [envelop.bbox[2], envelop.bbox[3]],
  ];
};

export const getFeatureBounds = (points: Point[]) => {
  const positions = points.map((p) => getPositionFromLatLng(p.coords));

  const collection = featureCollection(positions.map((pos) => turfPoint(pos)));

  const envelop = getEnvelope(collection);

  return [
    [envelop?.bbox?.[0], envelop?.bbox?.[1]],
    [envelop?.bbox?.[2], envelop.bbox?.[3]],
  ];
};

export const getNearestPointIndex = (target: Point, calcPoints: Point[]) => {
  if (calcPoints.length === 0) return 0;
  const targetPos = getPositionFromLatLng(target.coords);
  const positions = calcPoints.map((p) => getPositionFromLatLng(p.coords));

  const p = getNearest(lineString([...positions, positions[0]]), targetPos);

  const indexOfNearestPoint = Number(p.properties.index) || 0;

  // if points of polygon less than 3 there could not be its line overlapping
  if (calcPoints.length > 2) {
    // if true then set proper index to prevent overlapping of polygons edges
    if (isLinesIntersect(indexOfNearestPoint, positions, targetPos)) {
      return indexOfNearestPoint;
    }
  }

  return indexOfNearestPoint + 1;
};

/**
 * Check for polygon edges overlapping
 * @param indexOfNearestPoint
 * @param points
 * @param targetPos
 * @return true or false
 */
const isLinesIntersect = (indexOfNearestPoint: number, points: Position[], targetPos: Position) => {
  const lastPointsIndex = points.length - 1;
  const indexOfPrevPoint = indexOfNearestPoint > 0 ? indexOfNearestPoint - 1 : lastPointsIndex;
  const indexOfNextPoint = indexOfNearestPoint === lastPointsIndex ? 0 : indexOfNearestPoint + 1;

  const lineBeforeNearest = lineString([points[indexOfPrevPoint], points[indexOfNearestPoint]]);
  const lineFromTargetToAfterNearest = lineString([targetPos, points[indexOfNextPoint]]);

  // if [features].length is more than 0 there is overlapping
  return lineIntersect(lineBeforeNearest, lineFromTargetToAfterNearest).features.length > 0;
};

export const getAzimuth = (point1: Position, point2: Position) => {
  const y1 = point1[0];
  const x1 = point1[1];
  const y2 = point2[0];
  const x2 = point2[1];

  if (y1 === y2) return 90;
  if (x1 === x2) return 0;

  const dx = x1 - x2;
  const dy = y1 - y2;
  return Math.atan2(dx, dy) * (180 / Math.PI);
};

export const getMapAngle = (point1: Position, point2: Position) => {
  const az = getAzimuth(point1, point2);

  let angle = 0;

  if (az > 0 && az <= 90) angle = -az;
  if (az > 90 && az <= 180) angle = 180 - az;
  if (az < 0 && az >= -90) angle = Math.abs(az);
  if (az < -90 && az >= -180) angle = Math.abs(az) - 180;

  const dev = getAngleDeviation(angle);

  if (angle > 0) angle += dev;
  if (angle < 0) angle -= dev;

  return angle;
};

export const getIconAnchorByAngle = (angle: number) => {
  const koef = Math.abs(angle) > 60 ? 2.5 : 3;
  let X = 50;
  let Y = 40;

  // if (angle === -90) (X = 80), (Y = 10);
  // if (angle === 0) (X = 50), (Y = 40);
  // if (angle === 90) (X = 20), (Y = 10);

  const dev = getAngleDeviation(angle);

  Y = Y - Math.abs(angle) / koef + dev;
  const signedDev = angle > 0 ? -dev - 10 : dev;
  X = X + -angle / koef + signedDev;

  return [X, Y] as PointExpression;
};

export const getAngleDeviation = (angle: number) => {
  const halfQuadrant = 45;

  const dev = Math.abs(Math.abs(angle) - halfQuadrant);

  if (dev < 5) return 5;
  if (dev < 20) return 4;
  if (dev < 25) return 3;
  if (dev < 30) return 2;
  if (dev < 35) return 1;
  if (dev < 40) return 0.5;
  return 0;
};

export const getLabelWidth = (distance: number) => {
  const digitWidth = 10;
  const unitSymbolsWidth = 30;

  const count = distance.toFixed(1).split('').length;

  return count * digitWidth + unitSymbolsWidth;
};

export const calculateDistanceBetweenPoints = (p1: { x: number; y: number }, p2: { x: number; y: number }) => {
  const offsetX = Math.abs(p1.x - p2.x);
  const offsetY = Math.abs(p1.y - p2.y);

  return { offsetX, offsetY };
};

export const checkPointsIsTooClosely = (
  p1: { x: number; y: number },
  p2: { x: number; y: number },
  tooCloseDistance = 8
) => {
  const { offsetX, offsetY } = calculateDistanceBetweenPoints(p1, p2);

  return offsetX < tooCloseDistance && offsetY < tooCloseDistance;
};

export const getPointByCounterClockWise = (targetPointId: string, points: Point[]) => {
  const targetIndex = points.findIndex((p) => p.id === targetPointId);
  const nextIndex = targetIndex + 1 > points.length - 1 ? 0 : targetIndex + 1;
  const prevIndex = targetIndex - 1 < 0 ? points.length - 1 : targetIndex - 1;

  const targetPoint = points[targetIndex];
  const nextPoint = points[nextIndex];
  const prevPoint = points[prevIndex];

  const calcPoints = points.map((p) => [p.coords.lat, p.coords.lng]);
  const center = getCenter(getPoints(calcPoints)).geometry.coordinates;

  // Clock Wise quadrants :
  //    ^
  // Q1 | Q4
  // -- 0 -- >
  // Q2 | Q3

  const cX = center[1];
  const cY = center[0];
  const tX = targetPoint?.coords.lng;
  const tY = targetPoint?.coords.lat;
  const X1 = nextPoint?.coords.lng;
  const Y1 = nextPoint?.coords.lat;
  const X2 = prevPoint?.coords.lng;
  const Y2 = prevPoint?.coords.lat;

  let targetPointQuadrant = 0;
  let nextPointQuadrant = 0;
  let prevPointQuadrant = 0;

  if (cX > tX && cY < tY) targetPointQuadrant = 1;
  if (cX > tX && cY > tY) targetPointQuadrant = 2;
  if (cX < tX && cY > tY) targetPointQuadrant = 3;
  if (cX < tX && cY < tY) targetPointQuadrant = 4;

  if (cX > X1 && cY < Y1) nextPointQuadrant = 1;
  if (cX > X1 && cY > Y1) nextPointQuadrant = 2;
  if (cX < X1 && cY > Y1) nextPointQuadrant = 3;
  if (cX < X1 && cY < Y1) nextPointQuadrant = 4;

  if (cX > X2 && cY < Y2) prevPointQuadrant = 1;
  if (cX > X2 && cY > Y2) prevPointQuadrant = 2;
  if (cX < X2 && cY > Y2) prevPointQuadrant = 3;
  if (cX < X2 && cY < Y2) prevPointQuadrant = 4;

  const hypotenuseTarget = getDistance([cY, cX], [tY, tX], { units: 'meters' });
  const hypotenuseNext = getDistance([cY, cX], [Y1, X1], { units: 'meters' });
  const hypotenusePrev = getDistance([cY, cX], [Y2, X2], { units: 'meters' });

  const ninetyDegreesTargetPoint = targetPointQuadrant === 1 || targetPointQuadrant === 3 ? [tY, cX] : [cY, tX];
  const ninetyDegreesNextPoint = nextPointQuadrant === 1 || nextPointQuadrant === 3 ? [Y1, cX] : [cY, X1];
  const ninetyDegreesPrevPoint = prevPointQuadrant === 1 || prevPointQuadrant === 3 ? [Y2, cX] : [cY, X2];

  const cathetusTarget = getDistance([cY, cX] as Position, ninetyDegreesTargetPoint as Position, { units: 'meters' });
  const cathetusNext = getDistance([cY, cX] as Position, ninetyDegreesNextPoint as Position, { units: 'meters' });
  const cathetusPrev = getDistance([cY, cX] as Position, ninetyDegreesPrevPoint as Position, { units: 'meters' });

  const cosNext = cathetusNext / hypotenuseNext;
  const cosPrev = cathetusPrev / hypotenusePrev;
  const cosTarget = cathetusTarget / hypotenuseTarget;

  let angleNext = Math.acos(cosNext) * (180 / Math.PI);
  let anglePrev = Math.acos(cosPrev) * (180 / Math.PI);
  let angleTarget = Math.acos(cosTarget) * (180 / Math.PI);

  if (targetPointQuadrant === 2) angleTarget += 90;
  if (targetPointQuadrant === 3) angleTarget += 180;
  if (targetPointQuadrant === 4) angleTarget += 270;

  if (nextPointQuadrant === 2) angleNext += 90;
  if (nextPointQuadrant === 3) angleNext += 180;
  if (nextPointQuadrant === 4) angleNext += 270;

  if (prevPointQuadrant === 2) anglePrev += 90;
  if (prevPointQuadrant === 3) anglePrev += 180;
  if (prevPointQuadrant === 4) anglePrev += 270;

  // console.log('next', angleNext);
  // console.log('T', angleTarget);
  // console.log('prev', anglePrev);

  if (angleNext > angleTarget && angleTarget > anglePrev) return nextPoint;
  if (anglePrev > angleTarget && angleTarget > angleNext) return prevPoint;

  if ((angleTarget > angleNext && angleTarget > anglePrev) || (angleTarget < angleNext && angleTarget < anglePrev)) {
    return angleNext < anglePrev ? nextPoint : prevPoint;
  }
};

const getPointRefIndex = (targetIndex: number, points: Point[]) => {
  const nextIndex = targetIndex + 1 > points.length - 1 ? 0 : targetIndex + 1;
  const prevIndex = targetIndex - 1 < 0 ? points.length - 1 : targetIndex - 1;

  const calcPoints = points.map((p) => getPositionFromLatLng(p.coords));

  const isClockWise = getBoolClockWise(lineString([...calcPoints, calcPoints[0]]));

  return isClockWise ? nextIndex : prevIndex;
};

export const getRefPointByCounterClockWise = (targetPointId: string, points: Point[]) => {
  const targetIndex = points.findIndex((p) => p.id === targetPointId);

  const refIndex = getPointRefIndex(targetIndex, points);

  return points[refIndex];
};

export const getRefLinePointByCounterClockWise = (targetPointId: string, points: LinePoint[]) => {
  const linearPoints = points.filter((p) => !p.parentPointId);
  const targetIndex = linearPoints.findIndex((p) => p.id === targetPointId);

  if (targetIndex === 0) {
    return points.at(1);
  }

  const targetPoint = points.find((p) => p.id === targetPointId);
  if (targetPoint?.parentPointId) {
    // return base point
    const refPoint = points.find((p) => p.id === targetPoint.parentPointId);
    if (refPoint) return refPoint;
  }

  if (targetIndex === linearPoints.length - 1) {
    return linearPoints.at(-2);
  }

  const refIndex = getPointRefIndex(targetIndex, points);

  return points[refIndex];
};

// export const getInvertedCoordsType = (coords: LatLngLiteral | Position): LatLngLiteral | Position =>
//   Array.isArray(coords) ? { lat: coords[0], lng: coords[1] } : [coords.lat, coords.lng];

export const getLatLngFromPosition = (coords: Position): LatLngLiteral => {
  return { lat: coords[0], lng: coords[1] };
};

export const getPositionFromLatLng = (coords: LatLngLiteral): Position => {
  return [coords.lat, coords.lng];
};

export const checkIfPointExistsInItem = (newPoint: LatLngLiteral, points: LatLngLiteral[]) => {
  return points.some((point) => point.lat === newPoint.lat && point.lng === newPoint.lng);
};

export const convertLatLngToXYPoints = (points: LatLng[]): { x: number; y: number }[] => {
  return points.map((point) => ({ x: point.lng, y: point.lat }));
};

export const convertXYPointsToLatLng = (points: LeafletPoint[]) => {
  return points.map((point) => ({ lng: point.x, lat: point.y }));
};

export const constrainPointToBoundary = (
  newPoint: LatLngLiteral,
  polygonBoundary: LatLngLiteral[],
  offsetRatio: number = 0.2
): LatLngLiteral => {
  const closedPolygon = [...polygonBoundary, polygonBoundary[0]];
  const polygon = turfPolygon([closedPolygon.map((coord) => [coord.lng, coord.lat])]);

  const point = turfPoint([newPoint.lng, newPoint.lat]).geometry.coordinates;

  const center = centroid(polygon).geometry.coordinates;

  let constrainedPoint = { lat: newPoint.lat, lng: newPoint.lng };

  if (booleanPointInPolygon(point, polygon)) {
    const constrainedPointInPolygon = getConstrainedPointInsidePolygon(
      point,
      polygonBoundary,
      polygon,
      center,
      offsetRatio
    );

    if (constrainedPointInPolygon) {
      constrainedPoint = constrainedPointInPolygon;
    }
  } else {
    constrainedPoint = getConstrainedPointOutsidePolygon(newPoint, polygonBoundary, center, point, offsetRatio);
  }

  return constrainedPoint;
};

const getConstrainedPointInsidePolygon = (
  point: Position,
  polygonBoundary: LatLngLiteral[],
  polygon: Feature<Polygon, Properties>,
  center: Position,
  offsetRatio: number = 0.2
) => {
  const lineBearing = bearing(center, point);

  const extendedPoint = destination(center, 100000, lineBearing);

  const line = lineString([center, extendedPoint.geometry.coordinates]);

  const closedPolygon = [...polygonBoundary, polygonBoundary[0]];

  const intersections = getIntersections(line, closedPolygon);

  if (intersections.length) {
    const closestIntersection = intersections[0];
    let minDistance = distance(center, closestIntersection);

    intersections.forEach((intersection) => {
      const dist = distance(center, intersection);
      if (dist < minDistance) {
        minDistance = dist;
      }
    });

    let offsetDistance = minDistance * (1 - offsetRatio);
    let constrainedPoint = destination(center, offsetDistance, lineBearing);

    while (!booleanPointInPolygon(constrainedPoint, polygon)) {
      offsetDistance *= 0.9;
      constrainedPoint = destination(center, offsetDistance, lineBearing);
    }

    return {
      lat: constrainedPoint.geometry.coordinates[1],
      lng: constrainedPoint.geometry.coordinates[0],
    };
  }
};

const getConstrainedPointOutsidePolygon = (
  newPoint: LatLngLiteral,
  polygonBoundary: LatLngLiteral[],
  center: Position,
  point: Position,
  offsetRatio: number = 0.2
) => {
  const closedPolygon = [...polygonBoundary, polygonBoundary[0]];

  const directionLine = lineString([
    [center[0], center[1]],
    [newPoint.lng, newPoint.lat],
  ]);

  const intersections = getIntersections(directionLine, closedPolygon);

  let constrainedPoint = { lat: newPoint.lat, lng: newPoint.lng };
  if (intersections.length > 0) {
    let closestIntersection = intersections[0];
    let minDistance = distance(point, turfPoint(closestIntersection.geometry.coordinates));

    intersections.forEach((intersection) => {
      const currentDistance = distance(point, turfPoint(intersection.geometry.coordinates));
      if (currentDistance < minDistance) {
        closestIntersection = intersection;
        minDistance = currentDistance;
      }
    });

    const closestIntersectionCoords = closestIntersection.geometry.coordinates;
    constrainedPoint = {
      lat: closestIntersectionCoords[1],
      lng: closestIntersectionCoords[0],
    };
  }

  const adjustedLat = center[1] + (constrainedPoint.lat - center[1]) * (1 - offsetRatio);
  const adjustedLng = center[0] + (constrainedPoint.lng - center[0]) * (1 - offsetRatio);

  return {
    lat: adjustedLat,
    lng: adjustedLng,
  };
};

const getIntersections = (line: Feature<LineString, Properties>, polygon: LatLngLiteral[]) => {
  const intersections = lineIntersect(line, lineString(polygon.map((coord) => [coord.lng, coord.lat])));
  return intersections.features;
};

export const getPolygonCentroid = (points: LatLngLiteral[]) => {
  const totalPoints = points.length;

  let sumLat = 0;
  let sumLng = 0;

  points.forEach((point) => {
    sumLat += point.lat;
    sumLng += point.lng;
  });

  return {
    lat: sumLat / totalPoints,
    lng: sumLng / totalPoints,
  };
};

export const getPolygonCenter = (pts: LatLngLiteral[]): LatLngLiteral => {
  const closedPolygon = [...pts, pts[0]];

  const polygonCentroid = getPolygonCentroid(pts);

  if (!pts.every((p) => p && p.lat !== undefined && p.lng !== undefined)) {
    return polygonCentroid;
  }

  if (
    !closedPolygon ||
    closedPolygon.length === 0 ||
    closedPolygon.some((p) => !p || p.lat === undefined || p.lng === undefined)
  ) {
    return polygonCentroid;
  }

  const polygon = turfPolygon([closedPolygon.map((p) => [p.lng, p.lat])]);
  const point = turfPoint([polygonCentroid.lng, polygonCentroid.lat]);

  if (booleanPointInPolygon(point, polygon)) return polygonCentroid;

  const pointInside = pointOnFeature(polygon);

  const [lng, lat] = pointInside.geometry.coordinates;

  return { lat, lng };
};

const calculateDistanceToPolygon = (point: Feature<tPoint, any>, polygon: Feature<Polygon, any>): number => {
  const vertices = explode(polygon);
  let minDistance = Infinity;
  vertices.features.forEach((vertex) => {
    const tdistance = distance(point, vertex, { units: 'meters' });
    if (tdistance < minDistance) {
      minDistance = tdistance;
    }
  });
  return minDistance;
};

export const getMaxInscribedCircleDiameter = (points: LatLngLiteral[], numPoints = 1000): number => {
  const closedPolygon = [...points, points[0]];
  const polygon = turfPolygon([closedPolygon.map((p) => [p.lng, p.lat])]);

  const randomPoints = randomPoint(numPoints, { bbox: bbox(polygon) });
  const insidePoints = randomPoints.features.filter((point) => booleanPointInPolygon(point, polygon));

  let maxDistance = 0;

  insidePoints.forEach((point) => {
    const distance = calculateDistanceToPolygon(point, polygon);
    if (distance > maxDistance) {
      maxDistance = distance;
    }
  });

  return maxDistance / 1.5;
};

export const findEastBoundaryPoint = (
  center: LatLngLiteral,
  points: Point[],
  offsetRatio: number = 0.2
): LatLngLiteral => {
  let closestPoint: LatLngLiteral | null = null;
  let minDistance = Infinity;

  for (let i = 0; i < points.length; i++) {
    const current = points[i];
    const next = points[(i + 1) % points.length];

    if (
      (current.coords.lat <= center.lat && next.coords.lat >= center.lat) ||
      (current.coords.lat >= center.lat && next.coords.lat <= center.lat)
    ) {
      const latDifference = next.coords.lat - current.coords.lat;
      const lngDifference = next.coords.lng - current.coords.lng;
      const ratio = (center.lat - current.coords.lat) / latDifference;

      const interpolatedLng = current.coords.lng + ratio * lngDifference;

      if (interpolatedLng > center.lng) {
        const distance = interpolatedLng - center.lng;

        if (distance < minDistance) {
          minDistance = distance;

          const adjustedLng = center.lng + (interpolatedLng - center.lng) * (1 - offsetRatio);
          const adjustedLat = center.lat;

          closestPoint = { lat: adjustedLat, lng: adjustedLng };
        }
      }
    }
  }

  return closestPoint || center;
};

export const calculateAngle = (center: LatLngLiteral, newPoint: LatLngLiteral) => {
  const deltaX = newPoint.lng - center.lng;
  const deltaY = newPoint.lat - center.lat;

  const angleRadians = Math.atan2(deltaY, deltaX);

  let angleDegrees = angleRadians * (180 / Math.PI);

  if (angleDegrees < 0) {
    angleDegrees += 360;
  }

  return angleDegrees;
};

export const getRowDirectionIconSize = (radius: number, zoomLevel: number) => {
  const baseSize = 30;
  const baseZoom = 15;
  const scale = (radius / 100) * Math.pow(2, zoomLevel - baseZoom);

  return baseSize * scale;
};

const getDistanceBetweenTwoPoints = (point1: LatLngLiteral, point2: LatLngLiteral): number => {
  const R = 6371000;
  const toRad = (value: number) => (value * Math.PI) / 180;

  const lat1 = toRad(point1.lat);
  const lat2 = toRad(point2.lat);
  const deltaLat = toRad(point2.lat - point1.lat);
  const deltaLng = toRad(point2.lng - point1.lng);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const getDistanceToSegment = (p: LatLngLiteral, v: LatLngLiteral, w: LatLngLiteral): number => {
  const dist2 = (a: LatLngLiteral, b: LatLngLiteral) => {
    const dLat = a.lat - b.lat;
    const dLng = a.lng - b.lng;
    return dLat * dLat + dLng * dLng;
  };

  const l2 = dist2(v, w);
  if (l2 === 0) return getDistanceBetweenTwoPoints(p, v);

  const t = Math.max(0, Math.min(1, ((p.lat - v.lat) * (w.lat - v.lat) + (p.lng - v.lng) * (w.lng - v.lng)) / l2));
  const projection = {
    lat: v.lat + t * (w.lat - v.lat),
    lng: v.lng + t * (w.lng - v.lng),
  };

  return getDistanceBetweenTwoPoints(p, projection);
};

export const getDistanceToNearestBorder = (polygon: LatLngLiteral[]): number => {
  const centroid = getPolygonCenter(polygon);

  let minDistance = Infinity;
  for (let i = 0; i < polygon.length; i++) {
    const v = polygon[i];
    const w = polygon[(i + 1) % polygon.length];
    const distance = getDistanceToSegment(centroid, v, w);
    if (distance < minDistance) {
      minDistance = distance;
    }
  }

  return minDistance;
};
