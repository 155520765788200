import { selectFormState, selectMasterGr, selectPipes, setLineInformation } from 'shared/slices';
import { CatalogItem, LineSectionProperties } from '../models';
import { useAppDispatch } from './useAppDispatch';
import { SelectChangeEvent } from '@mui/material';
import { useMemo, useState } from 'react';

const ALL = 'All';

export const useLinePipeFilters = (sectionId: string) => {
  const dispatch = useAppDispatch();
  const { masterGroups } = selectMasterGr();
  const { pipes } = selectPipes();
  const { lineInformation } = selectFormState();

  const [region] = useState('Europe');
  const filteredMasterGroups = useMemo(() => masterGroups.filter((item) => item.MAINLINE), [masterGroups]);

  const lineSection: LineSectionProperties = useMemo(
    () => lineInformation.lineSections.find((ls) => ls.sectionId === sectionId) as LineSectionProperties,
    [sectionId, lineInformation]
  );
  const { linePipeType, linePipeDiameter, linePipeClass } = lineSection;

  const onTypeChange = (e: SelectChangeEvent<unknown>) => {
    const lineSections = lineInformation.lineSections.map((s) => {
      if (s.sectionId === sectionId) {
        const linePipeTypeText = masterGroups.find((m) => m.ID === (e.target.value as number))?.MASTERGROUPNAME ?? '';
        return {
          ...s,
          linePipeType: e.target.value as string,
          linePipeTypeText,
          linePipeDiameter: ALL,
          linePipeClass: ALL,
        };
      }
      return s;
    });

    dispatch(setLineInformation({ lineSections, lineCopySettingsFromId: null }));
  };
  const onDiameterChange = (e: any /* SelectChangeEvent<unknown> */) => {
    const lineSections = lineInformation.lineSections.map((s) => {
      if (s.sectionId === sectionId) {
        return { ...s, linePipeDiameter: e.target.value as string, linePipeClass: ALL };
      }
      return s;
    });
    dispatch(setLineInformation({ lineSections, lineCopySettingsFromId: null }));
  };

  const onClassChange = (e: SelectChangeEvent<unknown>) => {
    const lineSections = lineInformation.lineSections.map((s) => {
      if (s.sectionId === sectionId) {
        return { ...s, linePipeClass: e.target.value as string };
      }
      return s;
    });
    dispatch(setLineInformation({ lineSections, lineCopySettingsFromId: null }));
  };

  const diameterFilter = (item: CatalogItem) => {
    if (region === 'USA' && item.AltInlet !== null) {
      return linePipeDiameter !== ALL ? item.AltInlet === linePipeDiameter : true;
    }
    return linePipeDiameter !== ALL ? item.INLET === linePipeDiameter : true;
  };

  const diameters = useMemo(
    () => [
      ALL,
      ...new Set(
        pipes
          .map((item) => (region === 'USA' && item.AltInlet !== null ? item.AltInlet ?? '' : item.INLET ?? ''))
          .sort((a, b) => +a - +b)
      ),
    ],
    [pipes]
  );

  const classTypes = useMemo(() => {
    return [
      ALL,
      ...new Set(
        pipes
          .filter((item) => diameterFilter(item))
          .map((item) =>
            region === 'USA' && item.AltClass !== null ? item.AltClass?.toString().trim() : item.Class.trim()
          )
          .sort((a, b) => +a - +b)
      ),
    ];
  }, [linePipeDiameter, pipes]);

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: linePipeType,
    onTypeChange,

    diameters,
    diameter: linePipeDiameter,
    onDiameterChange,

    classTypes,
    classType: linePipeClass,
    onClassChange,
  };
};
