import { useState } from 'react';
import { localStorageService } from '../../../shared/services';


export const useEmailSuggestion = () => {
  const [transferEmailSuggestions, setTransferEmailSuggestions] = useState(
    JSON.parse(localStorageService.transferEmailSuggestions ?? '[]')
  );

  const addTransferEmailSuggestion = (email: string) => {
    const updatedData = [...new Set([...transferEmailSuggestions, email])];
    localStorageService.transferEmailSuggestions = JSON.stringify(updatedData);
    setTransferEmailSuggestions(updatedData);
  };

  return { transferEmailSuggestions, addTransferEmailSuggestion }
}