import { Box, Link, Stack, styled, Typography } from '@mui/material';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosBase } from '../../shared/axios';
import { textFormatter } from '../../shared/lib';
import { ResetPasswordResponse } from '../../shared/models';
import { AuthInput, BigTitle, Button, Icon, Row } from '../../shared/ui';

const emailRegex = /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/;

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.dark,
  fontSize: '14px',
  fontWeight: 400,
}));

const loginRoute = '/common/login';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState<ResetPasswordResponse>();
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { t } = useTranslation();

  const isEmailValid = email.toLowerCase().match(emailRegex);

  const resetPassword = async () => {
    if (!isEmailValid) {
      setHelperText(!email.trim().length ? t('forgot_please_enter_valid_email') : t('forgot_email_is_not_valid'));
      return;
    }

    try {
      setLoading(true);
      setHelperText('');

      const rapidFieldAppType = 2;

      const response = await axiosBase.post<ResetPasswordResponse>('/api/User/reset-password', {
        email,
        appType: rapidFieldAppType,
      });
      setResponse(response.data);

      toast.success(t('forgot_email_sent'));
    } catch (e) {
      toast.error('Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack justifyContent="space-between" height="100%">
      <Box mt={3}>
        <Row justifyContent="flex-start" sx={{ a: { textDecoration: 'none' } }}>
          <Icon path="blue_arrow" />
          <Link component={RouterLink} to={loginRoute}>
            <Text sx={{ color: '#007AFF', m: 0, ml: 0.5, cursor: 'pointer' }}>{t('forgot_back_to_login')}</Text>
          </Link>
        </Row>

        {response?.data.userId ? (
          <>
            <BigTitle sx={{ mt: '30px' }}>{t('forgot_email_sent')}</BigTitle>
            <Text mt={3}>{textFormatter(t('forgot_sent_an_email'), [email]).output}</Text>
          </>
        ) : (
          <>
            <BigTitle sx={{ mt: '30px' }}>{t('forgot_forgot_password')}</BigTitle>
            <Text>{t('forgot_send_a_link')}</Text>

            <Box sx={{ mt: '10px' }}>
              <AuthInput
                label={t('forgot_email')}
                name="email"
                placeholder={t('forgot_enter_email')}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (helperText) setHelperText('');
                }}
                isCheckedIcon={!!isEmailValid}
                error={!!helperText}
                helperText={helperText}
              />

              <Box sx={{ height: '40px', mt: '100px' }}>
                <Button loading={loading} btnColor="primary.main" fullWidth onClick={resetPassword}>
                  <Typography sx={{ fontWeight: 700 }}>{t('forgot_request_a_reset')}</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Stack>
  );
};
