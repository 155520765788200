import getDistance from '@turf/distance';
import { Units } from '@turf/helpers';
import getMidPoint from '@turf/midpoint';
import { divIcon } from 'leaflet';
import { FC } from 'react';
import { Marker, Polyline } from 'react-leaflet';
import { LENGTH_UNITS } from 'shared/constants';
import { getIconAnchorByAngle, getLabelWidth, getMapAngle } from 'shared/lib';
import { Point } from 'shared/models';
import { selectUnitsLength } from 'shared/slices';

interface Props {
  points: Point[];
  onClose: () => void;
}

const MIN_DISTANCE_POINTS = 2;

export const DistanceLine: FC<Props> = ({ points, onClose }) => {
  // Check if we could measure distance between at least two points
  if (points.filter(Boolean).length < MIN_DISTANCE_POINTS) return null;

  const lengthUnit = selectUnitsLength();

  const pointCoords = points.filter(Boolean).map((p) => p.coords);
  const positions = pointCoords.map(({ lat, lng }) => [lng, lat]);

  const point1 = positions[0];
  const point2 = positions[1];

  const distance = Number(getDistance(point1, point2, {
    units: LENGTH_UNITS[lengthUnit as keyof typeof LENGTH_UNITS] as Units,
  }));
  const center = getMidPoint(point1, point2).geometry.coordinates;
  const centerPoint = { lng: center[0], lat: center[1] };

  const angle = getMapAngle(point1, point2);

  const centerIcon = divIcon({
    html: `
      <div class='distance_icon_container' style='transform: rotate(${angle}deg)'>
        ${distance.toFixed(1)} ${lengthUnit}
      </div>
      `,
    className: 'distance_icon',
    iconAnchor: getIconAnchorByAngle(angle),
    iconSize: [getLabelWidth(distance), 20],
  });

  return (
    <Polyline positions={pointCoords} pathOptions={{ weight: 3, color: 'white' }}>
      <Marker
        position={centerPoint}
        icon={centerIcon}
        eventHandlers={{
          click() {
            onClose();
          },
        }}
      />
    </Polyline>
  );
};
