import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  darken,
  Dialog,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { Icon } from '../../shared/ui';
import { useSharingEmails } from './hooks';

interface Props {
  iconType: 'share' | 'export';
  title: string;
  buttonText: string;
  open: boolean;
  loading: boolean;
  onSubmit: (email: string) => void;
  onClose: () => void;
  isUserExist?: (email: string) => Promise<boolean>;
  transferEmailSuggestions: string[];
  inputError?: string;
  clearError: () => void;
  freeSolo?: boolean;
}

export const TransferProjectModal: FC<Props> = ({
  open,
  onClose,
  iconType,
  buttonText,
  title,
  onSubmit,
  loading,
  inputError,
  clearError,
  transferEmailSuggestions,
}) => {
  const { t } = useTranslation();
  const { email, onEmailChange, emails, isLoading } = useSharingEmails(
    open,
    transferEmailSuggestions[transferEmailSuggestions.length - 1]
  );

  const isButtonDisabled = isLoading || !email || loading;

  const isKeyboardOpen = useDetectKeyboardOpen();

  const onChange = (_: any, email: string | null) => {
    inputError && clearError();
    onEmailChange(email ?? '');
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          overflow: 'visible',
          display: 'flex',
          padding: '24px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          width: '84.2666666667svw',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          transform: isKeyboardOpen ? 'translateY(-50%)' : 'none',
          transition: 'transform 0.3s ease',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px', width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '12px', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Icon path={iconType === 'share' ? 'sideMenuIcons/shareGreen' : 'sideMenuIcons/exportGreen'} />
            <Typography
              sx={{
                flex: '1 0 0',
                color: '#242731',
                fontSize: '18px',
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Autocomplete
              onChange={onChange}
              value={email}
              options={emails ?? []}
              freeSolo
              autoSelect
              disablePortal
              getOptionLabel={(item) => item}
              isOptionEqualToValue={(option, value) => option == value}
              filterOptions={createFilterOptions({ matchFrom: 'any' })}
              disabled={isLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('forgot_enter_email')}
                  error={!!inputError}
                  helperText={inputError}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '44px',
                      borderRadius: '8px',
                      p: '0 10px',
                      fontSize: 16,
                    },
                  }}
                />
              )}
              noOptionsText={'No such email'}
            />

            {isLoading && (
              <Box sx={{ position: 'absolute', right: 5, top: 5 }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </Box>
        </Box>
        <Button
          disabled={isButtonDisabled}
          onClick={() => onSubmit(email)}
          sx={{
            display: 'flex',
            width: '126px',
            height: '38px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            background: '#1D8742',
            color: '#FFF',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
            '&:hover': {
              backgroundColor: darken('#1D8742', 0.1),
              color: 'white',
            },
          }}
        >
          {loading ? <CircularProgress size={20} color={'info'} /> : buttonText}
        </Button>
      </Box>
    </Dialog>
  );
};
