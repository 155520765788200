import { Box, Button, CircularProgress, styled } from '@mui/material';
import { LatLngLiteral, LeafletMouseEvent } from 'leaflet';
import { FC, useEffect, useState } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import { useLocation } from 'react-router-dom';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { SavingStep } from 'shared/enums';
import { useAppDispatch, useAppNavigator, useMapActions } from 'shared/hooks';
import { useMapControl } from 'shared/hooks/useMapControl';
import { useThrottle } from 'shared/hooks/useThrottle';
import {
  dragLinePoint,
  resetDrawMode,
  selectCurrentLinePointId,
  selectDrawMode,
  selectIsColorPicker,
  selectIsItemDrawing,
  selectSaveProjectStep,
  selectUnitsChanged,
  setSaveProjectStep,
  toggleColorPicker,
  unselectItems,
  updatePolygonRowDirectionLine,
} from 'shared/slices';
import { Icon } from 'shared/ui';
import { LocationBlockedModal } from '../LocationBlockedModal';

const NavBtn = styled(Button)({
  backgroundColor: 'rgba(0, 0, 0, 0.68)',
  height: '48px',
  minWidth: '48px !important',
  maxWidth: '48px !important',
  borderBottom: '1px solid grey',
  borderRadius: 0,
  display: 'flex',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.68)',
  },
});

interface Props {
  isHide: boolean;
  onOpenLayers: () => void;
  isSearch: boolean;
  onToggleSearch: () => void;
}

export const MapControl: FC<Props> = ({ onOpenLayers, isHide, isSearch, onToggleSearch }) => {
  const dispatch = useAppDispatch();

  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  const map = useMap();

  const isColorPicker = selectIsColorPicker();
  const saveProjectStep = selectSaveProjectStep();
  const isDrawing = selectIsItemDrawing();
  const selectedLinePointId = selectCurrentLinePointId();

  const unitsChanged = selectUnitsChanged();

  const {
    isSelectedItem,
    isViewportBtn,
    handleFitViewport,
    findUser,
    loading,
    openLocationBlockedModal,
    setOpenLocationBlockedModal,
    findNearPoint,
  } = useMapControl(isSearch, onToggleSearch);

  const drawMode = selectDrawMode();

  const { createPoint } = useMapActions();

  const [prevLocation, setPrevLocation] = useState<LatLngLiteral | null>(null);

  const isRowDirectionEditing = isSelectedItem.includes(POLYGON_ROW_DIRECTION_ID) && isDrawing;

  const [isDragging, setIsDragging] = useState(false);

  const throttledUpdatePos = useThrottle((newPos: LatLngLiteral) => dispatch(dragLinePoint(newPos)), 50);
  const onChangePosition = (newPos: LatLngLiteral) => throttledUpdatePos(newPos);

  const handleDragStart = (e: LeafletMouseEvent | TouchEvent) => {
    if (isRowDirectionEditing && selectedLinePointId) {
      setIsDragging(true);
      if ('latlng' in e) {
        dispatch(updatePolygonRowDirectionLine({ newPoint: e.latlng }));
      } else if ('touches' in e) {
        const touch = e.touches[0];
        const latlng = map.containerPointToLatLng([touch.clientX, touch.clientY]);
        dispatch(updatePolygonRowDirectionLine({ newPoint: latlng }));
      }
    }
  };

  const handleMove = (e: LeafletMouseEvent | TouchEvent) => {
    if (isDragging) {
      requestAnimationFrame(() => {
        if ('latlng' in e) {
          onChangePosition(e.latlng);
        } else if ('touches' in e) {
          const touch = e.touches[0];
          const latlng = map.containerPointToLatLng([touch.clientX, touch.clientY]);
          onChangePosition(latlng);
        }
      });
    }
  };

  const handleDragEnd = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  // row direction drag and drop functionality for desktop
  const mapEvents = useMapEvents({
    mousedown(e) {
      handleDragStart(e);
    },
    mousemove(e) {
      handleMove(e);
    },
    mouseup() {
      handleDragEnd();
    },
  });

  // row direction drag and drop functionality for mobile
  useEffect(() => {
    const mapContainer = map.getContainer();

    const handleTouchStart = (e: TouchEvent) => {
      if (isRowDirectionEditing) e.preventDefault();
      handleDragStart(e);
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isRowDirectionEditing) e.preventDefault();
      handleMove(e);
    };

    const handleTouchEnd = (e: TouchEvent) => {
      if (isRowDirectionEditing) e.preventDefault();
      handleDragEnd();
    };

    mapContainer.addEventListener('touchstart', handleTouchStart);
    mapContainer.addEventListener('touchmove', handleTouchMove);
    mapContainer.addEventListener('touchend', handleTouchEnd);

    return () => {
      mapContainer.removeEventListener('touchstart', handleTouchStart);
      mapContainer.removeEventListener('touchmove', handleTouchMove);
      mapContainer.removeEventListener('touchend', handleTouchEnd);
    };
  }, [map, isRowDirectionEditing, selectedLinePointId, isDragging]);

  // useEffect(() => {
  //   const handleMapMoveEnd = () => {
  //     if (!state?.zoom && !isDrawing) {
  //       navigate(pathname, { state: { zoom: true } });
  //       setPrevLocation(null);
  //     }
  //     console.log('map', map);
  //   };
  //
  //   map.on('dragend', handleMapMoveEnd);
  //
  //   return () => {
  //     map.off('dragend', handleMapMoveEnd);
  //   };
  // }, [map]);
  //
  // useEffect(() => {
  //   if (!state?.zoom && !prevLocation) {
  //     handleFitViewport();
  //   }
  // }, [state?.zoom]);

  // useEffect(() => {
  //   if (!state?.zoomToProject && !state?.findMyLocation && !state?.selectedObject && prevLocation) {
  //     map.flyTo(prevLocation);
  //     setPrevLocation(null);
  //   }
  // }, [state?.zoomToProject, state?.findMyLocation]);

  const checkIsMapClick = (target: any) => {
    let isClickOnMap = false;
    let isClickOnItem = false;

    if (typeof target === 'object') {
      if ((target as SVGAnimatedString).baseVal === 'leaflet-interactive') {
        isClickOnMap = true;
        isClickOnItem = true;
      }
    }
    if (typeof target === 'string') {
      // 'leaflet-container'
      if (target.includes('leaflet-zoom-animated')) {
        isClickOnMap = true;
      }
    }

    return { isClickOnMap, isClickOnItem };
  };

  const handleMapClick = (e: LeafletMouseEvent) => {
    if (drawMode === 'gps') return;

    const targetClassName = (e.originalEvent.target as HTMLElement).className;
    const { isClickOnMap, isClickOnItem } = checkIsMapClick(targetClassName);

    if (!isClickOnMap) return;

    if (isColorPicker) {
      dispatch(toggleColorPicker());
      return;
    }

    if (saveProjectStep !== SavingStep.DONE) return;

    if (!isDrawing && isSelectedItem && !isClickOnItem) {
      if (unitsChanged) {
        dispatch(setSaveProjectStep(SavingStep.SAVING));
        dispatch(resetDrawMode());
      }

      dispatch(unselectItems());
      return;
    }

    const pointCoords = findNearPoint(e.latlng) ?? e.latlng;
    createPoint(pointCoords);
  };

  const handleFitViewportClick = () => {
    setPrevLocation(map.getCenter());
    handleFitViewport();
    !state?.zoomToProject && navigate(pathname, { state: { zoomToProject: true }, replace: true });
  };

  const handleFindUsersLocationClick = () => {
    setPrevLocation(map.getCenter());
    findUser();
    !state?.findMyLocation && navigate(pathname, { state: { findMyLocation: true }, replace: true });
  };

  useMapEvents({
    click: handleMapClick,
  });

  return (
    <Box
      className="find-buttons"
      id={'find-buttons'}
      sx={{
        position: 'absolute',
        right: isHide ? '-100px' : '24px',
        top: !isSearch && !isSelectedItem ? '47px' : '95px',
        zIndex: 400,
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      {!isSearch && !isSelectedItem && (
        <NavBtn onClick={onToggleSearch}>
          <Icon path="search" />
        </NavBtn>
      )}
      <NavBtn onClick={onOpenLayers}>
        <Icon path="mapIcons/on_change_layers" />
      </NavBtn>

      {isViewportBtn && (
        <NavBtn onClick={handleFitViewportClick}>
          <Icon path="mapIcons/on_viewport_items" />
        </NavBtn>
      )}
      <NavBtn onClick={handleFindUsersLocationClick} sx={{ border: 'none' }}>
        {loading ? <CircularProgress color={'info'} size={20} /> : <Icon path="mapIcons/on_find_user" />}
      </NavBtn>
      <LocationBlockedModal open={openLocationBlockedModal} onClose={() => setOpenLocationBlockedModal(false)} />
    </Box>
  );
};
