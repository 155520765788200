import { ErrorBoundary } from 'app/error';
import { useOpenProject } from 'features/ProjectItem/ProjectActions/hooks/useOpenProject';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppNavigator } from 'shared/hooks';
import { useSaveProject } from 'shared/hooks/useSaveProject';
import {
  Header,
  ImagesViewer,
  LineInfo,
  Map,
  MapItemHeader,
  NewProject,
  PointInfo,
  PolygonInfo,
  SideMenu,
} from 'widgets';
import { useProjects } from 'widgets/ProjectManagement/hooks';
import { useWakeLock } from '../../shared/hooks/useWakeLock';
import { RowDirectionForm } from 'widgets/PolygonRowDirectionForm';
import { selectIsRowDirectionForm } from 'shared/slices';

export const HomePage = () => {
  useWakeLock();
  useSaveProject();

  const { projectId } = useParams();
  const [currentProject, setCurrentProject] = useState<any>(null);
  const { navigateToProjectManagement } = useAppNavigator();

  const { allProjects } = useProjects();

  const { handleOpenProject } = useOpenProject(currentProject);

  const isRowDirectionForm = selectIsRowDirectionForm();

  useEffect(() => {
    if (projectId && +projectId) {
      if (allProjects.length > 0) {
        const foundProject = allProjects.find((project) => project.id === +projectId);

        if (foundProject) {
          setCurrentProject(foundProject);
        } else {
          navigateToProjectManagement();
        }
      }
    } else {
      navigateToProjectManagement();
    }
  }, [projectId, allProjects]);

  useEffect(() => {
    if (currentProject) {
      handleOpenProject();
    }
  }, [currentProject]);

  return (
    <>
      <Header />
      <SideMenu />
      {/*Temporary decision for bug: 'Cannot read properties of undefined (reading "_leaflet_pos")' */}
      <ErrorBoundary error={'Leaflet-map container error'}>
        <Map />
      </ErrorBoundary>
      <MapItemHeader />
      <PolygonInfo />
      <LineInfo />
      <PointInfo />

      {isRowDirectionForm && <RowDirectionForm />}

      <ImagesViewer />

      <NewProject />
    </>
  );
};
