import { Box, CircularProgress } from '@mui/material';
import { LatLng, LatLngLiteral, Map as LeafletMap } from 'leaflet';
import { useEffect, useMemo, useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useLocation, useParams } from 'react-router-dom';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { useAppDispatch, useAppNavigator, useToggle } from 'shared/hooks';
import { fitItemToViewPort, getRelatedPolygonId } from 'shared/lib';
import {
  selectCurrentItemId,
  selectCurrentMapItem,
  selectDrawMode,
  selectIsColorPicker,
  selectIsItemDrawing,
  selectIsLineDrawing,
  selectIsTrackingPointDrawing,
  selectLineId,
  selectLines,
  selectMapLayer,
  selectNewLine,
  selectNewPoint,
  selectNewPolygon,
  selectPoints,
  selectPolygons,
  selectProjectId,
  selectSearchedPoint,
  selectSelectedItem,
  selectTrackingPoint,
  selectUserLocationPoint,
  setTrackingPoint,
} from 'shared/slices';
import { SearchedPoint, TrackingPoint, UserLocationPoint } from 'shared/ui';
import { LineItem, PointItem, PolygonItem } from '../../entities';
import {
  DrawModeFieldDelineation,
  DrawModeTooltip,
  FigureSizes,
  LayersMenu,
  MapControl,
  MapSpeedDial,
  PointPlacer,
  SearchMenu,
  TrackingPointTooltip,
} from '../../features';
import { ItemDrawingControl } from '../ItemDrawingControl';
import { MAP_TILE_LAYERS, mapStyles } from './consts';

const DRAWING_CURSOR = 'url(/images/drawing-cursor.svg) 13 3, auto';

const center: LatLngLiteral = { lat: 32.505, lng: 35.09 };

export const Map = () => {
  const mapRef = useRef<LeafletMap>(null);
  const dispatch = useAppDispatch();

  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  const { open: isOpenPointPlacer, toggle: togglePointPlacer } = useToggle(false);

  const isSearch = !!state?.searchTool;
  const isLayers = !!state?.layersMenu;

  const drawMode = selectDrawMode();
  // const isGpsMeasurementInitialized = selectIsGpsMeasurementInitialized();

  const polygons = selectPolygons();
  const newPolygon = selectNewPolygon();
  const lines = selectLines();
  const newLine = selectNewLine();
  const selectedLineId = selectLineId();
  const isLineDrawing = selectIsLineDrawing();

  const points = selectPoints();
  const newPoint = selectNewPoint();

  const isTrackingPointDrawing = selectIsTrackingPointDrawing();
  const isDrawing = selectIsItemDrawing();

  const isSelectedItem = selectCurrentItemId() || '';
  const { projectId } = useParams();
  const isProjectOpened = projectId && +projectId === selectProjectId();

  const searchedPoint = selectSearchedPoint();
  const trackingPoint = selectTrackingPoint();
  const mapLayer = selectMapLayer();
  const userLocationPoint = selectUserLocationPoint();

  const selectedMapItem = selectSelectedItem();
  const currentMapItem = selectCurrentMapItem();
  const currentItemId = selectCurrentItemId();
  const isColorPicker = selectIsColorPicker();

  const isDrawingNewItem = currentItemId?.includes('new');

  const toggleSearch = () => {
    if (!state?.searchTool) {
      navigate(pathname, { state: { searchTool: true } });
    } else {
      navigate(pathname, { state: { searchTool: false }, replace: true });
    }
  };
  const toggleLayers = () => {
    if (!state?.layersMenu) {
      navigate(pathname, { state: { layersMenu: true } });
    } else {
      navigate(pathname, { state: { layersMenu: false }, replace: true });
    }
  };

  // const isMapTileLoading = selectIsMapTileLoading();
  //
  // const [numberOfTilesLoading, setNumberOfTilesLoading] = useState(0);

  // useEffect(() => {
  //   // check if all tyles are loaded instead of load() event for hybrid map layer
  //   if (numberOfTilesLoading === 0 && isMapTileLoading && mapLayer === 'hybrid') {
  //     setTimeout(() => {
  //       dispatch(setMapTileLoading(false));
  //     }, 1300);
  //   }
  // }, [numberOfTilesLoading, isMapTileLoading, mapLayer]);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.invalidateSize();
      const currentZoom = map.getZoom();
      const maxZoom = map.getMaxZoom();
      if (currentZoom - maxZoom >= -2) {
        map.setZoom(maxZoom - 2);
      }
    }
  }, [mapRef]);

  useEffect(() => {
    const map = mapRef.current;

    if (selectedLineId?.includes(POLYGON_ROW_DIRECTION_ID) && map) {
      const polygonId = getRelatedPolygonId(selectedLineId ?? '');
      const relatedPolygon = [...polygons, newPolygon].find((p) => p.geoJson.id === polygonId);

      if (relatedPolygon) {
        fitItemToViewPort(map, relatedPolygon.geoJson.points);
      }
    }
  }, [selectedLineId, mapRef]);

  useEffect(() => {
    const mapElement = document.getElementById('map');
    if (!mapElement || selectedMapItem === 'point') return;

    if (drawMode === 'manual' && isDrawingNewItem) {
      mapElement.style.cursor = DRAWING_CURSOR;
    } else {
      mapElement.style.cursor = 'grab';
    }
  }, [selectedMapItem, drawMode]);

  const isRowDirectionEditing = useMemo(() => {
    return currentItemId?.includes(POLYGON_ROW_DIRECTION_ID) && isDrawing;
  }, [currentItemId, isDrawing]);

  useEffect(() => {
    if (mapRef.current) {
      if (isRowDirectionEditing) {
        mapRef.current.dragging.disable();
      } else {
        mapRef.current.dragging.enable();
      }
    }
  }, [isRowDirectionEditing]);

  const isNoPointsWhenDrawing = useMemo(() => {
    if (!isDrawing && !isTrackingPointDrawing) return false;

    if (currentMapItem) {
      if (currentMapItem.geoJson.points.length === 0) return true;
    }

    return isTrackingPointDrawing && !trackingPoint;
  }, [isDrawing, newPolygon, newLine, newPoint, polygons, lines, points, isTrackingPointDrawing, trackingPoint]);

  const isDelineationAble = useMemo(() => {
    if (!currentMapItem || selectedMapItem !== 'polygon') return false;

    return isDrawing && !isNoPointsWhenDrawing && isDrawingNewItem && currentMapItem.geoJson.points.length === 1;
  }, [isDrawing, isNoPointsWhenDrawing, newPolygon]);

  const onClickPoint = (coords: LatLng) => {
    isTrackingPointDrawing && dispatch(setTrackingPoint(coords));
  };

  return (
    <>
      <Box
        sx={{
          direction: 'ltr /* @noflip */',
          position: 'fixed',
          top: '48px',
          left: 0,
          zIndex: 0,
          height: 'calc(100vh - 48px)',
          width: '100%',
          bgcolor: 'secondary.dark',
        }}
      >
        {projectId &&
          (isProjectOpened ? (
            <Box sx={mapStyles}>
              <MapContainer
                id="map"
                ref={mapRef}
                style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 0 }}
                center={center}
                preferCanvas
                zoom={15}
                zoomControl={false}
                doubleClickZoom={false}
                attributionControl={false}
                scrollWheelZoom={true}
              >
                <TileLayer tileSize={256} maxNativeZoom={19} minZoom={1} maxZoom={22} url={MAP_TILE_LAYERS[mapLayer]} />
                <MapControl
                  onOpenLayers={toggleLayers}
                  isHide={isNoPointsWhenDrawing || !!trackingPoint}
                  isSearch={isSearch}
                  onToggleSearch={toggleSearch}
                />
                <ItemDrawingControl />

                {/* Disable screenshooting temporary */}
                {/*<MapScreenShooter />*/}
                <PointPlacer isOpen={isOpenPointPlacer} onClose={togglePointPlacer} />

                <UserLocationPoint coords={userLocationPoint} />
                <SearchedPoint coords={searchedPoint} onClick={onClickPoint} />
                <TrackingPoint coords={trackingPoint} />

                <PolygonItem polygonInfo={newPolygon} />
                {polygons.map((p) => (
                  <PolygonItem key={p.geoJson.id} polygonInfo={p} />
                ))}

                <LineItem lineInfo={newLine} />
                <div key={polygons.length}>
                  {lines.map((l) => (
                    <LineItem key={l.geoJson.id} lineInfo={l} />
                  ))}
                </div>

                <PointItem pointInfo={newPoint} />
                {points.map((p) => (
                  <PointItem key={p.geoJson.id} pointInfo={p} />
                ))}
                {isDelineationAble && <DrawModeFieldDelineation />}

                {isNoPointsWhenDrawing && drawMode === 'none' && <DrawModeTooltip />}
                {selectedLineId?.includes(POLYGON_ROW_DIRECTION_ID) && isLineDrawing && (
                  <DrawModeTooltip rowDirectionType />
                )}

                {/* Show DrawModeTooltip when there are no points when drawing in Manual mode */}
                {/*{drawMode === 'manual' && isNoPointsWhenDrawing && <DrawModeTooltip />}*/}
                {/* Show the DrawModeTooltip when the Gps measurement hasn't been started yet in Gps mode */}
                {/*{drawMode === 'gps' && !isGpsMeasurementInitialized && <DrawModeTooltip />}*/}
              </MapContainer>
            </Box>
          ) : (
            <Box
              sx={{
                height: '100%',
                bgcolor: 'primary.main',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color={'info'} />
            </Box>
          ))}

        {!isSelectedItem && !isColorPicker && <FigureSizes sx={{ bottom: 0 }} />}
      </Box>

      {!isDrawing && !isSelectedItem && <MapSpeedDial />}

      {/* {!!state?.heightValueTool && <TrackingPointTooltip />} */}
      {trackingPoint && <TrackingPointTooltip />}

      {isSearch && <SearchMenu onClose={toggleSearch} />}
      <LayersMenu open={isLayers} onClose={toggleLayers} />
    </>
  );
};
