import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import React, { FocusEvent, useEffect, useState } from 'react';
import { NEW_POLYGON_ID, POLYGON_DEFAULT_NAME } from 'shared/constants';
import { useAppDispatch } from 'shared/hooks';
import {
  ConvertUnit,
  getPolygonAreaByUnit,
  getRelatedPolygonId,
  getStringWithoutFirstZero,
  Math_round,
} from 'shared/lib';
import { CatalogItem, PolygonModel, PolygonProperties } from 'shared/models';
import {
  selectCurrentAreaInHa,
  selectCurrentMapItem,
  selectEmitters,
  selectIsPolygonInfo,
  selectIsRowDirectionForm,
  selectLaterals,
  selectMasterGr,
  selectPolygonById,
  selectPolygonInformation,
  selectPolygons,
  selectProjectPolygonCount,
  selectUnits,
  setPolygonFormState,
  setPolygonInformation,
} from 'shared/slices';
import { useMapItemActions } from 'widgets/MapItemHeader/hooks';

export const useManagePolygonInfo = () => {
  const dispatch = useAppDispatch();

  const {
    units: { Area, AppnDepth, Length },
  } = selectUnits();

  const isPolygonInfo = selectIsPolygonInfo();
  const polygonInformation = selectPolygonInformation();
  const currentAreaInHa = selectCurrentAreaInHa();

  const { masterGroups } = selectMasterGr();
  const { emitterGroups } = selectEmitters();
  const { lateralGroups } = selectLaterals();

  const polygonCounter = selectProjectPolygonCount();

  const currentMapItemId = selectCurrentMapItem()?.geoJson.id;
  const polygonId = getRelatedPolygonId(currentMapItemId ?? '');
  const isRowDirectionFormOpen = selectIsRowDirectionForm();

  const polygon = selectPolygonById(polygonId ?? '') as PolygonModel;
  const polygons = selectPolygons();

  const { onSaveItem } = useMapItemActions();

  const polygonSettingsFrom = selectPolygonById(polygon?.properties.polygonCopySettingsFromId || '');

  const [currentPolygonCopySettingsFrom, setCurrentPolygonCopySettingsFrom] = useState<string | null>(null);

  useEffect(() => {
    if (polygonInformation.polygonCopySettingsFromId) {
      const polygonSettingsFrom = polygons.find((p) => p.geoJson.id === polygonInformation.polygonCopySettingsFromId);

      if (polygonSettingsFrom) {
        setCurrentPolygonCopySettingsFrom(polygonSettingsFrom.information.name);

        const {
          polygonCropType,
          polygonPlantingDate,
          polygonDailyConsumption,
          polygonDailyConsumptionUnit,
          polygonIrrigationHoursPerDay,
          polygonLateralsPerRow,
          polygonRowSpacing,
          polygonRowSpacingUnit,
          polygonPlantSpacing,
          polygonPlantSpacingUnit,
          polygonIsSoilType,
          polygonSoilType,
          polygonRowDirection,
          isSelectProduct,
          selectedProductType,
          lateralNominalFlow,
          lateralSpacing,
          lateralDiameter,
          lateralFlowPer,
          lateralClassType,
          lateralType,
          lateralGroup,
          emitterType,
          emitterTypeText,
          emitterSubtypeText,
          emitterNominalFlow,
          emitterSpacing,
          emitterGroup,
          polygonOtherProduct,
          emitter,
          lateral,
          lateralSubtypeText,
        } = polygonSettingsFrom.properties;

        dispatch(
          setPolygonInformation({
            polygonCopySettingsFromId: polygonSettingsFrom.geoJson.id,
            polygonLateralsPerRow,
            polygonCropType,
            polygonPlantingDate,
            polygonDailyConsumption,
            polygonDailyConsumptionUnit,
            polygonIrrigationHoursPerDay,
            polygonRowSpacing,
            polygonRowSpacingUnit,
            polygonPlantSpacing,
            polygonPlantSpacingUnit,
            polygonIsSoilType,
            polygonSoilType,
            polygonRowDirection,
            isSelectProduct,
            selectedProductType,
            lateral,
            lateralNominalFlow,
            lateralSpacing,
            lateralDiameter,
            lateralFlowPer,
            lateralClassType,
            lateralType,
            lateralSubtypeText,
            lateralGroup,
            emitter,
            emitterType,
            emitterTypeText,
            emitterSubtypeText,
            emitterNominalFlow,
            emitterSpacing,
            emitterGroup,
            polygonOtherProduct,
          })
        );
      }
    } else {
      setCurrentPolygonCopySettingsFrom(null);
    }
  }, [polygonInformation.polygonCopySettingsFromId, polygonInformation.isSelectProduct]);

  useEffect(() => {
    const currentPolygon = polygonSettingsFrom ?? polygon;

    if (!isRowDirectionFormOpen) {
      if (currentPolygon) {
        const lateralTypeText =
          masterGroups.find((m) => m.ID === currentPolygon.properties.lateralType)?.MASTERGROUPNAME ?? '';
        const emitterTypeText =
          masterGroups.find((m) => m.ID === currentPolygon.properties.emitterType)?.MASTERGROUPNAME ?? '';
        const lateralSubtypeText =
          lateralGroups.find((m) => m.GROUPS === currentPolygon.properties.lateralGroup)?.GROUPTYPENAME ?? '';
        const emitterSubtypeText =
          emitterGroups.find((m) => m.GROUPS === currentPolygon.properties.emitterGroup)?.GROUPTYPENAME ?? '';

        dispatch(
          setPolygonInformation({
            polygonName: polygon?.information.name,
            polygonDescription: polygon?.information.description,
            isIrrigationProperties: currentPolygon.information.isIrrigationProperties,
            polygonFieldSize: polygon?.properties.polygonFieldSize,
            polygonFieldSizeUnit: polygon?.properties.polygonFieldSizeUnit,
            polygonCopySettingsFromId:
              currentPolygon?.properties.polygonCopySettingsFromId || polygon?.properties.polygonCopySettingsFromId,
            polygonCropType: currentPolygon.properties.polygonCropType,
            polygonDailyConsumption: currentPolygon.properties.polygonDailyConsumption,
            polygonDailyConsumptionUnit: currentPolygon.properties.polygonDailyConsumptionUnit,
            polygonIrrigationHoursPerDay: currentPolygon.properties.polygonIrrigationHoursPerDay,
            polygonLateralsPerRow: currentPolygon.properties.polygonLateralsPerRow,
            polygonRowSpacing: currentPolygon.properties.polygonRowSpacing,
            polygonRowSpacingUnit: currentPolygon.properties.polygonRowSpacingUnit,
            polygonPlantSpacing: currentPolygon.properties.polygonPlantSpacing,
            polygonPlantSpacingUnit: currentPolygon.properties.polygonPlantSpacingUnit,
            polygonIsSoilType: currentPolygon.properties.polygonIsSoilType,
            polygonSoilType: currentPolygon.properties.polygonSoilType,
            polygonRowDirection: currentPolygon.properties.polygonRowDirection,
            isSelectProduct: currentPolygon.properties.isSelectProduct,
            selectedProductType: currentPolygon.properties.selectedProductType,
            emitterSpacing: currentPolygon.properties.emitterSpacing,
            polygonPlantingDate: currentPolygon.properties.polygonPlantingDate,
            emitter: currentPolygon.properties.emitter,
            lateral: currentPolygon.properties.lateral,
            lateralNominalFlow: currentPolygon.properties.lateralNominalFlow,
            lateralFlowPer: currentPolygon.properties.lateralFlowPer,
            lateralClassType: currentPolygon.properties.lateralClassType,
            lateralDiameter: currentPolygon.properties.lateralDiameter,
            lateralSpacing: currentPolygon.properties.lateralSpacing,
            lateralGroup: currentPolygon.properties.lateralGroup,
            lateralType: currentPolygon.properties.lateralType,
            emitterGroup: currentPolygon.properties.emitterGroup,
            emitterType: currentPolygon.properties.emitterType,
            emitterNominalFlow: currentPolygon.properties.emitterNominalFlow,
            polygonOtherProduct: currentPolygon.properties.polygonOtherProduct,
            images: polygon?.images,
            lateralTypeText,
            emitterTypeText,
            lateralSubtypeText,
            emitterSubtypeText,
          })
        );
      }

      if (polygonId === NEW_POLYGON_ID) {
        onChangePolygonArea(Area);

        const polygonName = `${POLYGON_DEFAULT_NAME} ${polygonCounter}`;

        dispatch(
          setPolygonInformation({
            polygonName,
            polygonFieldSizeUnit: Area,
            polygonDailyConsumptionUnit: AppnDepth,
            polygonRowSpacingUnit: Length,
            polygonPlantSpacingUnit: Length,
          })
        );
      }
    }

    if (!isPolygonInfo) {
      // dispatch(resetFormState());
      dispatch(setPolygonFormState({ validationCount: 0 }));
    }
  }, [isPolygonInfo, polygonId]);

  useEffect(() => {
    const {
      polygonName,
      isIrrigationProperties,
      polygonCropType,
      polygonDailyConsumption,
      polygonIrrigationHoursPerDay,
      polygonRowSpacing,
      polygonPlantSpacing,
      polygonPlantingDate,
    } = polygonInformation;

    const isValidPolygonName = !!polygonName;
    const isValidPolygonCropType = !!polygonCropType;
    const isValidPolygonDailyConsumption = polygonDailyConsumption > 0;
    const isValidPolygonIrrigationHours = polygonIrrigationHoursPerDay > 0;
    const isValidPolygonRowSpacing = polygonRowSpacing > 0;
    const isValidPolygonPlantSpacing = polygonPlantSpacing > 0;
    const isValidPolygonPlantingDate = dayjs(polygonPlantingDate).isValid();

    dispatch(
      setPolygonFormState({
        isValidPolygonName,
        isValidPolygonCropType,
        isValidPolygonDailyConsumption,
        isValidPolygonIrrigationHours,
        isValidPolygonRowSpacing,
        isValidPolygonPlantSpacing,
        isValidPolygonPlantingDate,
      })
    );

    if (!isIrrigationProperties) {
      dispatch(
        setPolygonFormState({
          isValidPolygonCropType: true,
          isValidPolygonDailyConsumption: true,
          isValidPolygonIrrigationHours: true,
          isValidPolygonRowSpacing: true,
          isValidPolygonPlantSpacing: true,
          isValidPolygonPlantingDate: true,
        })
      );
    }
  }, [polygonInformation, polygonInformation.isIrrigationProperties]);

  useEffect(() => {
    const unit = polygonInformation.polygonFieldSizeUnit;
    onChangePolygonArea(unit);
  }, [polygonInformation.polygonFieldSizeUnit]);

  // const isPolygonNameError = !!polygonFormState.validationCount && !polygonFormState.isValidPolygonName;

  const onChangePolygonArea = (unit: string) => {
    const area = getPolygonAreaByUnit(currentAreaInHa, unit);

    dispatch(setPolygonInformation({ polygonFieldSize: area }));
  };

  const onChangeInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = getStringWithoutFirstZero(e.target.value, e.target.type);
    dispatch(setPolygonInformation({ [e.target.name]: value, polygonCopySettingsFromId: null }));
  };

  const onChangePolygonRowDirection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = getStringWithoutFirstZero(e.target.value, e.target.type);
    const numericValue = Number(value);

    if (numericValue >= 0 && numericValue <= 360) {
      dispatch(setPolygonInformation({ [e.target.name]: value, polygonCopySettingsFromId: null }));
    }
  };

  const onChangePolygonSoilType = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    console.log('value', value);

    dispatch(setPolygonInformation({ polygonSoilType: typeof value === 'string' ? value.split(',') : value }));
  };

  const onChangePlantingDate = (value: string | null) => {
    dispatch(setPolygonInformation({ polygonPlantingDate: value }));
  };

  const onChangeDailyConsumption = (value: number, shouldResetCopySettingsFrom: boolean = true) => {
    if (shouldResetCopySettingsFrom) {
      dispatch(
        setPolygonInformation({ polygonDailyConsumption: Math_round(value, 3), polygonCopySettingsFromId: null })
      );
    } else {
      dispatch(setPolygonInformation({ polygonDailyConsumption: Math_round(value, 3) }));
    }
  };

  /**
   * Handle PolygonIrrigationProperties changes of unit values and convert it
   * @param value
   * @param destinationUnit
   * @param fieldName
   */
  const onChangeUnit = (value: number, destinationUnit: string, fieldName: keyof PolygonProperties) => {
    // get current polygon unit
    const unitFieldName = (fieldName + 'Unit') as keyof PolygonProperties;
    const sourceUnit = polygonInformation[unitFieldName] as string;
    const convertedValue = Math_round(ConvertUnit(value, sourceUnit, destinationUnit, null), 6).toFixed(2);
    dispatch(
      setPolygonInformation({
        [unitFieldName]: destinationUnit,
        [fieldName]: convertedValue,
        polygonCopySettingsFromId: null,
      })
    );
  };

  const onChangeCopySettingsFrom = (
    _: any,
    item:
      | {
          id: number | string;
          name: string;
        }
      | string
  ) => {
    if (typeof item === 'object' && typeof item.id === 'string') {
      dispatch(setPolygonInformation({ polygonCopySettingsFromId: item.id }));
    }
  };

  const onChangeCropType = (
    _: any,
    item:
      | {
          id: number | string;
          name: string;
        }
      | string
  ) => {
    if (typeof item === 'object') {
      dispatch(setPolygonInformation({ polygonCropType: item.name, polygonCopySettingsFromId: null }));
    }
    if (typeof item === 'string') {
      dispatch(setPolygonInformation({ polygonCropType: item, polygonCopySettingsFromId: null }));
    }
  };
  const onToggleIsIrrigationProps = () => {
    dispatch(setPolygonInformation({ isIrrigationProperties: !polygonInformation.isIrrigationProperties }));
  };
  const onToggleSelectProduct = () => {
    dispatch(setPolygonInformation({ isSelectProduct: !polygonInformation.isSelectProduct }));
  };

  const onTogglePolygonSoilType = () => {
    dispatch(setPolygonInformation({ polygonIsSoilType: !polygonInformation.polygonIsSoilType }));
  };

  const saveEmitter = (item: CatalogItem) => dispatch(setPolygonInformation({ emitter: item }));
  const saveLateral = (item: CatalogItem) => dispatch(setPolygonInformation({ lateral: item }));

  const handleSaveItem = () => {
    if (polygon) {
      const lateralTypeText = masterGroups.find((m) => m.ID === polygon.properties.lateralType)?.MASTERGROUPNAME ?? '';
      const emitterTypeText = masterGroups.find((m) => m.ID === polygon.properties.emitterType)?.MASTERGROUPNAME ?? '';
      const lateralSubtypeText =
        lateralGroups.find((m) => m.GROUPS === polygon.properties.lateralGroup)?.GROUPTYPENAME ?? '';
      const emitterSubtypeText =
        emitterGroups.find((m) => m.GROUPS === polygon.properties.emitterGroup)?.GROUPTYPENAME ?? '';

      dispatch(
        setPolygonInformation({
          lateralTypeText,
          emitterTypeText,
          lateralSubtypeText,
          emitterSubtypeText,
        })
      );
    }
    onSaveItem();
  };

  const onPolygonNameFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return {
    isPolygonInfo,
    polygonInformation,
    handleSaveItem,
    onChangeInformation,
    onPolygonNameFocus,
    onToggleIsIrrigationProps,
    onChangeUnit,
    onChangePlantingDate,
    onChangeCopySettingsFrom,
    onChangeCropType,
    onChangeDailyConsumption,
    onChangePolygonSoilType,
    onChangePolygonRowDirection,
    onToggleSelectProduct,
    onTogglePolygonSoilType,
    saveEmitter,
    saveLateral,
    currentPolygonCopySettingsFrom,
  };
};
