import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GpsIcon, Icon, Row } from 'shared/ui';
import { useAppDispatch } from 'shared/hooks';
import {
  selectDrawMode,
  selectIsTrackingPointDrawing,
  selectOpenGpsPermissionBlocked,
  selectSelectedItem,
  setDrawMode,
  toggleGpsPermissionBlocked,
} from 'shared/slices';
import { useFindLocation } from '../MapControl/hooks/useFindLocation';
import { GpsTrackingBlockedModal } from '../GpsTrackingBlockedModal';

const defaultTooltipColor = 'rgba(0 0 0 / 0.4)';
const activeTooltipColor = 'rgba(0, 0, 0, 0.5)';

type Props = {
  rowDirectionType?: boolean;
};

export const DrawModeTooltip: FC<Props> = ({ rowDirectionType = false }) => {
  const { t } = useTranslation();
  const openLocationBlockedModal = selectOpenGpsPermissionBlocked();
  const dispatch = useAppDispatch();
  const { handleFindUser, loading, checkPermissionGrant } = useFindLocation();
  const drawMode = selectDrawMode();
  const itemDrawing = selectSelectedItem();

  const isTrackingPointDrawing = selectIsTrackingPointDrawing();
  const isManualModeActive = drawMode === 'manual';
  const isGpsModeActive = drawMode === 'gps';

  const toggleLocationBlockedModal = () => dispatch(toggleGpsPermissionBlocked());

  const handleSetGpsDrawMode = async () => {
    const isPermissionGranted = await checkPermissionGrant();
    if (!isPermissionGranted) {
      toggleLocationBlockedModal();
      return;
    }
    dispatch(setDrawMode('gps'));
    handleFindUser();
  };

  const handleSetManualDrawMode = () => {
    dispatch(setDrawMode('manual'));
  };

  const showGpsMode = !isTrackingPointDrawing;

  return (
    <Stack
      alignItems="center"
      sx={{
        position: 'fixed',
        zIndex: 500,
        width: '100%',
        top: rowDirectionType ? '90px' : '150px',
      }}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          bgcolor: defaultTooltipColor,
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      >
        <Row
          sx={{
            py: 1,
            px: 2,
            cursor: 'pointer',
            transition: 'background-color .3s linear',
            bgcolor: isManualModeActive ? activeTooltipColor : 'transparent',
            '&:hover': { bgcolor: activeTooltipColor },
          }}
          onClick={handleSetManualDrawMode}
        >
          <Icon path="mapIcons/click_on_map" />
          <Typography sx={{ fontSize: 18, ml: 1, color: 'white' }}>
            {!rowDirectionType ? t('click_on_the_map_to_place_point') : t('click_on_the_map_to_set_direction')}
          </Typography>
        </Row>
        <Divider sx={{ bgcolor: 'rgba(255 255 255 /.3)' }} variant="fullWidth" />
        {showGpsMode && itemDrawing !== 'point' && !rowDirectionType && (
          <Row
            sx={{
              py: 1,
              px: 2,
              cursor: 'pointer',
              color: 'info.dark',
              pointerEvents: loading ? 'none' : 'all',
              transition: 'background-color .15s linear',
              bgcolor: isGpsModeActive ? activeTooltipColor : 'transparent',
              '&:hover': { bgcolor: activeTooltipColor },
            }}
            onClick={handleSetGpsDrawMode}
          >
            <Typography sx={{ fontSize: 18, ml: 1, color: 'white' }}>
              <GpsIcon />
              {t('use_gps_tracking')}
            </Typography>
            {loading && <CircularProgress size={'20px'} sx={{ ml: '10px' }} color={'info'} />}
          </Row>
        )}
      </Stack>
      <GpsTrackingBlockedModal open={openLocationBlockedModal} onClose={() => toggleLocationBlockedModal()} />
    </Stack>
  );
};
