import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const BigTitle: FC<TypographyProps> = ({ children, noWrap, sx, ...props }) => {
  return (
    <Typography
      noWrap={noWrap}
      sx={{
        fontSize: { xs: '24px', lg: '24px' },
        lineHeight: '28px',
        color: 'primary.main',
        fontWeight: 600,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
