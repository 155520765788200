import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FALLBACK_LANGUAGE = 'en';

const TranslationsContext = createContext<{ loading: boolean } | undefined>(undefined);

interface POEditorExportResponse {
  result: {
    url: string;
  };
  response: {
    status: string;
    code: string;
  };
}

export const useTranslationsContext = () => {
  const context = useContext(TranslationsContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a TranslationsProvider');
  }

  return context;
};

const Loader = () => (
  <Box
    sx={{
      height: '100svh',
      bgcolor: 'primary.main',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress color={'info'} />
  </Box>
);

export const TranslationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const POEDITOR_API_URL = '/poeditor/projects/export';

  const translationCache: Record<string, Array<Record<string, string>>> = {};

  const fetchTranslations = async (language: string) => {
    if (translationCache[language]) {
      return translationCache[language];
    }

    try {
      const formData = new FormData();
      formData.append('api_token', process.env.REACT_APP_POEDITOR_API_KEY!);
      formData.append('id', process.env.REACT_APP_POEDITOR_PROJECT_ID!);
      formData.append('language', language);
      formData.append('type', 'json');

      let response = await axios.post<POEditorExportResponse>(POEDITOR_API_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.response.status === 'fail' && response.data.response.code === '4044') {
        formData.append('language', FALLBACK_LANGUAGE);

        response = await axios.post<POEditorExportResponse>(POEDITOR_API_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      const downloadUrl = response.data.result.url.replace('https://api.poeditor.com/v2', '/poeditor');

      const translationFile = await axios.get<Array<Record<string, string>>>(downloadUrl);
      const translations = translationFile.data;

      translationCache[language] = translations;

      return translations;
    } catch (error) {
      console.error('Error fetching translations:', error);
      throw error;
    }
  };

  const getPOEditorLanguage = (lng: string) => {
    return lng.split('-')[0];
  };

  const loadFallbackTranslations = async (): Promise<void> => {
    const translations = await fetchTranslations(FALLBACK_LANGUAGE);

    const mappedTranslations = translations.reduce((acc, curr) => {
      acc[curr.reference] = curr.definition ?? '';
      return acc;
    }, {});

    i18n.addResourceBundle(FALLBACK_LANGUAGE, 'translation', mappedTranslations);
  };

  const loadTranslations = async (lng: string): Promise<void> => {
    const POEditorLng = getPOEditorLanguage(lng);
    
    if (!POEditorLng) {
      console.error('No POEditor language found');
      return;
    }

    const translations = await fetchTranslations(POEditorLng);

    const mappedTranslations = translations.reduce((acc, curr) => {
      acc[curr.reference] = curr.definition ?? '';
      return acc;
    }, {});

    i18n.addResourceBundle(lng, 'translation', mappedTranslations);

    if (i18n.language !== lng) {
      await i18n.changeLanguage(lng);
    }
  };

  useEffect(() => {
    if (!i18n.isInitialized) return;

    i18n.on('languageChanged', async (lng) => {
      await loadTranslations(lng);
    });

    const load = async () => {
      if (i18n.language !== FALLBACK_LANGUAGE) {
        await loadFallbackTranslations();
      }
      await loadTranslations(i18n.language);

      setLoading(false);
    };

    load();

    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n.isInitialized]);

  return (
    <TranslationsContext.Provider value={{ loading }}>
      {loading ? <Loader /> : <>{children}</>}
    </TranslationsContext.Provider>
  );
};
