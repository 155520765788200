import { Box, Fab, Grow, SpeedDial, SpeedDialAction } from '@mui/material';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PointIconType } from 'shared/enums';
import {
  selectMap,
  setNewPointIcon,
  setTrackingPoint,
  startLineDrawing,
  startPointDrawing,
  startPolygonDrawing,
  toggleTrackingPointDrawing,
} from 'shared/slices';
import { useAppDispatch, useAppNavigator, useToggle } from '../../shared/hooks';
import { Icon } from '../../shared/ui';

const speedActionStyles = {
  '& .MuiButtonBase-root': {
    width: '57px',
    height: '57px',
    bgcolor: 'primary.main',
    color: 'info.main',
    margin: '5px 0',
  },
  '& .MuiButtonBase-root:hover': {
    bgcolor: 'primary.main',
  },
  '& .MuiSpeedDialAction-staticTooltipLabel': {
    bgcolor: 'rgba(0,0,0,0.4)',
    color: 'info.main',
    borderRadius: '28px',
    width: '120px',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'left',
    paddingLeft: '35px',
    marginLeft: '-25px',
    py: '10px',
    cursor: 'pointer',
  },
};

export const MapSpeedDial: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { isTrackingPointDrawing } = selectMap();

  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  const actions = useMemo(
    () => [
      { name: t('point_types_other'), iconPath: 'mapIcons/obstacle' },
      { name: t('point_types_valve'), iconPath: 'mapIcons/valve' },
      { name: t('map_speed_dial_line'), iconPath: 'mapIcons/Line' },
      { name: t('map_speed_dial_field'), iconPath: 'mapIcons/Field' },
    ],
    [t, i18n.language]
  );
  // const subActions = useMemo(
  //   () => [
  //     { name: t('point_types_obstacle'), iconPath: 'mapIcons/obstacle', type: PointIconType.OBSTACLE },
  //     { name: t('point_types_valve'), iconPath: 'mapIcons/valve', type: PointIconType.VALVE },
  //     { name: t('point_types_water_source'), iconPath: 'mapIcons/waterSource', type: PointIconType.WATER_SOURCE },
  //   ],
  //   [t, i18n.language]
  // );

  const { open: isPointModes, toggle: togglePointModes } = useToggle(false);

  const isAddNewItemMenuOpen = !!state?.addMapObjectMenu;
  const toggleAddNewItemMenu = () => {
    if (!state?.addMapObjectMenu) {
      navigate(pathname, { state: { addMapObjectMenu: true } });
    } else {
      navigate(pathname, { state: { addMapObjectMenu: false }, replace: true });
    }
  };

  const handleToggle = () => {
    toggleAddNewItemMenu();
    isPointModes && togglePointModes();
    dispatch(setTrackingPoint(null));
  };

  // const handleOpenPointModes = () => {
  //   toggle();
  //   !isPointModes && togglePointModes();
  // };

  useEffect(() => {
    if (!state?.heightValueTool && isTrackingPointDrawing) {
      dispatch(toggleTrackingPointDrawing());
    }
  }, [state?.heightValueTool]);

  const onStartPolygonDrawing = () => dispatch(startPolygonDrawing());
  const onStartLineDrawing = () => dispatch(startLineDrawing());
  const onStartObstacleDrawing = () => {
    dispatch(setNewPointIcon(PointIconType.OBSTACLE));
    dispatch(startPointDrawing());
  };
  const onStartValveDrawing = () => {
    dispatch(setNewPointIcon(PointIconType.VALVE));
    dispatch(startPointDrawing());
  };

  const methods = [onStartObstacleDrawing, onStartValveDrawing, onStartLineDrawing, onStartPolygonDrawing];

  const onStartTrackingPointDrawing = () => {
    dispatch(toggleTrackingPointDrawing());
    if (!state?.heightValueTool) {
      navigate(pathname, { state: { heightValueTool: true } });
    } else {
      navigate(pathname, { state: { heightValueTool: false }, replace: true });
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 30,
          transition: 'all 0.3s',
          left: '14px',
          zIndex: 100,
        }}
      >
        {/* {!isTrackingPointDrawing && ( */}
        {!state?.heightValueTool && (
          <>
            <SpeedDial
              ariaLabel="Map SpeedDial"
              open={isAddNewItemMenuOpen}
              // open={open}
              onClick={handleToggle}
              icon={
                <Icon
                  path="mapIcons/big_plus"
                  sx={{
                    transform: `rotate(${isAddNewItemMenuOpen ? 45 : 0}deg)`,
                    transition: 'all 0.3s',
                  }}
                />
              }
              sx={{
                transition: 'all 0.3s',
                button: {
                  width: '64px',
                  height: '64px',
                  bgcolor: isAddNewItemMenuOpen ? 'rgba(255, 255, 255, 0.35)' : 'primary.main',
                  '&:hover': {
                    bgcolor: isAddNewItemMenuOpen ? 'rgba(255, 255, 255, 0.35)' : 'primary.main',
                  },
                },
                '& .MuiSpeedDial-actions': {
                  transition: 'all 0.3s',
                  marginBottom: `${isPointModes ? 200 : 0}px !important`,
                  paddingBottom: `${isPointModes ? 10 : 10}px !important`,
                },
              }}
            >
              {actions.map((action, i) => (
                <SpeedDialAction
                  key={action.name}
                  onClick={methods[i]}
                  icon={<Icon path={i == 0 && isPointModes ? 'mapIcons/Point_green' : action.iconPath} />}
                  tooltipOpen
                  tooltipPlacement="right"
                  tooltipTitle={action.name}
                  sx={{
                    ...speedActionStyles,
                    '& .MuiButtonBase-root': {
                      ...speedActionStyles['& .MuiButtonBase-root'],
                      bgcolor: i == 0 && isPointModes ? 'info.main' : 'primary.main',
                    },
                    '& .MuiButtonBase-root:hover': {
                      bgcolor: i == 0 && isPointModes ? 'info.main' : 'primary.main',
                    },
                  }}
                />
              ))}
            </SpeedDial>
            {/*<SpeedDial*/}
            {/*  ariaLabel="Map SubSpeedDial"*/}
            {/*  open={isPointModes}*/}
            {/*  icon={''}*/}
            {/*  direction="up"*/}
            {/*  sx={{*/}
            {/*    transition: 'all 0.3s',*/}
            {/*    position: 'absolute',*/}
            {/*    bottom: '55px',*/}
            {/*    ml: '60px',*/}
            {/*    '& .MuiSpeedDial-fab': { display: 'none' },*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {subActions.map((action) => (*/}
            {/*    <SpeedDialAction*/}
            {/*      key={action.name}*/}
            {/*      onClick={() => onStartPointDrawing(action.type)}*/}
            {/*      icon={<Icon path={action.iconPath} />}*/}
            {/*      tooltipOpen*/}
            {/*      tooltipPlacement="right"*/}
            {/*      tooltipTitle={action.name}*/}
            {/*      sx={{*/}
            {/*        ...speedActionStyles,*/}
            {/*        '& .MuiSpeedDialAction-staticTooltipLabel': {*/}
            {/*          ...speedActionStyles['& .MuiSpeedDialAction-staticTooltipLabel'],*/}
            {/*          width: '160px',*/}
            {/*        },*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  ))}*/}
            {/*</SpeedDial>*/}
          </>
        )}
      </Box>

      <Box
        sx={{
          position: 'fixed',
          top: `calc(100svh - 90px)`,
          right: '14px',
          zIndex: 100,
        }}
      >
        <Grow in={!isPointModes}>
          <div>
            <Fab
              onClick={onStartTrackingPointDrawing}
              sx={{
                width: '64px',
                height: '64px',
                border: '1px solid white',
                bgcolor: isTrackingPointDrawing ? 'grey' : 'rgba(0, 0, 0, 0.68)',
                '&:hover': {
                  bgcolor: isTrackingPointDrawing ? 'grey' : 'rgba(0, 0, 0, 0.68)',
                },
              }}
            >
              {state?.heightValueTool ? <Icon path="mapIcons/Close" /> : <Icon path="mapIcons/mount" />}
              {/* {isTrackingPointDrawing ? <Icon path="mapIcons/Close" /> : <Icon path="mapIcons/mount" />} */}
            </Fab>
          </div>
        </Grow>
      </Box>
    </>
  );
};
