import { selectIsGpsMeasurementInitialized } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { TrackingPlayStopButtons } from './TrackingPlayStopButtons';
import { TrackingStartButton } from './TrackingStartButton';

export const GpsStateButtons = () => {
  const isGpsMeasurementInitialized = selectIsGpsMeasurementInitialized();
  const { t } = useTranslation();

  return isGpsMeasurementInitialized ? (
    <TrackingPlayStopButtons />
  ) : (
    <TrackingStartButton label={t('start_live_gps_measurement')} />
  );
};
