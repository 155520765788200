import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SORT_BY } from 'shared/enums';
import { useAppNavigator } from 'shared/hooks';
import { getSortedByProjectValue } from 'shared/lib';
import { Project } from 'shared/models';
import { CloseIcon, Row, ThreeDotsIcon } from 'shared/ui';
import { ProjectActions } from './ProjectActions';

interface Props {
  project: Project;
  isShowLabel: boolean;
  sortBy: SORT_BY;
  isArchive: boolean;
  onUpdateProject: (projectId: number, p: Partial<Project>) => void;
}

export const ProjectItem: FC<Props> = ({ project, isShowLabel, sortBy, onUpdateProject, isArchive }) => {
  const {
    id,
    ownerName,
    project: { projectName, customerName },
  } = project;

  const { t, i18n } = useTranslation();
  const { navigateToProject, navigate } = useAppNavigator();
  const { pathname, state } = useLocation();

  const label = getSortedByProjectValue(project, sortBy, i18n.language);

  const isOpenMenu = state?.projectMenu && state?.project?.id === id;

  const toggleProjectMenu = (e: any) => {
    e.stopPropagation();
    if (!state?.projectMenu) {
      navigate(pathname, { state: { ...state, projectMenu: true, project } });
    } else {
      navigate(pathname, { state: { ...state, projectMenu: false }, replace: true });
    }
  };

  const handleOpenProject = () => navigateToProject(project.id);

  return (
    <Stack sx={{ width: '100%', position: 'relative' }}>
      {isShowLabel && <Typography sx={{ color: 'info.dark', mt: '20px' }}>{t(label)}</Typography>}

      <Box
        sx={{
          mt: '20px',
          bgcolor: 'info.main',
          borderRadius: '16px',
          border: '1px solid grey',
          borderColor: 'info.light',
          cursor: 'pointer',
        }}
      >
        <Row onClick={handleOpenProject} direction="row" justifyContent="space-between" sx={{ p: '12px' }}>
          <Box>
            <Typography sx={{ wordBreak: 'break-all' }}>{projectName}</Typography>
            <Typography noWrap sx={{ fontSize: 12, color: 'info.dark' }}>
              {`${t('project_management_owner')}: ${ownerName} | ${t('project_management_customer')}: ${
                customerName || t('project_management_none')
              }`}
            </Typography>
          </Box>

          <IconButton onClick={toggleProjectMenu}>
            {isOpenMenu ? (
              <CloseIcon width="24" height="24" viewBox="0 0 24 24" />
            ) : (
              <ThreeDotsIcon width="24" height="25" viewBox="0 0 24 25" />
            )}
          </IconButton>
        </Row>

        <Collapse in={isOpenMenu}>
          <ProjectActions isArchive={isArchive} project={project} onUpdateProject={onUpdateProject} />
        </Collapse>
      </Box>
    </Stack>
  );
};
