import { Button, Dialog, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
};

const typographyStyles = {
  color: '#242731',
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '0.18px',
};

export const GpsTrackingBlockedModal: FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          display: 'flex',
          padding: '48px 24px 40px 24px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          width: '80svw',
          borderRadius: '16px',
          background: '#FFF',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      }}
      hideBackdrop
    >
      <Typography sx={typographyStyles}>❗Device Location turned off❗</Typography>
      <Typography sx={{ ...typographyStyles, textAlign: 'center', fontWeight: 400 }}>
        Enable device location in your phone settings, or create the polygon manually.
      </Typography>

      <Button
        onClick={onClose}
        sx={{
          display: 'flex',
          width: '126px',
          padding: '8px 16px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          border: '1px solid #000',
          background: '#FFF',
          color: '#000',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '130%',
          letterSpacing: '0.18px',
        }}
      >
        Close
      </Button>
    </Dialog>
  );
};
