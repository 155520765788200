import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import { SVGProps } from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
} & SVGProps<SVGElement> &
  BoxProps;
export const ContinueMeasurementIcon = ({ width = 18, height = 18, color = '#1D8742', ...rest }: Props) => {
  return (
    <Box component={'a'} sx={{ display: 'inline-block', verticalAlign: 'top', marginRight: '7px', ...rest.sx }}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.65825 17.4211L17.0689 9.71555C17.6586 9.42073 17.6586 8.57929 17.0689 8.28447L1.65781 0.578904C1.12588 0.312939 0.50002 0.699753 0.500037 1.29447L0.500256 9L0.500475 16.7056C0.500491 17.3003 1.12634 17.6871 1.65825 17.4211Z"
          fill={color}
        />
      </svg>
    </Box>
  );
};
