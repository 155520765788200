import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { useAppNavigator } from './useAppNavigator';
import { localStorageService } from '../services';

const urlDictionary = {
  projectHub: process.env.REACT_APP_PROJECT_HUB_URL!,
};

export const useHubConnection = (hubName: keyof typeof urlDictionary) => {
  const { navigateToLogin } = useAppNavigator();
  const [hubConnection, setHubConnection] = useState<HubConnection>();

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(urlDictionary[hubName], { accessTokenFactory: () => localStorageService.rapidToken })
      .withAutomaticReconnect()
      .build();

    connection.start().then(() => {
      setHubConnection(connection);
    }).catch(err => {
      console.error(err);
      if (err.message.includes('401')) {
        toast.error('Hub connection error.');
        navigateToLogin();
      }
    });

    return () => {
      connection.stop().catch(err => console.error(err));
    };
  }, []);

  return { hubConnection };
};