import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { LineModel } from '../../../shared/models';
import {
  selectCurrentItemId,
  selectCurrentPolygonPointId,
  selectIsItemDrawing,
  selectIsLineDrawing,
  selectIsPolygonDrawing,
  selectLineId,
  selectPolygonById,
  selectSaveProjectStep,
  updatePolygonRowDirectionLineFromAngle,
} from '../../../shared/slices';
import { RowDirectionLineIcon } from '../../../shared/ui';
import {
  calculateAngle,
  getMaxInscribedCircleDiameter,
  getPolygonCenter,
  getRelatedPolygonId,
  getRowDirectionIconSize,
} from 'shared/lib';
import { POLYGON_ROW_DIRECTION_ID } from 'shared/constants';
import { renderToStaticMarkup } from 'react-dom/server';
import { SavingStep } from 'shared/enums';
import { useAppDispatch } from 'shared/hooks';

interface Props {
  lineInfo: LineModel;
  eventHandlers: {
    click(): void;
  };
}

export const RowDirectionLineItem: FC<Props> = ({ lineInfo, eventHandlers }) => {
  const dispatch = useAppDispatch();

  const map = useMap();

  const markerRef = useRef<any>(null);
  const isDrawing = selectIsItemDrawing();
  const selectedObjectId = selectCurrentItemId();
  const selectedLineId = selectLineId();
  const saveProjectStep = selectSaveProjectStep();
  const isLineDrawing = selectIsLineDrawing();
  const isPolygonDrawing = selectIsPolygonDrawing();
  const currentPolygonPointId = selectCurrentPolygonPointId();

  const [zoomLevel, setZoomLevel] = useState(15);

  const isRowDirectionLine = lineInfo.geoJson.id.includes(POLYGON_ROW_DIRECTION_ID);
  const relatedPolygonId = getRelatedPolygonId(lineInfo.geoJson.id);
  const relatedPolygon = selectPolygonById(relatedPolygonId);

  const polygonCenter = useMemo(() => {
    if (relatedPolygon) {
      const polygonBoundary = relatedPolygon.geoJson.points.map((p) => p.coords);
      const center = getPolygonCenter(polygonBoundary);
      if (!Number(isNaN(center?.lat)) && !Number.isNaN(center.lng)) {
        return center;
      }
    }

    return {
      lat: 0,
      lng: 0,
    };
  }, [relatedPolygon]);

  useEffect(() => {
    setZoomLevel(map.getZoom());
  }, []);

  useEffect(() => {
    const onZoomChange = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoom', onZoomChange);

    return () => {
      map.off('zoom', onZoomChange);
    };
  }, [map]);

  useEffect(() => {
    if (markerRef.current) {
      const markerElement = markerRef.current.getElement();
      if (markerElement) {
        const shouldHide = isDrawing && selectedObjectId !== lineInfo.geoJson.id;
        markerElement.style.pointerEvents = shouldHide ? 'none' : 'auto';
      }
    }
  }, [isDrawing, selectedObjectId]);

  useEffect(() => {
    // if polygon shape is changed, related row direction line should change it's position
    if (!isLineDrawing && relatedPolygon && !currentPolygonPointId) {
      const angle = Number(calculateAngle(polygonCenter, lineInfo.geoJson.points[1].coords).toFixed(2));

      if (relatedPolygon.properties.polygonRowDirection !== angle) {
        dispatch(
          updatePolygonRowDirectionLineFromAngle({
            angle: relatedPolygon.properties.polygonRowDirection ?? 0,
            polygon: relatedPolygon,
          })
        );
      }
    }
  }, [polygonCenter, currentPolygonPointId]);

  const iconSize = useMemo(() => {
    if (isRowDirectionLine && relatedPolygon && relatedPolygon.geoJson.points.length >= 3) {
      const points = relatedPolygon?.geoJson.points.map((p) => p.coords) ?? [];

      const radius = getMaxInscribedCircleDiameter(points);
      const size = getRowDirectionIconSize(radius, zoomLevel);
      return size === Infinity ? 50 : size;
    } else {
      return 50;
    }
  }, [zoomLevel, relatedPolygon]);

  const rotationAngle = useMemo(() => {
    let currentAngle = relatedPolygon?.properties.polygonRowDirection ?? 0;

    if (lineInfo && relatedPolygon && relatedPolygon.geoJson.points.length >= 3 && !isPolygonDrawing) {
      const newPoint = lineInfo.geoJson.points[1].coords;
      const angle = calculateAngle(polygonCenter, newPoint).toFixed(2);
      currentAngle = Number(angle);
    }

    return currentAngle;
  }, [lineInfo.geoJson.points[1], polygonCenter, isPolygonDrawing]);

  const icon = useMemo(() => {
    const iconWidth = iconSize;
    const iconHeight = iconSize / 4;

    const rowDirectionIcon = (
      <div
        style={{
          transform: `rotate(-${rotationAngle}deg)`,
          transformOrigin: 'center',
          width: iconWidth,
          height: iconHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RowDirectionLineIcon selected={selectedLineId === lineInfo.geoJson.id && !isLineDrawing} />
      </div>
    );

    return L.divIcon({
      html: renderToStaticMarkup(rowDirectionIcon as ReactElement),
      className: 'custom-icon',
      iconSize: [iconWidth, iconHeight],
    });
  }, [rotationAngle, iconSize, selectedLineId, isLineDrawing]);

  const isHidden = selectedLineId !== lineInfo.geoJson.id && saveProjectStep === SavingStep.SCREEN_SHOOTING;

  return (
    <>
      {!isHidden && (
        <Marker
          ref={markerRef}
          position={[polygonCenter.lat, polygonCenter.lng]}
          icon={icon}
          eventHandlers={eventHandlers}
        />
      )}
    </>
  );
};
