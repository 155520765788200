import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useSideMenuItems = () => {
  const { t, i18n } = useTranslation();

  const sideMenuItems = useMemo(
    () => [
      { title: t('side_menu_share'), icon: 'share' },
      { title: t('side_menu_export'), icon: 'email' },
      { title: t('side_menu_projects'), icon: 'projects' },
      { title: t('side_menu_new_project'), icon: 'newproject' },
      { title: t('side_menu_units'), icon: 'units' },
      { title: t('side_menu_language'), icon: 'language' },
    ],
    [t, i18n.language]
  );

  return { sideMenuItems };
};
