import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, Direction, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { useI18nUtils } from '../../shared/hooks';

export const MUIWrapperContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  changeDirection: (dir: Direction) => {},
});

const cacheRtlOptions = {
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
};

const cacheRtl = createCache(cacheRtlOptions);

const emptyCacheOptions = {
  key: 'meaningless-key',
};

const emptyCache = createCache(emptyCacheOptions);

export const MuiThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getCurrentDirection } = useI18nUtils();
  const [direction, setDirection] = useState<Direction>(getCurrentDirection());
  const muiWrapperUtils = useMemo(
    () => ({
      changeDirection: (dir: Direction) => {
        setDirection(dir);
      },
    }),
    []
  );

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  const theme = useMemo(
    () =>
      createTheme({
        direction,
        palette: {
          primary: {
            light: 'rgba(21, 143, 69, 0.75)',
            main: 'rgba(29, 135, 66, 1)',
            dark: 'rgb(54, 124, 43)',
            contrastText: 'rgba(233, 243, 237, 1)',
          },
          secondary: {
            light: 'rgba(188, 190, 192, 0.46)',
            main: 'rgb(188, 190, 192)',
            dark: 'rgba(44, 44, 44, 1)',
          },
          info: {
            main: 'rgb(255, 255, 255)',
            light: 'rgba(218, 218, 218, 1)',
            dark: 'rgba(36, 39, 49, 0.50)',
          },
          error: {
            main: 'rgba(236, 23, 23, 1)',
            dark: 'rgba(192, 0, 0, 1)',
          },
        },
        typography: {
          fontFamily: direction === 'rtl' ? 'Heebo' : 'Myriad Pro',
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                backgroundColor: '#4E4E4E',
              },
            },
          },
        },
      }),
    [direction]
  );

  return (
    <CacheProvider value={direction === 'rtl' ? cacheRtl : emptyCache}>
      <MUIWrapperContext.Provider value={muiWrapperUtils}>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </MUIWrapperContext.Provider>
    </CacheProvider>
  );
};
