import { SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { METRIC_DEFAULTS, US_DEFAULTS } from 'shared/constants';
import { SavingStep } from 'shared/enums';
import { useAppDispatch, useAppNavigator } from 'shared/hooks';
import { ConvertUnit, Math_round } from 'shared/lib';
import { Unit } from 'shared/models';
import { localStorageService } from 'shared/services';
import {
  resetDrawMode,
  saveItem,
  selectLines,
  selectPoints,
  selectPolygons,
  selectUnits,
  setSaveProjectStep,
  setUnitsValue,
} from 'shared/slices';
import { useMapItemActions } from 'widgets/MapItemHeader/hooks';

const convertUnit = (value: number, source: string, destination: string, unitType: string | null = null) =>
  Math_round(ConvertUnit(value, source, destination, unitType), 6);

export const useManageUnits = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { navigate } = useAppNavigator();

  const { units } = selectUnits();

  const polygons = selectPolygons();
  const lines = selectLines();
  const points = selectPoints();

  const { isSelectedItem } = useMapItemActions();

  const [unitValues, setUnitValues] = useState({
    pressure: units.Pressure,
    flowPerLength: units.FlowPerLength,
    flow: units.Flow,
    totalFlow: units.TotalFlow,
    length: units.Length,
    velocity: units.Velocity,
    pipeDiameter: units.PipeDiameter,
    valveDiameter: units.ValveDiameter,
    emitterSpacing: units.EmitterSpacing,
    lateralSpacing: units.LateralSpacing,
    area: units.Area,
    appnDepth: units.AppnDepth,
    appnRate: units.AppnRate,
  });

  const [convertUnitValues, setConvertUnitValues] = useState({
    pressure: units.Pressure,
    flowPerLength: units.FlowPerLength,
    flow: units.Flow,
    totalFlow: units.TotalFlow,
    length: units.Length,
    velocity: units.Velocity,
    pipeDiameter: units.PipeDiameter,
    valveDiameter: units.ValveDiameter,
    emitterSpacing: units.EmitterSpacing,
    lateralSpacing: units.LateralSpacing,
    area: units.Area,
    appnDepth: units.AppnDepth,
    appnRate: units.AppnRate,
  });

  const [values, setValues] = useState({
    pressure: 1,
    flowPerLength: 1,
    flow: 1,
    totalFlow: 1,
    length: 1,
    velocity: 1,
    pipeDiameter: 1,
    valveDiameter: 1,
    emitterSpacing: 1,
    lateralSpacing: 1,
    area: 1,
    appnDepth: 1,
    appnRate: 1,
  });

  const [convertedValues, setConvertedValues] = useState({
    pressure: 1,
    flowPerLength: 1,
    flow: 1,
    totalFlow: 1,
    length: 1,
    velocity: 1,
    pipeDiameter: 1,
    valveDiameter: 1,
    emitterSpacing: 1,
    lateralSpacing: 1,
    area: 1,
    appnDepth: 1,
    appnRate: 1,
  });

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name;
    const inputValue = Number(e.target.value);

    const sourceKey = inputName as keyof typeof unitValues;
    const sourceUnit = unitValues[sourceKey];
    const destinationKey = inputName as keyof typeof convertUnitValues;
    const destinationUnit = convertUnitValues[destinationKey];

    const convertedKey = inputName as keyof typeof convertedValues;
    const convertedValue = Math_round(ConvertUnit(inputValue, sourceUnit, destinationUnit, null), 6);

    setValues((prev) => ({ ...prev, [e.target.name]: Number(e.target.value) }));
    setConvertedValues((prev) => ({ ...prev, [convertedKey]: convertedValue }));
  };

  const handleUnitChange = (e: SelectChangeEvent<unknown>) => {
    const inputName = e.target.name;
    const inputValue = e.target.value as string;

    const valueKey = inputName as keyof typeof values;
    const value = values[valueKey];
    const destinationKey = inputName as keyof typeof convertUnitValues;
    const destinationUnit = convertUnitValues[destinationKey];

    const convertedKey = inputName as keyof typeof convertedValues;
    const convertedValue = Math_round(ConvertUnit(value, inputValue, destinationUnit, null), 6);

    setUnitValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setConvertedValues((prev) => ({ ...prev, [convertedKey]: convertedValue }));
  };

  const handleConvertUnitChange = (e: SelectChangeEvent<unknown>) => {
    const inputName = e.target.name;
    const inputValue = e.target.value as string;

    const valueKey = inputName as keyof typeof values;
    const value = values[valueKey];
    const sourceKey = inputName as keyof typeof unitValues;
    const sourceUnit = unitValues[sourceKey];

    const convertedKey = inputName as keyof typeof convertedValues;
    const convertedValue = Math_round(ConvertUnit(value, sourceUnit, inputValue, null), 6);

    setConvertUnitValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setConvertedValues((prev) => ({ ...prev, [convertedKey]: convertedValue }));
  };

  const onClose = () => navigate(pathname, { state: { unitsModal: false }, replace: true })

  const onMetricDefaultsClick = () => {
    setUnitValues({
      pressure: METRIC_DEFAULTS.Pressure,
      flowPerLength: METRIC_DEFAULTS.FlowPerLength,
      flow: METRIC_DEFAULTS.Flow,
      totalFlow: METRIC_DEFAULTS.TotalFlow,
      length: METRIC_DEFAULTS.Length,
      velocity: METRIC_DEFAULTS.Velocity,
      pipeDiameter: METRIC_DEFAULTS.PipeDiameter,
      valveDiameter: METRIC_DEFAULTS.ValveDiameter,
      emitterSpacing: METRIC_DEFAULTS.EmitterSpacing,
      lateralSpacing: METRIC_DEFAULTS.LateralSpacing,
      area: METRIC_DEFAULTS.Area,
      appnDepth: METRIC_DEFAULTS.AppnDepth,
      appnRate: METRIC_DEFAULTS.AppnRate,
    });
    const convertedPressure = convertUnit(values.pressure, METRIC_DEFAULTS.Pressure, convertUnitValues.pressure);
    const convertedFlowPerLength = convertUnit(
      values.flowPerLength,
      METRIC_DEFAULTS.FlowPerLength,
      convertUnitValues.flowPerLength
    );
    const convertedFlow = convertUnit(values.flow, METRIC_DEFAULTS.Flow, convertUnitValues.flow);
    const convertedTotalFlow = convertUnit(values.totalFlow, METRIC_DEFAULTS.TotalFlow, convertUnitValues.totalFlow);
    const convertedLength = convertUnit(values.length, METRIC_DEFAULTS.Length, convertUnitValues.length);
    const convertedVelocity = convertUnit(values.velocity, METRIC_DEFAULTS.Velocity, convertUnitValues.velocity);
    const convertedPipeDiameter = convertUnit(
      values.pipeDiameter,
      METRIC_DEFAULTS.PipeDiameter,
      convertUnitValues.pipeDiameter
    );
    const convertedValveDiameter = convertUnit(
      values.valveDiameter,
      METRIC_DEFAULTS.ValveDiameter,
      convertUnitValues.valveDiameter
    );
    const convertedEmitterSpacing = convertUnit(
      values.emitterSpacing,
      METRIC_DEFAULTS.EmitterSpacing,
      convertUnitValues.emitterSpacing
    );
    const convertedLateralSpacing = convertUnit(
      values.lateralSpacing,
      METRIC_DEFAULTS.LateralSpacing,
      convertUnitValues.lateralSpacing
    );
    const convertedArea = convertUnit(values.area, METRIC_DEFAULTS.Area, convertUnitValues.area);
    const convertedAppnDepth = convertUnit(values.appnDepth, METRIC_DEFAULTS.AppnDepth, convertUnitValues.appnDepth);
    const convertedAppnRate = convertUnit(values.appnRate, METRIC_DEFAULTS.AppnRate, convertUnitValues.appnRate);

    setConvertedValues({
      pressure: convertedPressure,
      flowPerLength: convertedFlowPerLength,
      flow: convertedFlow,
      totalFlow: convertedTotalFlow,
      length: convertedLength,
      velocity: convertedVelocity,
      pipeDiameter: convertedPipeDiameter,
      valveDiameter: convertedValveDiameter,
      emitterSpacing: convertedEmitterSpacing,
      lateralSpacing: convertedLateralSpacing,
      area: convertedArea,
      appnDepth: convertedAppnDepth,
      appnRate: convertedAppnRate,
    });
  };

  const onUSDefaultsClick = () => {
    setUnitValues({
      pressure: US_DEFAULTS.Pressure,
      flowPerLength: US_DEFAULTS.FlowPerLength,
      flow: US_DEFAULTS.Flow,
      totalFlow: US_DEFAULTS.TotalFlow,
      length: US_DEFAULTS.Length,
      velocity: US_DEFAULTS.Velocity,
      pipeDiameter: US_DEFAULTS.PipeDiameter,
      valveDiameter: US_DEFAULTS.ValveDiameter,
      emitterSpacing: US_DEFAULTS.EmitterSpacing,
      lateralSpacing: US_DEFAULTS.LateralSpacing,
      area: US_DEFAULTS.Area,
      appnDepth: US_DEFAULTS.AppnDepth,
      appnRate: US_DEFAULTS.AppnRate,
    });

    const convertedPressure = convertUnit(values.pressure, US_DEFAULTS.Pressure, convertUnitValues.pressure);
    const convertedFlowPerLength = convertUnit(
      values.flowPerLength,
      US_DEFAULTS.FlowPerLength,
      convertUnitValues.flowPerLength
    );
    const convertedFlow = convertUnit(values.flow, US_DEFAULTS.Flow, convertUnitValues.flow);
    const convertedTotalFlow = convertUnit(values.totalFlow, US_DEFAULTS.TotalFlow, convertUnitValues.totalFlow);
    const convertedLength = convertUnit(values.length, US_DEFAULTS.Length, convertUnitValues.length);
    const convertedVelocity = convertUnit(values.velocity, US_DEFAULTS.Velocity, convertUnitValues.velocity);
    const convertedPipeDiameter = convertUnit(
      values.pipeDiameter,
      US_DEFAULTS.PipeDiameter,
      convertUnitValues.pipeDiameter
    );
    const convertedValveDiameter = convertUnit(
      values.valveDiameter,
      US_DEFAULTS.ValveDiameter,
      convertUnitValues.valveDiameter
    );
    const convertedEmitterSpacing = convertUnit(
      values.emitterSpacing,
      US_DEFAULTS.EmitterSpacing,
      convertUnitValues.emitterSpacing
    );
    const convertedLateralSpacing = convertUnit(
      values.lateralSpacing,
      US_DEFAULTS.LateralSpacing,
      convertUnitValues.lateralSpacing
    );
    const convertedArea = convertUnit(values.area, US_DEFAULTS.Area, convertUnitValues.area);
    const convertedAppnDepth = convertUnit(values.appnDepth, US_DEFAULTS.AppnDepth, convertUnitValues.appnDepth);
    const convertedAppnRate = convertUnit(values.appnRate, US_DEFAULTS.AppnRate, convertUnitValues.appnRate);

    setConvertedValues({
      pressure: convertedPressure,
      flowPerLength: convertedFlowPerLength,
      flow: convertedFlow,
      totalFlow: convertedTotalFlow,
      length: convertedLength,
      velocity: convertedVelocity,
      pipeDiameter: convertedPipeDiameter,
      valveDiameter: convertedValveDiameter,
      emitterSpacing: convertedEmitterSpacing,
      lateralSpacing: convertedLateralSpacing,
      area: convertedArea,
      appnDepth: convertedAppnDepth,
      appnRate: convertedAppnRate,
    });
  };

  const updatePolygonsUnits = (newUnits: Unit) => {
    const { AppnDepth, Area, Length } = newUnits;

    polygons.forEach((polygon) => {
      const { name, description, isIrrigationProperties } = polygon.information;

      const {
        polygonDailyConsumption,
        polygonDailyConsumptionUnit,
        polygonFieldSize,
        polygonFieldSizeUnit,
        polygonPlantSpacing,
        polygonPlantSpacingUnit,
        polygonRowSpacing,
        polygonRowSpacingUnit,
      } = polygon.properties;

      const newFieldSize = Number(ConvertUnit(polygonFieldSize, polygonFieldSizeUnit, Area, null).toFixed(2));
      const newDailyConsumption = Number(
        ConvertUnit(polygonDailyConsumption, polygonDailyConsumptionUnit, AppnDepth, null).toFixed(2)
      );
      const newPlantSpacing = Number(
        ConvertUnit(polygonPlantSpacing, polygonPlantSpacingUnit, Length, null).toFixed(2)
      );
      const newRowSpacing = Number(ConvertUnit(polygonRowSpacing, polygonRowSpacingUnit, Length, null).toFixed(2));

      const polygonInformation = {
        polygonName: name,
        polygonDescription: description,
        isIrrigationProperties: isIrrigationProperties,
        images: polygon.images,

        polygonFieldSizeUnit: Area,
        polygonDailyConsumptionUnit: AppnDepth,
        polygonRowSpacingUnit: Length,
        polygonPlantSpacingUnit: Length,
        polygonFieldSize: newFieldSize,
        polygonDailyConsumption: newDailyConsumption,
        polygonRowSpacing: newRowSpacing,
        polygonPlantSpacing: newPlantSpacing,
      };

      dispatch(
        saveItem({
          itemId: polygon.geoJson.id,
          information: {
            ...polygon.properties,
            ...polygonInformation,
          },
          itemType: 'polygon',
        })
      );
    });
  };

  const updateLinesUnits = (newUnits: Unit) => {
    const { Flow, Length, PipeDiameter, Velocity } = newUnits;

    lines.forEach((line) => {
      const { name, description, isIrrigationProperties } = line.information;

      const { lineTotalLength, lineTotalLengthUnit, lineSections, lineCopySettingsFromId } = line.properties;

      const newTotalLength = Number(ConvertUnit(lineTotalLength, lineTotalLengthUnit, Length, null).toFixed(2));

      const newLineSections = lineSections.map((section) => {
        const newDiameter = Number(
          ConvertUnit(Number(section.linePipeDiameter), section.linePipeDiameterUnit, PipeDiameter, null).toFixed(2)
        );
        const newLength = Number(ConvertUnit(section.lineLength, section.lineLengthUnit, Length, null).toFixed(2));
        const newFlowRate = Number(ConvertUnit(section.lineFlowRate, section.lineFlowRateUnit, Flow, null).toFixed(2));
        const newMaxVelocity = Number(
          ConvertUnit(section.lineMaxVelocity, section.lineMaxVelocityUnit, Velocity, null).toFixed(2)
        );

        return {
          ...section,
          linePipeDiameter: newDiameter,
          linePipeDiameterUnit: PipeDiameter,
          lineLength: newLength,
          lineLengthUnit: Length,
          lineFlowRate: newFlowRate,
          lineFlowRateUnit: Flow,
          lineMaxVelocity: newMaxVelocity,
          lineMaxVelocityUnit: Velocity,
        };
      });

      const information = {
        lineName: name,
        lineDescription: description,
        isIrrigationProperties,
        images: line.images,
        lineTotalLength: newTotalLength,
        lineTotalLengthUnit: Length,
        lineSections: newLineSections,
        lineCopySettingsFromId,
      };

      dispatch(
        saveItem({
          itemId: line.geoJson.id,
          information: information,
          itemType: 'line',
        })
      );
    });
  };

  const updatePointsUnits = (newUnits: Unit) => {
    const { Length, ValveDiameter, Pressure, TotalFlow } = newUnits;

    points.forEach((point) => {
      const { name, description, isIrrigationProperties } = point.information;

      const {
        pointElevationUnit,
        pointDiameterUnit,
        pointPressureUnit,
        pointFlowRateUnit,
        pointDiameter,
        pointElevation,
        pointPressure,
        pointFlowRate,
      } = point.properties;

      const newPointElevation = Number(ConvertUnit(pointElevation, pointElevationUnit, Length, null).toFixed(2));
      const newDiameter = Number(ConvertUnit(pointDiameter, pointDiameterUnit, ValveDiameter, null).toFixed(2));
      const newPressure = Number(ConvertUnit(pointPressure, pointPressureUnit, Pressure, null).toFixed(2));
      const newFlowRate = Number(ConvertUnit(pointFlowRate, pointFlowRateUnit, TotalFlow, null).toFixed(2));

      const pointInformation = {
        pointName: name,
        pointDescription: description,
        isIrrigationProperties,
        images: point.images,

        pointElevation: newPointElevation,
        pointElevationUnit: Length,
        pointDiameter: newDiameter,
        pointDiameterUnit: ValveDiameter,
        pointPressure: newPressure,
        pointPressureUnit: Pressure,
        pointFlowRate: newFlowRate,
        pointFlowRateUnit: TotalFlow,
      };

      dispatch(
        saveItem({
          itemId: point.geoJson.id,
          information: {
            ...point.properties,
            ...pointInformation,
          },
          itemType: 'point',
        })
      );
    });
  };

  const updateItemsUnits = (newUnits: Unit) => {
    updatePolygonsUnits(newUnits);
    updateLinesUnits(newUnits);
    updatePointsUnits(newUnits);

    if (!isSelectedItem) {
      dispatch(setSaveProjectStep(SavingStep.SAVING));
      dispatch(resetDrawMode());
    }
  };

  const onSaveClick = async () => {
    const unitsObj = {
      Pressure: unitValues.pressure,
      FlowPerLength: unitValues.flowPerLength,
      Flow: unitValues.flow,
      TotalFlow: unitValues.totalFlow,
      Length: unitValues.length,
      Velocity: unitValues.velocity,
      PipeDiameter: unitValues.pipeDiameter,
      ValveDiameter: unitValues.valveDiameter,
      EmitterSpacing: unitValues.emitterSpacing,
      LateralSpacing: unitValues.lateralSpacing,
      Area: unitValues.area,
      AppnDepth: unitValues.appnDepth,
      AppnRate: unitValues.appnRate,
    };

    dispatch(setUnitsValue({ units: unitsObj, unitsChanged: true }));
    localStorageService.units = unitsObj;

    onClose();

    updateItemsUnits(unitsObj);
  };

  return {
    convertUnitValues,
    convertedValues,
    handleConvertUnitChange,
    handleUnitChange,
    handleValuesChange,
    onMetricDefaultsClick,
    onSaveClick,
    onUSDefaultsClick,
    onClose,
    unitValues,
    values,
  };
};
