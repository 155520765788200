import { createContext, FC, PropsWithChildren, useEffect } from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useHubConnection } from 'shared/hooks/useHubConnection';
import { useAppDispatch } from 'shared/hooks';
import { ProjectDataFromServer } from 'shared/models';
import { parseLinesFromServer, parsePointsFromServer, parsePolygonsFromServer } from 'shared/lib';
import {
  selectProject,
  setIsLineInfo,
  setIsPointInfo,
  setIsPolygonInfo,
  setLines,
  setPoints,
  setPolygons,
  setProjectData,
  stopItemDrawing,
} from 'shared/slices';

interface HubContextType {
  hubConnection: HubConnection | undefined;
  updateProject: (projectId: string, project: string) => void;
  onUpdatedProject: (project: string) => void;
  joinProjectRoom: (projectId: string) => void;
}

export const ProjectHubContext = createContext<HubContextType | undefined>(undefined);

export const ProjectHubProvider: FC<PropsWithChildren> = ({ children }) => {
  const { hubConnection } = useHubConnection('projectHub');
  const dispatch = useAppDispatch();
  const { id: projectId } = selectProject();

  useEffect(() => {
    let mounted = true;

    if (projectId && hubConnection?.state === HubConnectionState.Connected) {
      joinProjectRoom(projectId.toString());

      hubConnection.on('updatedProject', onUpdatedProject);
      hubConnection.onreconnected(() => {
        if (!mounted) return;

        joinProjectRoom(projectId.toString());
      });
    }

    return () => {
      if (!projectId || hubConnection?.state !== HubConnectionState.Connected) return;

      mounted = false;
      leaveProjectRoom(projectId.toString());
    };
  }, [hubConnection?.state, projectId]);

  const joinProjectRoom = (projectId: string) => {
    try {
      hubConnection?.send('joinRoom', projectId);
    } catch (e) {
      console.log('e', e);
    }
  };

  const leaveProjectRoom = (projectId: string) => {
    try {
      hubConnection?.send('leaveRoom', projectId);
    } catch (e) {
      console.log('e', e);
    }
  };

  const updateProject = (projectId: string, project: string) => {
    if (hubConnection?.state === 'Connected') {
      try {
        hubConnection?.send('updateProject', projectId, project);
      } catch (e) {
        console.log('e', e);
      }
    }
  };

  const onUpdatedProject = (project: string) => {
    const parsedData = JSON.parse(project) as ProjectDataFromServer;

    const polygons = parsePolygonsFromServer(parsedData);
    const lines = parseLinesFromServer(parsedData);
    const points = parsePointsFromServer(parsedData);

    dispatch(setProjectData(parsedData));
    dispatch(setPolygons(polygons));
    dispatch(setLines(lines));
    dispatch(setPoints(points));
    dispatch(stopItemDrawing());
    dispatch(setIsPolygonInfo(false));
    dispatch(setIsPointInfo(false));
    dispatch(setIsLineInfo(false));
  };

  const contextValue: HubContextType = { hubConnection, updateProject, onUpdatedProject, joinProjectRoom };

  return <ProjectHubContext.Provider value={contextValue}>{children}</ProjectHubContext.Provider>;
};
