import { useTranslation } from 'react-i18next';

export const useI18nUtils = () => {
  const { i18n } = useTranslation();

  const getCurrentDirection = () => {
    const languageCode = i18n.language;
    return languageCode === 'he' || languageCode === 'he-HE' ? 'rtl' : 'ltr';
  };

  return { getCurrentDirection };
};
