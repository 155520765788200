import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  IconButton as MuiIconButton,
  InputAdornment,
  Popover,
  RadioGroup,
  Stack,
  styled,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ProjectItem } from 'features';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CURRENT_TAB, SORT_BY } from 'shared/enums';
import { useAnchorEl, useAppNavigator } from 'shared/hooks';
import {
  ArchiveIcon,
  AscSortIcon,
  DescSortIcon,
  PlusIcon,
  ProjectsFilterIcon,
  Radio,
  Row,
  SearchIcon,
} from 'shared/ui';
import { TAB } from '../../shared/ui/Tab';
import { useProjects } from './hooks';

const IconButton = styled(MuiIconButton)(() => ({
  padding: 0,
  border: '1px solid green',
  width: '32px',
  height: '32px',
}));

export const ProjectManagement = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:550px)');

  const { pathname, state } = useLocation();
  const { navigate } = useAppNavigator();

  const { open, anchorEl, onOpenMenu } = useAnchorEl();
  const openNewProject = !!state?.newProjectModal;
  const onAddNewProject = () => navigate(pathname, { state: { newProjectModal: true } });

  const {
    loading,
    projects,
    searchValue,
    onChangeSearchValue,
    onClearSearchValue,
    onChangeTab,
    sortBy,
    onChangeSortBy,
    isAscSorting,
    onToggleSortDirection,
    firstIndexesOfSortGroups,
    onUpdateProject,
    isArchive,
    onToggleShowArchived,
  } = useProjects();

  const onOpenSortByMenu = (event: React.MouseEvent<HTMLElement>) => {
    onOpenMenu(event);
    navigate(pathname, { state: { sortBy: true } });
  };

  const onCloseSortByMenu = () => {
    navigate(pathname, { state: { sortBy: false }, replace: true });
  };

  return (
    <>
      <Row>
        <Row>
          <IconButton onClick={onOpenSortByMenu} sx={{ bgcolor: open ? 'rgba(29, 135, 66, 0.20);' : 'transparent' }}>
            <ProjectsFilterIcon width="18" height="18" viewBox="-3 -3 24 24" />
          </IconButton>
          <Typography sx={{ fontWeight: 700, ml: 1, fontSize: 22, color: open ? 'primary.main' : 'info.dark' }}>
            {isArchive ? t('side_menu_archive_projects') : t('side_menu_projects')}
          </Typography>
        </Row>

        <IconButton onClick={onAddNewProject} sx={{ bgcolor: openNewProject ? 'primary.main' : 'transparent' }}>
          <PlusIcon width="22" height="22" viewBox="5 5 22 22" fill={openNewProject ? 'white' : '#1D8742'} />
        </IconButton>
      </Row>

      <Popover
        open={!!state?.sortBy}
        anchorEl={anchorEl}
        onClose={onCloseSortByMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            ...(isMobile && {
              left: '0 !important',
              right: '0 !important',
              width: '110vw',
              maxWidth: '100% !important',
            }),
            borderRadius: '0 0 16px 16px',
            p: '16px 24px',
          },
        }}
      >
        <Typography sx={{ color: 'info.dark' }}>{t('sort_sort_by')}</Typography>

        <RadioGroup
          value={sortBy}
          onChange={(e) => {
            onChangeSortBy(e);
            onCloseSortByMenu();
          }}
          name="isExistingFiltration"
          sx={{ flexWrap: 'nowrap', mt: 1 }}
        >
          {Object.values(SORT_BY).map((value) => (
            <Radio value={value} label={t(value)} key={value} radioColor={'primary.main'} />
          ))}
        </RadioGroup>

        <Box sx={{ width: '100%', height: '1px', background: '#D4D4D4', my: 1 }} />

        <Button
          fullWidth
          sx={{ color: 'black', textTransform: 'capitalize', px: 0, justifyContent: 'flex-start' }}
          onClick={() => {
            onToggleShowArchived();
            onCloseSortByMenu();
          }}
        >
          <ArchiveIcon sx={{ mr: 1 }} />
          {isArchive ? t('projects') : t('archive')}
        </Button>
      </Popover>

      <Tabs value={state?.currentTab ?? CURRENT_TAB.ALL} onChange={onChangeTab} sx={{ minHeight: '40px', mt: 1 }}>
        {Object.values(CURRENT_TAB).map((value) => (
          <TAB label={t(value)} value={value} key={value} />
        ))}
      </Tabs>

      <Box sx={{ position: 'relative', pb: 1 }}>
        <TextField
          value={searchValue}
          onChange={onChangeSearchValue}
          variant="standard"
          placeholder={t('search')}
          sx={{ width: '100%', mt: 1.5, borderBottom: '1px', input: { py: '2px' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? (
                  <IconButton onClick={onClearSearchValue} size={'small'} sx={{ p: 0, border: 'none' }}>
                    <CloseIcon sx={{ color: '#667085' }} />
                  </IconButton>
                ) : (
                  <SearchIcon width="24" height="24" viewBox="0 0 24 24" />
                )}
              </InputAdornment>
            ),
          }}
        />

        <Row justifyContent="flex-end">
          <Row
            justifyContent="flex-end"
            sx={{ position: 'relative', zIndex: 10, bgcolor: 'rgba(240, 251, 255, 1)', pt: 1, pl: 1 }}
          >
            <Typography sx={{ color: 'info.dark' }}>{t(sortBy)}</Typography>

            <MuiIconButton onClick={onToggleSortDirection} sx={{ pr: 0 }}>
              {isAscSorting ? (
                <AscSortIcon width="16" height="16" viewBox="0 0 16 16" />
              ) : (
                <DescSortIcon width="16" height="16" viewBox="0 0 16 16" />
              )}
            </MuiIconButton>
          </Row>
        </Row>
      </Box>

      <Stack
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar-thumb': { border: `2px solid green` },
          '&::-webkit-scrollbar': { width: '3px' },
        }}
      >
        {loading ? (
          <CircularProgress sx={{ mt: 8 }} />
        ) : (
          <>
            {projects.map((p, i) => (
              <ProjectItem
                key={p.id}
                project={p}
                isShowLabel={firstIndexesOfSortGroups.includes(i)}
                sortBy={sortBy}
                onUpdateProject={onUpdateProject}
                isArchive={isArchive}
              />
            ))}

            {!projects.length && (
              <Typography align="center" sx={{ color: 'info.dark', mt: 5 }}>
                {t('no_projects')}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
