import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Icon } from '../Icon';

interface Props extends CheckboxProps {
  label?: ReactNode;
  small?: boolean;
}

export const CheckBox: FC<Props> = ({ label, disabled, name, checked, onChange, small, sx }) => {
  return (
    <FormControlLabel
      label={label && label}
      disabled={disabled}
      sx={{
        ml: 0,
        '& .MuiTypography-root': {
          color: small ? 'black' : 'primary.main',
          fontWeight: small ? 400 : 700,
          fontSize: small ? 16 : 18,
          userSelect: 'none',
          ml: 1,
          mt: '3px',
        },
        ...sx,
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          icon={<Icon path={`${small ? 'small_' : ''}unchecked_checkbox`} />}
          checkedIcon={<Icon path={`${small ? 'small_' : ''}checked_checkbox`} />}
          sx={{ px: '3px' }}
        />
      }
    />
  );
};
