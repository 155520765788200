export enum DataTab {
  INFORMATION = 'information',
  PROPERTIES = 'properties',
}

export enum PointIconType {
  WATER_SOURCE = 'waterSource',
  VALVE = 'valve',
  OBSTACLE = 'obstacle',
}

export type MAP_LAYER = 'roadmap' | 'terrain' | 'hybrid';

export enum SavingStep {
  DONE,
  SCREEN_SHOOTING,
  SCREEN_SHOOTING_DONE,
  SAVING,
  CANCELED,
}

export enum PolygonSelectedProductType {
  INTEGRATED = 'integrated',
  ON_LINE = 'online',
  OTHER = 'other',
}

export enum CURRENT_TAB {
  ALL = 'filter_all',
  MY = 'filter_my',
  SHARED = 'filter_shared',
}

export enum SORT_BY {
  NAME = 'sort_name',
  DATE_CREATED = 'sort_created_date',
  LAST_MODIFIED = 'sort_last_modified_date',
}

export enum ProjectPermission {
  Editor = 1,
  Viewer = 2,
  ViewerCanShare = 4,
  EditorCanShare = 5,
  NoAccess = 0,
}

export enum ProjectStatus {
  Active = 3,
  Archive = 2,
  Deleted = 1,
}
