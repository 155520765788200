import { CircularProgress } from '@mui/material';
import { FC } from 'react';

interface Props {
  image: string;
  onChange: (e: any) => void;
  loading: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export const ImageInput: FC<Props> = ({ image, onChange, loading, disabled }) => {
  return (
    <label
      style={{
        borderRadius: '15px',
        border: '1px solid white',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(loading && { opacity: 0 }),
        ...(image ? { background: `url(${image}) center/cover no-repeat` } : { backgroundColor: 'transparent' }),
      }}
    >
      {loading && <CircularProgress size={40} sx={{ color: 'primary.main' }} />}
      {!disabled && <input accept="image/*" type="file" onChange={onChange} style={{ display: 'none' }} />}
    </label>
  );
};
