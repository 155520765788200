import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  additionalInput?: JSX.Element;
  error?: boolean;
  helperText?: string;
  options: { id: number | string; name: string }[];
  value: { id: number | string; name: string } | string;
  onChange: (e: any, item: { id: number | string; name: string } | string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  loading?: boolean;
  disabled?: boolean;
};

const autoFillColor = {
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:hover': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px rgba(233, 243, 237, 1) inset !important',
    WebkitTextFillColor: 'black !important',
  },
};

export const FreeSoloInput: FC<Props> = ({
  options,
  name,
  label,
  placeholder,
  disabled,
  error,
  helperText,
  additionalInput,
  onChange,
  value,
  onBlur,
  loading,
}) => {
  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Typography noWrap sx={{ color: error ? 'red' : 'secondary.dark', fontSize: 16, fontWeight: 600 }}>
        {`${label ? label : ''} ${error ? '*' : ''}`}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Autocomplete
          freeSolo
          onChange={(event, newValue) => onChange(event, newValue ?? '')}
          // onInputChange={(event, newValue) => onChange(event, newValue ?? '')}
          onBlur={onBlur}
          loading={loading}
          options={options}
          value={value}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.name) {
              return option.name;
            }
            return option.name;
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={({ ...props }) => (
            <TextField
              {...props}
              fullWidth
              placeholder={placeholder || 'Enter'}
              // placeholder="Enter"
              // name="polygonCropType"
              name={name || 'polygonCropType'}
              helperText={error ? helperText : ''}
              error={error}
              disabled={disabled}
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {props.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              sx={{
                mt: 0.5,
                'input, textarea': {
                  ...autoFillColor,
                  py: 0,
                  bgcolor: 'white',
                  color: 'info.dark',
                },
                '& .MuiInputBase-root': {
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.15)',
                  borderRadius: '10px',
                  py: 0,
                  px: 1,

                  border: '1px solid white',
                  borderColor: disabled ? 'info.light' : error ? 'red' : 'info.light',

                  bgcolor: disabled ? 'info.light' : 'white',
                  // height: props.multiline ? 'auto' : '42px',
                  minHeight: '42px',
                },
                '& .MuiFormHelperText-root': {
                  m: 0,
                  fontSize: 12,
                  fontWeight: 400,
                },
                fieldset: {
                  border: 'none !important',
                },
              }}
            />
          )}
        />
        <Box sx={{ position: 'absolute', top: 0, right: 0, width: 'auto', mt: 0.5 }}>{additionalInput}</Box>
      </Box>
    </Box>
  );
};
