import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { localStorageService } from 'shared/services';
import { userApi } from 'shared/api/UserApi';
import { handleApiError } from 'shared/lib/errorHandling';
import { UsersResponse } from 'shared/models';
import { filterUniqueByField } from 'shared/lib';

const ACTIVE_STATUS = 3;
const HYDRAULIC_TOOL_ID = 9999;

export const useSharingEmails = (open: boolean, lastUsedEmail: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [email, setEmail] = useState('');

  const getUsers = async () => {
    try {
      setIsLoading(true);

      const userOrganizationIds = JSON.parse(localStorageService.userOrganizationsIds || '[]') as number[];
      const userName = localStorageService.rapidUsername;

      if (!userOrganizationIds.length) {
        setEmails([]);
        toast.warn('User is not included in any organization');
        return;
      }

      if (isUserOnlyInHT(userOrganizationIds)) return; // if the user is only member of 9999(HT) he could not share the project

      const promises = userOrganizationIds
        .filter((orgId) => orgId !== HYDRAULIC_TOOL_ID) // exclude users from HT
        .map((orgId) => userApi.getUsersByOrganizationId(orgId));
      const result = await Promise.all(promises);

      const userOrganizations = result.reduce<UsersResponse[][]>((acc, { data: responseData }) => {
        acc.push(responseData.data);
        return acc;
      }, []);

      if (!userOrganizations.length) return;

      const usersRaw = userOrganizations.flat();

      const users = filterUniqueByField(usersRaw, 'name'); // remove duplicates

      const userMails = users
        .filter((u) => u.status === ACTIVE_STATUS)
        .filter((u) => {
          if (u.relatedOrganizationsIds) {
            return !(isUserOnlyInHT(u.relatedOrganizationsIds) || u.relatedOrganizationsIds.length === 0);
          }
          return false; // user should be at least in one organization
        })
        .map((u) => u.email);
      setEmails(userMails);
      // disable due client decision (could be changed in future)
      // setEmail(lastUsedEmail);
      setEmail('');
    } catch (e) {
      handleApiError(e);
      toast.error("Can't fetch users data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getUsers();
    }
  }, [open]);

  const onEmailChange = (email: string) => setEmail(email);

  return {
    email,
    onEmailChange,
    emails,
    isLoading,
  };
};

const isUserOnlyInHT = (organizationIds: number[]) => {
  return organizationIds.length === 1 && organizationIds.includes(HYDRAULIC_TOOL_ID);
};
