import { Box } from '@mui/material';
import { SVGProps } from 'react';

type Props = SVGProps<SVGElement>;

export const GpsIcon = ({ fill = '#fff' }: Props) => {
  return (
    <Box component={'a'} sx={{ display: 'inline-block', marginRight: '7px', '& svg': { verticalAlign: 'bottom' } }}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3120_3987)">
          <path
            d="M27.4167 13.4167H24.4708C24.3253 10.8417 23.2369 8.41047 21.4132 6.5868C19.5895 4.76313 17.1583 3.67469 14.5833 3.52917V0.583333C14.5833 0.428624 14.5219 0.280251 14.4125 0.170854C14.3031 0.0614582 14.1547 0 14 0C13.8453 0 13.6969 0.0614582 13.5875 0.170854C13.4781 0.280251 13.4167 0.428624 13.4167 0.583333V3.52917C10.8417 3.67469 8.41047 4.76313 6.5868 6.5868C4.76313 8.41047 3.67469 10.8417 3.52917 13.4167H0.583333C0.428624 13.4167 0.280251 13.4781 0.170854 13.5875C0.0614582 13.6969 0 13.8453 0 14C0 14.1547 0.0614582 14.3031 0.170854 14.4125C0.280251 14.5219 0.428624 14.5833 0.583333 14.5833H3.52917C3.67469 17.1583 4.76313 19.5895 6.5868 21.4132C8.41047 23.2369 10.8417 24.3253 13.4167 24.4708V27.4167C13.4167 27.5714 13.4781 27.7197 13.5875 27.8291C13.6969 27.9385 13.8453 28 14 28C14.1547 28 14.3031 27.9385 14.4125 27.8291C14.5219 27.7197 14.5833 27.5714 14.5833 27.4167V24.4708C17.1583 24.3253 19.5895 23.2369 21.4132 21.4132C23.2369 19.5895 24.3253 17.1583 24.4708 14.5833H27.4167C27.5714 14.5833 27.7197 14.5219 27.8291 14.4125C27.9385 14.3031 28 14.1547 28 14C28 13.8453 27.9385 13.6969 27.8291 13.5875C27.7197 13.4781 27.5714 13.4167 27.4167 13.4167ZM14 23.3333C8.85383 23.3333 4.66667 19.1462 4.66667 14C4.66667 8.85383 8.85383 4.66667 14 4.66667C19.1462 4.66667 23.3333 8.85383 23.3333 14C23.3333 19.1462 19.1462 23.3333 14 23.3333Z"
            fill={fill}
          />
          <path
            d="M13.9997 9.33331C11.426 9.33331 9.33301 11.4263 9.33301 14C9.33301 16.5736 11.426 18.6666 13.9997 18.6666C16.5733 18.6666 18.6663 16.5736 18.6663 14C18.6663 11.4263 16.5733 9.33331 13.9997 9.33331Z"
            fill={fill}
          />
          <path
            d="M27.4167 13.4167H24.4708C24.3253 10.8417 23.2369 8.41047 21.4132 6.5868C19.5895 4.76313 17.1583 3.67469 14.5833 3.52917V0.583333C14.5833 0.428624 14.5219 0.280251 14.4125 0.170854C14.3031 0.0614582 14.1547 0 14 0C13.8453 0 13.6969 0.0614582 13.5875 0.170854C13.4781 0.280251 13.4167 0.428624 13.4167 0.583333V3.52917C10.8417 3.67469 8.41047 4.76313 6.5868 6.5868C4.76313 8.41047 3.67469 10.8417 3.52917 13.4167H0.583333C0.428624 13.4167 0.280251 13.4781 0.170854 13.5875C0.0614582 13.6969 0 13.8453 0 14C0 14.1547 0.0614582 14.3031 0.170854 14.4125C0.280251 14.5219 0.428624 14.5833 0.583333 14.5833H3.52917C3.67469 17.1583 4.76313 19.5895 6.5868 21.4132C8.41047 23.2369 10.8417 24.3253 13.4167 24.4708V27.4167C13.4167 27.5714 13.4781 27.7197 13.5875 27.8291C13.6969 27.9385 13.8453 28 14 28C14.1547 28 14.3031 27.9385 14.4125 27.8291C14.5219 27.7197 14.5833 27.5714 14.5833 27.4167V24.4708C17.1583 24.3253 19.5895 23.2369 21.4132 21.4132C23.2369 19.5895 24.3253 17.1583 24.4708 14.5833H27.4167C27.5714 14.5833 27.7197 14.5219 27.8291 14.4125C27.9385 14.3031 28 14.1547 28 14C28 13.8453 27.9385 13.6969 27.8291 13.5875C27.7197 13.4781 27.5714 13.4167 27.4167 13.4167ZM14 23.3333C8.85383 23.3333 4.66667 19.1462 4.66667 14C4.66667 8.85383 8.85383 4.66667 14 4.66667C19.1462 4.66667 23.3333 8.85383 23.3333 14C23.3333 19.1462 19.1462 23.3333 14 23.3333ZM14 9.33333C11.4263 9.33333 9.33333 11.4263 9.33333 14C9.33333 16.5737 11.4263 18.6667 14 18.6667C16.5737 18.6667 18.6667 16.5737 18.6667 14C18.6667 11.4263 16.5737 9.33333 14 9.33333Z"
            fill="url(#paint0_linear_3120_3987)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_3120_3987"
            x1="2.45075"
            y1="8.6145"
            x2="25.5493"
            y2="19.3855"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill} stopOpacity="0.2" />
            <stop offset="1" stopColor={fill} stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3120_3987">
            <rect width="28" height="28" fill={fill} />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
