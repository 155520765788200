import { useRef } from 'react';

export const useThrottle = (cb: (c: any) => void, limit: number) => {
  const lastRun = useRef(Date.now());

  return (c: any) => {
    if (Date.now() - lastRun.current >= limit) {
      cb(c);
      lastRun.current = Date.now();
    }
  };
};
