import { styled } from '@mui/material';

export const StartMeasurement = styled('a')`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #fff;
  vertical-align: top;
  margin-right: 7px;
  &::after {
    content: '';
    position: absolute;
    display: block;
    inset: 2px;
    background-color: #1d8742;
    border-radius: 50%;
  }
`;
