import { Stack } from '@mui/material';
import { FC } from 'react';
import { ControlContainer } from '../index';
import { GpsStateButtons } from './GpsStateButtons';

type Props = {
  id: string;
};

export const DrawingControlGps: FC<Props> = ({ id }) => {
  return (
    <Stack
      id={id}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.68)',
        position: 'fixed',
        width: '100%',
        height: '68px',
        top: 'calc(100svh - 68px)',
        left: 0,
        zIndex: 1000,
      }}
    >
      <ControlContainer sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {<GpsStateButtons />}
      </ControlContainer>
    </Stack>
  );
};
