import { parseLinesFromServer, parsePointsFromServer, parsePolygonsFromServer } from 'shared/lib';
import { setLines, setPoints, setPolygons, setProject } from 'shared/slices';
import { Project, ProjectDataFromServer } from 'shared/models';
import { useAppDispatch } from 'shared/hooks';

export const useOpenProject = (project: Project) => {
  const dispatch = useAppDispatch();

  const handleOpenProject = () => {
    const polygons = parsePolygonsFromServer(project.project);
    const lines = parseLinesFromServer(project.project);
    const points = parsePointsFromServer(project.project);

    // temporary solution to smooth transition for projects that has no item counters
    project.project = initializeProjectItemCounters(project.project);

    dispatch(setProject(project));
    dispatch(setPolygons(polygons));
    dispatch(setLines(lines));
    dispatch(setPoints(points));
  };
  return { handleOpenProject };
};

const initializeProjectItemCounters = (project: ProjectDataFromServer) => {
  const projectData = project;
  if (!project?.pointCount) {
    projectData.pointCount = 1;
  }
  if (!project?.polygonCount) {
    projectData.polygonCount = 1;
  }
  if (!project?.lineCount) {
    projectData.lineCount = 1;
  }
  return projectData;
};
