import { Box, Typography } from '@mui/material';
import L, { LatLngExpression } from 'leaflet';
import { FC, useEffect } from 'react';
import { Marker, Polyline } from 'react-leaflet';
import { useGpsTracking } from '../../hooks/useGpsTracking';
import { Nullable } from '../../index';
import { GpsIcon } from '../Icons';

const cssLocationIcon = L.divIcon({
  html: `<div class="trackingPoint"/>`,
  iconSize: [12, 12],
  className: 'trackingPoint',
});

interface Props {
  coords: LatLngExpression | null;
  onClick?: (coords: LatLngExpression) => void;
}
export const UserLocationPoint: FC<Props> = ({ coords, onClick }) => {
  const { isShowTrackingLine, isGpsTrackingStarted, gpsTrackingPoints, geoCoords, trackingPoint, setTrackingPoint } =
    useGpsTracking();

  const isLowLocationAccuracy = isShowTrackingLine && geoCoords?.accuracy && geoCoords.accuracy > 6;

  useEffect(() => {
    setTrackingPoint(coords);
  }, [coords]);

  return (
    <>
      <Box
        sx={{
          'div:has(&) .trackingPoint': {
            animationIterationCount: isGpsTrackingStarted ? 'infinite' : '0',
          },
        }}
      >
        {trackingPoint && (
          <Marker
            icon={cssLocationIcon}
            position={trackingPoint}
            eventHandlers={{
              click() {
                onClick?.(trackingPoint);
              },
            }}
          />
        )}
      </Box>
      {isShowTrackingLine && <Polyline pathOptions={{ color: '#f00' }} positions={gpsTrackingPoints} />}
      {isLowLocationAccuracy && (
        <Typography
          sx={{
            textAlign: 'center',
            position: 'fixed',
            left: '50%',
            top: 'calc(100svh - 85%)',
            transform: 'translateX(-50%)',
            zIndex: 999,
            bgcolor: 'rgba(238 39 2 / 0.8)',
            borderRadius: '16px',
            padding: '12px 30px',
            color: 'info.main',
          }}
        >
          <GpsIcon />
          Low Accuracy
        </Typography>
      )}
    </>
  );
};

const GpsInfo = ({
  speed,
  accuracy,
  pointsQty,
}: {
  speed: Nullable<number>;
  accuracy: Nullable<number>;
  pointsQty: number;
}) => {
  return (
    <Box
      className="GPS INFO"
      sx={{
        position: 'fixed',
        left: '0',
        top: '100px',
        bgcolor: 'rgba(0 0 0 / .4)',
        border: '1px solid #fff',
        padding: '10px',
        zIndex: 999,
      }}
    >
      <Typography sx={{ color: '#fff', fontSize: '14px' }}>Speed: {speed?.toFixed(2)}</Typography>
      <Typography sx={{ color: '#fff', fontSize: '14px' }}>Accuracy: {accuracy?.toFixed(2)}</Typography>
      <Typography sx={{ color: '#fff', fontSize: '14px' }}>Points added : {pointsQty}</Typography>
    </Box>
  );
};
