import { AddToHome } from 'features/AddToHome';
import { ReloadModal } from 'features/ReloadModal';
import { FC, PropsWithChildren, useEffect } from 'react';
import { selectMasterGr, showAddToHome } from 'shared/slices';
import { Units } from '../../features';
import { LanguageSelector } from '../../features/LanguageSelector';
import { useAppDispatch, useMobileDetect } from '../../shared/hooks';
import { localStorageService } from '../../shared/services';

export const Modals: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const mobileInfo = useMobileDetect();

  const { loading: masterGroupsLoading } = selectMasterGr();

  useEffect(() => {
    const isMobile = (mobileInfo?.isAndroid() || mobileInfo?.isIos()) && mobileInfo?.standalone === false;

    if (!localStorageService.hasSeenAddToHome && isMobile) {
      dispatch(showAddToHome());
    }
  });

  return (
    <>
      {children}

      <ReloadModal />

      {!masterGroupsLoading ? (
        <>
          <Units />
          <AddToHome />
          <LanguageSelector />
          {/* Disable screenshotting temporary */}
          {/*<ScreenshottingModal />*/}
        </>
      ) : null}
    </>
  );
};
