import { CircularProgress, ListItem, ListItemButton, ListItemIcon, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { CatalogUserIcon } from 'shared/ui/Icons/CatalogUserIcon';
import { axiosBase } from '../../../../../shared/axios';
import { ProjectPermission } from '../../../../../shared/enums';
import { BaseResponse, SharedProject, SharedWithUsers } from '../../../../../shared/models';
import { Row } from '../../../../../shared/ui';
import { ProjectManagePermissionModal } from '../../ProjectManagePermissionModal';

type Props = {
  item: SharedWithUsers;
  projectId: number;
  revalidateSharedWithUsers: (data: SharedWithUsers[]) => void;
};

export const SharedWithUser: React.FC<Props> = ({ item, projectId, revalidateSharedWithUsers }) => {
  const isMobile = useMediaQuery('(max-width:400px)');
  const [isManagePermissionModal, setIsManagePermissionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleManagePermissionModal = () => setIsManagePermissionModal((prev) => !prev);

  const onSubmit = async (permission: ProjectPermission) => {
    setIsLoading(true);
    try {
      const payload = {
        organizationId: 0,
        userId: item.userId,
        permissionType: permission,
        projectId,
      };
      const { data: updatedProject } = await axiosBase.put<BaseResponse<SharedProject>>(
        '/api/Project/share-project',
        payload
      );
      toggleManagePermissionModal();
      revalidateSharedWithUsers(updatedProject.data.sharedWithUsers);
    } catch (err) {
      console.error('share error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ListItem
        disablePadding
        sx={{
          px: '12px',
          '&:hover': { backgroundColor: 'rgba(29, 135, 69, 0.16)' },
        }}
      >
        <ListItemButton
          disableGutters
          onClick={toggleManagePermissionModal}
          disabled={isLoading}
          sx={{
            display: 'block',
            borderBottom: '1px solid grey',
            borderColor: 'info.light',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <Row sx={{ py: '4px' }}>
            <Row>
              <ListItemIcon sx={{ minWidth: '45px' }}>
                <CatalogUserIcon />
              </ListItemIcon>
              <Typography
                sx={{
                  color: '#242731)',
                  fontSize: '16px',
                  lineHeight: '130%',
                }}
              >
                {item.userName}
              </Typography>
            </Row>
            <Typography
              sx={{
                color: ' #1D8742',
                fontSize: isMobile ? '12px' : '16px',
                lineHeight: '130%',
              }}
            >
              {/*split string by capital letters*/}
              {ProjectPermission[item?.permissionType]?.split(/(?=[A-Z])/).join(' ')}
            </Typography>
          </Row>
        </ListItemButton>
        {isLoading && <CircularProgress size={12} sx={{ m: 0.5 }} />}
      </ListItem>
      <ProjectManagePermissionModal
        open={isManagePermissionModal}
        onClose={toggleManagePermissionModal}
        onSubmit={onSubmit}
        loading={isLoading}
        userName={item.userName}
        email={item.email}
        userPermission={item.permissionType}
      />
    </>
  );
};
