import { FormControlLabel, Radio as MuiRadio, RadioProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props extends RadioProps {
  label?: ReactNode;
  radioColor?: string;
}

export const Radio: FC<Props> = ({ value, label, checked, sx, radioColor }) => {
  return (
    <FormControlLabel
      label={label}
      value={value}
      checked={checked}
      sx={{
        ml: 0,
        '& .MuiTypography-root': {
          color: checked ? 'primary.main' : 'secondary.dark',
          fontWeight: 500,
          userSelect: 'none',
          p: 0,
          ml: 1,
        },
        ...sx,
      }}
      control={<MuiRadio sx={{ p: '3px 0px', color: radioColor }} />}
    />
  );
};
